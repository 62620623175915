import { ANALYTICS_DASHBOARD_MARGIN } from 'style/dimensions';

export default theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    columnGap: 38,
    marginRight: ANALYTICS_DASHBOARD_MARGIN,
    '&[data-is-realtime="false"]': {
      gridTemplateColumns: '2fr 1fr 1fr',
      columnGap: 18
    }
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.03em',
    color: theme.grey1
  },
  exportWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.grey2,
    '& > svg': {
      width: 18,
      height: 18,
      marginRight: 6
    }
  },
  exportLabel: {
    fontSize: 12,
    lineHeight: '15px',
    textAlign: 'right'
  },
  selectWorkflowButton: {
    '& > button': {
      height: 40,
      borderRadius: 5,
      borderColor: 'transparent',
      boxShadow: theme.indigoShadow1,
      width: '100%',
      color: theme.grey1,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
      background: theme.white1,
      '& > span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },
  pulldownMenuItem: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.grey5,
    '&[data-is-selected=true]': {
      '& > li': {
        color: theme.grey1,
        background: theme.grey6
      },
      '& > li > button > span': {
        fontWeight: 600,
        color: theme.grey1
      },
      '& > li > button:hover': {
        cursor: 'unset'
      }
    }
  }
});
