const flexRowBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  width: '50%'
};

export default () => ({
  root: {
    extend: flexRowBetween,
    height: '100%'
  },
  leftColumn: {
    extend: flexColumn,
    marginRight: 18
  },
  topRowLeftColumn: {
    extend: flexRowBetween,
    height: 97,
    marginBottom: 20
  },
  kpiCardLeft: {
    width: '100%',
    marginRight: 18
  },
  kpiCardRight: {
    width: '100%'
  },
  rightColumn: {
    extend: flexColumn
  }
});
