import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import { Icon } from '@stratumn/atomic';

import { getByPath } from 'utils/widgets';

import { Widget } from 'components/ui/widget';

import styles from './keyValue.style';

// localstorage functions to store the collapsed state for each trace and each

const localStorageKey = 'keyValueArraysCollapsed';

const storeCollapsedState = (traceId, arrayKey, collapsedState) => {
  let storage;
  try {
    // Check if storage is a valid parsable JSON string
    storage = JSON.parse(localStorage.getItem(localStorageKey));
    // If no error, check if it is not null and that it is a real object (and not an array since the type of an array is 'object' in javascript)
    storage =
      !!storage && typeof storage === 'object' && !Array.isArray(storage)
        ? storage // If yes keep the value
        : {}; // If not, set the value as an empty object
  } catch {
    // If there is an error (this would happen at the JSON.parse), set the value as an empty object
    storage = {};
  }
  // Create the trace's key as an empty object if it doesn't exist yet
  storage[traceId] = storage[traceId] || {};
  // Store the collapsed state of the array's key
  storage[traceId][arrayKey] = collapsedState;
  // Store the complete object as a string in the localStorage key
  localStorage.setItem(localStorageKey, JSON.stringify(storage));
};

const getCollapsedState = (traceId, arrayKey) => {
  try {
    const storage = JSON.parse(localStorage.getItem(localStorageKey));
    return storage?.[traceId]?.[arrayKey];
  } catch {
    return undefined;
  }
};

// KeyValue widget component
export const KeyValueView = React.memo(
  ({ classes, view, data, setSelectableItemData }) => {
    const { key, value } = view;

    const dataValueByPath = value.view.path && getByPath(data, value.view.path);

    useEffect(() => {
      if (setSelectableItemData) setSelectableItemData(dataValueByPath);
    }, [dataValueByPath]);

    // If the path doesn't return any value, then return null
    if (dataValueByPath === undefined) {
      return null;
    }

    const {
      trace: { rowId: traceId }
    } = data;

    // Check if value is an array and if it should be collapsed (when the array contains more than 1 item)
    const isArray =
      value.view.type === 'array' && Array.isArray(dataValueByPath);
    const isCollapsable = isArray && dataValueByPath.length > 1;

    // Collapsed State variable
    const [isCollapsed, setIsCollapsed] = useState(() => {
      const localStorageState = getCollapsedState(traceId, key);
      return localStorageState !== undefined
        ? localStorageState
        : isCollapsable;
    });

    // Event handler
    const toggleCollapse = () => {
      storeCollapsedState(traceId, key, !isCollapsed);
      setIsCollapsed(!isCollapsed);
    };

    return (
      <div className={classes.keyValueRoot}>
        <div
          className={classnames(classes.titleWrapper, {
            [classes.collapsableTitleWrapper]: isCollapsable
          })}
          onClick={isCollapsable ? toggleCollapse : undefined}
        >
          <div className={classes.title} title={key}>
            {key}
          </div>
          {isCollapsable && (
            <div className={classes.icon} data-is-collapsed={isCollapsed}>
              <Icon name="ArrowDownFill" />
            </div>
          )}
        </div>
        <div
          className={classnames({
            [classes.collapsedContentWrapper]: isCollapsed
          })}
        >
          <Widget widget={value} data={data} className={classes.value} />
        </div>
      </div>
    );
  }
);

KeyValueView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setSelectableItemData: PropTypes.func
};

KeyValueView.defaultProps = {
  setSelectableItemData: undefined
};

export default {
  component: injectSheet(styles)(KeyValueView)
};
