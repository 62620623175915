import {
  ATOMIC_HEADER_HEIGHT,
  TAB_HEIGHT,
  TABS_WRAPPER_WIDTH
} from 'style/dimensions';

export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: ATOMIC_HEADER_HEIGHT - TAB_HEIGHT / 2, // center the menu in the left side bar
    width: TABS_WRAPPER_WIDTH,
    minWidth: TABS_WRAPPER_WIDTH,
    height: '100%',
    backgroundColor: theme.white1,
    fontSize: 9,
    textTransform: 'uppercase',
    boxShadow: theme.indigoShadow1,
    position: 'fixed',
    zIndex: 1
  },
  tabsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    /**
     * The height adapts to the numbers of tabs provided.
     * (aka tabMenu children)
     */
    height: props => TAB_HEIGHT * props.children[0].length || 'unset'
  },
  slider: {
    position: 'absolute',
    top: 0,
    borderLeft: `8px solid ${theme.teal1}`,
    height: TAB_HEIGHT,
    transition: 'top 200ms'
  }
});
