import React, { Component } from 'react';
import PropType from 'prop-types';
import { compose } from 'react-apollo';
import { Popover, PopoverMenuItem } from '@stratumn/atomic';
import { SignOut, Profile } from '@stratumn/icons';

import { logout } from 'client/account';
import { withUser } from 'contexts';
import { ROUTE_LOGIN } from 'constant/routes';
import { ACCOUNT_URL } from 'constant/api';
import { history } from 'components/root';

export class ProfileButton extends Component {
  static propTypes = {
    user: PropType.object.isRequired,
    avatarSize: PropType.number
  };

  static defaultProps = {
    avatarSize: null
  };

  goToProfile = () => {
    window.open(ACCOUNT_URL);
  };

  signOut = async () => {
    await logout();
    history.push(ROUTE_LOGIN);
  };

  getMenuItems = () => [
    {
      label: 'Profile',
      icon: <Profile />,
      onClick: this.goToProfile
    },
    {
      label: 'Sign out',
      icon: <SignOut />,
      onClick: this.signOut
    }
  ];

  render = () => {
    const {
      user: { loading, me },
      avatarSize
    } = this.props;

    const items = loading ? [] : this.getMenuItems();
    const avatarSrc = loading ? '' : me && me.avatar;

    return (
      <Popover
        avatarButton
        avatarSize={avatarSize}
        avatarSrc={avatarSrc}
        loading={loading}
      >
        {items.map(item => (
          <PopoverMenuItem
            onClick={item.onClick}
            icon={item.icon}
            key={item.label}
          >
            {item.label}
          </PopoverMenuItem>
        ))}
      </Popover>
    );
  };
}

export default compose(withUser)(ProfileButton);
