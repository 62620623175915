import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { parse } from '@stratumn/canonicaljson';
import { Pushtext, Icon, FormReader } from '@stratumn/atomic';
import { EVIDENCE_BATCH_FOSSILIZER } from 'constant/chainscript';

import theme from 'style';

import DataActionReader from 'components/ui/dataActionReader';

import fossilizingImg from 'assets/fossilizing.svg';
import WhoDidWhatWhen from './whoDidWhatWhen';
import References from '../references';

import { countFn as count } from '../count';
import styles from './segmentDetail.style';

export class SegmentDetail extends Component {
  static propTypes = {
    traceId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    linkCreatedByAccount: PropTypes.object.isRequired,
    workflowContext: PropTypes.object.isRequired
  };

  state = {
    evidencesHidden: true,
    referencesHidden: true
  };

  renderAction = () => {
    const {
      link: { action }
    } = this.props;
    return action ? action.title : null;
  };

  handleEvidenceClick = () => {
    this.setState(prevState => ({
      evidencesHidden: !prevState.evidencesHidden
    }));
  };

  renderEvidenceButton = () => {
    const { classes } = this.props;
    const { evidencesHidden } = this.state;

    return (
      <Pushtext
        prefix={<Icon name="Blockchain" size={24} color={theme.indigo3} />}
        onClick={this.handleEvidenceClick}
        aria-expanded={!evidencesHidden}
      >
        <p className={classes.evidenceText}>{`${
          evidencesHidden ? 'Show' : 'Hide'
        } blockchain info`}</p>
      </Pushtext>
    );
  };

  renderBatchEvidence = evidence => {
    const proof = parse(Buffer.from(evidence.proof.proof, 'base64').toString());
    return this.renderProof(proof, evidence.provider);
  };

  renderProof = (proof, provider) => {
    const {
      classes,
      link: { linkHash }
    } = this.props;

    const network = provider.includes('test') ? 'btc-testnet' : 'btc';
    const explorerURL = `https://live.blockcypher.com/${network}/tx/${proof.txid}`;

    return (
      <div
        className={classes.evidences}
        key={proof.txid}
        ref={e => {
          this.evidencesRef = e;
        }}
      >
        <dl className={classes.evidence}>
          <dt className={classes.evidenceTitle}>Event hash:</dt>
          <dd className={classes.evidenceData}>{linkHash}</dd>
        </dl>
        <dl className={classes.evidence}>
          <dt className={classes.evidenceTitle}>Blockchain:</dt>
          <dd className={classes.evidenceData}>
            This event hash is independently verifiable on the Bitcoin public
            blockchain. Check on{' '}
            <a href={explorerURL} className={classes.outsideLink}>
              blockcypher.com
              <Icon name="Link" size={18} color={theme.indigo3} />
            </a>
          </dd>
        </dl>
        <dl className={classes.evidence}>
          <dt className={classes.evidenceTitle}>Transfer ID:</dt>
          <dd className={classes.evidenceData}>{proof.txid}</dd>
        </dl>
      </div>
    );
  };

  renderEvidenceLoading = () => {
    const { classes } = this.props;
    return (
      <div
        className={classes.evidenceLoadingContainer}
        ref={e => {
          this.evidencesRef = e;
        }}
      >
        <img src={fossilizingImg} alt="" className={classes.fossilizingImg} />
        <div className={classes.evidenceLoadingText}>
          The blockchain info for this action is not yet ready. Actions are
          normally recorded every half hour.
        </div>
      </div>
    );
  };

  renderEvidences = () => {
    const {
      link: { evidences }
    } = this.props;

    if (!evidences.totalCount) return this.renderEvidenceLoading();

    return (
      <>
        {evidences.nodes.map(e => {
          switch (e.backend) {
            case EVIDENCE_BATCH_FOSSILIZER:
              return this.renderBatchEvidence(e);
            default:
              return null;
          }
        })}
      </>
    );
  };

  renderEncryptedNote = () => {
    const { classes } = this.props;

    return (
      <div className={classes.decryptionNote}>
        <dl>
          <dt>
            Action encrypted <Icon size={24} name="Key" />
          </dt>
        </dl>
      </div>
    );
  };

  renderFormBody = (action, data) => {
    const {
      link: { group, createdAt },
      linkCreatedByAccount,
      workflowContext
    } = this.props;

    if (!action) {
      // Fallback on displaying the JSON data if
      // action is not set for some reason.
      return <pre>{JSON.stringify(data, null, 2)}</pre>;
    }

    const { title, description } = action;

    const schema = action.form
      ? action.form.schema
      : {
          type: 'object',
          title: action.title,
          description: action.description
        };
    const uiSchema = action.form ? action.form.uiSchema : {};
    const { name } = linkCreatedByAccount?.entity || group;

    return (
      <FormReader
        title={title}
        description={description}
        schema={schema}
        uiSchema={uiSchema}
        formData={data}
        hideTitle={false}
        hideFiles={false}
        who={name}
        when={createdAt}
        workflowContext={workflowContext}
      />
    );
  };

  renderDataToolBody = (action, data) => (
    <DataActionReader action={action} data={data} />
  );

  renderBody = () => {
    const {
      link: { data, action },
      classes
    } = this.props;

    // If data is of type string, it means it contains encrypted data.
    if (typeof data === 'string') {
      return this.renderEncryptedNote();
    }

    const isDataTool = action && (action.dataImporter || action.dataEditor);

    return (
      <div className={classes.attestation}>
        {isDataTool
          ? this.renderDataToolBody(action, data)
          : this.renderFormBody(action, data)}
      </div>
    );
  };

  handleReferencesClick = () => {
    this.setState(prevState => ({
      referencesHidden: !prevState.referencesHidden
    }));
  };

  componentDidUpdate = prevProps => {
    if (this.props.link.height !== prevProps.link.height) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        referencesHidden: true
      });
    }
  };

  render = () => {
    const { link, linkCreatedByAccount, classes, traceId } = this.props;
    const { evidencesHidden, referencesHidden } = this.state;
    const evidencesHeight =
      !evidencesHidden && this.evidencesRef
        ? this.evidencesRef.scrollHeight
        : 0;

    return (
      <div className={classes.root}>
        <div className={classes.step}>Action {count(link.height)}</div>
        <div className={classes.titleRow}>
          <div className={classes.what}>{this.renderAction()}</div>
          {this.renderEvidenceButton()}
        </div>
        <div className={classes.container}>
          <div
            className={classes.evidenceContainer}
            aria-hidden={evidencesHidden}
            style={{ height: evidencesHeight }}
          >
            {this.renderEvidences()}
          </div>
          <References
            link={link}
            traceId={traceId}
            collapsed={referencesHidden}
            handleClick={this.handleReferencesClick}
          />
          <WhoDidWhatWhen
            link={link}
            linkCreatedByAccount={linkCreatedByAccount}
          />
          {this.renderBody()}
        </div>
      </div>
    );
  };
}

export default injectSheet(styles)(SegmentDetail);
