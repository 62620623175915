import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Skeleton from '../../../skeleton';
import {
  pixelToPercentConvertion,
  SMALL_PROPS,
  MEDIUM_PROPS
} from '../../../skeleton/utils';

import style from './barChartSkeleton.style';

export const BarChartSkeleton = ({ classes }) => {
  const SkeletonRowLeft = () => (
    <div
      className={classnames(classes.skeletonCardGroup, classes.skeletonLarge)}
    >
      <Skeleton
        height={18}
        width={pixelToPercentConvertion(247, 18)}
        {...SMALL_PROPS}
      />
      <Skeleton
        height={14}
        width={pixelToPercentConvertion(247, 167)}
        {...SMALL_PROPS}
      />
    </div>
  );

  const SkeletonRowMiddle = () => (
    <>
      <Skeleton
        height={18}
        width={pixelToPercentConvertion(247, 247)}
        {...MEDIUM_PROPS}
      />
      <Skeleton
        height={18}
        width={pixelToPercentConvertion(247, 139)}
        {...MEDIUM_PROPS}
      />
      <Skeleton
        height={18}
        width={pixelToPercentConvertion(247, 192)}
        {...MEDIUM_PROPS}
      />
    </>
  );

  const SkeletonRowRight = () => (
    <div
      className={classnames(classes.skeletonCardGroup, classes.skeletonLarge)}
      data-is-right
    >
      <Skeleton
        height={18}
        width={pixelToPercentConvertion(247, 18)}
        {...SMALL_PROPS}
      />
    </div>
  );

  return (
    <div className={classes.skeletonCardThree}>
      <div
        className={classnames(classes.skeletonCardGroup, classes.skeletonLarge)}
      >
        <div
          className={classnames(classes.firstColumn, classes.skeletonMedium)}
        >
          {Array(9)
            .fill('')
            .map((el, i) => (
              <SkeletonRowLeft key={i} />
            ))}
        </div>
        <div
          className={classnames(classes.secondColumn, classes.skeletonMedium)}
        >
          {Array(3)
            .fill('')
            .map((el, i) => (
              <SkeletonRowMiddle key={i} />
            ))}
        </div>
        <div
          className={classnames(classes.firstColumn, classes.skeletonMedium)}
        >
          {Array(9)
            .fill('')
            .map((el, i) => (
              <SkeletonRowRight key={i} />
            ))}
        </div>
      </div>
    </div>
  );
};

BarChartSkeleton.propTypes = {
  classes: PropType.object.isRequired
};

export default injectSheet(style)(BarChartSkeleton);
