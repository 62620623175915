export default theme => ({
  headerButton: {
    color: theme.grey2,
    display: 'block',
    width: 28,
    height: 28
  },
  headerIcon: {
    display: 'block',
    width: 'inherit',
    height: 'inherit',
    fill: theme.grey2,
    cursor: 'pointer'
  },
  container: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20
  },
  containerHolder: {
    width: '100%',
    height: '100%'
  },
  downloadData: {
    position: 'absolute',
    top: 10,
    right: 0,
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    width: 400,
    justifyContent: 'space-between'
  }
});
