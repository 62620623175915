import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Switch } from '@stratumn/atomic';
import { formatNumber } from 'utils';
import { ENABLE_SUB_TOTAL_AGGREGATION } from 'constant/featureFlags';

import styles from './tableHeaders.style';

import TableHeaderElement, {
  HEADER_CHECKBOX,
  HEADER_CHECKBOX_INDETERMINATE
} from '../tableHeaderElement';

import DisplayMenu from '../displayMenu';
import SavedViewsMenu from '../savedViewsMenu';

import { getAggregationConfig } from '../aggregationRow';

const columnsHaveAggregationSetup = columnsDisplayConfig =>
  !!columnsDisplayConfig &&
  columnsDisplayConfig.some(column => !!getAggregationConfig(column));

// table headers
const TableHeaders = React.memo(
  ({
    classes,
    toggleFilters,
    isEditing,
    toggleEdition,
    tableConfig,
    displayConfig,
    toggleColumnSelection,
    setGroupBy,
    nbRows,
    currentNbRows,
    update,
    displayDiffs,
    toggleDisplayDiffs,
    onExport,
    showAggregation,
    toggleShowAggregation,
    nbRowsSelected,
    toggleSelectAll,
    resetDisplayConfig,
    shrinkRightIcons,
    saveConfig,
    updateUserDisplayConfig
  }) => {
    const [showDisplayMenu, setShowDisplayMenu] = useState(false);
    const [showSavedViewsMenu, setShowSavedViewsMenu] = useState(false);

    const {
      onClickConfig,
      configClicked,
      onClickNew,
      newClicked,
      onClickUpdate,
      updateClicked,
      edit: { allowDisplayDiffs, onClickBatchEdit, batchEditClicked } = {},
      pagination,
      group
    } = update;

    const { allowColumnsSelection = true } = tableConfig;
    const {
      applyFilters: showFilters = false,
      fixedColumns,
      columns
    } = displayConfig;

    const toggleShowDisplayMenu = useCallback(() => {
      setShowDisplayMenu(!showDisplayMenu);
    }, [showDisplayMenu]);

    const toggleShowSavedViewsMenu = useCallback(() => {
      setShowSavedViewsMenu(!showSavedViewsMenu);
    }, [showSavedViewsMenu]);

    const hasAggregationSetup = useMemo(
      () =>
        tableConfig.aggregation ||
        columnsHaveAggregationSetup(fixedColumns) ||
        columnsHaveAggregationSetup(columns),
      [fixedColumns, columns]
    );

    return (
      <div className={classes.tableHeader}>
        <div className={classes.tableHeaderGroup} data-is-left>
          {[
            // allow select all only if onClickUpdate or onClickBatchEdit function are provided
            // AND there is no pagination or grouping
            (onClickUpdate || onClickBatchEdit) && !pagination && !group && (
              <TableHeaderElement
                label={`${formatNumber(nbRowsSelected)} rows selected`}
                icon={
                  nbRowsSelected === nbRows
                    ? HEADER_CHECKBOX
                    : HEADER_CHECKBOX_INDETERMINATE
                }
                clicked={nbRowsSelected > 0}
                onClick={toggleSelectAll}
              />
            ),
            nbRows > 0 && allowDisplayDiffs && (
              <TableHeaderElement
                label="Initial values"
                icon="PasswordHide"
                iconIfClicked="PasswordShow"
                clicked={displayDiffs}
                onClick={toggleDisplayDiffs}
              />
            ),
            onClickNew && (
              <TableHeaderElement
                label="New"
                icon="AddCircle"
                clicked={newClicked}
                onClick={onClickNew}
              />
            ),
            onClickUpdate && (
              <TableHeaderElement
                label="Update"
                icon="CircleUpload"
                clicked={updateClicked}
                onClick={onClickUpdate}
              />
            ),
            onClickBatchEdit && nbRowsSelected > 0 && (
              <TableHeaderElement
                label="Edit selection"
                icon="Pen"
                clicked={batchEditClicked}
                onClick={onClickBatchEdit}
              />
            )
          ].map((tableHeaderElement, index, arr) => (
            <React.Fragment key={index}>
              {!!index && arr[index - 1] && (
                <div className={classes.tableHeaderElementSeparator} />
              )}
              {tableHeaderElement}
            </React.Fragment>
          ))}
        </div>
        <div className={classes.tableHeaderGroup} data-is-right>
          {
            // allow aggregation only if there is no pagination or grouping
            // and if aggregation has been setup on at least one column
            !pagination &&
              !group &&
              hasAggregationSetup &&
              ENABLE_SUB_TOTAL_AGGREGATION && (
                <>
                  <Switch
                    label={
                      <div className={classes.showAggregationLabel}>
                        {tableConfig.aggregation?.label || 'Show sub-totals'}
                      </div>
                    }
                    showLabel
                    invert
                    on={showAggregation}
                    handleChange={toggleShowAggregation}
                    dataCy="toggle-show-aggregation"
                  />
                  <div className={classes.tableHeaderElementSeparator} />
                </>
              )
          }
          <div className={classes.rowNumberStyle}>
            {currentNbRows} {currentNbRows > 1 ? 'rows' : 'row'}{' '}
          </div>
          <div className={classes.tableHeaderGroupSeparator} />
          <TableHeaderElement
            label={shrinkRightIcons ? null : 'Export'}
            icon="Download"
            clicked={false}
            onClick={onExport}
            tooltip="Export current table data"
          />
          <div className={classes.tableHeaderGroupSeparator} />
          <TableHeaderElement
            label={shrinkRightIcons ? null : 'Filter rows'}
            icon="Filter"
            clicked={showFilters}
            onClick={toggleFilters}
            tooltip={`${showFilters ? 'Disable' : 'Apply'} filters${
              !pagination ? `\nCurrently showing ${nbRows} rows` : ''
            }`}
          />
          <div className={classes.tableHeaderElementSeparator} />
          <TableHeaderElement
            label={shrinkRightIcons ? null : 'Customize layout'}
            icon="CustomizeRuler"
            clicked={isEditing}
            onClick={toggleEdition}
            tooltip="Reorder columns, as well as resize columns width and rows height"
          />
          {allowColumnsSelection && (
            <>
              <div className={classes.tableHeaderElementSeparator} />
              <div>
                <TableHeaderElement
                  label={shrinkRightIcons ? null : 'Display'}
                  icon="TableColumns"
                  clicked={showDisplayMenu}
                  onClick={toggleShowDisplayMenu}
                  tooltip="Select the columns you want to display"
                />
                {showDisplayMenu && (
                  <div className={classes.tableHeaderDisplayWrapper}>
                    <div
                      className={classes.displayMenuBackground}
                      onClick={toggleShowDisplayMenu}
                    />
                    <div className={classes.displayMenuWrapper}>
                      <DisplayMenu
                        tableConfig={tableConfig}
                        displayConfig={displayConfig}
                        toggleColumnSelection={toggleColumnSelection}
                        setGroupBy={setGroupBy}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {
            // Reset button
            resetDisplayConfig && (
              <>
                <div className={classes.tableHeaderElementSeparator} />
                <TableHeaderElement
                  label={shrinkRightIcons ? null : 'Reset'}
                  icon="Reset"
                  clicked={false}
                  onClick={resetDisplayConfig}
                  tooltip="Reset initial table view"
                />
              </>
            )
          }
          {allowColumnsSelection && (
            <>
              <div className={classes.tableHeaderGroupSeparator} />
              <div>
                <TableHeaderElement
                  label={shrinkRightIcons ? null : 'Saved views'}
                  icon="Save"
                  clicked={showSavedViewsMenu}
                  onClick={toggleShowSavedViewsMenu}
                  tooltip="Create new table views or load existing ones"
                />
                {showSavedViewsMenu && (
                  <div className={classes.tableHeaderDisplayWrapper}>
                    <div
                      className={classes.displayMenuBackground}
                      onClick={toggleShowSavedViewsMenu}
                    />
                    <div className={classes.displayMenuWrapper}>
                      <SavedViewsMenu
                        saveConfig={saveConfig}
                        updateUserDisplayConfig={updateUserDisplayConfig}
                        resetDisplayConfig={resetDisplayConfig}
                        setVisible={setShowSavedViewsMenu}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {onClickConfig && (
            <>
              <div className={classes.tableHeaderGroupSeparator} />
              <TableHeaderElement
                label={shrinkRightIcons ? null : 'Config'}
                icon="Settings"
                clicked={configClicked}
                onClick={onClickConfig}
              />
            </>
          )}
        </div>
      </div>
    );
  }
);
TableHeaders.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleFilters: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEdition: PropTypes.func.isRequired,
  tableConfig: PropTypes.object.isRequired,
  displayConfig: PropTypes.object.isRequired,
  toggleColumnSelection: PropTypes.func.isRequired,
  setGroupBy: PropTypes.func.isRequired,
  nbRows: PropTypes.number.isRequired,
  currentNbRows: PropTypes.number,
  update: PropTypes.object.isRequired,
  displayDiffs: PropTypes.bool.isRequired,
  toggleDisplayDiffs: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  showAggregation: PropTypes.bool,
  toggleShowAggregation: PropTypes.func.isRequired,
  nbRowsSelected: PropTypes.number.isRequired,
  toggleSelectAll: PropTypes.func.isRequired,
  resetDisplayConfig: PropTypes.func,
  shrinkRightIcons: PropTypes.bool,
  saveConfig: PropTypes.func,
  updateUserDisplayConfig: PropTypes.func
};
TableHeaders.defaultProps = {
  showAggregation: false,
  resetDisplayConfig: null,
  shrinkRightIcons: false,
  currentNbRows: null,
  saveConfig: null,
  updateUserDisplayConfig: null
};

export default injectSheet(styles)(TableHeaders);
