import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import pathToRegexp from 'path-to-regexp';
import qs from 'query-string';

import { REFERENCES, REFERENCED_BY } from 'constant/chainscript';
import { ROUTE_INSPECT_TRACE, ROUTE_WORKFLOW_BOARD } from 'constant/routes';

import styles from './reference.style';

export const fragment = gql`
  fragment ReferenceFragment on Link {
    linkHash
    height
    actionKey
    traceId
    workflow {
      rowId
      name
    }
  }
`;

export const Reference = ({
  classes,
  link,
  traceId,
  refCount,
  workflowId,
  referenceType
}) => {
  const step = link.height < 10 ? `0${link.height}` : link.height;

  const hrefTrace = `${pathToRegexp.compile(ROUTE_INSPECT_TRACE)({
    id: link.traceId
  })}`;
  const hrefWorkflow = `${pathToRegexp.compile(ROUTE_WORKFLOW_BOARD)({
    id: link.workflow.rowId
  })}`;

  const Prefix = () => {
    let text = '';

    if (refCount === 1) {
      text += 'this action ';
    }

    if (referenceType === REFERENCES) {
      text += 'references';
    } else {
      text += 'referenced by';
    }

    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const Step = () => (
    <a
      href={`${hrefTrace}?${qs.stringify({ height: link.height })}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`${link.actionKey}, action ${step}`}
    </a>
  );

  const Trace = () => (
    <a href={hrefTrace} target="_blank" rel="noopener noreferrer">
      Trace {link.traceId.substring(0, 6)}
    </a>
  );

  const Workflow = () => (
    <a href={hrefWorkflow} target="_blank" rel="noopener noreferrer">
      {link.workflow.name}
    </a>
  );

  if (link.traceId === traceId) {
    return (
      <span className={classes.reference}>
        <Prefix /> <Step /> of this Trace.
      </span>
    );
  }

  if (link.workflow.rowId === workflowId) {
    return (
      <span className={classes.reference}>
        <Prefix /> <Step /> of <Trace /> in this workflow.
      </span>
    );
  }

  return (
    <span className={classes.reference}>
      <Prefix /> <Step /> of <Trace /> in workflow <Workflow />.
    </span>
  );
};

Reference.propTypes = {
  classes: PropType.object.isRequired,
  link: PropType.object.isRequired,
  traceId: PropType.string.isRequired,
  workflowId: PropType.string.isRequired,
  refCount: PropType.number.isRequired,
  referenceType: PropType.oneOf([REFERENCES, REFERENCED_BY]).isRequired
};

export default injectSheet(styles)(Reference);
