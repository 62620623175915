export default theme => ({
  tableCellWrapper: {
    '&[data-is-selected=true]': {
      backgroundColor: theme.indigoPastel3,
      '& $firstColumnWrapper': {
        borderRight: '1px solid white'
      },
      '& $tableCell': {
        borderRight: '1px solid white',
        '&[data-is-focus=true]': {
          border: `2px solid ${theme.indigo3}`
        }
      }
    },
    '&[data-is-evenrow=true]': {
      backgroundColor: '#F8F8F8',
      '&[data-is-selected=true]': {
        backgroundColor: theme.indigoPastel2
      }
    },
    '&[data-is-patched=true]': {
      fontWeight: 'bold'
    }
  },
  firstColumnWrapper: {
    borderRight: `1px solid ${theme.grey6}`,
    width: '100%',
    height: '100%',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  rowIndex: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  groupButtonFiller: {
    width: 29
  },
  checkFiller: {
    width: 18
  },
  tableCell: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '3px 6px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'default',
    borderRight: `1px solid ${theme.grey6}`,
    '&[data-is-hoverable=true]': {
      cursor: 'pointer'
    },
    '&[data-is-hoverable=true]:hover, &[data-is-focus=true]': {
      padding: '1px 5px 1px 4px',
      border: `2px solid ${theme.indigo3}`,
      borderRadius: 1
    }
  },
  tableCellWithDiffWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
    width: '100%'
  },
  tableCellDiffItem: {
    position: 'relative',
    flexBasis: '50%',
    '&[data-initial-item=true]': {
      color: theme.grey4,
      fontWeight: 'normal'
    }
  },
  tableCellPatchIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 5,
    height: '100%',
    backgroundColor: theme.teal1,
    '&[data-initial-item=true]': {
      backgroundColor: theme.grey4
    }
  }
});
