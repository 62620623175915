import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import Tooltip from '../../../utils/tooltip';

import styles from './tooltipWrapper.style';

const TooltipWrapper = React.memo(
  ({
    classes,
    children,
    title,
    body,
    position,
    delay,
    withArrow,
    setClientFocus
  }) => {
    const cellRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    return (
      <>
        <div
          ref={cellRef}
          onMouseEnter={() => {
            setShowTooltip(true);
            setClientFocus(true);
          }}
          onMouseLeave={() => {
            setShowTooltip(false);
            setClientFocus(false);
          }}
          className={classes.tooltipWrapper}
        >
          {children}
          {showTooltip && (
            <Tooltip
              clientEl={cellRef.current}
              portalEl={document.getElementById(TOOLTIP_PORTAL)}
              position={position}
              arrowUp={
                withArrow && <div className={classes.tooltipWrapperArrowUp} />
              }
              delay={delay}
            >
              <div className={classes.tooltipWrapperContent}>
                {title && (
                  <div className={classes.tooltipWrapperTitle}>{title}</div>
                )}
                <div className={classes.tooltipWrapperBody}>{body}</div>
              </div>
            </Tooltip>
          )}
        </div>
      </>
    );
  }
);

TooltipWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  body: PropTypes.node.isRequired,
  position: PropTypes.object,
  delay: PropTypes.number,
  withArrow: PropTypes.bool,
  setClientFocus: PropTypes.func
};

TooltipWrapper.defaultProps = {
  title: null,
  position: {},
  delay: 0,
  withArrow: true,
  setClientFocus: () => {}
};

export default injectSheet(styles)(TooltipWrapper);
