import React, { memo, useState } from 'react';
import PropType from 'prop-types';

import injectSheet from 'react-jss';
import { formatDuration } from '../utils/index';

import KpiTab from './kpiTab';

import styles from './report.style';

// Anticipate a possible analytics dashboard configuration
const analyticsConfig = {
  kpiTabs: [
    {
      label: 'activity',
      description: 'completed traces',
      icon: 'Analytics',
      value: 73 // this could be a path leading to this value
    },
    {
      label: 'efficiency',
      description: 'avg. treatment time',
      icon: 'ClockGeneral',
      value: 1209600, // same here
      time: true
    }
  ]
};

export const Report = ({ classes }) => {
  const [activeTab, setActiveTab] = useState(analyticsConfig.kpiTabs[0].label);

  const { kpiTabs } = analyticsConfig;

  const handleSwitchingTab = tabIndex => setActiveTab(tabIndex);

  return (
    <div className={classes.root}>
      <div className={classes.kpiTabsWrapper}>
        {kpiTabs.map((tab, index) => (
          <div
            key={tab.label}
            className={classes.kpiTabWrapper}
            onClick={() => handleSwitchingTab(tab.label)}
            data-cy={`kpi-tab-wrapper-${index}`}
          >
            <KpiTab
              label={tab.label}
              description={tab.description}
              icon={tab.icon}
              value={tab.time ? formatDuration(tab.value) : tab.value}
              active={activeTab === tab.label}
              loading={false}
            />
          </div>
        ))}
      </div>
      <div className={classes.tabView}>
        <div className={classes.filtersRow}>
          TODO: Filters row for {activeTab}
        </div>
        <div className={classes.graphWrapper}>
          TODO: Graph Parent for {activeTab}
        </div>
      </div>
    </div>
  );
};

Report.propTypes = {
  classes: PropType.object.isRequired
};

export default injectSheet(styles)(memo(Report));
