import React, { useCallback } from 'react';
import PropType from 'prop-types';
import { compose } from 'react-apollo';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import Path from 'path-to-regexp';

import { ModalActions, Pushbutton } from '@stratumn/atomic';

import { ROUTE_NEW_LINK } from 'constant/routes';

import GroupActionsList from './groupActionsList';

import styles from './actionsList.style';

export const ActionsList = React.memo(
  ({
    classes,
    history,
    nextActions,
    tasks,
    workflowId,
    message,
    traceIds,
    toggleTray
  }) => {
    const [selectedLink, setSelectedLink] = React.useState({});

    const actionsHandler = useCallback(() => {
      const { groupKey, actionKey } = selectedLink;

      const baseUrl = Path.compile(ROUTE_NEW_LINK)({
        wfid: workflowId
      });

      return history.push(
        `${baseUrl}?groupKey=${groupKey}&actionKey=${actionKey}${
          traceIds !== null ? `&traceIds=${traceIds.join(',')}` : ''
        }`,
        {
          from: history.location,
          goToTraceInspector: traceIds.length === 0
        }
      );
    }, [selectedLink, workflowId, history, traceIds]);

    const usedNextActions = nextActions || [];
    let priorityActionKey = '';
    let priorityGroupLabel = '';

    if (tasks && tasks.groups) {
      priorityActionKey = '';
      priorityGroupLabel =
        Object.keys(tasks?.groups).find(groupKey => {
          const priorityActions = tasks.groups[groupKey].todo;
          if (!priorityActions?.length) return false;
          // Leverage the fact that we are currently where we need to be to save the action key
          [priorityActionKey] = priorityActions;
          return true;
        }) || '';
    }

    return (
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsBody}>
          <div className={classes.actionsMessage}>{message}</div>
          {usedNextActions.map(nextAction => (
            <GroupActionsList
              key={nextAction.group.label}
              groupActions={nextAction}
              priority={{ priorityActionKey, priorityGroupLabel }}
              selectedLink={selectedLink}
              setSelectedLink={setSelectedLink}
              initDisplay={
                usedNextActions.length <= 3 ||
                nextAction.group.label === priorityGroupLabel
              } // note: "3"... ask product
            />
          ))}
        </div>
        <div className={classes.actionsFooter}>
          <ModalActions>
            <Pushbutton dataCy="cancel" secondary onClick={() => toggleTray()}>
              cancel
            </Pushbutton>
            <Pushbutton
              primary
              dataCy="submit"
              onClick={() => actionsHandler()}
              disabled={!selectedLink.groupKey || !selectedLink.actionKey}
            >
              Perform action
            </Pushbutton>
          </ModalActions>
        </div>
      </div>
    );
  }
);

ActionsList.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  workflowId: PropType.string.isRequired,
  nextActions: PropType.array,
  tasks: PropType.array,
  message: PropType.string,
  toggleTray: PropType.func,
  traceIds: PropType.arrayOf(PropType.string)
};

ActionsList.defaultProps = {
  nextActions: [],
  tasks: [],
  message: '',
  toggleTray: () => {},
  traceIds: []
};

export default compose(injectSheet(styles), withRouter)(ActionsList);
