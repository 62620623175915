export default () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: '100%',
    marginTop: 14
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 18
  }
});
