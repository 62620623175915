import React, { Component } from 'react';
import to from 'await-to-js';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import Path from 'path-to-regexp';
import { FILTERS_QUERY } from 'gql/localQueries';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';

import {
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_WORKFLOW_DASHBOARD,
  ROUTE_USER_DASHBOARD,
  ROUTE_NEW_LINK
} from 'constant/routes';
import { TRAY_PORTAL_RIGHT, TOOLTIP_PORTAL } from 'constant/htmlIds';
import {
  TYPE_WORKFLOW,
  TYPE_WORKFLOW_CONFIG,
  TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD
} from 'gql/types';

import { withError } from 'components/errorBoundary';
import { Header } from 'components/layouts';

import { withSnackbarsContext, Tray, Pushbutton } from '@stratumn/atomic';
import { Settings } from '@stratumn/icons';
import { stringify } from '@stratumn/canonicaljson';

import { getNextActionsArray, deepGet } from 'utils';
import {
  getUserInfoDisplayConfig,
  manageLocalStorage,
  sectionsLocalStorage
} from 'components/ui/utils/localStorage';
import { withUser, LocalStorageContext } from 'contexts';
import TraceIconSpinner from 'components/ui/traceIconSpinner';
import { Widget } from 'components/ui/widget';
import JsonEditor from 'components/ui/utils/jsonEditor';

import { WorkflowContext, buildWorkflowContext } from 'utils/workflowContext';

import envVars from 'constant/env';

import SegmentList from './segmentList';
import SegmentInfo from './segmentInfo';
import PushbuttonInspector from './pushButton';
import fragments from './fragments';

import { ActionsList } from '../ui';

import styles from './traceInspector.style';

const configEditorCodemirrorOptions = {
  theme: 'material'
};

export class TraceInspector extends Component {
  static propTypes = {
    traceQuery: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    watchTraceMutation: PropTypes.func.isRequired,
    errorSnackbar: PropTypes.func.isRequired,
    successSnackbar: PropTypes.func.isRequired,
    updateTraceInfoConfigMutation: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    activeSegment: undefined,
    menuOpen: false,
    showUpdateTraceTray: false,
    showTraceInfoConfigEditor: false
  };

  setDocTitle() {
    const { traceQuery: { traceById: { name } = {} } = {} } = this.props;

    if (name) document.title = `${name} - Inspector - Trace`;
  }

  componentDidMount() {
    this.setDocTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.traceQuery !== this.props.traceQuery) this.setDocTitle();

    // once the trace has been loaded by the query
    if (
      this.props.traceQuery.traceById &&
      prevProps &&
      !prevProps.traceQuery.traceById
    ) {
      this.getNextActionsArray();
    }
  }

  handleTraceInfoConfigUpdate = async newTraceInfoConfigStr => {
    const {
      traceQuery: {
        traceById: { workflow }
      },
      updateTraceInfoConfigMutation,
      successSnackbar,
      errorSnackbar
    } = this.props;

    const {
      rowId: workflowRowId,
      config: { rowId: workflowConfigId } = {}
    } = workflow;
    const newTraceInfoConfig = newTraceInfoConfigStr
      ? JSON.parse(newTraceInfoConfigStr)
      : null;

    const [err] = await to(
      updateTraceInfoConfigMutation({
        variables: {
          workflowConfigId,
          newTraceInfoConfig
        },
        optimisticResponse: {
          updateWorkflowConfigByRowId: {
            workflow: {
              rowId: workflowRowId,
              config: {
                rowId: workflowConfigId,
                info: newTraceInfoConfig,
                __typename: TYPE_WORKFLOW_CONFIG
              },
              __typename: TYPE_WORKFLOW
            },
            __typename: TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD
          }
        }
      })
    );

    if (err) {
      errorSnackbar(
        'Something went wrong during the trace info config update...'
      );
      return;
    }

    successSnackbar('The trace info config was correctly updated');
  };

  toggleShowWorkflowConfigEditor = () =>
    this.setState(prevState => ({
      showTraceInfoConfigEditor: !prevState.showTraceInfoConfigEditor
    }));

  toggleMenu = () =>
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));

  subscribeToTrace(props) {
    const { traceQuery, match } = props;
    this.unsubscribeFromTrace = traceQuery.subscribeToMore({
      document: subscriptions.trace,
      variables: { id: `trace:${match.params.id}` }
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.subscribeToTrace(this.props);
  }

  componentWillUnmount() {
    this.unsubscribeFromTrace();
  }

  shouldComponentUpdate(props) {
    const {
      traceQuery: { loading, traceById: trace, error }
    } = props;
    return !loading && (!!trace || !!error);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps = nextProps => {
    const {
      traceQuery: { loading, traceById: trace },
      location,
      errorContext: { handleError },
      match: { params }
    } = nextProps;

    // // This check avoids rerendering the component after the erroBoundary has been triggered
    if (!loading && !trace) {
      handleError('trace', params.id, ROUTE_WORKFLOW_DASHBOARD);
      return;
    }

    if (
      !loading &&
      trace &&
      (!this.state.activeSegment ||
        this.state.activeSegment.linkHash !== trace.head.linkHash)
    ) {
      const { height } = qs.parse(location.search);

      this.setState({
        activeSegment: this.getActiveSegment(trace, height)
      });
    }
  };

  getActiveSegment = (trace, height) => {
    if (height) {
      const h = parseInt(height, 10);
      const idx = trace.links.nodes.findIndex(l => l.height === h);
      if (idx === -1) {
        throw new Error(`Invalid trace height ${h}`);
      }
      return trace.links.nodes[idx];
    }

    return trace.head;
  };

  handleWatchTrace = () => {
    const {
      watchTraceMutation,
      traceQuery: {
        traceById: { rowId, watched }
      }
    } = this.props;
    watchTraceMutation({
      variables: { id: rowId, watched: !watched }
    });
  };

  setActiveSegment = segment => {
    const {
      traceQuery: {
        traceById: { head }
      }
    } = this.props;
    this.setState({
      activeSegment: this.isHead(segment) ? { ...head, ...segment } : segment
    });
  };

  getNextActionsArray = () => {
    const nextActions = this.getNextActions();
    const nextActionsWithoutComments = nextActions.reduce((acc, current) => {
      const tmp = current.actions.filter(action => action.key !== 'comment');
      if (tmp.length > 0) {
        acc.push({ action: tmp, group: current.group });
      }
      return acc;
    }, []);

    return {
      nextActionsWithoutComments,
      nextActions
    };
  };

  toggleShowUpdateTraceTray = () => {
    const { showUpdateTraceTray } = this.state;
    this.getNextActionsArray();
    this.setState({
      showUpdateTraceTray: !showUpdateTraceTray
    });
  };

  isHead = segment => {
    const {
      traceQuery: { traceById: trace }
    } = this.props;
    return segment.linkHash === trace.head.linkHash;
  };

  getTasks = () => {
    const {
      traceQuery: {
        traceById: {
          state: { tasks }
        }
      }
    } = this.props;
    return tasks;
  };

  getNextActions = () => {
    const {
      traceQuery: {
        traceById: {
          workflow: { groups, actions },
          state: { nextActions }
        }
      }
    } = this.props;

    return getNextActionsArray({
      nextActions,
      groups: groups.nodes,
      actions: actions.nodes
    });
  };

  getWorkflowId = () => {
    const {
      traceQuery: { traceById: trace }
    } = this.props;
    return trace.workflow.rowId;
  };

  renderHeader = () => {
    const {
      traceQuery: { loading: traceLoading, traceById: trace },
      user: { loading: userLoading, me }
    } = this.props;
    const loading = traceLoading || userLoading;

    const { showTraceInfoConfigEditor } = this.state;

    let isSuperuser = false;
    let info;
    let traceInfoConfigStr;
    if (!loading) {
      ({
        workflow: {
          config: { info }
        }
      } = trace);
      ({ isSuperuser } = me);
      traceInfoConfigStr = stringify(info, null, 2);
    }

    const configHeader = {
      loading,
      environment: envVars.REACT_APP_ENVIRONMENT,
      topLevel: {
        title: {
          label: deepGet(trace, 'workflow.name', null),
          path: !loading
            ? Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
                id: trace.workflow.rowId
              })
            : null
        },
        links: [
          {
            label: 'dashboard',
            path: ROUTE_USER_DASHBOARD
          }
        ]
      },
      bottomLevel: {
        workflowPage: true,
        infoContext: {
          links: [
            {
              label: !loading ? trace.name : null
            }
          ]
        },
        actions: isSuperuser
          ? {
              links: [
                {
                  icon: <Settings />,
                  label: 'Trace Info configuration',
                  onClick: this.toggleShowWorkflowConfigEditor
                }
              ]
            }
          : null
      }
    };

    return (
      <>
        <Header config={configHeader} />
        {isSuperuser && showTraceInfoConfigEditor && (
          <JsonEditor
            title="Trace Info configuration"
            jsonString={traceInfoConfigStr}
            onSubmit={this.handleTraceInfoConfigUpdate}
            onClose={this.toggleShowWorkflowConfigEditor}
            codemirrorOptions={configEditorCodemirrorOptions}
          />
        )}
      </>
    );
  };

  handleLocalStorage = ({ index, isCollapsed }) => {
    const {
      traceQuery: { traceById }
    } = this.props;
    return manageLocalStorage(traceById, { index, isCollapsed });
  };

  goToActionLink = (link, traceIds) => {
    const { groupKey, actionKey } = link;

    const baseUrl = Path.compile(ROUTE_NEW_LINK)({
      wfid: this.getWorkflowId()
    });

    let traceIdsString = '';
    if (traceIds && traceIds.length > 0) {
      traceIdsString = `&traceIds=${traceIds.join(',')}`;
    }

    return this.props.history.push(
      `${baseUrl}?groupKey=${groupKey}&actionKey=${actionKey}${traceIdsString}`,
      {
        from: this.props.history.location
      }
    );
  };

  nextActionButton = () => {
    const { classes } = this.props;
    const {
      traceQuery: {
        variables: { traceId }
      }
    } = this.props;

    const {
      nextActionsWithoutComments,
      nextActions
    } = this.getNextActionsArray();

    if (nextActions.length === 0) {
      return (
        <div className={classes.nothingButton}>
          <PushbuttonInspector primary={false} disabled title="Nothing to do" />
        </div>
      );
    }

    // only one action is possible other than commenting
    if (
      nextActionsWithoutComments.length === 1 &&
      nextActionsWithoutComments[0].action.length === 1 &&
      nextActions.length === 1
    ) {
      const link = {
        groupKey: nextActionsWithoutComments[0].group.label,
        actionKey: nextActionsWithoutComments[0].action[0].key
      };

      return (
        <div>
          <PushbuttonInspector
            title={nextActionsWithoutComments[0].action[0].title}
            onClick={() => {
              this.goToActionLink(link, [traceId]);
            }}
          />
          <div className={classes.doubleButton}>
            <Pushbutton
              onClick={() => {
                this.goToActionLink(
                  { groupKey: link.groupKey, actionKey: 'comment' },
                  [traceId]
                );
              }}
            >
              Comment
            </Pushbutton>
          </div>
        </div>
      );
    }

    // only commenting is possible
    if (nextActionsWithoutComments.length === 0 && nextActions.length === 1) {
      return (
        <div className={classes.commentButton}>
          <PushbuttonInspector
            primary={false}
            title="Comment"
            onClick={() => {
              this.goToActionLink(
                {
                  groupKey: nextActions[0].group.label,
                  actionKey: 'comment'
                },
                [traceId]
              );
            }}
          />
        </div>
      );
    }

    // multiple actions are possible we display the old "Next Actions" button
    return (
      <PushbuttonInspector primary onClick={this.toggleShowUpdateTraceTray} />
    );
  };

  renderInspector = () => {
    const {
      traceQuery: { traceById: trace }
    } = this.props;
    const { activeSegment, showUpdateTraceTray } = this.state;
    const { nextActions } = this.getNextActionsArray();
    const tasks = this.getTasks();

    const { rowId: traceId } = trace;

    let newTracesTrayMessage =
      'The following actions are available for this trace.';
    if (showUpdateTraceTray && !nextActions.length) {
      newTracesTrayMessage = 'No actions are available for this trace.';
    }

    return (
      <>
        <SegmentList
          activeSegment={activeSegment}
          links={trace.links.nodes}
          onClick={this.setActiveSegment}
          pulldown={this.nextActionButton()}
        />
        {showUpdateTraceTray && (
          <Tray
            portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
            title="Next action"
            onClose={this.toggleShowUpdateTraceTray}
          >
            <ActionsList
              nextActions={nextActions}
              tasks={tasks}
              workflowId={this.getWorkflowId()}
              traceIds={[traceId]}
              message={newTracesTrayMessage}
              toggleTray={this.toggleShowUpdateTraceTray}
            />
          </Tray>
        )}
      </>
    );
  };

  renderSegment = () => {
    const {
      traceQuery: { traceById: trace },
      classes
    } = this.props;
    const { activeSegment } = this.state;

    const { rowId: traceId, workflow } = trace;

    // build the workflow context passed to the form reader
    const workflowContext = buildWorkflowContext(workflow);

    return (
      <div className={classes.segmentWrapper}>
        <div className={classes.segment}>
          <SegmentInfo
            activeSegment={activeSegment}
            traceId={traceId}
            workflowContext={workflowContext}
          />
        </div>
      </div>
    );
  };

  renderTraceInfo = () => {
    const {
      traceQuery: { traceById: trace },
      classes
    } = this.props;

    const { rowId: traceId, state, workflow } = trace;
    const {
      config: { info },
      rowId: workflowRowId
    } = workflow;

    // build the workflow context passed to the form reader
    const workflowContext = buildWorkflowContext(workflow);

    const wfUserDisplayConfig = getUserInfoDisplayConfig(workflowRowId);

    const localStorageContext = {
      userInfoConfig:
        (wfUserDisplayConfig && wfUserDisplayConfig[traceId]) ||
        sectionsLocalStorage(workflow?.config?.info?.view?.sections || []),
      setLocalStorage: this.handleLocalStorage
    };

    return (
      <div className={classes.traceInfo}>
        {info && (
          <WorkflowContext.Provider value={workflowContext}>
            <LocalStorageContext.Provider value={localStorageContext}>
              <Widget widget={info} data={state} />
            </LocalStorageContext.Provider>
          </WorkflowContext.Provider>
        )}
      </div>
    );
  };

  render = () => {
    const {
      traceQuery: { loading, error, traceById: trace },
      classes
    } = this.props;

    const { activeSegment } = this.state;

    /**
     * If the trace id doesn't exist,
     * we return null and let errorBoundary render the oops page
     */
    if (error || (!trace && !loading)) {
      return null;
    }

    return (
      <>
        <div id={TOOLTIP_PORTAL} />
        <div
          id={TRAY_PORTAL_RIGHT}
          className={classes.inspectorTraysContainer}
        />
        {this.renderHeader()}
        {loading || !activeSegment ? (
          <TraceIconSpinner />
        ) : (
          <div className={classes.body}>
            {this.renderInspector()}
            <div className={classes.content}>
              {this.renderSegment()}
              {this.renderTraceInfo()}
            </div>
          </div>
        )}
      </>
    );
  };
}

export const queries = {
  traceQuery: gql`
    query traceQuery($traceId: UUID!) {
      traceById(id: $traceId) {
        ...TraceInspectorFragment
      }
    }
    ${fragments.trace}
  `
};

export const mutations = {
  watchTrace: gql`
    mutation watchTrace($id: UUID!, $watched: Boolean!) {
      watchTrace(input: { id: $id, watched: $watched }) {
        trace {
          rowId
          watched
        }
      }
    }
  `,
  updateTraceInfoConfig: gql`
    mutation updateTraceInfoConfigMutation(
      $workflowConfigId: BigInt!
      $newTraceInfoConfig: JSON
    ) {
      updateWorkflowConfigByRowId(
        input: {
          rowId: $workflowConfigId
          patch: { info: $newTraceInfoConfig }
        }
      ) {
        workflow {
          rowId
          config {
            rowId
            info
          }
        }
      }
    }
  `
};

export const subscriptions = {
  trace: gql`
    subscription listenTrace($id: String!) {
      listen(topic: $id) {
        relatedNodeId
        relatedNode {
          id
          ... on Trace {
            ...TraceInspectorFragment
          }
        }
      }
    }
    ${fragments.trace}
  `
};

export default compose(
  withUser,
  withRouter,
  graphql(FILTERS_QUERY, {
    name: 'filtersQuery'
  }),
  graphql(mutations.watchTrace, {
    name: 'watchTraceMutation'
  }),
  graphql(mutations.updateTraceInfoConfig, {
    name: 'updateTraceInfoConfigMutation'
  }),
  graphql(queries.traceQuery, {
    name: 'traceQuery',
    options: ({ match }) => ({
      variables: { traceId: match.params.id },
      fetchPolicy: 'cache-and-network'
    })
  }),
  injectSheet(styles),
  withSnackbarsContext,
  withError
)(TraceInspector);
