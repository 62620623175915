import React, { memo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import Skeleton from '../../skeleton';
import { pixelToPercentConvertion } from '../../skeleton/utils';

import styles from './kpiCardSkeleton.style';

export const KpiCardSkeleton = ({ classes, icon }) => (
  <div className={classes.skeletonCardOne}>
    {icon && (
      <Skeleton
        height={40}
        width={pixelToPercentConvertion(276, 40)}
        borderRadius={10}
      />
    )}
    <section className={classes.skeletonLarge}>
      <Skeleton
        height={18}
        width={pixelToPercentConvertion(276, 150)}
        borderRadius={2}
      />
      <Skeleton
        height={29}
        width={pixelToPercentConvertion(276, 60)}
        borderRadius={2}
      />
    </section>
  </div>
);

KpiCardSkeleton.propTypes = {
  classes: PropType.object.isRequired,
  icon: PropType.bool
};

KpiCardSkeleton.defaultProps = {
  icon: false
};

export default injectSheet(styles)(memo(KpiCardSkeleton));
