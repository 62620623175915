import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import { Header } from 'components/layouts';
import TraceIconSpinner from 'components/ui/traceIconSpinner';

import { LayoutKanban, LayoutKanbanHeader } from '@stratumn/atomic';

import envVars from 'constant/env';

import fragments from './fragments';

import styles from './userDashboard.style';

import UserDashboardHeader from './userDashboardHeader';
import GroupsTaskList from './groupsTaskList';
import WorkflowsList, {
  fragments as workflowsListFragments
} from './workflowsList';

// some constants specific to dashboard
const LOCAL_STORAGE_KEY = `userDashboardConfig`;

export const getUserDisplayConfig = () => {
  try {
    const localStorageDisplayConfigStr = localStorage.getItem(
      LOCAL_STORAGE_KEY
    );
    return localStorageDisplayConfigStr
      ? JSON.parse(localStorageDisplayConfigStr)
      : null;
  } catch (err) {
    return null;
  }
};

export const saveUserDisplayConfig = cfg => {
  const lsKey = LOCAL_STORAGE_KEY;
  if (!cfg) {
    localStorage.removeItem(lsKey);
    return;
  }
  localStorage.setItem(lsKey, JSON.stringify(cfg));
};

export const UserDashboard = React.memo(({ classes, dashboardUserQuery }) => {
  // set doc title at mount
  useEffect(() => {
    document.title = 'Dashboard - Trace';
  }, []);

  // state init
  const [userDisplayConfig, setUserDisplayConfig] = useState(
    getUserDisplayConfig() || {}
  );
  const {
    collapsedHeader,
    groupsTaskList: groupsTaskListConfig
  } = userDisplayConfig;

  // actions logged in userDisplayConfig
  // overlay a partial config onto the main config
  const updateUserDisplayConfig = useCallback(
    overlayConfig => {
      // update the user display config and store it
      const newUserDisplayConfig = {
        ...userDisplayConfig,
        ...overlayConfig
      };
      setUserDisplayConfig(newUserDisplayConfig);
      saveUserDisplayConfig(newUserDisplayConfig);
    },
    [userDisplayConfig]
  );

  // set collapsed dashboard header
  const setCollapsedHeader = useCallback(
    newCollapsedHeader => {
      updateUserDisplayConfig({ collapsedHeader: newCollapsedHeader });
    },
    [updateUserDisplayConfig]
  );

  // set groups task list config
  const setGroupsTaskListConfig = useCallback(
    newGroupsTaskListConfig => {
      updateUserDisplayConfig({ groupsTaskList: newGroupsTaskListConfig });
    },
    [updateUserDisplayConfig]
  );

  // main header memo
  const header = useMemo(() => {
    const configHeader = {
      fullLogo: false,
      topLevel: {
        title: {
          label: 'User dashboard'
        }
      },
      environment: envVars.REACT_APP_ENVIRONMENT
    };
    return (
      <LayoutKanbanHeader>
        <Header config={configHeader} />
      </LayoutKanbanHeader>
    );
  }, []);

  const { loading: userQueryLoading, me, workflows } = dashboardUserQuery;
  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <LayoutKanban>
        {header}
        <div className={classes.dashboardContainer}>
          {userQueryLoading ? (
            <TraceIconSpinner />
          ) : (
            <div className={classes.dashboardContent}>
              <UserDashboardHeader
                className={classes.dashboardItem}
                me={me}
                collapsed={collapsedHeader}
                setCollapsed={setCollapsedHeader}
              />
              <WorkflowsList
                className={classes.dashboardItem}
                workflows={workflows}
                isSuperuser={me.isSuperuser}
              />
              <GroupsTaskList
                className={classes.dashboardItem}
                me={me}
                workflows={workflows.nodes}
                config={groupsTaskListConfig}
                setConfig={setGroupsTaskListConfig}
              />
            </div>
          )}
        </div>
      </LayoutKanban>
    </>
  );
});

UserDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dashboardUserQuery: PropTypes.object.isRequired
};

UserDashboard.defaultProps = {};

export const queries = {
  dashboardUserQuery: gql`
    query dashboardUserQuery {
      ...DashboardUserFragment
      ...WorkflowsFragment
    }
    ${fragments.dashboardUser}
    ${workflowsListFragments.workflows}
  `
};

export default compose(
  graphql(queries.dashboardUserQuery, {
    name: 'dashboardUserQuery',
    options: () => ({
      fetchPolicy: 'cache-and-network'
    })
  }),
  injectSheet(styles)
)(UserDashboard);
