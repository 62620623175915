export default theme => ({
  body: {
    width: '100vw',
    height: 'calc(100vh - 70px)',
    backgroundColor: theme.grey9,
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden'
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    backgroundColor: theme.grey8,
    padding: 10
  },
  segmentWrapper: {
    width: '50%',
    padding: 10
  },
  segment: {
    overflow: 'hidden',
    backgroundColor: theme.white1,
    borderRadius: 5,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.indigoShadow1
  },
  traceInfo: {
    width: '50%',
    padding: 10
  },
  '@media (max-width: 800px)': {
    content: {
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    segmentWrapper: {
      width: '100%'
    },
    traceInfo: {
      width: '100%'
    }
  },
  inspectorTraysContainer: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    '&[data-is-left=true]': {
      right: 'unset',
      left: 0,
      flexFlow: 'row nowrap'
    }
  },
  traceInfoConfigIcon: {
    marginRight: 10,
    cursor: 'pointer',
    color: theme.grey2,
    width: 25,
    '&[data-is-clicked=true]': {
      color: theme.indigo3
    }
  },
  doubleButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 100px 0 50px'
  },
  commentButton: {
    '& button': {
      backgroundColor: 'white'
    }
  },
  nothingButton: {
    '& button': {
      backgroundColor: 'white',
      opacity: '1 !important',
      border: '1px solid #E5E3EB'
    }
  }
});
