export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: 10,
    backgroundColor: theme.white1,
    border: `1px solid ${theme.grey7}`,
    borderRadius: 5
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    height: 12,
    '&[data-is-loading=true]': {
      width: '100%'
    }
  },
  label: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    paddingRight: 6,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '35px',
    letterSpacing: '0.03em',
    color: theme.grey1,
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  periodFilterWrapper: {
    display: 'flex',
    '& > button:disabled': {
      color: theme.grey1
    },
    '& button > span': {
      textTransform: 'capitalize',
      fonStyle: 'normal',
      fontWeight: 500,
      lineHeight: '13px',
      fontSize: 10
    },
    '& > button:not(:last-child)': {
      borderRightWidth: 0.5,
      borderRightStyle: 'solid',
      paddingRight: 5,
      marginRight: 5,
      borderColor: theme.grey4
    }
  },
  menuIcon: {
    marginRight: -12, // the menu 3 dots is a square 32 x 32, therefore we need to move the icon further to the right manually
    color: theme.grey6
  },
  menuContent: {
    maxWidth: 250,
    backgroundColor: theme.white1,
    boxShadow: theme.indigoShadow1,
    padding: 10,
    borderRadius: 2
  },
  menuDescription: {
    fontSize: 11,
    lineHeight: '14px',
    color: theme.grey3,
    marginBottom: 10
  },
  settingsWrapper: {
    borderTop: `1px solid ${theme.grey7}`,
    color: theme.grey1
  },
  settingslabel: {
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '13px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
  },
  settingsList: {
    fontSize: 12,
    lineHeight: '15px'
  },
  cardBody: {
    height: '100%',
    paddingTop: 18
  }
});
