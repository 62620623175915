import { getScrollbarSize } from 'utils';

const SCROLLBAR_SIZE = getScrollbarSize();

export default theme => ({
  container: {
    flexShrink: 0,
    overflow: 'hidden',
    backgroundColor: theme.white1,
    padding: '30px 15px',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  header: {
    marginBottom: 10,
    padding: '0px 15px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  headerTitle: {
    flexShrink: 0,
    marginRight: 30,
    display: 'flex',
    flexFlow: 'column nowrap',
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'justify'
  },
  headerTitleUnderline: {
    backgroundColor: theme.teal1,
    width: 15,
    height: 5,
    borderRadius: 10
  },
  listContainer: {
    position: 'relative'
  },
  workflowsTable: {
    borderSpacing: 0,
    emptyCells: 'show',
    width: '100%',
    '& th, & td': {
      paddingLeft: 15,
      paddingRight: 15
    },
    '& thead': {
      display: 'block',
      paddingLeft: 15,
      paddingRight: SCROLLBAR_SIZE,
      color: theme.grey4,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: '1px',
      fontWeight: 500,
      whiteSpace: 'nowrap'
    },
    '& thead tr': {
      width: '100%',
      display: 'table',
      tableLayout: 'fixed',
      borderBottom: `1px solid ${theme.grey6}`
    },
    '& thead th': {
      textTransform: 'uppercase',
      paddingTop: 17,
      paddingBottom: 11
    },
    '& tbody': {
      display: 'block',
      paddingLeft: 15,
      paddingRight: SCROLLBAR_SIZE,
      color: theme.grey1,
      whiteSpace: 'nowrap',
      '&[data-is-scrollable=true]': {
        overflowY: 'auto',
        paddingRight: 0,
        maxHeight: 199
      }
    },
    '& tbody tr:not(:last-child)': {
      borderBottom: `1px solid ${theme.grey6}`
    },
    '& tbody tr:hover': {
      backgroundColor: theme.grey9
    },
    '& tbody tr': {
      width: '100%',
      display: 'table',
      tableLayout: 'fixed',
      height: 50
    }
  },
  columnName: {
    width: '45%'
  },
  columnNameSmaller: {
    width: '40%' // leave room for 5th settings column
  },
  columnTraces: {
    whiteSpace: 'nowrap',
    width: '15%'
  },
  columnGroups: {
    whiteSpace: 'nowrap',
    width: '15%'
  },
  columnDocumentation: {
    width: '25%'
  },
  columnSettings: {
    width: '5%'
  },
  emptyListMessage: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.grey1
  },
  listInfoContainer: {
    height: 86,
    width: '100%',
    opacity: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
