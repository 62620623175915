export default theme => ({
  keyValueRoot: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '5px'
  },
  titleWrapper: {
    display: 'flex',
    color: theme.indigo2,
    alignItems: 'center'
  },
  collapsableTitleWrapper: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.indigoPastel1
    }
  },
  collapsedContentWrapper: {
    display: 'none'
  },
  title: {
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: '0.5px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase'
  },
  icon: {
    width: 12,
    height: 12,
    marginLeft: 5,
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-collapsed="false"]': {
      transform: 'rotate(-180deg)'
    }
  },
  value: {
    minHeight: 18,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginLeft: 9,
    borderLeft: `2px solid ${theme.grey7}`,
    padding: '0 16px',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
