import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Path from 'path-to-regexp';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import to from 'await-to-js';

import {
  LayoutKanban,
  LayoutKanbanHeader,
  Pushbutton,
  withSnackbarsContext
} from '@stratumn/atomic';

import TraceIconSpinner from 'components/ui/traceIconSpinner';
import { Header } from 'components/layouts';

import { pluralize } from 'utils';
import { useToggle } from 'utils/hooks';

import {
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_USER_DASHBOARD,
  ROUTE_WORKFLOW_GROUPS
} from 'constant/routes';
import { TOOLTIP_PORTAL } from 'constant/htmlIds';
import { withError } from 'components/errorBoundary';

import envVars from 'constant/env';

import fragments from './fragments';
import styles from './groupSettings.style';

import { getGroupParticipants } from './utils';

import SubHeader from './subHeader';
import ParticipantsList from './participantsList';
import RemoveGroupMembersModal from './removeGroupMembersModal';
import AddParticipantsModal from './addParticipantsModal';

export const GroupSettings = ({
  errorContext: { handleError },
  history,
  match,
  successSnackbar,
  errorSnackbar,
  classes,
  groupSettingsQuery,
  updateGroupMutation,
  addUsersMutation,
  updateGroupMembersMutation
}) => {
  const { loading, error, groupByRowId: group } = groupSettingsQuery;

  // set doc title at mount
  useEffect(() => {
    const { name } = group || {};
    if (name) document.title = `${name} - Group - Trace`;
  }, [group]);

  // Raise 404 page when group not found.
  const groupError = error || (!loading && !group);
  useEffect(() => {
    const { params } = match;

    // This check avoids rerendering the component after the erroBoundary has been triggered
    if (groupError) {
      handleError('group', params.id, ROUTE_USER_DASHBOARD);
    }
  }, [groupError, match, handleError]);

  // memoized header config
  const header = useMemo(() => {
    const headerConfig = {
      environment: envVars.REACT_APP_ENVIRONMENT,
      topLevel: {
        title: {
          label: group ? group.workflow.name : 'Trace Workflow',
          path: group
            ? Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
                id: group.workflow.rowId
              })
            : null
        },
        links: [
          {
            label: 'dashboard',
            path: ROUTE_USER_DASHBOARD
          }
        ]
      },
      bottomLevel: {
        workflowPage: false,
        infoContext: {
          links: [
            {
              label: 'Settings',
              path: group
                ? Path.compile(ROUTE_WORKFLOW_GROUPS)({
                    id: group.workflow.rowId
                  })
                : null
            },
            {
              label: 'Groups',
              path: group
                ? Path.compile(ROUTE_WORKFLOW_GROUPS)({
                    id: group.workflow.rowId
                  })
                : null
            },
            {
              label: group ? group.name : match.params.id
            }
          ]
        }
      }
    };

    return (
      <LayoutKanbanHeader>
        <Header config={headerConfig} />
      </LayoutKanbanHeader>
    );
  }, [group]);

  // go back to workflow groups page
  const goBack = useCallback(() => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_GROUPS)({
        id: group.workflow.rowId
      })
    );
  }, [history, group]);

  // update group mutation callback
  const updateGroup = useCallback(
    async patch => {
      const [err] = await to(
        updateGroupMutation({
          variables: {
            groupRowId: group.rowId,
            patch: patch
          }
        })
      );

      if (err) {
        errorSnackbar(`Something went wrong when updating Group ${group.name}`);
        return;
      }

      successSnackbar(
        `Group ${patch.name || group.name} was correctly updated`
      );
    },
    [group, updateGroupMutation, errorSnackbar, successSnackbar]
  );

  // state to remove a set of members from the group
  const [membersToRemove, setMembersToRemove] = useState(null);
  // callback passed to children to trigger the confirmation modal
  const triggerRemoveGroupMemberModal = useCallback(
    (membersAccountIds, participantsStr) => {
      setMembersToRemove({ membersAccountIds, participantsStr });
    },
    []
  );
  // callback to cancel removal of group members
  const cancelRemoveFromGroup = useCallback(() => {
    setMembersToRemove(null);
  }, []);
  // callback to submit removal of group members
  const removeGroupMembers = useCallback(async () => {
    const { membersAccountIds, participantsStr } = membersToRemove;
    const [err] = await to(
      updateGroupMembersMutation({
        variables: {
          groupId: group.rowId,
          members: membersAccountIds.map(memberId => ({
            memberId,
            action: 'REVOKE',
            role: 'READER'
          }))
        }
      })
    );

    if (err) {
      errorSnackbar(
        `Something went wrong when removing ${participantsStr} from Group ${group.name}`
      );
      return;
    }
    successSnackbar(
      `${participantsStr} correctly removed from Group ${group.name}`
    );

    // clear the members to remove to close the modal
    setMembersToRemove(null);
  }, [
    membersToRemove,
    group,
    updateGroupMembersMutation,
    errorSnackbar,
    successSnackbar
  ]);

  // add participants modal state
  const [showAddParticipantsModal, switchShowAddParticipantsModal] = useToggle(
    false
  );

  // callback to submit addition of users from their email
  const addUsers = useCallback(
    async usersEmails => {
      const [err] = await to(
        addUsersMutation({
          variables: {
            groupRowId: group.rowId,
            emails: usersEmails
          }
        })
      );

      if (err) {
        errorSnackbar(
          `Something went wrong when adding users to Group ${group.name}`
        );
        return;
      }
      successSnackbar(
        `${pluralize(usersEmails.length, 'user')} correctly added to Group ${
          group.name
        }`
      );

      // if everything went well close the modal
      switchShowAddParticipantsModal();
    },
    [
      group,
      addUsersMutation,
      errorSnackbar,
      successSnackbar,
      switchShowAddParticipantsModal
    ]
  );

  // callback to submit addition of teams from their accountId
  const addTeams = useCallback(
    async teamsAccountIds => {
      const [err] = await to(
        updateGroupMembersMutation({
          variables: {
            groupId: group.rowId,
            members: teamsAccountIds.map(memberId => ({
              memberId,
              action: 'GRANT',
              role: 'READER'
            }))
          }
        })
      );

      if (err) {
        errorSnackbar(
          `Something went wrong when adding teams to Group ${group.name}`
        );
        return;
      }
      successSnackbar(
        `${pluralize(
          teamsAccountIds.length,
          'team'
        )} correctly added to Group ${group.name}`
      );

      // if everything went well close the modal
      switchShowAddParticipantsModal();
    },
    [
      group,
      updateGroupMembersMutation,
      errorSnackbar,
      successSnackbar,
      switchShowAddParticipantsModal
    ]
  );

  // memoized group participants
  const { otherParticipants = [], teams = [], nbParticipants } = useMemo(
    () => (!group ? {} : getGroupParticipants(group.members.nodes)),
    [group]
  );

  // memoized sections list
  const sectionsList = useMemo(() => {
    if (loading) return <TraceIconSpinner />;

    // if no participant at all display a specific message
    if (!nbParticipants)
      return (
        <div className={classes.noParticipantsMessage}>
          No participants to display here
        </div>
      );

    // otherwise display first the list of 'other participants'
    // and then all teams
    return (
      <div className={classes.sectionsList}>
        <ParticipantsList
          title="Other Participants"
          participants={otherParticipants}
          removeGroupMembers={triggerRemoveGroupMemberModal}
        />
        {teams.map(team => (
          <ParticipantsList
            key={team.accountId}
            title={team.name}
            participants={team.participants}
            teamAccountId={team.accountId}
            removeGroupMembers={triggerRemoveGroupMemberModal}
          />
        ))}
      </div>
    );
  }, [loading, otherParticipants, teams, nbParticipants]);

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <LayoutKanban>
        {header}
        <div className={classes.content}>
          <SubHeader
            group={group}
            updateGroup={updateGroup}
            nbParticipants={nbParticipants}
            openAddParticipantsModal={switchShowAddParticipantsModal}
          />
          <div className={classes.sectionsListContainer}>{sectionsList}</div>
          <div className={classes.footer}>
            <Pushbutton
              onClick={goBack}
              disabled={false}
              dataCy="go-back-button"
            >
              Go Back To Groups
            </Pushbutton>
          </div>
        </div>
      </LayoutKanban>
      {membersToRemove && (
        <RemoveGroupMembersModal
          groupName={group.name}
          participantsStr={membersToRemove.participantsStr}
          submit={removeGroupMembers}
          cancel={cancelRemoveFromGroup}
        />
      )}
      {showAddParticipantsModal && (
        <AddParticipantsModal
          groupName={group.name}
          groupId={group.rowId}
          submitAddUsers={addUsers}
          submitAddTeams={addTeams}
          cancel={switchShowAddParticipantsModal}
        />
      )}
    </>
  );
};

GroupSettings.propTypes = {
  errorContext: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  successSnackbar: PropTypes.func.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  groupSettingsQuery: PropTypes.object.isRequired,
  updateGroupMutation: PropTypes.func.isRequired,
  addUsersMutation: PropTypes.func.isRequired,
  updateGroupMembersMutation: PropTypes.func.isRequired
};

GroupSettings.defaultProps = {};

const GROUP_SETTINGS_QUERY = gql`
  query groupSettingsQuery($groupRowId: BigInt!) {
    groupByRowId(rowId: $groupRowId) {
      ...GroupSettingsFragment
    }
  }
  ${fragments.groupSettings}
`;

const UPDATE_GROUP_MUTATION = gql`
  mutation updateGroupMutation($groupRowId: BigInt!, $patch: GroupPatch!) {
    updateGroupByRowId(input: { rowId: $groupRowId, patch: $patch }) {
      group {
        rowId
        name
        avatar
      }
    }
  }
`;

const ADD_USERS_MUTATION = gql`
  mutation addUsersMutation($groupRowId: BigInt!, $emails: [String]!) {
    inviteUsersToGroup(input: { groupRowId: $groupRowId, emails: $emails }) {
      group {
        ...GroupSettingsFragment
      }
    }
  }
  ${fragments.groupSettings}
`;

const UPDATE_GROUP_MEMBERS_MUTATION = gql`
  mutation updateGroupMembersMutation(
    $groupId: BigInt
    $members: [UpdateGroupMemberActionInput]
  ) {
    updateGroupMembers(input: { groupId: $groupId, members: $members }) {
      group {
        ...GroupSettingsFragment
      }
    }
  }
  ${fragments.groupSettings}
`;

export default compose(
  graphql(GROUP_SETTINGS_QUERY, {
    name: 'groupSettingsQuery',
    options: ({ match }) => ({
      variables: {
        groupRowId: match.params.id
      },
      fetchPolicy: 'cache-and-network'
    })
  }),
  graphql(UPDATE_GROUP_MUTATION, {
    name: 'updateGroupMutation'
  }),
  graphql(ADD_USERS_MUTATION, {
    name: 'addUsersMutation'
  }),
  graphql(UPDATE_GROUP_MEMBERS_MUTATION, {
    name: 'updateGroupMembersMutation'
  }),
  withRouter,
  withError,
  withSnackbarsContext,
  injectSheet(styles),
  React.memo
)(GroupSettings);
