export default theme => ({
  skeletonCardRoot: {
    backgroundColor: theme.white1,
    borderRadius: 15,
    position: 'relative',
    padding: 5,
    width: '100%',
    height: '100%'
  },
  skeletonLarge: {
    width: '100%',
    '&[data-is-flex=true]': {
      display: 'flex'
    }
  },
  skeletonMedium: {
    width: '49%',
    '&[data-is-flex=true]': {
      display: 'flex'
    },
    '&[data-is-flex-end=true]': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  skeletonCardGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& section': {
      padding: 10
    }
  },
  skeletonCardThree: {
    extend: 'skeletonCardRoot',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  firstColumn: {
    '& div': {
      margin: '8px 3px'
    }
  },
  secondColumn: {
    '& div': {
      margin: '24px 0px'
    }
  }
});
