import { getMediaClient } from 'client/media';
import apm from 'monitoring/apm';
import to from 'await-to-js';
import { saveAs } from 'file-saver';

import { MEDIA_API_URL } from 'constant/api';
import { getAuthToken } from 'utils/localStorage';

// eslint-disable-next-line
import DownloadFileWorker from 'worker-loader!utils/downloadFile/downloadFile.worker';

/**
 * @function downloadFile
 * @param {object} data
 * @param {Function} errorSnackbar - onError
 * @param {Function} setLoading - onProgress
 * @param {boolean} loading
 * @param {Function} getResponse - Returns download result to the parent component
 */
export const downloadFile = async (
  data,
  errorSnackbar,
  setLoading,
  loading,
  getResponse
) => {
  const { digest, key } = data;

  if (loading) return;

  setLoading(true);

  let err;
  let res;

  // Checking if workers are enabled and handling both cases
  if (window.Worker) {
    [err, res] = await to(
      downloadFileUsingWorker(
        digest,
        undefined /* onProgress */,
        key,
        getAuthToken(),
        MEDIA_API_URL
      )
    );
  } else {
    // Checking if workers are enabled and handling both cases
    [err, res] = await to(
      getMediaClient().downloadFile(digest, undefined /* onProgress */, key)
    );
  }

  if (err) {
    setLoading(false);
    apm.captureError(err);
    errorSnackbar('Failed to download file.');
    if (getResponse) getResponse(null);
    return;
  }

  setLoading(false);

  // set a file name and save the file from media-sdk.
  saveAs(res, res.name);
  // Return response object to parent
  if (getResponse) getResponse(res);
};

const downloadFileUsingWorker = async (
  digest,
  onProgress, // This callback will be used once the progress feature has been designed
  key,
  authToken,
  mediaApiUrl
) => {
  const downloadFileWorker = new DownloadFileWorker();
  return new Promise((resolve, reject) => {
    /**
     * Run the worker;
     * postMessage only accepts one argument.
     * We will pass an object of the necessary arguments.
     */
    downloadFileWorker.postMessage({ digest, key, authToken, mediaApiUrl });

    // return data to ui component where downloadFile has been triggered
    downloadFileWorker.onmessage = event => {
      if (event.data[0]) reject(event.data[0]);
      resolve(event.data[1]);
    };

    // report worker error
    downloadFileWorker.onerror = reject;
  }).finally(() => {
    // Stop listening to worker messages
    downloadFileWorker.terminate();
  });
};
