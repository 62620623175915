export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: 18
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 12,
    '&[data-is-loading=true]': {
      width: '100%'
    }
  },
  title: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    paddingRight: 6,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '35px',
    letterSpacing: '0.03em',
    color: theme.grey1,
    '&::first-letter': {
      textTransform: 'uppercase'
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 15,
      height: 5,
      bottom: 0,
      borderBottom: `5px solid ${theme.teal1}`,
      borderRadius: 10
    }
  },
  tooltipWrapper: {
    display: 'inline-flex',
    '& > svg': {
      width: 19,
      height: 19
    }
  },
  cardBody: {
    height: '100%',
    paddingTop: 18
  },
  arrowRight: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: 20,
    height: 20,
    left: 0,
    transition: 'left 250ms',
    '&[data-is-dynamic=true]': {
      '& > svg': {
        position: 'relative',
        flexShrink: 0,
        width: 20,
        height: 20,
        top: 5, // as the arrow svg is not centered, we're lowering it by 5px.
        left: 0,
        transition: 'left 250ms'
      }
    },
    '&:hover': {
      left: 5
    }
  }
});
