export default () => ({
  loading: {
    position: 'relative',
    height: ({ height }) => height,
    width: ({ width }) => `${width}%`,
    borderRadius: ({ borderRadius }) => borderRadius,
    background: 'linear-gradient(90deg, #E5E3EB 0%, #F2F1F5 100%)',
    overflow: 'hidden',
    margin: ({ margin }) => margin,
    '&::after': {
      content: '""',
      position: 'relative',
      display: 'block',
      height: ({ height }) => height,
      width: '100%',
      backgroundImage:
        'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.8) 50%, rgba(255,255,255,0) 100%)',
      animation: 'loadingAnimation 1.5s infinite cubic-bezier(.17,.67,.4,.96)'
    }
  },
  '@keyframes loadingAnimation': {
    from: { transform: 'translate(-100%)' },
    to: { transform: 'translate(100%)' }
  }
});
