export default theme => ({
  root: {
    maxHeight: 40,
    width: '100%',
    '& > div:first-child': {
      width: 'inherit'
    },
    '& .DateRangePicker_picker': {
      zIndex: 3
    },
    '& .DateRangePicker > div:first-child': {
      width: 'inherit'
    },
    '& .DateInput_input': {
      padding: 9.5,
      fontWeight: 600,
      fontSize: 11,
      lineHeight: '17px',
      color: theme.grey1,
      textOverflow: 'ellipsis'
    },
    '& .DateInput_1': {
      width: '45%',
      background: 'transparent'
    },
    '& .DateInput_input__focused': {
      borderColor: theme.indigo2
    },
    '& .CalendarDay__selected_span, .CalendarDay__hovered_span:hover, .CalendarDay__hovered_span': {
      background: theme.indigoPastel3,
      color: 'black',
      border: `1px solid ${theme.white1}`
    },
    '& .CalendarDay__selected': {
      background: theme.indigo2,
      color: theme.white1,
      border: `1px solid ${theme.white1}`
    },
    // Calendar attached arrow pointing upward to input
    '& .DateInput_fang': {
      display: 'none'
    },
    // Calendar left and right arrows
    '& .DayPickerNavigation_button': {
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
    // Calendar wrapper
    '& .DayPicker__withBorder': {
      boxShadow: '0px 5px 15px #28128733'
    },
    '& .DateRangePickerInput__withBorder': {
      borderRadius: 5,
      borderColor: 'transparent',
      boxShadow: theme.indigoShadow1,
      width: '100%',
      color: theme.grey1,
      fontWeight: 600,
      fontSize: 14,
      background: theme.white1
    }
  },
  calendarInfoWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: 22,
    paddingBottom: 22,
    transition: '250ms',
    '& svg': {
      fill: 'currentColor',
      height: 14,
      width: 'auto',
      marginRight: 10,
      transition: '250ms'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7,
      '& svg': {
        transform: 'rotate(-90deg)'
      }
    }
  },
  calendarInfoText: {
    lineHeight: '1.18px',
    fontSize: 11,
    color: '#343132'
  }
});
