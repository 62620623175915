import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import theme from 'style';

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';

import ColumnChartSkeleton from './skeleton';
import { CustomTooltip, Placeholder } from '../ui';

import { mainStyles, rechartsStyles } from './column.style';

export const ColumnChart = ({ classes, dataset, loading, subType }) => {
  const [focusBar, setFocusBar] = useState(null);

  const renderBars = useMemo(
    () =>
      dataset.map((entry, index) => {
        const cellProps = {
          key: `cell-${index}`,
          radius: 2,
          fill: focusBar === index ? theme.indigo1 : theme.indigo2
        };

        // Even for a month without any trace, we want to display a tiny column
        // to match the design
        return entry.y === 0 ? (
          <Cell {...cellProps} height={-1} />
        ) : (
          <Cell {...cellProps} />
        );
      }),
    [dataset, focusBar, theme]
  );

  if (loading) return <ColumnChartSkeleton />;
  if (dataset.length === 0) return <Placeholder />;

  return (
    <div className={classes.wrapper}>
      <ResponsiveContainer>
        <BarChart
          data={dataset}
          {...rechartsStyles[subType].root}
          onMouseMove={({ isTooltipActive, activeTooltipIndex }) =>
            setFocusBar(
              isTooltipActive ? activeTooltipIndex : setFocusBar(null)
            )
          }
        >
          <CartesianGrid {...rechartsStyles[subType].cartesianGrid} />
          {rechartsStyles[subType].xAxis && (
            <XAxis dataKey="x" {...rechartsStyles[subType].xAxis} />
          )}
          {rechartsStyles[subType].yAxis && (
            <YAxis {...rechartsStyles[subType].yAxis} />
          )}
          {rechartsStyles[subType].tooltip && (
            <Tooltip
              content={<CustomTooltip {...rechartsStyles[subType].tooltip} />}
              cursor={rechartsStyles[subType].tooltip.cursor}
            />
          )}

          <Bar dataKey="y">{renderBars}</Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

ColumnChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.date,
      y: PropTypes.number
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  // TODO: remove once analytics configuration is activated
  subType: PropTypes.string
};

ColumnChart.defaultProps = {
  // TODO: remove once analytics configuration is activated
  subType: ''
};

export default injectSheet(mainStyles)(memo(ColumnChart));
