export default theme => ({
  skeletonCardRoot: {
    backgroundColor: theme.white1,
    borderRadius: 15,
    position: 'relative',
    padding: 5,
    width: '100%',
    height: '100%'
  },
  skeletonLarge: {
    width: '100%',
    '&[data-is-flex=true]': {
      display: 'flex'
    }
  },
  skeletonCardOne: {
    extend: 'skeletonCardRoot',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10
  }
});
