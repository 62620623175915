export default theme => ({
  displayMenuContent: {
    minWidth: 150,
    maxWidth: 400,
    color: theme.grey1,
    backgroundColor: theme.grey9,
    borderRadius: 2,
    border: `1px solid ${theme.grey5}`,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  displayMenuHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 15,
    margin: 5,
    padding: 3,
    '&:hover': {
      cursor: 'pointer'
    },
    '&[data-is-expanded=true]': {
      borderBottom: `1px solid ${theme.grey5}`
    }
  },
  displayMenuHeaderToggle: {
    width: 16,
    height: 16,
    flexShrink: 1,
    '& > svg': {
      display: 'block',
      width: '100%',
      height: '100%'
    }
  },
  displayMenuList: {
    fontSize: 12,
    padding: '7px 10px',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  displayMenuItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    borderRadius: 2,
    padding: '3px 5px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.grey7
    }
  },
  displayMenuItemIcon: {
    width: 25,
    flexShrink: 0
  },
  displayMenuRadioIcon: {
    // these icons are weird
    margin: '-9px 5px -15px -12px',
    flexShrink: 0
  },
  displayMenuItemHeader: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  displayMenuArrow: {
    width: 20,
    height: 10,
    display: 'block',
    position: 'absolute',
    top: -8,
    right: 22,
    zIndex: 3,
    stroke: theme.grey5,
    fill: theme.grey9,
    strokeLinecap: 'round',
    strokeWidth: '1',
    strokeLinejoin: 'round'
  }
});
