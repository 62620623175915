export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    background: theme.white1,
    boxShadow: theme.indigoShadow1
  },
  title: {
    display: 'block',
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 9,
    lineHeight: '11px',
    textTransform: 'capitalize',
    color: theme.grey3
  },
  description: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    margin: 0,
    padding: 0,
    color: theme.grey1
  }
});
