export default theme => ({
  numberInput: {
    width: '100%',
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: 'border-color 150ms linear 0ms',
    borderRadius: 3,
    padding: '5px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.white1,
      borderColor: theme.grey5,
      '& $numberInputIcon': {
        color: theme.grey3,
        width: 17
      }
    },
    '&[data-input-focused=true]': {
      cursor: 'text',
      backgroundColor: theme.white1,
      borderColor: theme.indigo3
    }
  },
  numberInputField: {
    cursor: 'pointer',
    flexGrow: 1,
    fontSize: 13,
    color: theme.grey1,
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::placeholder': {
      color: theme.grey3
    },
    '&:focus': {
      cursor: 'unset',
      color: theme.grey2,
      fontWeight: 'normal'
    }
  },
  numberInputIcon: {
    marginLeft: 5,
    flexShrink: 0,
    height: 17,
    width: 0,
    color: theme.grey4,
    '&[data-is-visible=true]': {
      width: 17
    }
  },
  numberCompactInputWrapper: {
    width: '100%',
    height: 40,
    '& label': {
      height: '100%'
    }
  },
  numberCompactInput: {
    display: 'flex',
    flexGrow: 1,
    borderColor: theme.grey5,
    backgroundColor: theme.grey9,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 2,
    transition: 'border-color 150ms linear 0ms',
    '&[data-has-focus="true"]': {
      borderColor: theme.indigo3
    },
    '&[data-has-value="true"]:not([data-has-focus="true"])': {
      borderColor: theme.grey2
    }
  },
  numberCompactInputField: {
    display: 'block',
    flexGrow: 1,
    appearance: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 'inherit',
    padding: '8px 11px 9px 11px',
    fontSize: 14,
    fontWeight: 400,
    color: theme.grey1,
    '&::placeholder': {
      color: theme.grey4
    }
  },
  disabledInput: {
    padding: '5px 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
