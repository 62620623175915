import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { AvatarGroup } from '@stratumn/atomic';

import styles from './label.style';

export const ChartLabel = ({ classes, avatar, name, value, type }) => {
  const isList = type === 'list';

  return (
    <div className={classes.root}>
      <AvatarGroup size={15} src={avatar} />
      <h5 className={classes.name}>{name}</h5>
      {isList && <div className={classes.dashedLine} />}
      <span className={classes.value} data-is-list={isList}>
        {isList ? value : `${(value * 100).toFixed()}%`}
      </span>
    </div>
  );
};

export default injectSheet(styles)(memo(ChartLabel));

ChartLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  type: PropTypes.string
};

ChartLabel.defaultProps = {
  type: ''
};
