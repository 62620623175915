import React, { memo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import { TAB_HEIGHT } from 'style/dimensions';

import styles from './tabMenu.style';

export const TabMenu = ({ classes, children, activeTabIndex }) => {
  // This computation is to enable the accurate animation of the tab slider
  const sliderTopPosition =
    activeTabIndex === 0 ? 0 : TAB_HEIGHT * activeTabIndex;

  return (
    <div className={classes.root}>
      <div className={classes.tabsWrapper}>
        {children}
        {/* Since we are calculating the top position dynamically, we have to use inline style */}
        <div style={{ top: sliderTopPosition }} className={classes.slider} />
      </div>
    </div>
  );
};

TabMenu.propTypes = {
  classes: PropType.object.isRequired,
  children: PropType.node.isRequired,
  activeTabIndex: PropType.number.isRequired
};

export default injectSheet(styles)(memo(TabMenu));
