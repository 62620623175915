import React, { useState, useCallback, memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

import injectSheet from 'react-jss';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker as ReactDatesPicker } from 'react-dates';
import uniqueId from 'lodash.uniqueid';

import { useMeasure } from 'utils/hooks';

import { Reset } from '@stratumn/icons';

import styles from './dateRangePicker.style';

// dummy function for DateRangePicker prop **isOutsideRange** which lets
// any dates be chosen (past and future). Avoids creating a new function on every render
const falseFunc = () => false;

export const DateRangePicker = ({
  classes,
  onSelectDates,
  defaultValues,
  lastSelectedDates
}) => {
  /**
   * We close the calendar when the client's browser is being resized.
   * This avoids a calendar bug where it disappears to the left of the screen
   */
  const containerRef = useRef();
  const [measureRef, bounds] = useMeasure(containerRef, 1);
  const [dates, setDates] = useState({
    startDate: lastSelectedDates?.startDate || defaultValues?.startDate || null,
    endDate: lastSelectedDates?.endDate || defaultValues?.endDate || null
  });
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    setFocusedInput(null);
  }, [bounds.width]);

  /**
   * Some unique ids are required by react-dates.
   * These should allow us to render two DateRangePickers on the same page without id conflicts
   */
  const UNIQUE_START_DATE_ID = useCallback(uniqueId('startDate-'), []);
  const UNIQUE_END_DATE_ID = useCallback(uniqueId('endDate-'), []);

  const resetDates = useCallback(() => {
    setDates({
      startDate: defaultValues.startDate || null,
      endDate: defaultValues.endDate || null
    });
    setFocusedInput(null);
    onSelectDates(null);
  }, [dates]);

  // This renders the Calendar section where we can clear the dates
  const renderCalendarInfo = useCallback(
    () => (
      <div
        data-cy="calendar-info"
        className={classes.calendarInfoWrapper}
        onClick={resetDates}
      >
        <Reset size={14} />
        <span className={classes.calendarInfoText}>Reset dates</span>
      </div>
    ),
    []
  );

  /**
   * @function handleSelectedDates - updates the analyticsDashboard parent component
   * with the newly selected dates.
   * We also set the state of the DateRangePicker to display UI changes
   * @param {object} d - the dates object
   * @param {object} startDate - a Moment Object
   * @param {object} endDate - a Moment Object
   */
  const handleSelectedDates = useCallback(d => {
    setDates(d);
    onSelectDates(d);
  }, []);

  return (
    <div className={classes.root} ref={measureRef}>
      <ReactDatesPicker
        startDate={dates.startDate}
        startDateId={UNIQUE_START_DATE_ID}
        endDate={dates.endDate}
        endDateId={UNIQUE_END_DATE_ID}
        onDatesChange={({ startDate, endDate }) =>
          handleSelectedDates({
            startDate: startDate,
            endDate: endDate
          })
        }
        focusedInput={focusedInput}
        onFocusChange={value => setFocusedInput(value)}
        displayFormat="MMMM, Do YYYY"
        hideKeyboardShortcutsPanel
        isOutsideRange={falseFunc}
        calendarInfoPosition="bottom"
        renderCalendarInfo={renderCalendarInfo}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectDates: PropTypes.func,
  defaultValues: PropTypes.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj
  }),
  lastSelectedDates: PropTypes.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj
  })
};

DateRangePicker.defaultProps = {
  onSelectDates: () => {},
  defaultValues: null,
  lastSelectedDates: null
};

export default injectSheet(styles)(memo(DateRangePicker));
