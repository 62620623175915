import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import Path from 'path-to-regexp';

import { ROUTE_WORKFLOW_OVERVIEW } from 'constant/routes';

import FormsBatch from 'components/ui/formsBatch';

import styles from './newLink.style';

const BATCH_MAX_FORMS = 10;

export const Batch = React.memo(props => {
  const {
    classes,
    match,
    history,
    location,
    action,
    workflowContext,
    toggleSignDialog,
    disabled,
    setFormData,
    setCleanupFn
  } = props;

  const goBack = useCallback(() => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // by default go back to workflow overview
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  }, [match, location]);

  const handleSubmit = useCallback(
    (data, fn) => {
      setFormData(data);
      setCleanupFn(fn);
      toggleSignDialog();
    },
    [setFormData, setCleanupFn, toggleSignDialog]
  );

  const { rowId: workflowRowId } = workflowContext;
  const formsBatchCachingKey = useMemo(
    () => `formsBatch_${workflowRowId}_${location.search}`,
    [workflowRowId, location]
  );

  const schema = useMemo(
    () =>
      action.form
        ? action.form.schema
        : {
            type: 'object',
            title: action.title,
            description: action.description
          },
    [action]
  );

  const uiSchema = useMemo(() => (action.form ? action.form.uiSchema : {}), [
    action
  ]);

  return (
    <div className={classes.formsBody}>
      <FormsBatch
        workflowContext={workflowContext}
        schema={schema}
        uiSchema={uiSchema}
        maxForms={BATCH_MAX_FORMS}
        onSubmit={handleSubmit}
        onCancel={goBack}
        submitDisabled={disabled}
        cachedUpdatesKey={formsBatchCachingKey}
      />
    </div>
  );
});

Batch.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  action: PropType.object.isRequired,
  workflowContext: PropType.object.isRequired,
  toggleSignDialog: PropType.func.isRequired,
  disabled: PropType.bool.isRequired,
  setFormData: PropType.func.isRequired,
  setCleanupFn: PropType.func.isRequired
};

export default compose(injectSheet(styles), withRouter)(Batch);
