import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import { FieldTextCompact } from '@stratumn/atomic';

import './react-datepicker.scss';

// TODO: Reimplement in @stratumn/atomic as another compact field

const CustomInput = ({ label, value, onClick, onChange }) => {
  // HACK: We need the datepicker to show on focus, but there's no onFocus
  // prop for FieldTextCompact. We can however leverage the inputRef prop to
  // manually add an event listener to the input!
  // It can most likely be implemented in a cleaner way in @stratumn/atomic.
  const initInput = ref => {
    if (!ref) return;
    ref.addEventListener('focus', onClick);
  };
  return (
    <FieldTextCompact
      label={label}
      value={value || 'DD/MM/YYYY'}
      inputRef={initInput}
      onValueChange={onChange}
    />
  );
};

CustomInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const DatePicker = ({ label, ...props }) => (
  <ReactDatePicker
    customInput={<CustomInput label={label} />}
    dateFormat="dd/MM/yyyy"
    {...props}
  />
);

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object
};

DatePicker.defaultProps = {
  selected: null
};

export default DatePicker;
