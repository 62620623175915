import React, { useEffect, useContext, useState } from 'react';
import PropType from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import { PDFDocument } from 'pdf-lib';
import { LoaderTraceLogo, FormWriter, Pushbutton } from '@stratumn/atomic';
import { uploadConfig } from 'client/media';

import axios from 'axios';

import styles from './newLink.style';
import { wordContext } from './caviardageTool';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

const EMAIL_REGEX = RegExp(
  '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,16}',
  'g'
);

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/'
};

const Box = ({ bid, x, y, width, height, selected, replaced }) => {
  const { updateWord } = useContext(wordContext);
  const [mouseHere, setMouseHere] = useState(false);

  const toggleBox = () => {
    updateWord(bid, 'selected', !selected);
  };

  const boxStyle = {
    width: width,
    height: height,
    fill: selected ? '#5246f7' : 'transparent',
    cursor: 'pointer',
    opacity: selected ? 0.5 : 1,
    stroke: mouseHere ? '#5246f7' : undefined,
    strokeWidth: mouseHere ? 2 : 0
  };

  const textStyle = {
    transform: 'translateY(10px)',
    fill: 'white',
    fontSize: 10
  };

  const boxTextStyle = {
    fill: 'black',
    width: width,
    height: height
  };

  if (replaced) {
    return (
      <>
        <rect x={x} y={y} style={boxTextStyle} onClick={toggleBox} />;
        <text x={x} y={y} style={textStyle}>
          {replaced}
        </text>
      </>
    );
  }

  return (
    <rect
      x={x}
      y={y}
      ariaLabelledby="svg1Title"
      style={boxStyle}
      onClick={toggleBox}
      onMouseEnter={() => setMouseHere(true)}
      onMouseLeave={() => setMouseHere(false)}
    />
  );
};

Box.defaultProps = {
  replaced: undefined
};

Box.propTypes = {
  bid: PropType.string.isRequired,
  x: PropType.number.isRequired,
  y: PropType.number.isRequired,
  width: PropType.number.isRequired,
  height: PropType.number.isRequired,
  selected: PropType.bool.isRequired,
  replaced: PropType.any
};

const BoundingBoxLayer = ({ boxCoordinates }) => (
  <svg
    style={{
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: 1200
    }}
  >
    {boxCoordinates.map(({ box, id, selected, replaced }) => (
      <Box
        key={id}
        bid={id}
        x={box.x}
        y={box.y}
        width={box.width}
        height={box.height}
        selected={selected}
        replaced={replaced}
      />
    ))}
  </svg>
);

BoundingBoxLayer.propTypes = {
  boxCoordinates: PropType.array.isRequired
};

const PageWithBoundingBoxLayer = compose(injectSheet(styles))(
  ({ pageNumber, file, setWords, classes, wordData, ...rest }) => {
    const [loading, setLoading] = useState(true);

    const { words } = useContext(wordContext);
    const boxCoordinates = words.length > 0 ? words[pageNumber - 1] : [];

    useEffect(() => {
      if (!file) {
        return;
      }
      // Fetch data
      const fetchData = async () => {
        const formData = new FormData();
        formData.append('file', file);
        const entitiesData = await axios
          .post('https://media-api.demo.stratumn.com/pii', formData, {
            headers: {
              'Content-Type': 'multipart/pdf'
            },
            withCredentials: true
          })
          .then(res => res.data);

        setLoading(false);
        setWords(
          entitiesData.map((page, pid) =>
            page.map((w, i) => {
              if (w?.text?.match(EMAIL_REGEX)) {
                console.log('email', w.text, w.containsEntity);
                w.containsEntity = true;
              }
              return {
                id: `${pid}-${i}`,
                ...w,
                selected: w.containsEntity
              };
            })
          )
        );
      };

      fetchData();
    }, [file]);

    if (loading) {
      return (
        // Display the page with a gray filter to indicate that it is loading with a spinner
        <Page
          height={1200}
          pageNumber={pageNumber}
          {...rest}
          renderAnnotationLayer={false}
          className={classes.pageLoading}
        >
          <div className={classes.bgLoader} data-loading={loading}>
            <LoaderTraceLogo />
          </div>
        </Page>
      );
    }

    return (
      <Page height={1200} pageNumber={pageNumber} {...rest}>
        <BoundingBoxLayer boxCoordinates={boxCoordinates} />
      </Page>
    );
  }
);

PageWithBoundingBoxLayer.propTypes = {
  file: PropType.object.isRequired,
  pageNumber: PropType.number.isRequired
};

const PdfEditor = compose(injectSheet(styles))(props => {
  const { classes, setPdfDoc, setWords } = props;
  const [file, setFile] = useState(null);
  const [shouldRenderForm, setShouldRenderForm] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleFileChange = async event => {
    const fileTmp = event;
    setFile(fileTmp);
    const reader = new FileReader();
    reader.onload = async () => {
      const arrayBuffer = reader.result;
      const uint8Array = new Uint8Array(arrayBuffer);
      const pdfDoc = await PDFDocument.load(uint8Array);
      setPdfDoc(pdfDoc);
    };
    await reader.readAsArrayBuffer(fileTmp);

    setPageNumber(1);
  };

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const { MAX_FILE_SIZE, FILE_EXTENSION_WHITELIST_MAP } = uploadConfig;

  if (!file) {
    return (
      <FormWriter
        id="formid"
        title="Sensitive document uploader"
        description="Upload the document with sentitive information here"
        schema={{
          type: 'object',
          properties: {
            file: {
              title: 'Document',
              type: 'array',
              items: {
                type: 'object',
                required: ['digest', 'name', 'size', 'createdAt'],
                properties: {
                  digest: {
                    type: 'string'
                  },
                  name: {
                    type: 'string'
                  },
                  size: {
                    type: 'integer'
                  },
                  key: {
                    type: 'string'
                  },
                  createdAt: {
                    type: 'string',
                    format: 'date-time'
                  }
                }
              }
            }
          },
          required: []
        }}
        uiSchema={{
          'ui:order': ['file'],
          file: { 'ui:field': 'FileUploadField' }
        }}
        onChange={() => {}}
        uploadFile={handleFileChange}
        uploadConfig={{
          maxFileSize: MAX_FILE_SIZE,
          fileExtensionWhitelistMap: FILE_EXTENSION_WHITELIST_MAP
        }}
        onSubmit={() => {}}
        workflowContext={{}}
      />
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxHeight: 1200,
          overflow: 'hidden'
        }}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          renderMode="canvas"
        >
          <PageWithBoundingBoxLayer
            pageNumber={pageNumber}
            file={file}
            renderTextLayer={false}
            setWords={setWords}
          />
        </Document>
      </div>
      <div className={classes.buttonBottom}>
        <Pushbutton
          primary
          onClick={() => setPageNumber(page => (page > 1 ? page - 1 : 1))}
        >
          {'<'}
        </Pushbutton>
        <p className={classes.middleText}>
          {pageNumber} / {numPages}
        </p>
        <div className={classes.rightTop}>
          <Pushbutton
            primary
            onClick={() =>
              setPageNumber(page => (page < numPages ? page + 1 : numPages))
            }
          >
            {'>'}
          </Pushbutton>
        </div>
      </div>
    </div>
  );
});

export default PdfEditor;
