import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import moment from 'moment';

import { pluralize } from 'utils';

import { graphType } from '../../../constant';
import styles from './customTooltip.style';

export const CustomTooltip = ({
  classes,
  title,
  active,
  payload,
  type,
  description
}) => {
  // bar chart var
  const name = payload && payload[0]?.payload.action;
  const value = payload && payload[0]?.value;

  // column chart var
  const count = payload && payload[0]?.payload.y;
  const day = payload && payload[0]?.payload.x;

  const renderTitle = useMemo(() => {
    let createdAt;

    switch (type) {
      case graphType.BAR:
        if (description) return description;
        return name;

      case graphType.AREA:
        return 'completed traces';

      case graphType.COLUMN:
        createdAt = moment.utc(day).local();
        return `${pluralize(count, title, true)} created in ${createdAt.format(
          'MMMM YYYY'
        )}`;

      default:
        return null;
    }
  }, [type, name, count, day]);

  const renderValue = useMemo(() => {
    switch (type) {
      case graphType.BAR:
        if (description) return value;
        return pluralize(value, title, true);
      case graphType.AREA:
        return value;

      default:
        return null;
    }
  }, [type, value]);

  return active ? (
    <div className={classes.root}>
      <h5
        className={classes.title}
        style={{ paddingBottom: type === graphType.BAR ? 10 : 0 }}
      >
        {renderTitle}
      </h5>
      <p className={classes.description}>{renderValue}</p>
    </div>
  ) : null;
};

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        action: PropTypes.string,
        x: PropTypes.string,
        y: PropTypes.number
      }),
      value: PropTypes.number
    })
  )
};

CustomTooltip.defaultProps = {
  title: '',
  description: '',
  payload: null
};

export default injectSheet(styles)(memo(CustomTooltip));
