export default theme => ({
  textInput: {
    width: '100%',
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: 'border-color 150ms linear 0ms',
    borderRadius: 3,
    padding: '5px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.white1,
      borderColor: theme.grey5,
      '& $textInputIcon': {
        color: theme.grey3,
        width: 17
      }
    },
    '&[data-input-focused=true]': {
      cursor: 'text',
      backgroundColor: theme.white1,
      borderColor: theme.indigo3
    }
  },
  textInputField: {
    cursor: 'pointer',
    flexGrow: 1,
    fontSize: 13,
    color: theme.grey1,
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::placeholder': {
      color: theme.grey3
    },
    '&:focus': {
      cursor: 'unset',
      color: theme.grey2,
      fontWeight: 'normal'
    }
  },
  textInputIcon: {
    marginLeft: 5,
    flexShrink: 0,
    height: 17,
    width: 0,
    color: theme.grey4,
    '&[data-is-visible=true]': {
      width: 17
    }
  },
  textCompactInput: {
    width: '100%',
    height: 40,
    '& label': {
      height: '100%'
    }
  },
  disabledInput: {
    padding: '5px 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
