export default theme => ({
  root: {
    display: 'flex',
    height: 8,
    width: '100%',
    backgroundColor: theme.grey8,
    borderRadius: 10
  },
  fill: {
    height: '100%',
    width: ({ percent }) => `${percent * 100}%`,
    background: 'linear-gradient(90deg, #4B35D2 0%, #5246F7 100%)',
    borderRadius: 'inherit',
    transformOrigin: 'left center',
    animation: 'expandWidth 350ms cubic-bezier(.17,.67,.4,.96)',
    '&[data-is-largest=true]': {
      background: 'linear-gradient(90deg, #30CECA 0%, #6BEDD8 100%)'
    }
  },
  '@keyframes expandWidth': {
    from: { transform: 'scaleX(0)' },
    to: { transform: 'scaleX(1)' }
  }
});
