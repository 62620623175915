import React, { useCallback, useEffect } from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import moment from 'moment';
import filesize from 'filesize';
import { withSnackbarsContext, Icon, TextEllipsis } from '@stratumn/atomic';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { useSelectableItemsContext } from 'components/ui/widget/contexts/selectableItems';

import { downloadFile } from 'utils/downloadFile';
import { getFileIconStr } from 'utils';
import { getByPath } from 'utils/widgets';

import styles from './file.style';

const convertDate = date => {
  const newdate = moment(date).format('DD/MM/YYYY LT');
  return newdate;
};

const DEFAULT_FILE_PATHS = {
  USER_NAME: 'upload.user',
  DATE: 'upload.date'
};

export const FileView = React.memo(
  ({ classes, view, data, errorSnackbar, setSelectableItemData }) => {
    const selectableItemsContext = useSelectableItemsContext();

    const [loading, setLoading] = React.useState(false);
    const [downloadResponse, setDownloadResponse] = React.useState(null);

    const {
      fileDataPath = '',
      uploadUserNamePath = DEFAULT_FILE_PATHS.USER_NAME,
      uploadDatePath = DEFAULT_FILE_PATHS.DATE
    } = view;

    const fileData = getByPath(data, fileDataPath);
    const uploadUserName = getByPath(data, uploadUserNamePath);
    const uploadDate = getByPath(data, uploadDatePath);

    useEffect(() => {
      if (setSelectableItemData) setSelectableItemData(fileData);
    }, [fileData]);

    const downloadFileFn = useCallback(
      () =>
        downloadFile(
          fileData,
          errorSnackbar,
          setLoading,
          loading,
          setDownloadResponse
        ),
      [fileData]
    );

    if (!fileData || typeof fileData !== 'object') return null;

    return (
      <div className={classes.root}>
        <LoadingIndicator
          spinning={loading}
          showSuccess={
            downloadResponse !== null || !!selectableItemsContext?.isCompleted
          }
        >
          <div
            className={classes.file}
            onClick={downloadFileFn}
            data-cy="file-link"
            data-is-loading={loading}
          >
            <div className={classes.left}>
              <div className={classes.fileIcon}>
                <Icon
                  name={getFileIconStr(fileData.mimetype, fileData.name)}
                  size={20}
                />
              </div>
              <div className={classes.fileInfo}>
                <TextEllipsis
                  className={classes.fileName}
                  text={fileData.name}
                  middleEllipsis
                />
                <div className={classes.fileSize}>
                  {filesize(fileData.size)}
                </div>
              </div>
            </div>
            <div className={classes.right}>
              {uploadUserName && (
                <div className={classes.uploader}>{uploadUserName}</div>
              )}
              {uploadDate && (
                <div className={classes.uploadDate}>
                  {convertDate(uploadDate)}
                </div>
              )}
            </div>
          </div>
        </LoadingIndicator>
      </div>
    );
  }
);

// sort defaults to text on datePath (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text',
  path: view.datePath || DEFAULT_FILE_PATHS.DATE
});

FileView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
  setSelectableItemData: PropTypes.func
};
FileView.defaultProps = {
  setSelectableItemData: undefined
};

export default {
  component: compose(withSnackbarsContext, injectSheet(styles))(FileView),
  getSortConfig
};
