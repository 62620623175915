import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import Skeleton from '../../../skeleton';
import { pixelToPercentConvertion, SMALL_PROPS } from '../../../skeleton/utils';

import style from './columnChartSkeleton.style';

export const ColumnChartSkeleton = ({ classes }) => {
  const SkeletonGroup = () => (
    <>
      <Skeleton
        height={96}
        width={pixelToPercentConvertion(576, 14)}
        {...SMALL_PROPS}
      />
      <Skeleton
        height={24}
        width={pixelToPercentConvertion(576, 14)}
        {...SMALL_PROPS}
      />
      <Skeleton
        height={62}
        width={pixelToPercentConvertion(576, 14)}
        {...SMALL_PROPS}
      />
    </>
  );

  return (
    <div className={classes.skeletonCardFour}>
      <section className={classes.skeletonCardGroup}>
        {Array(10)
          .fill('')
          .map((_el, i) => (
            <SkeletonGroup key={i} />
          ))}
        <Skeleton
          height={96}
          width={pixelToPercentConvertion(576, 14)}
          {...SMALL_PROPS}
        />
      </section>
      <section className={classes.skeletonCardGroup}>
        {Array(2)
          .fill('')
          .map((_el, i) => (
            <Skeleton
              height={13}
              width={pixelToPercentConvertion(576, 80)}
              {...SMALL_PROPS}
              key={i}
            />
          ))}
      </section>
    </div>
  );
};

ColumnChartSkeleton.propTypes = {
  classes: PropType.object.isRequired
};

export default injectSheet(style)(ColumnChartSkeleton);
