import moment from 'moment';

import { getByPath, numberSatisfiesCondition } from 'utils/widgets';

const getInterpreterConfigType = (interpreterConfig = {}) =>
  (interpreterConfig.type || '').toLowerCase();

const getTextDataToMatch = (path, dataRow, interpreterConfig = {}) => {
  let dataToMatch = getByPath(dataRow, path) || '';
  if (typeof dataToMatch === 'object') {
    dataToMatch = JSON.stringify(dataToMatch);
  }
  if (getInterpreterConfigType(interpreterConfig) === 'date') {
    // cast to the required format
    // to apply correct comparison with what the user is seeing
    // when inputting the filter value
    const { inputFormat, format } = interpreterConfig;
    dataToMatch = moment(dataToMatch, inputFormat).format(format);
  }
  return dataToMatch.toString();
};

// simple search of string occurrence
const applySearchFilter = (path, interpreterConfig, filterValue, data) => {
  // build the list of values to search for
  const filterConditions = filterValue
    .split(',')
    .map(filterCondition => filterCondition.trim().toUpperCase());

  return data.filter(dataRow => {
    const dataToMatch = getTextDataToMatch(
      path,
      dataRow,
      interpreterConfig
    ).toUpperCase();
    for (let idx = 0; idx < filterConditions.length; idx += 1) {
      const match = filterConditions[idx];
      if (match !== '' && dataToMatch.includes(match)) {
        return true;
      }
    }
    return false;
  });
};

// regex patttern match
const applyRegexFilter = (path, interpreterConfig, filterValue, data) => {
  // try to build the regular expression object
  try {
    const regExpr = new RegExp(filterValue, interpreterConfig.flags);

    return data.filter(dataRow => {
      const dataToMatch = getTextDataToMatch(path, dataRow);
      return regExpr.test(dataToMatch);
    });
  } catch (err) {
    // if the regex is invalid just send the original data
    console.log(`Invalid regular expression: ${filterValue}`);
    return data;
  }
};

const getConditionNumberValue = numberString => {
  if (numberString === '') return undefined;
  const numberValue = Number(numberString);
  if (Number.isNaN(numberValue)) return undefined;
  return numberValue;
};
// number match
const applyNumberFilter = (path, interpreterConfig, filterValue, data) => {
  // build the list of number conditions to check for
  const filterConditions = filterValue.split(',').map(filterCondition => {
    if (!filterCondition) return null;
    if (filterCondition.includes('<>')) {
      // difference condition eg <>3
      return {
        notEqual: getConditionNumberValue(filterCondition.split('<>')[1])
      };
    }
    if (filterCondition.includes('<<')) {
      // range condition eg 10<<=15
      const bits = filterCondition.split('<<');
      const condition = {};
      // lower bound
      if (bits[0].includes('=')) {
        // inclusive lower bound
        condition.minimum = getConditionNumberValue(bits[0].split('=')[0]);
      } else {
        // exclusive lower bound
        condition.exclusiveMinimum = getConditionNumberValue(bits[0]);
      }
      // upper bound
      if (bits[1].includes('=')) {
        // inclusive upper bound
        condition.maximum = getConditionNumberValue(bits[1].split('=')[1]);
      } else {
        // exclusive upper bound
        condition.exclusiveMaximum = getConditionNumberValue(bits[1]);
      }
      // return the condition
      return condition;
    }
    if (filterCondition.includes('<')) {
      // lower than condition eg <=15
      const bits = filterCondition.split('<');
      const condition = {};
      // upper bound
      if (bits[1].includes('=')) {
        // inclusive upper bound
        condition.maximum = getConditionNumberValue(bits[1].split('=')[1]);
      } else {
        // exclusive upper bound
        condition.exclusiveMaximum = getConditionNumberValue(bits[1]);
      }
      // return the condition
      return condition;
    }
    if (filterCondition.includes('>')) {
      // greater than condition eg >=10
      const bits = filterCondition.split('>');
      const condition = {};
      // lower bound
      if (bits[1].includes('=')) {
        // inclusive lower bound
        condition.minimum = getConditionNumberValue(bits[1].split('=')[1]);
      } else {
        // exclusive lower bound
        condition.exclusiveMinimum = getConditionNumberValue(bits[1]);
      }
      // return the condition
      return condition;
    }
    // by default it is interpreted as a number equality
    return {
      equal: getConditionNumberValue(filterCondition)
    };
  });

  return data.filter(dataRow => {
    const dataToMatch = Number(getByPath(dataRow, path));
    if (Number.isNaN(dataToMatch)) {
      return false;
    }
    for (let idx = 0; idx < filterConditions.length; idx += 1) {
      const condition = filterConditions[idx];
      if (condition && numberSatisfiesCondition(dataToMatch, condition)) {
        return true;
      }
    }
    return false;
  });
};

// check for a row matching a text input at a given path
const applyTextInputFilter = (path, interpreterConfig, filterValue, data) => {
  // filter value needs to be a real string
  if (typeof filterValue !== 'string') return data;

  switch (getInterpreterConfigType(interpreterConfig)) {
    case 'regex':
      return applyRegexFilter(path, interpreterConfig, filterValue, data);
    case 'number':
      return applyNumberFilter(path, interpreterConfig, filterValue, data);
    case 'search':
    case 'date':
    default:
      return applySearchFilter(path, interpreterConfig, filterValue, data);
  }
};

// check for a date being in a range
const applyDateRangeFilter = (path, format, inputFormat, filterValue, data) => {
  // filter value needs to be a real js object
  if (typeof filterValue !== 'object' || Array.isArray(filterValue)) {
    return data;
  }

  const { startDate, endDate } = filterValue;
  const startDateObj = startDate
    ? moment.utc(startDate, format, true).local()
    : undefined;
  const endDateObj = endDate
    ? moment.utc(endDate, format, true).local()
    : undefined;

  return data.filter(dataRow => {
    // get the value at the specified path for this row
    const dateToMatchStr = getByPath(dataRow, path);
    if (dateToMatchStr === undefined || typeof dateToMatchStr !== 'string')
      return false;

    // build the date and check it is a valid one
    const dateToMatch = moment.utc(dateToMatchStr, inputFormat, true).local();
    if (!dateToMatch.isValid()) return false;

    // compare to start and an end dates
    if (startDateObj && dateToMatch.diff(startDateObj) < 0) return false;
    if (endDateObj && dateToMatch.diff(endDateObj) > 0) return false;

    return true;
  });
};

// client side filter

export const filterData = (dataArray, filterConfig, filterValue) => {
  // console logging of the filter execution, very useful for debugging but commented out in prod not to slow UX down
  // console.log(`filtering ${JSON.stringify(filterConfig)} ${filterValue}`);

  const { path, type } = filterConfig;
  // switch filter interface type, default to 'text'
  switch (type) {
    case 'dateRange':
      // date range picker interface
      return applyDateRangeFilter(
        path,
        filterConfig.format,
        filterConfig.inputFormat,
        filterValue,
        dataArray
      );
    default:
      // text input interface
      return applyTextInputFilter(
        path,
        filterConfig.interpreter,
        filterValue,
        dataArray
      );
  }
};
