export default theme => ({
  tableHeader: {
    padding: '10px 8px',
    borderBottom: `1px solid ${theme.grey4}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableHeaderGroup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'right',
    alignItems: 'center',
    '&[data-is-left=true]': {
      justifyContent: 'left'
    },
    '&[data-is-right=true]': {
      justifyContent: 'right'
    }
  },
  tableHeaderElementSeparator: {
    width: 20
  },
  tableHeaderGroupSeparator: {
    width: 20,
    height: 20,
    borderLeft: `1px solid ${theme.grey5}`,
    marginLeft: 20
  },
  tableHeaderDisplayWrapper: {
    position: 'relative'
  },
  displayMenuBackground: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  displayMenuWrapper: {
    position: 'absolute',
    zIndex: 6,
    top: 7,
    right: 'calc(100% - 42px)'
  },
  showAggregationLabel: {
    fontSize: 12
  },
  rowNumberStyle: {
    fontSize: 12,
    color: theme.grey2,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: '5px',
    '&[data-is-clicked=true]': {
      color: theme.indigo3
    }
  }
});
