export const KPI_CARD_BORDER_RADIUS = 5;

export default theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    borderTopStyle: 'solid',
    borderTopWidth: '5px',
    borderRadius: `${KPI_CARD_BORDER_RADIUS}px ${KPI_CARD_BORDER_RADIUS}px 0 0`
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 14,
    backgroundColor: theme.white1,
    transition: '250ms',
    '&[data-is-active=false]': {
      background: 'transparent',
      color: theme.grey2,
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    marginLeft: 10,
    textTransform: 'uppercase'
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.07em',
    '&[data-is-active=true]': {
      color: theme.grey1
    }
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    color: theme.grey2
  },
  value: {
    paddingRight: 5,
    fontWeight: 'normal',
    fontSize: 20,
    lineHeight: '25px'
  },
  description: {
    marginTop: 14,
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '13px'
  }
});
