import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import WorkflowRow, { fragments as workflowRowFragments } from './row';

import styles from './workflowsList.style';

// workflows list of the user dashboard
export const WorkflowsList = React.memo(
  ({ classes, className, workflows, isSuperuser }) => {
    const { nodes: workflowsList = [] } = workflows;

    // check if user is admin of at least one workflow
    // to see if a 5th settings column is required
    const hasSettingsColumn =
      useMemo(() => workflowsList.some(workflow => workflow.canUpdate), [
        workflowsList
      ]) && isSuperuser;

    // note: to make the tbody scrollable we need to set thead and tbody display: 'block'
    // and then reset thead tr and tbody tr style to display: 'table'
    // the consequence is that <colgroup> styling defined at table level cannot apply anymore
    // so we pass down the columns classNames to the row component so it can apply the correct style to each column
    // while defining the widths at a single place
    // also note that table-layout has to be 'fixed' and not 'auto' so that both udl tables don't adjust themselves independently and finish displaced...
    const columnsClassNames = useMemo(
      () => ({
        columnName: hasSettingsColumn
          ? classes.columnNameSmaller
          : classes.columnName,
        columnTraces: classes.columnTraces,
        columnGroups: classes.columnGroups,
        columnDocumentation: classes.columnDocumentation,
        columnSettings: classes.columnSettings
      }),
      [classes]
    );

    // the table itself...
    // note: as above having tbody scrollable 'auto' is a mess...
    // we need to check programmatically if tbody will be effectively scrollable
    // and if not apply a padding of the size of the scrollbar
    // each line has height 50px and the tbody should have max height 200px so it should be scrollable only if more than 4 items
    // note: no header for the 5th column, settings, in the design
    const workflowsListContent =
      workflowsList.length > 0 ? (
        <table className={classes.workflowsTable}>
          <thead>
            <tr>
              <th className={columnsClassNames.columnName}>Name</th>
              <th className={columnsClassNames.columnTraces}>Traces</th>
              <th className={columnsClassNames.columnGroups}>Groups</th>
              <th className={columnsClassNames.columnDocumentation}>
                Workflow documentation
              </th>
              {hasSettingsColumn && (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <th className={columnsClassNames.columnSettings} />
              )}
            </tr>
          </thead>
          <tbody data-is-scrollable={workflowsList.length > 4}>
            {workflowsList.map(w => (
              <WorkflowRow
                key={w.rowId}
                columnsClassNames={columnsClassNames}
                workflow={w}
                isSuperuser={isSuperuser}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className={classes.listInfoContainer}>
          <div className={classes.emptyListMessage}>No workflow.</div>
        </div>
      );

    return (
      <div className={classnames(className, classes.container)}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            Your workflows
            <div className={classes.headerTitleUnderline} />
          </div>
        </div>
        <div className={classes.listContainer}>{workflowsListContent}</div>
      </div>
    );
  }
);

WorkflowsList.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  workflows: PropTypes.object,
  isSuperuser: PropTypes.bool
};
WorkflowsList.defaultProps = {
  className: '',
  workflows: {},
  isSuperuser: false
};

export default injectSheet(styles)(WorkflowsList);

// export the workflows table query
export const fragments = {
  workflows: gql`
    fragment WorkflowsFragment on Query {
      workflows(filter: { draft: { equalTo: false } }) {
        totalCount
        nodes {
          rowId
          ...WorkflowRowFragment
        }
      }
    }
    ${workflowRowFragments.workflowRow}
  `
};
