import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import theme from 'style';

import {
  Bar,
  BarChart,
  XAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';

import ColumnChartSkeleton from './skeleton';
import { CustomTooltip, Placeholder } from '../ui';
import CustomTick from './customTick';

import { TYPE_COLUMN } from '../../constant';

import styles from './column.style';

export const ColumnChart = ({ classes, dataset, loading }) => {
  const [focusBar, setFocusBar] = useState(null);

  if (loading) return <ColumnChartSkeleton />;
  if (dataset.length === 0) return <Placeholder />;

  return (
    <ResponsiveContainer className={classes.root} width="100%">
      <BarChart
        data={dataset}
        barCategoryGap="15%"
        margin={{ left: -9 }}
        onMouseMove={({ isTooltipActive, activeTooltipIndex }) =>
          setFocusBar(isTooltipActive ? activeTooltipIndex : setFocusBar(null))
        }
      >
        <CartesianGrid
          horizontal={false}
          stroke={theme.grey5}
          strokeDasharray="5 5"
        />
        <XAxis
          dataKey="day"
          axisLine={{ stroke: 'rgb(236,235,242)' }}
          tickLine={{ stroke: 'rgb(236,235,242)', right: 10 }}
          // 0 means we'll render all ticks
          interval={0}
          tick={<CustomTick />}
        />
        <Tooltip
          content={<CustomTooltip title="trace" type={TYPE_COLUMN} />}
          cursor={false}
        />
        <Bar dataKey="count">
          {dataset.map((entry, index) => {
            const cellProps = {
              key: `cell-${index}`,
              radius: 2,
              fill: focusBar === index ? theme.indigo1 : theme.indigo2
            };

            // Even for a month without any trace, we want to display a tiny column
            // to match the design
            return entry.count === 0 ? (
              <Cell {...cellProps} height={-1} />
            ) : (
              <Cell {...cellProps} />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

ColumnChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.date,
      count: PropTypes.number
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectSheet(styles)(memo(ColumnChart));
