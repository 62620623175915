import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

// Drag n' Drop n' Enjoy
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

// Resizable column header
import ResizableColHeader from '../resizableColHeader';

import styles from './colHeadersEditor.style';

// table column headers editor
// drag n drop functionality and columns resizing
const ColHeadersEditor = React.memo(
  ({
    classes,
    height,
    columns,
    minColumnsWidth,
    reorderColumns,
    setColumnWidth
  }) => (
    <DragDropContext
      onDragEnd={result => {
        reorderColumns(result);
      }}
    >
      <div
        className={classes.colHeadersEditor}
        style={{
          height: height + 2
        }}
      >
        <Droppable
          droppableId="columns-headers-droppable"
          direction="horizontal"
        >
          {providedDroppable => (
            <div
              className={classes.colHeadersEditorDroppable}
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {columns.map((colConfig, index) => (
                <Draggable
                  key={colConfig.key}
                  draggableId={colConfig.key}
                  index={index}
                >
                  {(providedDraggable, snapshotDraggable) => {
                    const draggableItem = (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        className={classes.colHeadersEditorDraggableItem}
                        data-is-dragging={snapshotDraggable.isDragging}
                        style={{
                          ...providedDraggable.draggableProps.style
                        }}
                      >
                        <ResizableColHeader
                          width={colConfig.width}
                          minWidth={minColumnsWidth}
                          height={height}
                          columnKey={colConfig.key}
                          columnHeader={colConfig.def.header}
                          setColumnWidth={setColumnWidth}
                          dragHandleProps={providedDraggable.dragHandleProps}
                          allowResizing={!snapshotDraggable.isDragging}
                        />
                      </div>
                    );
                    return snapshotDraggable.isDragging
                      ? createPortal(
                          draggableItem,
                          document.getElementById(TOOLTIP_PORTAL)
                        )
                      : draggableItem;
                  }}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
);
ColHeadersEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  minColumnsWidth: PropTypes.number.isRequired,
  reorderColumns: PropTypes.func.isRequired,
  setColumnWidth: PropTypes.func.isRequired
};

export default injectSheet(styles)(ColHeadersEditor);
