import React from 'react';
import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';
import { Transfer, Clipboard, Outgoing } from '@stratumn/icons';

import {
  STAGE_TYPE_INCOMING,
  STAGE_TYPE_OUTGOING,
  STAGE_TYPE_BACKLOG,
  STAGE_TYPE_ATTESTATION
} from 'constant/stage';

export const orderStages = stages => [
  ...stages.filter(s => s.type === STAGE_TYPE_INCOMING),
  ...stages.filter(s => s.type === STAGE_TYPE_BACKLOG),
  ...stages.filter(s => s.type === STAGE_TYPE_ATTESTATION),
  ...stages.filter(s => s.type === STAGE_TYPE_OUTGOING)
];

export const renderStageIcon = stage => {
  const icon = (stage.action && stage.action.icon) || 'Document';

  switch (stage.type) {
    case STAGE_TYPE_INCOMING:
      return <Transfer />;
    case STAGE_TYPE_BACKLOG:
      return <Clipboard />;
    case STAGE_TYPE_OUTGOING:
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Outgoing />
        </div>
      );
    case STAGE_TYPE_ATTESTATION:
      return <DynamicIcon icon={icon} />;
    default:
      return null;
  }
};
