import React, { memo, useMemo } from 'react';
import PropType from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import injectSheet from 'react-jss';

import { useQuery } from 'react-query';
import { REPORTING_API_REPORT } from 'constant/api';

import moment from 'moment';

import { TYPE_REPORT } from '../constant';

import KpiCard from '../kpiCard';
import GraphCard from '../graphCard';

import { BarChartReport, ProgressChart } from '../charts';

import styles from './report.style';

/**
 * @function formatDate
 * @param {object} d - moment object (startDate or endDate)
 * @returns {string} - the format expected by the reporting api
 */
const formatDate = d => {
  const formattedDate = moment.utc(d).local().format('YYYY-MM-DD');
  return formattedDate === 'Invalid date' ? null : formattedDate;
};

export const fetchReportingData = async (_key, workflowRowId, dateRange) => {
  const startDate = formatDate(dateRange.startDate);
  const endDate = formatDate(dateRange.endDate);

  const response = await fetch(
    `${REPORTING_API_REPORT}/${workflowRowId}?startDate=${startDate}&endDate=${endDate}`,
    {
      credentials: 'include'
    }
  );
  return response.json();
};

export const Report = ({ classes, workflowRowId, dateRange }) => {
  /**
   * @function useQuery
   * @param {array}
   * @param {array[0]-string} key - the query key so manage the cache
   * @param {array[1]-string} workflowRowId - the first query param to fetch the data for a particular workflow
   * @param {array[2]-object} dateRange - the second query param to fetch data filtered by date range.
   * @param {string} dateRange.startDate
   * @param {string} dateRange.endDate
   * @param {function} fetchReportingData - a promise using the fetch api
   * @returns {object} - internal state managed by react-query
   */
  const { isLoading, error, data } = useQuery(
    ['reporting', workflowRowId, dateRange],
    fetchReportingData
  );

  /**
   * @function renderBarChartReport - computes the pagination if dataset.length > 9 entries
   * @returns {Array} - an array of React.nodes
   */
  const renderBarChartReport = useMemo(() => {
    // the design is set to 9 rows
    const rowsPerCard = 9;

    const actions = data?.averageTimePerAction;
    if (!actions) return null;
    /**
     * If no data, we return an empty BarChart.
     * It will then call the Placeholder component with "No data to display"
     */
    if (actions.length === 0) return <BarChartReport />;

    const copyActions = [...actions];

    // split the dataset into sub datasets arrays.
    return (
      new Array(Math.ceil(copyActions.length / rowsPerCard))
        .fill()
        .map(() => copyActions.splice(0, rowsPerCard))
        // each dataset is then its own BarChart to fit inside the pagination tabs
        .map((e, i) => (
          <BarChartReport key={i} rowsPerCard={rowsPerCard} dataset={e} />
        ))
    );
  }, [data?.averageTimePerAction]);
  if (error) return `An error has occurred: ${error.message}`;

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <div className={classes.topRowLeftColumn}>
          <div className={classes.kpiCardLeft}>
            <KpiCard
              title="completed traces"
              iconLarge="TimeLine"
              value={data?.openTraces?.count || 0}
              fluctuation={data?.openTraces?.percentChange || null}
              tooltip="All traces where a completion action happened during the period."
              light
              loading={isLoading}
            />
          </div>
          <div className={classes.kpiCardRight}>
            <KpiCard
              title="New traces"
              iconLarge="AddCircle"
              value={data?.newTraces?.count || 0}
              fluctuation={data?.newTraces?.percentChange || null}
              tooltip="Number of traces created within the selected date range."
              light
              loading={isLoading}
            />
          </div>
        </div>
        <GraphCard
          title="Average time per main action"
          tooltip="The average delay between actions performed on the trace."
          loading={isLoading}
        >
          {/** This empty BarChart component will call the <BarChartSkeleton /> */}
          {isLoading ? (
            <BarChartReport loading={isLoading} />
          ) : (
            renderBarChartReport
          )}
        </GraphCard>
      </div>

      <div className={classes.rightColumn}>
        <GraphCard
          title="Top 10 groups in terms of workflow activity"
          tooltip="Distribution of all the actions that happened in the workflow during the period, by group (%)."
          loading={false}
        >
          {/** Displays the top 10 contributing groups */}
          <ProgressChart
            dataset={data?.topContributingGroups?.slice(0, 10) || []}
            type={TYPE_REPORT}
            loading={isLoading}
          />
        </GraphCard>
      </div>
    </div>
  );
};

Report.propTypes = {
  classes: PropType.object.isRequired,
  workflowRowId: PropType.string.isRequired,
  dateRange: PropType.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj
  })
};

Report.defaultProps = {
  dateRange: null
};

export default injectSheet(styles)(memo(Report));
