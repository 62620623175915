import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import pathToRegexp from 'path-to-regexp';
import gql from 'graphql-tag';

import { ROUTE_GROUP_BOARD } from 'constant/routes';
import { orderStages } from 'utils/stage';
import { ATCGroup } from '@stratumn/atomic';
import { history } from 'components/root';
import StageBox, { fragments as stageBoxFragments } from '../stage';

export class GroupColumn extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired
  };

  goToGroupBoard = () =>
    history.push(
      pathToRegexp.compile(ROUTE_GROUP_BOARD)({ id: this.props.group.rowId })
    );

  render = () => {
    const { group } = this.props;
    // if the group id starts with a '-', it comes from an optimistic response.
    const isGroupReady = !group.rowId.startsWith('-');

    const stages = orderStages(group.stages.nodes);

    return (
      <ATCGroup
        key={group.rowId}
        avatarSrc={group.avatar}
        header={group.name}
        headerOnClick={this.goToGroupBoard}
        loading={!isGroupReady}
      >
        {stages.map(stage => (
          <StageBox groupId={group.rowId} stage={stage} key={stage.rowId} />
        ))}
      </ATCGroup>
    );
  };
}

export const fragments = {
  group: gql`
    fragment GroupColumnFragment on Group {
      rowId
      name
      avatar
      stages {
        nodes {
          ...StageBoxFragment
        }
        totalCount
      }
    }
    ${stageBoxFragments.stage}
  `
};

export default compose()(GroupColumn);
