export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fill: theme.grey1
  },
  value: {
    color: theme.grey1,
    marginLeft: 5,
    fontSize: 11,
    lineHeight: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
