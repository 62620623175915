import React, { memo, useState } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import theme from 'style';

import {
  Bar,
  BarChart as Chart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Cell
} from 'recharts';

import CustomTick from './customTick';
import BarChartSkeleton from './skeleton';
import { CustomTooltip, Placeholder } from '../ui';

import { computeAspectRatio } from '../../utils';

import { TYPE_BAR } from '../../constant';

import styles from './bar.style';

export const BarChart = ({ classes, rowsPerCard, dataset, loading }) => {
  const [focusBar, setFocusBar] = useState(null);

  const handleOnMouseMove = ({ isTooltipActive, activeTooltipIndex }) =>
    setFocusBar(isTooltipActive ? activeTooltipIndex : setFocusBar(null));

  if (loading) return <BarChartSkeleton />;
  if (dataset.length === 0) return <Placeholder />;

  const rowCount = dataset.length;

  return (
    <div className={classes.wrapper}>
      <ResponsiveContainer
        width="99%"
        aspect={computeAspectRatio(rowsPerCard, rowCount)}
      >
        <Chart
          data={dataset}
          margin={{ top: 0, right: 10, left: 20, bottom: 0 }}
          barCategoryGap="25%"
          layout="vertical"
          onMouseMove={handleOnMouseMove}
        >
          <CartesianGrid
            horizontal={false}
            stroke={theme.grey5}
            strokeDasharray="5 5"
          />
          <YAxis
            width={250}
            type="category"
            axisLine={false}
            tickLine={false}
            tick={<CustomTick data={dataset} payload />}
            dataKey="action"
          />
          <XAxis
            type="number"
            domain={[0, 'dataMax']}
            tickLine={{ stroke: 'transparent' }}
            tick={{ fontSize: 10 }}
            axisLine={false}
            tickCount={9}
            allowDecimals={false}
          />
          <Tooltip
            content={<CustomTooltip title="trace" type={TYPE_BAR} />}
            cursor={false}
          />
          <Bar dataKey="count">
            {dataset.map((_entry, index) => (
              <Cell
                height={18}
                key={`cell-${index}`}
                radius={5}
                fill={focusBar === index ? theme.indigo1 : theme.indigo2}
              />
            ))}
          </Bar>
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

BarChart.propTypes = {
  classes: PropType.object.isRequired,
  loading: PropType.bool,
  dataset: PropType.arrayOf(
    PropType.shape({
      icon: PropType.string,
      averageTime: PropType.number,
      action: PropType.string,
      count: PropType.number
    })
  ),
  rowsPerCard: PropType.number
};

BarChart.defaultProps = {
  dataset: [],
  rowsPerCard: null,
  loading: false
};

export default injectSheet(styles)(memo(BarChart));
