import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Skeleton from '../../../skeleton';
import { pixelToPercentConvertion, SMALL_PROPS } from '../../../skeleton/utils';

import style from './progressChartSkeleton.style';

export const ProgressChartSkeleton = ({ classes }) => {
  const SkeletonSection = () => (
    <section className={classes.skeletonLarge}>
      <div className={classes.skeletonLarge} data-is-flex>
        <div className={classes.skeletonMedium} data-is-flex>
          <Skeleton
            height={15}
            width={pixelToPercentConvertion(115, 15)}
            {...SMALL_PROPS}
          />
          <Skeleton
            height={15}
            width={pixelToPercentConvertion(115, 100)}
            {...SMALL_PROPS}
          />
        </div>
        <div className={classes.skeletonMedium} data-is-flex-end>
          <Skeleton
            height={13}
            width={pixelToPercentConvertion(115, 20)}
            {...SMALL_PROPS}
          />
        </div>
      </div>
      <div>
        <Skeleton
          height={10}
          width={pixelToPercentConvertion(264, 264)}
          {...SMALL_PROPS}
        />
      </div>
    </section>
  );

  return (
    <div className={classes.skeletonCardTwo}>
      <div className={classes.skeletonLarge} data-is-flex>
        <div
          className={classnames(
            classes.skeletonCardGroup,
            classes.skeletonMedium
          )}
        >
          {Array(3)
            .fill('')
            .map((el, i) => (
              <SkeletonSection key={i} />
            ))}
        </div>
        <div
          className={classnames(
            classes.skeletonCardGroup,
            classes.skeletonMedium
          )}
        >
          {Array(2)
            .fill('')
            .map((el, i) => (
              <SkeletonSection key={i} />
            ))}
        </div>
      </div>
    </div>
  );
};

ProgressChartSkeleton.propTypes = {
  classes: PropType.object.isRequired
};

export default injectSheet(style)(ProgressChartSkeleton);
