import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import styles from './iconKpi.style';

export const IconKpi = ({ classes, name }) => (
  <div className={classes.root}>
    <DynamicIcon icon={name} className={classes.svg} />
  </div>
);

IconKpi.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string
};

IconKpi.defaultProps = {
  name: ''
};

export default injectSheet(styles)(memo(IconKpi));
