import React, { useState, memo, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Pushtext, Icon } from '@stratumn/atomic';

import Tooltip from 'components/ui/utils/tooltip';
import { useFocus } from 'utils/hooks';
import { LESS_THAN_30_DAYS, GREATER_THAN_30_DAYS } from '../constant';

import Pagination from './pagination';

import Skeleton from '../skeleton';
import { pixelToPercentConvertion } from '../skeleton/utils';

import styles from './graphCard.style';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

const DAILY = 'daily';
const WEEKLY = 'weekly';
const MONTHLY = 'monthly';

export const GraphCard = ({
  classes,
  label,
  description,
  children,
  periodFilter,
  loading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  // focus state
  const [isFocused, focus, blur, menuBtnRef] = useFocus(false);
  // TODO: pull this logic into the upcoming analytics context
  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState(
    periodFilter === LESS_THAN_30_DAYS ? DAILY : WEEKLY
  );

  // callback to close tooltip on escape
  const closeOnEsc = useCallback(e => {
    if (e.key === 'Escape') {
      blur();
      e.stopPropagation();
    }
  }, []);

  const nextPage = useCallback(() => setCurrentPage(currentPage + 1), [
    currentPage
  ]);

  const previousPage = useCallback(() => setCurrentPage(currentPage - 1), [
    currentPage
  ]);

  useEffect(() => {
    /**
     * If we change the workflow,
     * we want to reset pagination
     */
    setCurrentPage(1);
  }, [children]);

  const handlePeriodFilter = p => setSelectedPeriodFilter(p);

  const renderPeriodFilters = useMemo(() => {
    let periodFilterArr;

    switch (periodFilter) {
      case LESS_THAN_30_DAYS:
        periodFilterArr = [DAILY, WEEKLY];
        break;
      case GREATER_THAN_30_DAYS:
        periodFilterArr = [WEEKLY, MONTHLY];
        break;

      default:
        periodFilterArr = [];
        break;
    }

    return periodFilterArr.map(frequency => (
      <Pushtext
        key={frequency}
        dataCy={`toggle-period-filter-${frequency}`}
        onClick={() => handlePeriodFilter(frequency)}
        disabled={selectedPeriodFilter === frequency}
        mute={selectedPeriodFilter !== frequency}
      >
        {frequency}
      </Pushtext>
    ));
  }, [periodFilter, selectedPeriodFilter]);

  return (
    <>
      <div className={classes.root} data-cy="graph-card">
        <div className={classes.headerWrapper}>
          <div className={classes.labelWrapper} data-is-loading={loading}>
            {loading ? (
              <Skeleton
                height={20}
                width={pixelToPercentConvertion(576, 248)}
                borderRadius={2}
              />
            ) : (
              <h5 className={classes.label}>{label}</h5>
            )}
          </div>
          {children?.length > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={children.length}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          )}
          {periodFilter && (
            <div
              className={classes.periodFilterWrapper}
              data-cy="period-filters"
            >
              {renderPeriodFilters}
            </div>
          )}
          <div
            className={classes.menuIcon}
            ref={menuBtnRef}
            data-cy="graph-card-menu"
            onClick={focus}
            onKeyDown={closeOnEsc}
          >
            <Icon name="Menu" size={30} />
          </div>
        </div>
        <div className={classes.cardBody}>
          {children &&
            (!Array.isArray(children) ? children : children[currentPage - 1])}
        </div>
      </div>

      {isFocused && (
        <Tooltip
          clientEl={menuBtnRef.current}
          portalEl={document.body}
          position={TOOLTIP_POSITION}
          onClickOutside={blur}
          delay={0}
          boxShadow
        >
          <div className={classes.menuContent}>
            <div className={classes.menuDescription}>{description}</div>
            <div className={classes.settingsWrapper}>
              <h2 className={classes.settingslabel}>display settings</h2>
              <p className={classes.settingsList}>
                TODO: add radio buttons to switch between types of graphs
              </p>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

GraphCard.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  periodFilter: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

GraphCard.defaultProps = {
  label: '',
  description: '',
  children: null,
  periodFilter: ''
};

export default injectSheet(styles)(memo(GraphCard));
