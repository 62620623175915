import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';

import { StageItem } from '@stratumn/atomic';

import { FILTERS_QUERY } from 'gql/localQueries';
import { graphqlWithFilters } from 'utils';
import TraceCard, {
  fragments as traceCardFragments
} from 'components/traceCard';
import TraceIconSpinner from 'components/ui/traceIconSpinner';
import styles from './traces.style';

export class StageTraces extends Component {
  static propTypes = {
    filtersQuery: PropTypes.object.isRequired,
    groupId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    stageQuery: PropTypes.object.isRequired
  };

  subscribeToStage(props) {
    const { stageQuery, stageId } = props;
    this.unsubscribeFromStage = stageQuery.subscribeToMore({
      document: subscriptions.stage,
      variables: { id: `stage:${stageId}` }
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.subscribeToStage(this.props);
  }

  componentWillUnmount() {
    this.unsubscribeFromStage();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filtersQuery.filters === this.props.filtersQuery.filters) {
      return;
    }
    if (this.unsubscribeFromStage) {
      this.unsubscribeFromStage();
    }
    this.subscribeToStage(nextProps);
  }

  render = () => {
    const {
      stageQuery: { loading, stageByRowId: stage },
      classes,
      groupId
    } = this.props;

    if (loading)
      return <TraceIconSpinner small className={classes.loadingGif} />;

    return stage.traces.nodes.map(trace => (
      <StageItem key={trace.rowId}>
        <TraceCard trace={trace} groupId={groupId} />
      </StageItem>
    ));
  };
}

export const fragments = {
  stage: gql`
    fragment StageQueryFragment on Stage {
      rowId
      traces(
        # Only get the first 100 traces. This should be paginated at some point.
        first: 100
        filter: {
          watched: { equalTo: $watched }
          or: [
            { rowId: { includesInsensitive: $search } }
            { tags: { contains: [$search] } }
          ]
        }
      ) {
        nodes {
          ...TraceCardFragment
        }
      }
    }
    ${traceCardFragments.trace}
  `
};

export const queries = {
  stageQuery: gql`
    query stageQuery($stageId: BigInt!, $watched: Boolean, $search: String) {
      stageByRowId(rowId: $stageId) {
        ...StageQueryFragment
      }
    }
    ${fragments.stage}
  `
};

export const subscriptions = {
  stage: gql`
    subscription listenStage($id: String!, $watched: Boolean, $search: String) {
      listen(topic: $id) {
        relatedNodeId
        relatedNode {
          ...StageQueryFragment
        }
      }
    }
    ${fragments.stage}
  `
};

export default compose(
  graphql(FILTERS_QUERY, {
    name: 'filtersQuery'
  }),
  graphqlWithFilters(queries.stageQuery, {
    name: 'stageQuery',
    options: ({ stageId }) => ({
      variables: { stageId }
    })
  }),
  injectSheet(styles),
  withApollo
)(StageTraces);
