import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { ArrowRightLight, ArrowLeftLight } from '@stratumn/icons';

import styles from './pagination.style';

export const Pagination = ({
  classes,
  currentPage,
  totalPages,
  nextPage,
  previousPage
}) => (
  <div className={classes.root}>
    <div
      className={classes.arrow}
      data-cy="graph-pagination-previous"
      data-is-disabled={currentPage === 1}
      onClick={currentPage > 1 ? previousPage : null}
    >
      <ArrowLeftLight />
    </div>

    <span className={classes.numbers}>
      {currentPage} of {totalPages}
    </span>
    <div
      className={classes.arrow}
      data-cy="graph-pagination-next"
      data-is-disabled={currentPage === totalPages}
      onClick={currentPage < totalPages ? nextPage : null}
    >
      <ArrowRightLight />
    </div>
  </div>
);

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired
};

export default injectSheet(styles)(memo(Pagination));
