import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import Path from 'path-to-regexp';

import { Pushbutton, Tray } from '@stratumn/atomic';

import { ROUTE_WORKFLOW_OVERVIEW } from 'constant/routes';
import { TRAY_PORTAL_RIGHT } from 'constant/htmlIds';

import DataImporter from 'components/ui/dataImporter';
import DataEditor from 'components/ui/dataEditor';
import { Widget } from 'components/ui/widget';
import { WorkflowContext } from 'utils/workflowContext';
import { useToggle } from 'utils/hooks';

import styles from './newLink.style';

export const DataTool = React.memo(props => {
  const {
    classes,
    match,
    history,
    location,
    action,
    workflowContext,
    traces,
    toggleSignDialog,
    disabled,
    setFormData,
    setCleanupFn
  } = props;

  const [showTraceInfoTray, toggleShowTraceInfoTray] = useToggle(false);

  const goBack = useCallback(() => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // by default go back to workflow overview
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  }, [match, location]);

  const handleDataToolSubmit = useCallback(
    (data, fn) => {
      setFormData(data);
      setCleanupFn(fn);
      toggleSignDialog();
    },
    [setFormData, setCleanupFn, toggleSignDialog]
  );

  const { rowId: workflowRowId } = workflowContext;
  const dataToolCachingKey = useMemo(
    () =>
      !action.dataEditor
        ? `dataImporter_${workflowRowId}_${location.search}`
        : `dataEditor_${workflowRowId}_${location.search}`,
    [action, workflowRowId, location]
  );

  if (traces.length !== 1) {
    throw new Error(
      [
        'Data tool actions are only available for a single trace.',
        `${action.title || 'this action'} is not allowed for ${
          traces.length === 0 ? 'trace initialization' : 'batch update'
        }.`
      ].join('\n')
    );
  }

  const [trace] = traces;
  const traceInfoViewer = useMemo(
    () =>
      !trace.workflow.config.info
        ? undefined
        : {
            showTraceInfoTray,
            toggleShowTraceInfoTray
          },
    [showTraceInfoTray, trace]
  );

  return (
    <WorkflowContext.Provider value={workflowContext}>
      <div className={classes.dataToolBody}>
        {!action.dataEditor ? (
          <DataImporter
            config={action.dataImporter}
            onSubmit={handleDataToolSubmit}
            onCancel={goBack}
            submitDisabled={disabled}
            cachedUpdatesKey={dataToolCachingKey}
            traceInfoViewer={traceInfoViewer}
          />
        ) : (
          <DataEditor
            data={trace.state}
            config={action.dataEditor}
            onSubmit={handleDataToolSubmit}
            onCancel={goBack}
            submitDisabled={disabled}
            cachedUpdatesKey={dataToolCachingKey}
            traceInfoViewer={traceInfoViewer}
          />
        )}
      </div>
      {trace.workflow.config.info && showTraceInfoTray && (
        <Tray
          portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
          title="**Trace info** viewer"
          onClose={toggleShowTraceInfoTray}
          minWidth={450}
        >
          <div className={classes.traceInfoTray}>
            <div className={classes.traceInfoTrayBody}>
              <div className={classes.traceInfoTrayContent}>
                <Widget
                  widget={trace.workflow.config.info}
                  data={trace.state}
                />
              </div>
            </div>
            <div className={classes.traceInfoTrayFooter}>
              <div className={classes.traceInfoTrayActions}>
                <Pushbutton onClick={toggleShowTraceInfoTray} disabled={false}>
                  CLOSE
                </Pushbutton>
              </div>
            </div>
          </div>
        </Tray>
      )}
    </WorkflowContext.Provider>
  );
});

DataTool.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  action: PropType.object.isRequired,
  workflowContext: PropType.object.isRequired,
  traces: PropType.arrayOf(PropType.object).isRequired,
  toggleSignDialog: PropType.func.isRequired,
  disabled: PropType.bool.isRequired,
  setFormData: PropType.func.isRequired,
  setCleanupFn: PropType.func.isRequired
};

export default compose(injectSheet(styles), withRouter)(DataTool);
