export default theme => ({
  viewToggle: {
    color: theme.grey3,
    width: 60,
    transition: '250ms',
    '&:hover': {
      color: theme.grey2
    },
    '&:focus': {
      outline: 'none'
    },
    '&::first-letter': {
      textTransform: 'capitalize'
    },
    '&::first-child': {
      textAlign: 'right'
    },
    '&[data-is-active=true]': {
      fontWeight: 700,
      color: theme.grey1
    }
  },
  standard: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 23,
    transition: '250ms',
    '&:hover': {
      opacity: 0.8
    },
    '&::first-letter': {
      textTransform: 'capitalize'
    },
    '& > svg': {
      width: 18,
      height: 18,
      marginRight: 6
    },
    '&:focus': {
      outline: 'none'
    }
  }
});
