import MediaClient from '@stratumn/media-sdk';
import to from 'await-to-js';
import filesize from 'filesize';

import { MEDIA_API_URL } from 'constant/api';
import apm from 'monitoring/apm';
import { withSpanAsync, SpanType } from 'monitoring/transaction';

import { TYPE_ERROR, TYPE_PROGRESS, TYPE_SUCCESS } from 'constant/media';
import { getAuthToken } from 'utils/localStorage';

// eslint-disable-next-line
import MediaUploadFileWorker from 'worker-loader!client/media.worker';

export const getMediaClient = () => {
  const mediaClient = new MediaClient(MEDIA_API_URL);
  mediaClient.setToken(getAuthToken());
  return mediaClient;
};

/**
 * @function uploadFile
 * @param {object} file a File object.
 * @callback onSuccess callback excuted when upload is successful.
 * @callback onError callback excuted when upload fails.
 * @callback onProgress callback to keep track of the upload.
 * @param {boolean} disableEncryption set to true to disable encryption.
 * @returns information about the uploaded file.
 */
export const uploadFile = async (
  file,
  onSuccess,
  onError,
  onProgress,
  disableEncryption
) =>
  withSpanAsync('uploadFile', SpanType.outgoingRequest, async () => {
    const mediaClient = getMediaClient();
    const options = { onProgress, disableEncryption };

    let err;
    let res;

    // Checking if workers are enabled and handling both cases
    if (window.Worker && !disableEncryption) {
      [err, res] = await to(
        uploadFileUsingWorker(file, onProgress, getAuthToken(), MEDIA_API_URL)
      );
    } else {
      [err, res] = await to(mediaClient.uploadFile(file, options));
    }

    if (err) {
      apm.captureError(err);
      return onError(err);
    }

    return onSuccess({
      id: res.digest,
      createdAt: res.created_at,
      digest: res.digest,
      key: res.key,
      name: res.name,
      size: file.size,
      mimetype: file.type
    });
  });

const uploadFileUsingWorker = async (
  file,
  onProgress,
  authToken,
  mediaApiUrl
) => {
  // Initialise the worker
  const mediaUploadFileWorker = new MediaUploadFileWorker();
  return new Promise((resolve, reject) => {
    /**
     * Run the worker;
     * postMessage only accepts one argument.
     * We will pass an object of the necessary arguments.
     */
    mediaUploadFileWorker.postMessage({ file, authToken, mediaApiUrl });

    // return data to ui component where uploadFile has been triggered
    mediaUploadFileWorker.onmessage = ({ data: { type, payload } }) => {
      switch (type) {
        case TYPE_SUCCESS:
          resolve(payload);
          break;
        case TYPE_ERROR:
          reject(payload);
          break;
        case TYPE_PROGRESS:
          if (onProgress) onProgress(payload);
          break;
        default:
      }
    };

    // report worker error
    mediaUploadFileWorker.onerror = reject;
  }).finally(() => {
    // Stop listening to worker messages
    mediaUploadFileWorker.terminate();
  });
};

/**
 * Imported from media-api.
 * Until we plug this new configuration api, we can use this static cosntant.
 */
export const uploadConfig = {
  // Data Size Limit to upload files to S3 bucket
  MAX_FILE_SIZE: 1024 * 1024 * 100 * 0.6, // 60MB in bytes

  // The order is important to display the FileUpload description in atomic/forms
  FILE_EXTENSION_WHITELIST_MAP: {
    documents: [
      'doc',
      'txt',
      'pdf',
      'xml',
      'dot',
      'eml',
      'json',
      'msg',
      'odt',
      'oft',
      'one',
      'pages',
      'rtf',
      'xps',
      'docm',
      'docx'
    ],
    images: ['jpg', 'png', 'gif', 'bmp', 'ico', 'jpeg', 'tif', 'tiff'],
    presentations: ['ppt', 'pptx', 'pps', 'ppsx'],
    spreadsheets: ['xls', 'csv', 'numbers', 'xlsm', 'xlsx', 'xlsb'],
    archives: ['zip', 'rar', 'tar', 'gz', 'tgz', 'zipx']
  }
};

// Whitelisted file extensions in media-api error response
export const unsupportedMediaType = {
  STATUS: 415,
  message: filename =>
    `Sorry, the ${filename} file type is not permitted for security reasons. Please try a different file type.
    Accepted file types are: pdf, tiff, xml, json, csv, gif, ppt, pptx, jpg, png, xls, xlsx, numbers, doc, docx.`
};

// Once media-api has set an upload config parameters, we'll use this status to warn the user.
export const payloadTooLarge = {
  STATUS: 413,
  message: `Sorry, the maximum file size is ${filesize(
    uploadConfig.MAX_FILE_SIZE
  )}. Please reduce the file size and try again.`
};

/**
 * @param {string} filename
 * @param {number} status
 * @param {function} errorSnackbar
 * @returns an error message in a snackbar
 */
export const handleUploadFileOnError = (filename, status, errorSnackbar) => {
  switch (status) {
    case unsupportedMediaType.STATUS:
      return errorSnackbar(unsupportedMediaType.message(filename));

    case payloadTooLarge.STATUS:
      return errorSnackbar(payloadTooLarge.message);

    default:
      return errorSnackbar(
        `Sorry, ${filename} failed to upload. Please try again.`
      );
  }
};

/**
 * @function getDownloadURL
 * @param {object} file a File object.
 * @returns a url to query the file on media api.
 */
export const getDownloadURL = file => `${MEDIA_API_URL}/files/${file.digest}`;
