import React, { memo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './tab.style';

export const Tab = ({ classes, label, icon, active, onClick }) => (
  <div
    data-cy={`tab-${label.toLowerCase().replace(' ', '-')}`}
    className={classes.root}
    data-is-active={active}
    onClick={onClick}
  >
    <div className={classes.icon}>{icon}</div>
    <span className={classes.label}>{label}</span>
  </div>
);

Tab.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string.isRequired,
  icon: PropType.node,
  active: PropType.bool.isRequired,
  onClick: PropType.func.isRequired
};

Tab.defaultProps = {
  icon: null
};

export default injectSheet(styles)(memo(Tab));
