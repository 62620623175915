export default theme => ({
  dashboardContainer: {
    position: 'fixed',
    top: 70,
    overflowX: 'auto',
    overflowY: 'scroll',
    backgroundColor: theme.grey8,
    height: 'calc(100% - 70px)',
    width: '100%'
  },
  dashboardContent: {
    padding: 15,
    minWidth: 1000,
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  dashboardItem: {
    margin: 10,
    borderRadius: 15,
    boxShadow: theme.indigoShadow1
  }
});
