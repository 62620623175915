import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { DateRange } from '@stratumn/atomic';

import styles from './page1.style';

const GraphAverageDelays = React.lazy(() =>
  import('../graphs/Global-activity-history/average-delays-per-investigator.js')
);
const GraphAverageDelaysPerStep = React.lazy(() =>
  import('../graphs/Global-activity-history/average-delays-per-step.js')
);
const GraphFillingRateByInvestigator = React.lazy(() =>
  import('../graphs/Global-activity-history/filling-rate-by-investigator.js')
);
const GraphPercentageSuccessfulInvestigations = React.lazy(() =>
  import(
    '../graphs/Global-activity-history/percentage-of-successful-investigations-per-investigator.js'
  )
);
const GraphTotalInsuredAmountPerInvestigator = React.lazy(() =>
  import(
    '../graphs/Global-activity-history/total-insured-amount-per-investigator.js'
  )
);

const generalData = [
  {
    data: '120',
    title: 'Investigations treated'
  },
  {
    data: '2.4M€',
    title: 'Total Insured amount'
  },
  {
    data: '82%',
    title: 'Successful investigations'
  }
];

export const GlobalActivityHistory = ({ classes }) => (
  <div className={classes.globalActivityHistory}>
    <div className={classes.grid}>
      <div className={classes.stepGA}>
        <h1>Average delays per step</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphAverageDelaysPerStep />
          </Suspense>
        </div>
      </div>
      <div className={classes.generalDataGA}>
        {generalData.map(el => (
          <div className={classes.generalDataDivGA} key={el.title}>
            <h2>{el.data}</h2>
            <h6>{el.title}</h6>
          </div>
        ))}
      </div>
      <div className={classes.datePickerGA}>
        <div className={classes.datePickerSpacing}>
          <DateRange onValueChange={e => console.log(e)} placement="right" />
        </div>
      </div>
      <div className={classes.totalInvestigatorGA}>
        <h1>Total volumes of investigations per investigator</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphTotalInsuredAmountPerInvestigator />
          </Suspense>
        </div>
      </div>
      <div className={classes.delaysInvestigatorGA}>
        <h1>Average response time per investigator</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphAverageDelays />
          </Suspense>
        </div>
      </div>
      <div className={classes.fillingRateInvestigatorGA}>
        <h1>Average filling rate by investigator</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphFillingRateByInvestigator />
          </Suspense>
        </div>
      </div>
      <div className={classes.successfulInvestigatorGA}>
        <h1>Successful investigations in % per investigator</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphPercentageSuccessfulInvestigations />
          </Suspense>
        </div>
      </div>
    </div>
  </div>
);

GlobalActivityHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(GlobalActivityHistory);
