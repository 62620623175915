import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './cardLayout.style';

export const CardLayout = ({ classes, children, hoverable, bgWhite }) => (
  <div
    className={classes.root}
    data-is-hoverable={hoverable}
    data-background-white={bgWhite}
  >
    {children}
  </div>
);

CardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  hoverable: PropTypes.bool,
  bgWhite: PropTypes.bool
};

CardLayout.defaultProps = {
  hoverable: false,
  bgWhite: true
};

export default injectSheet(styles)(memo(CardLayout));
