import React, { memo, useMemo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import { useQuery } from 'react-query';
import { REPORTING_API_REALTIME } from 'constant/api';

import KpiCard from '../kpiCard';
import GraphCard from '../graphCard';
import { BarChart, ColumnChart, ProgressChart } from '../charts';

import styles from './realTime.style';

export const fetchRealTime = async (_key, workflowRowId) => {
  const response = await fetch(`${REPORTING_API_REALTIME}/${workflowRowId}`, {
    credentials: 'include'
  });
  return response.json();
};

export const RealTime = ({ classes, workflowRowId }) => {
  /**
   * @function useQuery
   * @param {array}
   * @param {array[0]-string} key - the query key so manage the cache
   * @param {array[1]-string} workflowRowId - the first query param to fetch the data for a particular workflow
   * @param {function} fetchReportingData - a promise using the fetch api
   * @returns {object} - internal state managed by react-query
   */
  const { isLoading, error, data } = useQuery(
    ['realtime', workflowRowId],
    fetchRealTime
  );

  /**
   * @function renderBarChart - computes the pagination if dataset.length > 9 entries
   * @returns {Array} - an array of React.nodes
   */
  const renderBarChart = useMemo(() => {
    // the design is set to 9 rows
    const rowsPerCard = 9;

    const openTraces = data?.lastActionOpenTraces;

    if (!openTraces) return null;
    /**
     * If no data, we return an empty BarChart.
     * It will then call the Placeholder component with "No data to display"
     */
    if (openTraces.length === 0) return <BarChart />;

    const copyOpenTraces = [...openTraces];

    // split the dataset into sub datasets arrays.
    return (
      new Array(Math.ceil(copyOpenTraces.length / rowsPerCard))
        .fill()
        .map(() => copyOpenTraces.splice(0, rowsPerCard))
        // each dataset is then its own BarChart to fit inside the pagination tabs
        .map((e, i) => (
          <BarChart key={i} rowsPerCard={rowsPerCard} dataset={e} />
        ))
    );
  }, [data?.lastActionOpenTraces]);

  if (error) return `An error has occurred: ${error.message}`;

  return (
    <div className={classes.root}>
      <div className={classes.kpiCardsWrapper}>
        <div className={classes.kpiCardSingleWrapper}>
          <KpiCard
            title="open traces"
            iconSmall="TimeLine"
            value={data?.openTraces || 0}
            tooltip="Number of traces that require further action."
            bgWhite={false}
            loading={isLoading}
          />
        </div>
        <div className={classes.kpiCardSingleWrapper}>
          <KpiCard
            title="inactive traces"
            iconLarge="Moon"
            value={data?.inactiveTraces || 0}
            tooltip="Number of traces that haven’t been updated in the last 7 days."
            light
            loading={isLoading}
          />
        </div>
        <div className={classes.kpiCardSingleWrapper}>
          <KpiCard
            title="avg. time between 2 actions"
            iconLarge="ClockGeneral"
            value={data?.averageTimeInAction || 0}
            tooltip="Average time between two actions on current open traces."
            time
            light
            loading={isLoading}
          />
        </div>
      </div>

      <div className={classes.graphCardsWrapper}>
        <div className={classes.graphCardsColumnLeft}>
          <GraphCard
            title="last main action on open traces"
            tooltip="Number of traces by the last action performed."
            loading={isLoading}
          >
            {/** This empty BarChart component will call the <BarChartSkeleton /> */}
            {isLoading ? <BarChart loading={isLoading} /> : renderBarChart}
          </GraphCard>
        </div>
        <div className={classes.graphCardsColumnRight}>
          <GraphCard
            title="top 5 groups in terms of traces contribution"
            tooltip="Number of traces in which the group is involved."
            loading={isLoading}
          >
            {/** Displays the top 5 contributing groups */}
            <ProgressChart
              dataset={data?.topContributingGroups?.slice(0, 5) || []}
              loading={isLoading}
            />
          </GraphCard>

          <GraphCard
            title="distribution by creation date"
            tooltip="Number of traces per month."
            loading={isLoading}
          >
            {/** Number of traces per month from today's date to a year ago */}
            <ColumnChart
              dataset={data?.distributionByCreationDate?.slice(0, 12) || []}
              loading={isLoading}
            />
          </GraphCard>
        </div>
      </div>
    </div>
  );
};

RealTime.propTypes = {
  classes: PropType.object.isRequired,
  workflowRowId: PropType.string.isRequired
};

export default injectSheet(styles)(memo(RealTime));
