/**
 * - Because recharts inserts the tooltip within its wrapper,
 * we cannot use a portal to bring it to the top of our document.
 * Therefore we add a zIndex.
 *
 * - In order to omit the first tick and its cartesian stroke,
 * we need to overide recharts css.
 */
export default () => ({
  root: {
    '& .recharts-tooltip-wrapper': {
      zIndex: 2
    },
    '& .recharts-cartesian-grid-vertical line:first-child': {
      stroke: 'none'
    },
    '& .recharts-cartesian-axis.recharts-xAxis.xAxis > g > g:nth-child(1) text': {
      fontSize: 0
    }
  }
});
