import { onError } from 'apollo-link-error';

import { history } from 'components/root';
import { ROUTE_LOGIN, ROUTE_UNAUTHORIZED } from 'constant/routes';
import apm from 'monitoring/apm';

export default onError(props => {
  const { graphQLErrors } = props;

  if (graphQLErrors)
    graphQLErrors.every(err => {
      apm.captureError(err);
      console.error(err);

      // When receiving a 502 we refresh the current page.
      if (err.message === 'Response not successful: Received status code 502') {
        history.go(0);
        return false;
      }

      const { status } = err;
      if (status === 401) {
        // use second argument (state) to pass redirect variable
        history.push(ROUTE_LOGIN, {
          redirect: `${window.location.pathname}${window.location.search}`
        });
        return false;
      }

      if (status === 403) {
        // redirect to the unauthorized page
        history.push(ROUTE_UNAUTHORIZED);
      }

      return true;
    });
});
