export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 5,
    background: theme.white1,
    boxShadow: theme.indigoShadow1
  },
  title: {
    display: 'block',
    margin: 0,
    fontWeight: 700,
    fontSize: 12,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: theme.grey1
  },
  description: {
    display: 'block',
    margin: 0,
    padding: 0,
    fontSize: 14,
    color: theme.grey2
  }
});
