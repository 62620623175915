import React, { memo } from 'react';
import injectSheet from 'react-jss';
import PropType from 'prop-types';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import styles from './customTick.style';

const ICON_SIZE = 22;

export const CustomTick = ({
  classes,
  payload: { coordinate, value },
  data,
  width
}) => {
  const tickDetails = data.filter(entry => entry.name === value);

  const icon = tickDetails[0]?.icon;

  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <foreignObject x="0" y={coordinate - 10} width="100%" height="20px">
        <div className={classes.root} xmlns="http://www.w3.org/1999/xhtml">
          <svg viewBox="0 0 32 32" width={ICON_SIZE} height={ICON_SIZE}>
            <DynamicIcon
              className={classes.icon}
              icon={icon || ''}
              title={icon}
            />
          </svg>
          <span
            style={{ width: width - ICON_SIZE }}
            className={classes.value}
            title={value}
          >
            {value}
          </span>
        </div>
      </foreignObject>
    </svg>
  );
};

CustomTick.propTypes = {
  classes: PropType.object.isRequired,
  payload: PropType.object.isRequired,
  data: PropType.array.isRequired,
  width: PropType.number.isRequired
};

export default injectSheet(styles)(memo(CustomTick));
