export default theme => ({
  root: {
    color: theme.grey1
  },
  step: {
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 1,
    lineHeight: '14px',
    marginBottom: 8
  },
  titleRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 7
  },
  what: {
    fontSize: 18,
    lineHeight: '25px',
    fontWeight: 600,
    marginRight: 14
  },
  evidenceText: {
    fontSize: 11,
    fontWeight: 400,
    color: theme.grey3,
    '&:hover': {
      color: theme.indigo3
    }
  },
  evidences: {
    borderBottom: `1px solid ${theme.grey6}`,
    fontSize: 12,
    lineHeight: '22px',
    color: theme.grey2,
    wordBreak: 'break-word'
  },
  evidence: {
    '&:last-of-type': {
      marginBottom: 17
    }
  },
  evidenceTitle: {
    display: 'inline',
    fontWeight: 700
  },
  evidenceData: {
    display: 'inline',
    paddingLeft: 3
  },
  outsideLink: {
    display: 'inline-flex',
    color: theme.indigo3,
    textDecoration: 'none'
  },
  outsideIcon: {
    width: 20,
    height: 20,
    display: 'inline-block'
  },
  evidenceContainer: {
    marginBottom: 0,
    opacity: 0,
    overflow: 'hidden',
    transition:
      'opacity 200ms linear 0ms, height 200ms ease 200ms, margin-bottom 200ms ease 200ms',
    '&[aria-hidden=false]': {
      overflow: 'visible',
      marginBottom: 21,
      opacity: 1,
      transition:
        'height 200ms ease 0ms, margin-bottom 200ms ease 0ms, opacity 200ms linear 200ms'
    }
  },
  evidenceLoadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  evidenceLoadingText: {
    fontSize: 12,
    fontWeight: 700
  },
  fossilizingImg: {
    width: '70%',
    marginTop: 20,
    marginBottom: 20
  },
  attestation: {
    backgroundColor: theme.grey9,
    border: `1px solid ${theme.greyTable}`,
    borderRadius: 2,
    overflow: 'auto',
    padding: 10
  },
  transferButtons: {
    display: 'flex',
    marginTop: 24,
    '& > button': {
      marginRight: 10
    }
  },
  decryptionNote: {
    display: 'flex',
    marginBottom: 14,
    '& > dl': {
      flexGrow: 0,
      border: `1px solid ${theme.grey3}`,
      borderRadius: 2,
      backgroundColor: theme.grey7,
      padding: `8px 15px 12px 15px`,
      '& > dt': {
        fontSize: 11,
        lineHeight: '14px',
        letterSpacing: 1.1,
        fontWeight: 700,
        color: theme.grey2,
        textTransform: 'uppercase',
        marginBottom: 4,
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          marginLeft: 7,
          flexShrink: 0,
          fill: 'currentColor',
          width: 24,
          height: 24
        }
      },
      '& > dd': {
        fontSize: 14,
        lineHeight: '18px',
        fontWeight: 400,
        color: theme.grey2,
        '&::first-letter': {
          textTransform: 'uppercase'
        }
      }
    }
  },
  commentArea: {
    border: `1px solid ${theme.grey6}`,
    borderRadius: 2,
    maxHeight: 600,
    overflow: 'auto',
    padding: 15
  },
  comment: {
    fontStyle: 'italic'
  }
});
