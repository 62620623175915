// These styles are being used by different charts.
import React from 'react';
import theme from 'style';
import CustomTick from '../ui/customTick';
import { graphType } from '../../constant';

// Recharts components styling
export const rechartsStyles = {
  root: {
    margin: {
      top: 10,
      right: 4,
      left: -29,
      bottom: 0
    }
  },
  area: {
    type: 'monotone',
    stroke: theme.indigo1,
    strokeWidth: '3px',
    fill: theme.indigoPastel3,
    activeDot: {
      stroke: theme.indigo1,
      strokeWidth: 2,
      r: 5,
      fill: theme.white1
    }
  },
  cartesianGrid: {
    vertical: false,
    stroke: theme.grey5,
    strokeDasharray: '5 5'
  },
  xAxis: {
    axisLine: { stroke: 'rgb(236,235,242)' },
    tickLine: { strokeWidth: 0 },
    interval: 0,
    tick: <CustomTick />
  },
  yAxis: {
    axisLine: { strokeWidth: 0 },
    tickLine: { stroke: 'rgb(236,235,242)', right: 10 },
    tick: { fill: theme.grey3, fontSize: 8 },
    interval: 0
  },
  tooltip: {
    title: 'trace',
    type: graphType.AREA,
    cursor: false
  }
};

export const mainStyles = () => ({
  root: {
    // recharts inserts the tooltip within its wrapper, we cannot use a portal to bring it to the top of our document.
    '& .recharts-tooltip-wrapper': {
      zIndex: 2
    },

    // so the start and end ticks don't get cut off.
    '& .recharts-surface': {
      overflow: 'unset'
    }
  },
  // TODO: remove once skeleton has been created
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 150,
    '& img': {
      margin: 0
    },
    '& span': {
      fontSize: 10,
      marginBottom: 5
    }
  }
});
