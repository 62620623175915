export default theme => ({
  list: {
    margin: '21px 0px',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  listHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  listTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    color: theme.grey1,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 600,
    textTransform: 'capitalize',
    paddingRight: 20,
    cursor: 'pointer'
  },
  displayListIcon: {
    marginLeft: 15,
    flexShrink: 0,
    height: 18,
    width: 18,
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-up=true]': {
      transform: 'rotate(180deg)'
    }
  },
  removeAllButton: {
    paddingLeft: 20,
    cursor: 'pointer',
    color: theme.grey1,
    fontSize: 12,
    lineHeight: '14px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.warningRed
    }
  },
  participants: {
    margin: '23px 0px 8px 0px'
  }
});
