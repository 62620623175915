import { disableFragmentWarnings } from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { RestLink } from 'apollo-link-rest';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';

import { GRAPHQL_ACCOUNT_API_URL, ACCOUNT_API_URL } from 'constant/api';
import { dataIdFromObject } from 'utils';

import errorLink from './errorLink';
import introspectionQueryResultData from './fragmentTypesAccount.json';

// Disable warning if multiple fragments have the same name.
disableFragmentWarnings();

const cache = new InMemoryCache({
  dataIdFromObject,
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })
});

const httpLink = new BatchHttpLink({
  uri: GRAPHQL_ACCOUNT_API_URL,
  credentials: 'include'
});

const restLink = new RestLink({
  uri: ACCOUNT_API_URL,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default new ApolloClient({
  link: ApolloLink.from([errorLink, restLink, httpLink]),
  cache,
  connectToDevTools: true
});
