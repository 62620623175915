import React, { Component } from 'react';
import PropType from 'prop-types';
import { compose, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import pathToRegexp from 'path-to-regexp';
import gql from 'graphql-tag';

import { FILTERS_QUERY } from 'gql/localQueries';
import { DocumentSearch } from '@stratumn/icons';
import {
  ROUTE_WORKFLOW_BOARD,
  ROUTE_GROUP_FORMS,
  ROUTE_USER_DASHBOARD
} from 'constant/routes';
import { Header } from 'components/layouts';
import {
  LayoutKanban,
  LayoutKanbanHeader,
  LayoutKanbanMain
} from '@stratumn/atomic';
import { deepGet, graphqlWithFilters } from 'utils';
import { orderStages } from 'utils/stage';

import envVars from 'constant/env';

import StageColumn, { fragments as stageColumnFragments } from './column';
import styles from './groupBoard.style';

export class GroupBoard extends Component {
  static propTypes = {
    filtersQuery: PropType.object.isRequired,
    groupQuery: PropType.object.isRequired,
    classes: PropType.object.isRequired,
    history: PropType.object.isRequired,
    match: PropType.object.isRequired
  };

  setDocTitle() {
    const { groupQuery: { groupByRowId: group = {} } = {} } = this.props;
    const { name, workflow: { name: workflowName } = {} } = group;

    if (name) document.title = `${name} - ${workflowName} - Trace`;
  }

  componentDidMount() {
    this.setDocTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.groupQuery !== this.props.groupQuery) this.setDocTitle();
  }

  subscribeToGroup(props) {
    const { groupQuery, match } = props;
    this.unsubscribeFromGroup = groupQuery.subscribeToMore({
      document: subscriptions.group,
      variables: { id: `group:${match.params.id}` }
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.subscribeToGroup(this.props);
  }

  componentWillUnmount() {
    this.unsubscribeFromGroup();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filtersQuery.filters === this.props.filtersQuery.filters) {
      return;
    }
    if (this.unsubscribeFromGroup) {
      this.unsubscribeFromGroup();
    }
    this.subscribeToGroup(nextProps);
  }

  shouldComponentUpdate(props) {
    const {
      groupQuery: { loading, groupByRowId: group, error }
    } = props;
    return !loading && (!!group || !!error);
  }

  goToWorkFlowBoard = () => {
    const {
      groupQuery: { groupByRowId: group },
      history
    } = this.props;
    history.push(
      pathToRegexp.compile(ROUTE_WORKFLOW_BOARD)({ id: group.workflow.rowId })
    );
  };

  goToGroupForms = () => {
    const {
      match: { params },
      history
    } = this.props;
    history.push(pathToRegexp.compile(ROUTE_GROUP_FORMS)({ id: params.id }));
  };

  getMenuItems = () => {
    const {
      groupQuery: { loading, groupByRowId: group }
    } = this.props;
    if (loading) return [];
    const formCount = deepGet(group, 'actions.totalCount');
    if (!formCount) return [];

    return [
      {
        label: 'View actions',
        icon: <DocumentSearch />,
        onClick: this.goToGroupForms
      }
    ];
  };

  renderHeader = () => {
    const {
      groupQuery: { loading, groupByRowId: group }
    } = this.props;

    const configHeader = {
      loading,
      environment: envVars.REACT_APP_ENVIRONMENT,
      topLevel: {
        title: {
          label: deepGet(group, 'workflow.name', null),
          path: !loading
            ? pathToRegexp.compile(ROUTE_WORKFLOW_BOARD)({
                id: group.workflow.rowId
              })
            : null
        },
        links: [
          {
            label: 'dashboard',
            path: ROUTE_USER_DASHBOARD
          }
        ]
      },
      bottomLevel: {
        workflowPage: true,
        infoContext: {
          links: [
            {
              label: !loading ? group.name : null
            }
          ]
        }
      }
    };

    return <Header config={configHeader} />;
  };

  renderMain = () => {
    const {
      groupQuery: { groupByRowId: group },
      classes
    } = this.props;

    const stages = orderStages(group.stages.nodes);
    return (
      <>
        <div className={classes.groups}>
          {stages.map(stage => (
            <StageColumn group={group} stage={stage} key={stage.rowId} />
          ))}
        </div>
      </>
    );
  };

  render() {
    const {
      groupQuery: { loading }
    } = this.props;
    return (
      <LayoutKanban>
        <LayoutKanbanHeader>{this.renderHeader()}</LayoutKanbanHeader>
        <LayoutKanbanMain lightTheme loading={loading}>
          {loading ? null : this.renderMain()}
        </LayoutKanbanMain>
      </LayoutKanban>
    );
  }
}

export const fragments = {
  group: gql`
    fragment GroupQueryFragment on Group {
      rowId
      label
      canUpdate
      name
      workflow {
        rowId
        name
        config {
          rowId
          initActions
        }
      }
      actions {
        totalCount
      }
      stages {
        nodes {
          type
          ...StageColumnFragment
        }
      }
    }
    ${stageColumnFragments.stage}
  `
};

export const queries = {
  groupQuery: gql`
    query groupQuery($groupId: BigInt!, $watched: Boolean, $search: String) {
      groupByRowId(rowId: $groupId) {
        ...GroupQueryFragment
      }
    }
    ${fragments.group}
  `
};

export const subscriptions = {
  group: gql`
    subscription listenGroup($id: String!, $watched: Boolean, $search: String) {
      listen(topic: $id) {
        relatedNodeId
        relatedNode {
          ...GroupQueryFragment
        }
      }
    }
    ${fragments.group}
  `
};

export default compose(
  graphql(FILTERS_QUERY, {
    name: 'filtersQuery'
  }),
  graphqlWithFilters(queries.groupQuery, {
    name: 'groupQuery',
    options: ({ match }) => ({
      variables: { groupId: match.params.id }
    })
  }),
  injectSheet(styles)
)(GroupBoard);
