import React, { useState, createContext, useRef, useContext } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import Path from 'path-to-regexp';
import { Icon, Pushbutton, withSnackbarsContext } from '@stratumn/atomic';
import { withLeavingAlertContext } from 'components/beforeLeavingAlert';
import { ROUTE_WORKFLOW_OVERVIEW } from 'constant/routes';
import { sig } from '@stratumn/js-crypto';
import { rgb } from 'pdf-lib';
import { uploadFile } from 'client/media';
import { FileUploadField } from '@stratumn/atomic/lib/forms/components/form/writer/fields/fileUpload';
import { saveAs } from 'file-saver';

import PdfEditor from './pdfEditor';
import styles from './newLink.style';

export const wordContext = createContext();

export const Caviardage = React.memo(props => {
  const [pdfDoc, setPdfDoc] = useState(null);

  const [words, setWords] = useState([]);

  const updateWord = (id, key, value) => {
    const oldWords = JSON.parse(JSON.stringify(words));

    const [page, idd] = id.split('-');
    const wordPage = oldWords[page];
    const word = wordPage[idd];

    const ids = words
      .flat()
      .filter(w => w.text === word.text)
      .map(i => i.id);

    updateMultipleWords(ids, key, value);
  };

  const updateMultipleWords = (ids, key, value) => {
    const oldWords = JSON.parse(JSON.stringify(words));

    ids.forEach(i => {
      const [page, idd] = i.split('-');
      const wordPage = oldWords[page];
      wordPage[idd][key] = value;
      oldWords[page] = wordPage;
    });

    setWords(oldWords);
  };

  const {
    classes,
    match,
    history,
    location,
    traces,
    user,
    createLinks
  } = props;

  const [filter, setFilter] = useState('');

  const [trace] = traces;

  const goToWorkflowOverview = () => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  };

  const goBack = () => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // By default go back to workflow overview
    goToWorkflowOverview();
  };

  const goToPosition = pos => {
    console.log(pos);
  };

  const selectedWords = words
    .flat()
    .filter(
      w =>
        (w.selected && filter.length === 0) ||
        (filter.length > 0 && w.text && w.text.includes(filter) && w.selected)
    );
  const finalWords = {};

  selectedWords.forEach(w => {
    if (finalWords[w.text]) {
      finalWords[w.text] = {
        replaced: w.replaced,
        text: w.text,
        pages: [...finalWords[w.text].pages, w.id],
        box: w.box,
        selected: w.selected,
        score: w.score,
        containsEntity: w.containsEntity
      };
    } else {
      finalWords[w.text] = {
        replaced: w.replaced,
        text: w.text,
        pages: [w.id],
        box: w.box,
        selected: w.selected,
        score: w.score,
        containsEntity: w.containsEntity
      };
    }
  });

  async function onSave() {
    const pages = pdfDoc.getPages();

    const wordToSave = words.flat().filter(w => w.selected);

    wordToSave.forEach(word => {
      const { containsEntity, selected, replaced } = word;

      const page = word.id.split('-')[0];
      const { box } = word;
      if (!box) {
        return;
      }
      const { width, height } = pages[page].getSize();

      if (containsEntity || selected) {
        const rectangle = {
          x: (parseFloat(box.x) * width) / 100,
          y: height - (parseFloat(box.y) * height) / 100,
          width: (parseFloat(box.width) * width) / 100,
          height: (parseFloat(box.height) * height) / 100
        };
        pages[page].drawRectangle({
          x: rectangle.x,
          y: rectangle.y - rectangle.height, // Hack since y is bottom left
          width: rectangle.width,
          height: rectangle.height,
          color: rgb(0.0, 0.0, 0.0)
        });
        if (replaced) {
          const fontSize = 7;
          pages[page].drawText(replaced, {
            x: rectangle.x,
            y: rectangle.y - fontSize,
            size: fontSize,
            color: rgb(1.0, 1.0, 1.0)
          });
        }
      }
    });
  }

  const getSigningPrivateKey = () => {
    const {
      me: {
        account: { signingKey }
      },
      password
    } = user;

    const { passwordProtected, decrypted } = signingKey.privateKey;

    if (passwordProtected)
      return new sig.SigningPrivateKey({
        pemPrivateKey: decrypted,
        password
      }).export();

    return new sig.SigningPrivateKey({
      pemPrivateKey: decrypted
    }).export();
  };

  const submitForm = async () => {
    await onSave();
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    // TODO: corriger ca
    const file = new File([blob], '2023-01-03_claims_contract.pdf');
    await uploadFile(
      file,
      mediaFile => {
        const sign = getSigningPrivateKey();
        createLinks(sign, {
          document_caviarde: mediaFile
        });
      },
      () => {},
      () => {},
      false
    );
  };

  return (
    <wordContext.Provider value={{ words, updateWord, updateMultipleWords }}>
      <div className={classes.container}>
        <div className={classes.formBody}>
          <div
            className={classes.form}
            data-cy="formjs-writer"
            data-has-traceinfo={trace && !!trace.workflow.config.info}
            style={{
              width: '60%',
              maxWidth: '100%'
            }}
          >
            <h1 style={{ paddingBottom: '20px' }}>Remove all sensitive data</h1>
            <PdfEditor setPdfDoc={setPdfDoc} setWords={setWords} />
            <div className={classes.actionButtonWrapper}>
              <div className={classes.actionButton}>
                <Pushbutton onClick={() => goBack()} dataCy="cancel">
                  Cancel
                </Pushbutton>
              </div>
              <div className={classes.actionButton}>
                <Pushbutton
                  formId="caviardage"
                  disabled={false}
                  dataCy="submit"
                  primary
                  onClick={submitForm}
                >
                  Submit
                </Pushbutton>
              </div>
            </div>
          </div>
          {words.length > 0 && (
            <div
              data-cy="trace-info"
              className={classes.form}
              style={{
                width: '25%',
                maxWidth: '40%',
                height: 'calc(100vh - 200px)'
              }}
            >
              <h1 style={{ paddingBottom: '20px' }}>Sensitive words list</h1>
              <div className={classes.wordListMain}>
                <input
                  className={classes.filterWordInput}
                  placeholder="Filter words"
                  onChange={e => {
                    setFilter(e.target.value);
                  }}
                />
                <div className={classes.wordListContainer}>
                  <div className={classes.wordList}>
                    {Object.keys(finalWords)
                      .sort()
                      .map(k => (
                        <WordRow
                          title={finalWords[k].text}
                          wid={finalWords[k].id}
                          position={finalWords[k].pages}
                          goToPosition={goToPosition}
                          replaced={finalWords[k].replaced}
                          score={finalWords[k].score}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </wordContext.Provider>
  );
});

const WordRow = compose(injectSheet(styles))(props => {
  const { classes, title, position, id, goToPosition, replaced } = props;
  const [edit, setEdit] = useState(false);
  const inputRef = useRef(null);
  const { updateMultipleWords } = useContext(wordContext);

  const [open, setOpen] = useState(false);

  const updateWordFn = w => {
    updateMultipleWords(position, 'replaced', w);
  };

  return (
    <div
      style={{
        height: open ? 80 : 40,
        alignItems: !open ? 'center' : 'start'
      }}
      className={classes.wordRow}
    >
      <div className={classes.rowHeader}>
        <div className={classes.wordRowLeft}>
          <div className={classes.leftIcon} onClick={() => setOpen(o => !o)}>
            <Icon name={open ? 'ArrowMinDown' : 'ArrowMinRight'} size={22} />
          </div>
          {edit || replaced ? (
            <>
              <p
                style={{
                  color: 'grey',
                  textDecoration: 'line-through'
                }}
              >
                <p>{title}</p>
              </p>
              <input
                onChange={e => updateWordFn(e.target.value)}
                className={classes.listInput}
                value={replaced || title}
              />
            </>
          ) : (
            <p>{title}</p>
          )}
        </div>
        <div
          style={{
            paddingBottom: open ? 1 : 0
          }}
          className={classes.rightIcon}
        >
          <div
            onClick={() => {
              setEdit(true);
            }}
          >
            <Icon name="Pen" size={22} />
          </div>
          <Icon name="Trash" size={22} />
        </div>
      </div>
      {open && (
        <div className={classes.positionRow}>
          {position.map(p => (
            <span
              className={classes.wordPosition}
              onClick={() => goToPosition(p)}
            >
              page {parseInt(p.split('-')[0], 10) + 1}, word{' '}
              {parseInt(p.split('-')[1], 10) + 1}
            </span>
          ))}
        </div>
      )}
    </div>
  );
});

WordRow.propTypes = {
  classes: PropType.object.isRequired,
  title: PropType.string.isRequired
};

Caviardage.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  traces: PropType.arrayOf(PropType.object).isRequired,
  createLinks: PropType.func.isRequired,
  user: PropType.object.isRequired
};

export default compose(
  injectSheet(styles),
  withSnackbarsContext,
  withRouter,
  withLeavingAlertContext
)(Caviardage);
