export const TYPE_BAR = 'bar';
export const TYPE_COLUMN = 'column';
export const TYPE_REPORT = 'report';
export const graphType = {
  BAR: 'bar',
  COLUMN: 'column',
  LINE: 'line',
  AREA: 'area',
  PROGRESS: 'progress'
};

export const LESS_THAN_30_DAYS = 'less-than-30-days';
export const GREATER_THAN_30_DAYS = 'greater-than-30-days';
