import {
  ATOMIC_HEADER_HEIGHT,
  TABS_WRAPPER_WIDTH,
  ANALYTICS_DASHBOARD_MARGIN
} from 'style/dimensions';

export default theme => ({
  body: {
    marginTop: 30,
    marginLeft: TABS_WRAPPER_WIDTH + ANALYTICS_DASHBOARD_MARGIN,
    width: '100%',
    minWidth: 980 + TABS_WRAPPER_WIDTH,
    whiteSpace: 'nowrap',
    maxHeight: `calc(100% - ${ATOMIC_HEADER_HEIGHT}px - 45px)`,
    // Avoid squashing our graphs - vertical scrolling below this point
    minHeight: 600
  },
  pageWrapper: {
    display: 'flex',
    marginTop: 25,
    marginBottom: 20,
    height: '100%',
    marginRight: ANALYTICS_DASHBOARD_MARGIN
  },
  betaLogoWrapper: {
    display: 'flex',
    backgroundColor: theme.teal1,
    borderRadius: 10,
    marginTop: 5,
    padding: '3px 10px'
  },
  betaLogoLabel: {
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '13px',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: theme.white1
  }
});
