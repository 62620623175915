import React, { useState, useRef, memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { CircleInfo as IconCircleInfo, ArrowRightLight } from '@stratumn/icons';

import InfoTooltip from 'components/ui/utils/infoTooltip';

import theme from 'style';

import { useTooltip } from 'utils/hooks';
import { TOOLTIP_POSITION } from '../constant';

import CardLayout from '../layout';
import Pagination from './pagination';

import Skeleton from '../skeleton';
import { pixelToPercentConvertion } from '../skeleton/utils';

import styles from './graphCard.style';

export const GraphCard = ({
  classes,
  title,
  dynamic,
  children,
  tooltip,
  loading
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Custom hook which takes care of tooltip's mouse events
  const [onMouseEnter, onMouseLeave, showTooltip] = useTooltip(tooltip);

  const graphCardRef = useRef(null);

  const nextPage = useCallback(() => setCurrentPage(currentPage + 1), [
    currentPage
  ]);

  const previousPage = useCallback(() => setCurrentPage(currentPage - 1), [
    currentPage
  ]);

  useEffect(() => {
    /**
     * If we change the workflow,
     * we want to reset pagination
     */
    setCurrentPage(1);
  }, [children]);

  const rootProps = {
    className: classes.root,
    'data-is-dynamic': dynamic,
    'data-cy': 'graph-card',
    onClick: () => dynamic && null
  };

  return (
    <>
      <CardLayout hoverable={dynamic}>
        <div {...rootProps}>
          <div className={classes.headerWrapper}>
            <div className={classes.titleWrapper} data-is-loading={loading}>
              {loading ? (
                <Skeleton
                  height={20}
                  width={pixelToPercentConvertion(576, 248)}
                  borderRadius={2}
                />
              ) : (
                <>
                  <h5 className={classes.title}>{title}</h5>
                  {tooltip && (
                    <div
                      data-cy="graph-card-tooltip"
                      className={classes.tooltipWrapper}
                      ref={graphCardRef}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    >
                      <IconCircleInfo />
                    </div>
                  )}
                </>
              )}
            </div>
            {children?.length > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={children.length}
                nextPage={nextPage}
                previousPage={previousPage}
              />
            )}
            {dynamic && (
              <div className={classes.arrowRight}>
                <ArrowRightLight data-is-dynamic={dynamic} />
              </div>
            )}
          </div>
          <div className={classes.cardBody}>
            {children &&
              (!Array.isArray(children) ? children : children[currentPage - 1])}
          </div>
        </div>
      </CardLayout>
      {showTooltip && (
        <InfoTooltip
          clientRef={graphCardRef}
          text={tooltip}
          textColor={theme.white1}
          backgroundColor={theme.grey2}
          position={TOOLTIP_POSITION}
          delay={200}
        />
      )}
    </>
  );
};

GraphCard.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  dynamic: PropTypes.bool,
  children: PropTypes.node,
  tooltip: PropTypes.string
};

GraphCard.defaultProps = {
  title: '',
  dynamic: false,
  children: null,
  tooltip: ''
};

export default injectSheet(styles)(memo(GraphCard));
