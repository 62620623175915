import gql from 'graphql-tag';
import { fragments as groupColumnFragments } from './group';

export default {
  group: groupColumnFragments.group,
  workflow: gql`
    fragment WorkflowQueryFragment on Workflow {
      rowId
      name
      canUpdate
      isReader
      groups {
        nodes {
          ...GroupColumnFragment
        }
      }
    }
    ${groupColumnFragments.group}
  `
};
