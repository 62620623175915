import {
  ENTITY_TYPE_USER,
  ENTITY_TYPE_BOT,
  ENTITY_TYPE_TEAM,
  ENTITY_TYPE_ORGANIZATION
} from 'gql/types';

// from the group members query get the number of teams and participants
export const getGroupCounts = groupMembers => {
  // build sets to get unique counts by rowId
  const teams = new Set();
  const users = new Set();
  const bots = new Set();

  groupMembers.forEach(groupMember => {
    const {
      account: { entity }
    } = groupMember;

    switch (entity.__typename) {
      case ENTITY_TYPE_USER:
        // register the user
        users.add(entity.rowId);
        break;
      case ENTITY_TYPE_BOT:
        // register the bot
        bots.add(entity.rowId);
        break;
      case ENTITY_TYPE_TEAM: {
        // register the team
        teams.add(entity.rowId);
        // register its users and bots
        const {
          members: { nodes: teamUsers },
          bots: { nodes: teamBots }
        } = entity;
        teamUsers.forEach(user => {
          users.add(user.userId);
        });
        teamBots.forEach(bot => {
          bots.add(bot.rowId);
        });
        break;
      }
      case ENTITY_TYPE_ORGANIZATION: {
        // register its teams, users and bots
        const {
          members: { nodes: orgaUsers },
          bots: { nodes: orgaBots },
          teams: { nodes: orgaTeams }
        } = entity;
        orgaTeams.forEach(team => {
          teams.add(team.rowId);
        });
        orgaUsers.forEach(user => {
          users.add(user.userId);
        });
        orgaBots.forEach(bot => {
          bots.add(bot.rowId);
        });
        break;
      }
      default:
        throw Error(`Unknown entity type ${entity.__typename}`);
    }
  });

  return {
    teams: teams.size,
    users: users.size,
    bots: bots.size
  };
};
