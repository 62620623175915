export default () => ({
  whoDidWhatWhen: {
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 20
  },
  whenDate: {
    fontWeight: 700,
    paddingRight: 7
  },
  whoDidWhat: {
    paddingLeft: 10
  }
});
