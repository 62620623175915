const ADD_CIRCLE = 'AddCircle';
const CLOCK_GENERAL = 'ClockGeneral';
const MOON = 'Moon';
const TIME_LINE = 'TimeLine';

const ADDCIRCLE_BACKGROUND_GRADIENT =
  'linear-gradient(272.97deg, #41AF50 0%, #6CDA7B 100%)';
const CLOCKGENERAL_BACKGROUND_GRADIENT =
  'linear-gradient(272.97deg, #802694 0%, #B458C8 100%)';
const MOON_BACKGROUND_GRADIENT =
  'linear-gradient(272.97deg, #4B35D2 0%, #5246F7 100%)';
const TIMELINE_BACKGROUND_GRADIENT =
  'linear-gradient(272.97deg, #30CECA 0%, #6BEDD8 100%)';

export default theme => ({
  root: {
    display: 'flex'
  },
  svg: {
    flexShrink: 0,
    width: 40,
    height: 40,
    borderRadius: 10,
    padding: 5,
    color: theme.white1,
    background: props => {
      switch (props.name) {
        case ADD_CIRCLE:
          return ADDCIRCLE_BACKGROUND_GRADIENT;

        case CLOCK_GENERAL:
          return CLOCKGENERAL_BACKGROUND_GRADIENT;

        case MOON:
          return MOON_BACKGROUND_GRADIENT;

        case TIME_LINE:
          return TIMELINE_BACKGROUND_GRADIENT;

        default:
          return 'none';
      }
    }
  }
});
