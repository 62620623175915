const graphCardMargin = 18;

export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  kpiCardsWrapper: {
    display: 'flex',
    width: '100%',
    height: 97,
    '& > div:first-child, > div:nth-child(2)': {
      marginRight: 21
    }
  },
  kpiCardSingleWrapper: {
    display: 'flex',
    width: '100%'
  },
  graphCardsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    marginTop: 20
  },
  graphCardsColumnLeft: {
    height: '100%',
    width: '100%',
    minWidth: 380,
    marginRight: graphCardMargin
  },
  graphCardsColumnRight: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    minWidth: 380,
    '& > div:first-child': {
      marginBottom: graphCardMargin / 2
    },
    '& > div:last-child': {
      marginTop: graphCardMargin / 2
    }
  }
});
