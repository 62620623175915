export default {
  search: '',
  filters: {
    __typename: 'Filters',
    search: '',
    watched: null,
    time: 'decr'
  },
  filtersPanelOpen: false
};
