import moment from 'moment';

// default query date range params "last 7 days"
export const today = () => moment().utc().local();

export const oneWeekAgo = () => moment().utc().local().subtract(7, 'd');

/**
 * @function computeAspectRatio - aspect is used if we
 * have a large dataset with pagination
 * @param {number} rowsPerCard - max number of rows per card
 * @param {number} rowCount - actual number of rows for the card to render
 */
export const computeAspectRatio = (rowsPerCard, rowCount) =>
  // add a space splot for the X axis when assessing the ratio rowsPerCard/rowCount
  rowsPerCard ? 1.4 * ((rowsPerCard + 1) / (rowCount + 1)) : undefined;
