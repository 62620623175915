import PropTypes from 'prop-types';

const queriesPropTypes = PropTypes.shape({
  error: PropTypes.object,
  loading: PropTypes.boolean
});

export default {
  classes: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.shape({
    name: PropTypes.string,
    rowId: PropTypes.string,
    groups: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          rowId: PropTypes.string,
          label: PropTypes.string,
          name: PropTypes.string
        })
      )
    })
  }).isRequired,
  config: PropTypes.shape({
    kpis: PropTypes.shape({
      height: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number,
          content: PropTypes.shape({
            label: PropTypes.string,
            icon: PropTypes.string,
            query: PropTypes.string,
            time: PropTypes.bool
          })
        })
      )
    }),
    graphs: PropTypes.shape({
      height: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number,
          content: PropTypes.shape({
            label: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string,
            query: PropTypes.string,
            size: PropTypes.number,
            style: PropTypes.string
          })
        })
      )
    })
  }).isRequired,
  kpisQuery: queriesPropTypes,
  graphsQuery: queriesPropTypes
};
