import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';

import { GRAPHQL_REPORTING_API_URL } from 'constant/api';

import errorLink from './errorLink';
import introspectionQueryResultData from './fragmentTypesReporting.json';

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })
});

const httpLink = new HttpLink({
  uri: GRAPHQL_REPORTING_API_URL,
  credentials: 'include'
});

export default new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache,
  connectToDevTools: true
});
