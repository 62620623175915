import React, { memo, useEffect } from 'react';
import { compose, withApollo, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import reportingClient from 'gql/client/reportingClient';

import gql from 'graphql-tag';

import { formatDuration, mapCharts } from '../utils';
import {
  lastActionOnOpenTracesFragment,
  top5GroupContributorsByTracesFragment,
  traceCountsByCreationDateFragment
} from './fragments';
import realTimePropTypes from './realTimePropTypes';

import KpiCard from './kpiCard';
import GraphCard from '../graphCard';
import { BarChart } from '../charts';
import { AnalyticsFilters } from '../ui';

import styles from './realTime.style';

// the design sets the bar chart with pagination to 9 rows per card
const ROWS_PER_CARD = 9;

/**
 * @function renderBarChart - computes the pagination if dataset.length > 9 entries
 * @param {Array} openTraces - data response from reporting-api
 * @param {boolean} loading - status response from reporting-api
 * @returns {Array} - an array of React.nodes
 */
const renderBarChart = (openTraces, loading) => {
  /**
   * If no data, we return an empty BarChart.
   * It will then call the Placeholder component with "No data to display"
   */
  if (openTraces.length === 0) return <BarChart loading={loading} />;

  // split the dataset into sub datasets arrays.
  const copyOpenTraces = [...openTraces];
  return (
    new Array(Math.ceil(copyOpenTraces.length / ROWS_PER_CARD))
      .fill()
      .map(() => copyOpenTraces.splice(0, ROWS_PER_CARD))
      // each dataset is then its own BarChart to fit inside the pagination tabs
      .map((e, i) => (
        <BarChart key={i} rowsPerCard={ROWS_PER_CARD} dataset={e} />
      ))
  );
};

export const RealTime = ({
  classes,
  selectedWorkflow,
  config,
  kpisQuery,
  graphsQuery,
  selectedGroups,
  setSelectedGroups
}) => {
  const { kpis, graphs } = config;

  // If the workflow changes, reset the selected groups
  useEffect(() => {
    setSelectedGroups([]);
  }, [selectedWorkflow.groups.nodes]);

  if (kpisQuery.error || graphsQuery.error)
    return <div>An error has occurred</div>;

  return (
    <div className={classes.root}>
      <section className={classes.kpiCardsWrapper}>
        {kpis?.items?.map(({ content: { label, icon, query, time } }) => (
          <KpiCard
            key={label}
            label={label}
            icon={icon}
            value={
              time ? formatDuration(kpisQuery?.[query]) : kpisQuery?.[query]
            }
            loading={kpisQuery.loading}
          />
        ))}
      </section>

      <div className={classes.body}>
        <section className={classes.filtersWrapper}>
          <AnalyticsFilters
            groups={selectedWorkflow.groups.nodes}
            selectedGroups={selectedGroups}
            handleSelectedGroups={setSelectedGroups}
          />
        </section>
        <section className={classes.graphGridWrapper}>
          {graphs?.items?.map(
            ({
              content: { label, description, widget, query, style },
              graphStyle
            }) => (
              <div key={label} style={graphStyle}>
                <GraphCard
                  label={label}
                  description={description}
                  loading={graphsQuery.loading}
                >
                  {mapCharts(
                    widget.type,
                    style,
                    {
                      dataset: graphsQuery?.[query] || [],
                      loading: graphsQuery.loading
                    },
                    renderBarChart
                  )}
                </GraphCard>
              </div>
            )
          )}
        </section>
      </div>
    </div>
  );
};

RealTime.propTypes = realTimePropTypes;

const GRAPHS_QUERY = gql`
  query($wfId: String!, $filters: GraphFilters) {
    lastActionOnOpenTraces(wfId: $wfId, filters: $filters) {
      ...lastActionOnOpenTracesFragment
    }
    top5GroupContributorsByTraces(wfId: $wfId, filters: $filters) {
      ...top5GroupContributorsByTracesFragment
    }
    traceCountsByCreationDate(wfId: $wfId, filters: $filters) {
      ...traceCountsByCreationDateFragment
    }
  }
  ${lastActionOnOpenTracesFragment}
  ${top5GroupContributorsByTracesFragment}
  ${traceCountsByCreationDateFragment}
`;

// KPIs returns scalars hence no need for a fragment
const KPIS_QUERY = gql`
  query($wfId: String!) {
    openTraces(wfId: $wfId)
    inactiveTraces(wfId: $wfId)
    avgTimeBetweenActions(wfId: $wfId)
  }
`;

export default compose(
  graphql(KPIS_QUERY, {
    name: 'kpisQuery',
    options: ({ selectedWorkflow: { rowId: wfId } }) => ({
      variables: { wfId },
      client: reportingClient
    })
  }),
  graphql(GRAPHS_QUERY, {
    name: 'graphsQuery',
    options: ({
      selectedWorkflow: { rowId: wfId },
      selectedGroups: groupLabels
    }) => ({
      variables: {
        wfId,
        filters: {
          groupLabels
        }
      },
      client: reportingClient
    })
  }),
  injectSheet(styles),
  withApollo
)(memo(RealTime));
