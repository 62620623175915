export default theme => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#ffffff',
    borderBottom: `1px solid ${theme.grey6}`,
    boxShadow: theme.indigoShadow1,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 70,
      height: '90%',
      zIndex: 2,
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255,255,255,0) 100%)',
      left: 0,
      top: 0,
      marginLeft: '1%'
    }
  },
  segments: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '96%',
    margin: '0 auto',
    marginBottom: 5,
    overflow: 'auto',
    padding: 0,
    position: 'relative',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      height: 6,
      display: 'block',
      background: '#FBFBFB',
      border: `1px solid ${theme.grey5}`,
      borderRadius: 100
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#dedce3',
      borderRadius: 100,
      height: 1,
      '&:hover': {
        background: '#D0CED5'
      }
    }
  },
  segmentItem: {
    width: 161,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1,
    '&:first-of-type': {
      width: 147,
      justifyContent: 'flex-end'
    },
    '&:last-of-type': {
      width: 'calc(50% + 98px)',
      flexGrow: 1,
      justifyContent: 'flex-start'
    }
  }
});
