import React, { useEffect, useRef, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import { Icon, Pushbutton, withSnackbarsContext } from '@stratumn/atomic';

import { uploadFile, handleUploadFileOnError } from 'client/media';

import theme from 'style';
import { useTooltip } from 'utils/hooks';
import InfoTooltip from 'components/ui/utils/infoTooltip';

import {
  initFormsData,
  FORMS_BATCH_MAIN_FORM_ID,
  FORMS_BATCH_ADD,
  FORMS_BATCH_INIT_VALIDATION,
  FORMS_BATCH_GLOBAL_VALIDATION_STATUS_VALIDATING,
  FORMS_BATCH_GLOBAL_VALIDATION_STATUS_SUCCESS,
  applyFormsDataChange
} from './reducers';

import BatchForm from './batchForm';
import { FORMS_WIDTH, FORMS_MARGIN_RIGHT } from './batchForm/batchForm.style';
import ErrorsSnackbar from './errorsSnackbar';

import styles from './formsBatch.style';

const PLUS_ZONE_TOOLTIP_POSITION = {
  place: 'above',
  adjustPlace: true
};

export const FormsBatch = React.memo(
  ({
    classes,
    errorSnackbar,
    workflowContext,
    schema,
    uiSchema,
    maxForms,
    onSubmit,
    submitDisabled,
    onCancel,
    cachedUpdatesKey
  }) => {
    // forms data
    const formsDataReducer = useCallback(
      (currentFormsData, action) =>
        applyFormsDataChange(currentFormsData, action, cachedUpdatesKey),
      [cachedUpdatesKey]
    );
    const [
      { mainFormData, locks, additionalForms, globalValidationStatus, errors },
      updateForms
    ] = useReducer(formsDataReducer, { cachedUpdatesKey }, initFormsData); // use lazy initialiser to avoid running this function at each rerender

    // callback to upload files
    const handleUploadFile = useCallback(
      (file, onSuccess, onError, onProgress, disableEncryption) => {
        // upload the file
        uploadFile(
          file,
          onSuccess,
          err => {
            handleUploadFileOnError(
              file.name,
              err.response && err.response.status,
              errorSnackbar
            );
            return onError(err);
          },
          onProgress,
          disableEncryption
        );
      },
      [errorSnackbar]
    );

    // trigger global validation
    const runValidation = useCallback(() => {
      updateForms({ type: FORMS_BATCH_INIT_VALIDATION });
    }, [updateForms]);
    useEffect(() => {
      // if the validaiton state is completed and successful, submit all the forms together
      if (
        globalValidationStatus === FORMS_BATCH_GLOBAL_VALIDATION_STATUS_SUCCESS
      ) {
        // trigger submission function
        onSubmit([{ data: mainFormData }, ...additionalForms], () => {
          if (cachedUpdatesKey) {
            localStorage.removeItem(cachedUpdatesKey);
          }
        });
      }
    }, [globalValidationStatus]);

    // if active error form id changes, scroll to it
    const activeErrorFormId = errors ? errors.activeFormId : undefined;
    const formsContainerRef = useRef(null);
    useEffect(() => {
      if (activeErrorFormId && formsContainerRef.current) {
        // find the position of the form to scroll to
        if (activeErrorFormId === FORMS_BATCH_MAIN_FORM_ID) {
          // scroll to the start of the container
          formsContainerRef.current.scrollTo({
            left: 0,
            behavior: 'smooth'
          });
        } else {
          const formIdx = additionalForms.findIndex(
            ({ id }) => id === activeErrorFormId
          );

          formsContainerRef.current.scrollTo({
            left: (FORMS_WIDTH + FORMS_MARGIN_RIGHT) * formIdx,
            behavior: 'smooth'
          });
        }
      }
    }, [errors]);

    // tooltip of the plus zone
    const plusZoneIconRef = useRef(null);
    const [
      onPlusZoneMouseEnter,
      onPlusZoneMouseLeave,
      showPlusZoneToolip
    ] = useTooltip(true);

    const nbForms = 1 + additionalForms.length;
    const validating =
      globalValidationStatus ===
      FORMS_BATCH_GLOBAL_VALIDATION_STATUS_VALIDATING;
    return (
      <>
        <div className={classes.forms} ref={formsContainerRef}>
          <BatchForm
            workflowContext={workflowContext}
            schema={schema}
            uiSchema={uiSchema}
            id={FORMS_BATCH_MAIN_FORM_ID}
            isMainForm
            isInBatch={nbForms > 1}
            data={mainFormData}
            lockedPaths={locks}
            updateForms={updateForms}
            uploadFile={handleUploadFile}
            validating={validating}
            hasErrorFocus={activeErrorFormId === FORMS_BATCH_MAIN_FORM_ID}
            dataCy={FORMS_BATCH_MAIN_FORM_ID}
          />
          {additionalForms.map(({ id, data, links }) => (
            <BatchForm
              key={id}
              workflowContext={workflowContext}
              schema={schema}
              uiSchema={uiSchema}
              id={id}
              isInBatch
              data={data}
              lockedPaths={locks}
              linkedPaths={links}
              updateForms={updateForms}
              uploadFile={handleUploadFile}
              validating={validating}
              hasErrorFocus={activeErrorFormId === id}
              dataCy="additional-form"
            />
          ))}
          <div
            className={classes.plusZone}
            onMouseEnter={onPlusZoneMouseEnter}
            onMouseLeave={onPlusZoneMouseLeave}
            onClick={() =>
              nbForms < maxForms && updateForms({ type: FORMS_BATCH_ADD })
            }
            data-is-disabled={nbForms === maxForms}
            data-cy="plus-zone"
          >
            <div ref={plusZoneIconRef}>
              <Icon name="CirclePlus" size={38} />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <Pushbutton onClick={onCancel} dataCy="cancel">
            Cancel
          </Pushbutton>
          <Pushbutton
            primary
            onClick={runValidation}
            disabled={submitDisabled}
            dataCy="submit"
          >
            {`Submit Trace${nbForms > 1 ? 's' : ''}`}
          </Pushbutton>
        </div>
        {showPlusZoneToolip && (
          <InfoTooltip
            clientRef={plusZoneIconRef}
            text={
              nbForms < maxForms
                ? 'Add another trace'
                : `You have reached the maximum number of forms for a single batch ( ${maxForms} )`
            }
            textColor={theme.white1}
            backgroundColor={
              nbForms < maxForms ? theme.grey2 : theme.warningRed
            }
            position={PLUS_ZONE_TOOLTIP_POSITION}
            delay={100}
          />
        )}
        {errors && <ErrorsSnackbar errors={errors} updateForms={updateForms} />}
      </>
    );
  }
);
FormsBatch.propTypes = {
  classes: PropTypes.object.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
  workflowContext: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  maxForms: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  cachedUpdatesKey: PropTypes.string
};

FormsBatch.defaultProps = {
  maxForms: 100,
  submitDisabled: false,
  cachedUpdatesKey: null
};

export default compose(injectSheet(styles), withSnackbarsContext)(FormsBatch);
