import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';
import { Check } from '@stratumn/atomic';

import injectSheet from 'react-jss';

import InfoTooltip from 'components/ui/utils/infoTooltip';

import styles from './tableHeaderElement.style';

export const HEADER_CHECKBOX = 'CHECKBOX';
export const HEADER_CHECKBOX_INDETERMINATE = 'CHECKBOX_INDETERMINATE';

// table header element
const TableHeaderElement = React.memo(
  ({ classes, label, icon, iconIfClicked, clicked, onClick, tooltip }) => {
    // handle tooltip attached to the element
    let onMouseEnter = null;
    let onMouseLeave = null;
    const [showToolip, setShowTooltip] = useState(false);
    const headerRef = useRef(null);

    if (tooltip) {
      onMouseEnter = () => {
        setShowTooltip(true);
      };
      onMouseLeave = () => {
        setShowTooltip(false);
      };
    }

    let iconComponent = null;
    const uCaseIcon = icon.toUpperCase();
    switch (uCaseIcon) {
      case HEADER_CHECKBOX:
      case HEADER_CHECKBOX_INDETERMINATE:
        iconComponent = (
          <Check
            label=""
            checked={clicked}
            showLabel={false}
            handleChange={onClick}
            isDark
            indeterminate={uCaseIcon === HEADER_CHECKBOX_INDETERMINATE}
          />
        );
        break;
      default:
        iconComponent = (
          <DynamicIcon
            icon={clicked && iconIfClicked ? iconIfClicked : icon}
            className={classes.tableHeaderElementIcon}
            onClick={onClick}
          />
        );
    }

    return (
      <>
        <div
          className={classes.tableHeaderElement}
          data-is-clicked={clicked}
          ref={headerRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          data-cy={`table-header-${(label || icon)
            .replace(' ', '-')
            .toLowerCase()}`}
        >
          <span>{iconComponent}</span>
          {label && (
            <span className={classes.tableHeaderElementLabel} onClick={onClick}>
              {label}
            </span>
          )}
        </div>
        {showToolip && (
          <InfoTooltip
            clientRef={headerRef}
            text={tooltip}
            textColor="white"
            backgroundColor="black"
            position={{
              place: 'left',
              placeShift: 3,
              adjustPlace: true
            }}
            delay={500}
          />
        )}
      </>
    );
  }
);
TableHeaderElement.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconIfClicked: PropTypes.string,
  clicked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  tooltip: PropTypes.string
};
TableHeaderElement.defaultProps = {
  label: null,
  iconIfClicked: null,
  onClick: () => {},
  tooltip: null
};

export default injectSheet(styles)(TableHeaderElement);
