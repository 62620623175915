export default theme => ({
  root: {
    display: 'flex'
  },
  svg: {
    flexShrink: 0,
    width: 40,
    height: 40,
    borderRadius: 10,
    padding: 5,
    color: theme.white1,
    background: ({ bgColor }) => bgColor || theme.indigo2
  }
});
