import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';

// React Window

import { AvatarGroup, Pulldown, PulldownMenuItem } from '@stratumn/atomic';
import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import { fragments as groupsTaskListItemFragments } from './groupsTaskListItem';

import styles from './groupsTaskList.style';

// some constants specific to groups task list
const DEFAULT_ORDER_BY = 'ALL_DESC';

const getSortFromConfig = config => config.orderBy || DEFAULT_ORDER_BY;

const getFilterFromConfig = config => {
  const { filterWorkflowId, filterGroupId } = config;
  return {
    workflowId: { equalTo: filterWorkflowId },
    groupId: { equalTo: filterGroupId }
  };
};

// groups task list of the user dashboard
export const GroupsTaskListHeader = React.memo(
  ({
    classes,
    refetchPriorities,
    workflows,
    config,
    setConfig,
    setRefetching
  }) => {
    // set filter workflow and group id
    const { filterWorkflowId, filterGroupId } = config;

    const setConfigAndRefetch = newConfig => {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfig(newConfig);
        setRefetching(true);
      });

      // Refetch priorities with the new variables
      const filter = getFilterFromConfig(newConfig);
      const orderBy = getSortFromConfig(newConfig);
      return refetchPriorities({
        filter,
        orderBy
      }).then(() => {
        setRefetching(false);
      });
    };

    const setFilterWorkflowId = value => {
      if (value === config.filterWorkflowId) return null;

      // When changing the workflow ID filter,
      // unset the group ID filter
      const newConfig = {
        ...config,
        filterWorkflowId: value,
        filterGroupId: undefined
      };
      return setConfigAndRefetch(newConfig);
    };

    const setFilterGroupId = value => {
      if (value === config.filterGroupId) return null;

      const newConfig = {
        ...config,
        filterGroupId: value
      };
      return setConfigAndRefetch(newConfig);
    };

    const filterWorkflow = workflows.find(w => w.rowId === filterWorkflowId);
    const groups = filterWorkflow
      ? filterWorkflow.groups.nodes.filter(g => g.canActQuick)
      : [];
    const filterGroup =
      filterWorkflow &&
      filterWorkflow.groups.nodes.find(g => g.rowId === filterGroupId);

    // ========================================================================
    // component to display all workflows and select one
    // ========================================================================

    const wfOptions = workflows
      .filter(w => w.groups.nodes.some(g => g.canActQuick))
      .map(w => {
        const { rowId, name } = w;
        return (
          <PulldownMenuItem
            key={rowId}
            onClick={() => setFilterWorkflowId(rowId)}
            dataCy="workflow-filter"
          >
            {name}
          </PulldownMenuItem>
        );
      });

    // insert select all at front
    wfOptions.unshift(
      <PulldownMenuItem
        key={-1}
        onClick={() => setFilterWorkflowId(undefined)}
        dataCy="noworkflow-filter"
      >
        All
      </PulldownMenuItem>
    );

    const workflowsListComponent = (
      <div className={classes.filter} data-is-button>
        <div className={classes.filterHeader}>workflows</div>
        <div className={classes.filterButton}>
          <Pulldown
            dataCy="workflow-filter-pulldown"
            buttonLabel={
              <div className={classes.filterItemContent}>
                {filterWorkflow ? filterWorkflow.name : 'All'}
              </div>
            }
          >
            {wfOptions}
          </Pulldown>
        </div>
      </div>
    );

    // ========================================================================
    // component to display all groups and select one when relevant
    // ========================================================================

    const groupOptions = groups.map(group => {
      const { rowId, name, avatar } = group;
      return (
        <PulldownMenuItem
          key={rowId}
          onClick={() => setFilterGroupId(rowId)}
          icon={
            avatar && (
              <div className={classes.groupAvatar}>
                <AvatarGroup src={avatar} size={22} />
              </div>
            )
          }
          dataCy="group-filter"
        >
          {name}
        </PulldownMenuItem>
      );
    });

    if (groups.length > 1) {
      // insert select all at front
      groupOptions.unshift(
        <PulldownMenuItem
          key={-1}
          onClick={() => setFilterGroupId(undefined)}
          icon={<DynamicIcon icon="World" className={classes.allGroupsIcon} />}
          dataCy="nogroup-filter"
        >
          All
        </PulldownMenuItem>
      );
    }

    let selectedGroup;
    if (filterGroup) {
      selectedGroup = (
        <>
          <div className={classes.groupAvatar}>
            <AvatarGroup src={filterGroup.avatar} size={22} />
          </div>
          {filterGroup.name}
        </>
      );
    } else if (groups.length === 1) {
      // In case there is only 1 group, selected it directly
      selectedGroup = (
        <>
          <div className={classes.groupAvatar}>
            <AvatarGroup src={groups[0].avatar} size={22} />
          </div>
          {groups[0].name}
        </>
      );
    } else {
      selectedGroup = (
        <>
          <DynamicIcon icon="World" className={classes.allGroupsIcon} />
          All
        </>
      );
    }

    const groupsListComponent = (
      <div className={classes.filter} data-is-button>
        <div className={classes.filterHeader}>groups</div>
        <div className={classes.filterButton}>
          <Pulldown
            dataCy="group-filter-pulldown"
            disabled={!filterWorkflow}
            buttonLabel={
              <div className={classes.filterItemContent}>{selectedGroup}</div>
            }
          >
            {groupOptions}
          </Pulldown>
        </div>
      </div>
    );

    // ========================================================================
    // render
    // ========================================================================

    return (
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          Group task list
          <div className={classes.headerTitleUnderline} />
        </div>
        {workflowsListComponent}
        {groupsListComponent}
      </div>
    );
  }
);

GroupsTaskListHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  workflows: PropTypes.array,
  refetchPriorities: PropTypes.func.isRequired,
  config: PropTypes.object,
  setConfig: PropTypes.func.isRequired,
  setRefetching: PropTypes.func.isRequired
};
GroupsTaskListHeader.defaultProps = {
  workflows: {},
  config: {}
};

export const queries = {
  prioritiesQuery: gql`
    query prioritiesQuery(
      $first: Int
      $cursor: Cursor
      $orderBy: [PrioritiesOrderBy!]
      $filter: PriorityFilter
    ) {
      priorities(
        first: $first
        after: $cursor
        orderBy: $orderBy
        filter: $filter
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          ...PriorityFragment
        }
      }
    }
    ${groupsTaskListItemFragments.priority}
  `
};

export default injectSheet(styles)(GroupsTaskListHeader);
