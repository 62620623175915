import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { getByPath, sortData } from 'utils';

import {
  Widget,
  getSortConfig as getWidgetSortConfig
} from 'components/ui/widget';

import styles from './array.style';

export const ArrayView = React.memo(({ classes, view, data }) => {
  const { itemsPath, itemsWidget, defaultSort } = view;

  const items = getByPath(data, itemsPath);

  if (!Array.isArray(items)) {
    return null;
  }

  let itemsToDisplay = items;
  if (defaultSort) {
    const { direction } = defaultSort;
    const defaultSortConfig = getWidgetSortConfig(itemsWidget || {});
    itemsToDisplay = sortData(itemsToDisplay, defaultSortConfig, direction);
  }

  return (
    <div className={classes.array}>
      {itemsToDisplay.map((item, index) => (
        <Widget key={index} widget={itemsWidget} data={item} />
      ))}
    </div>
  );
});

ArrayView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default {
  component: injectSheet(styles)(ArrayView)
};
