import React, { useState, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import to from 'await-to-js';
import isequal from 'lodash.isequal';
import { stringify } from '@stratumn/canonicaljson';
import { withSnackbarsContext } from '@stratumn/atomic';

import { deepGet } from 'utils';
import { WorkflowContext, buildWorkflowContext } from 'utils/workflowContext';
import {
  TYPE_WORKFLOW,
  TYPE_WORKFLOW_CONFIG,
  TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD
} from 'gql/types';

import OverviewTable from 'components/ui/table';
import JsonEditor from 'components/ui/utils/jsonEditor';

import { withUser } from 'contexts';
import { BUFFER } from 'constant/pagination';
import { displayConfig as defaultDisplayconfig } from './static/displayConfig';
import { withWorkflowOverviewContext } from './context';
import {
  saveUserDisplayConfig,
  getSortFromUserDisplayConfig,
  getGroupFromUserDisplayConfig,
  getFiltersFromUserDisplayConfig,
  findGroupTraceStatesByIndex
} from './utils';

import styles from './workflowOverview.style';

const configEditorCodemirrorOptions = {
  theme: 'material'
};

export const WorkflowOverviewTable = React.memo(props => {
  const {
    classes,
    user,
    errorSnackbar,
    successSnackbar,
    workflow,
    updateOverviewConfigMutation,
    handleOnLoadMore,
    refetchTraces,
    exportSetup,
    context
  } = props;
  const {
    setSelectedTraceIds,
    showNewTracesTray,
    showTracesUpdateTray,
    toggleNewTracesTray,
    toggleUpdateTracesTray,
    userDisplayConfig,
    setUserDisplayConfig
  } = context;
  const { config = {} } = workflow;
  const {
    me: { isSuperuser }
  } = user;
  const { rowId: configRowId, overview: configOverview } = config;
  const displayConfig = configOverview || defaultDisplayconfig;

  // Define state
  const [showTableConfigEditor, setShowTableConfigEditor] = useState(false);
  const toggleShowTableConfigEditor = useCallback(
    () => setShowTableConfigEditor(!showTableConfigEditor),
    [showTableConfigEditor, setShowTableConfigEditor]
  );
  const [refetching, setRefetching] = useState(false);

  // build the workflow context passed to the widgets framework
  const workflowContext = useMemo(() => buildWorkflowContext(workflow), [
    workflow
  ]);

  const onUpdateUserDisplayConfig = useCallback(
    newUserDisplayConfig => {
      // get sort, filter and group by configs

      const newFilter = getFiltersFromUserDisplayConfig(newUserDisplayConfig);
      const newOrderBy = getSortFromUserDisplayConfig(newUserDisplayConfig);
      const newGroupBy = getGroupFromUserDisplayConfig(newUserDisplayConfig);

      const previousFilter = getFiltersFromUserDisplayConfig(userDisplayConfig);
      const previousOrderBy = getSortFromUserDisplayConfig(userDisplayConfig);
      const previousGroupBy = getGroupFromUserDisplayConfig(userDisplayConfig);

      const isFilterByEqual = isequal(previousFilter, newFilter);
      const isOrderByEqual = isequal(previousOrderBy, newOrderBy);
      const isGroupByEqual = isequal(previousGroupBy, newGroupBy);

      saveUserDisplayConfig(workflow.rowId, newUserDisplayConfig);

      if (!isFilterByEqual || !isOrderByEqual || !isGroupByEqual) {
        ReactDOM.unstable_batchedUpdates(() => {
          setUserDisplayConfig(newUserDisplayConfig);
          setRefetching(true);
        });

        // Refetch traces with the new variables
        return refetchTraces({
          workflowId: workflow.rowId,
          filter: newFilter,
          orderBy: newOrderBy,
          groupBy: newGroupBy
        }).then(() => {
          setRefetching(false);
        });
      }
      return setUserDisplayConfig(newUserDisplayConfig);
    },
    [userDisplayConfig, setUserDisplayConfig, setRefetching, refetchTraces]
  );

  const handleTableConfigUpdate = useCallback(
    async newOverviewConfigStr => {
      const {
        rowId: workflowRowId,
        config: { rowId: workflowConfigId } = {}
      } = workflow;
      const newOverviewConfig = newOverviewConfigStr
        ? JSON.parse(newOverviewConfigStr)
        : null;

      const [err] = await to(
        updateOverviewConfigMutation({
          variables: {
            workflowConfigId,
            newOverviewConfig
          },
          optimisticResponse: {
            updateWorkflowConfigByRowId: {
              workflow: {
                rowId: workflowRowId,
                config: {
                  rowId: workflowConfigId,
                  overview: newOverviewConfig,
                  __typename: TYPE_WORKFLOW_CONFIG
                },
                __typename: TYPE_WORKFLOW
              },
              __typename: TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD
            }
          }
        })
      );

      if (err) {
        errorSnackbar('Something went wrong during the table config update...');
        return;
      }

      successSnackbar('The table config was correctly updated');
    },
    [workflow]
  );

  const { stateData, hasNextPage } = useMemo(() => {
    const selectedGroupIndex = deepGet(userDisplayConfig, 'groupBy.selected');
    const groupBy = getGroupFromUserDisplayConfig(userDisplayConfig);
    const { groupedTraceStates } = workflow;
    const traceStates = findGroupTraceStatesByIndex(
      groupedTraceStates,
      selectedGroupIndex
    );

    const data = groupBy
      ? groupedTraceStates.nodes.filter(g => g.traceStates.nodes.length)
      : traceStates.nodes;

    return { hasNextPage: traceStates.pageInfo.hasNextPage, stateData: data };
  }, [
    workflow.groupedTraceStates,
    userDisplayConfig && userDisplayConfig.groupBy
  ]);

  const tableUpdate = useMemo(
    () => ({
      loading: refetching,
      disableClientSideSortFilter: true,
      onClickConfig:
        configRowId !== undefined && isSuperuser
          ? toggleShowTableConfigEditor
          : null,
      configClicked: showTableConfigEditor,
      onClickNew: toggleNewTracesTray,
      newClicked: showNewTracesTray,
      onClickUpdate: toggleUpdateTracesTray,
      updateClicked: showTracesUpdateTray,
      setSelectedRows: setSelectedTraceIds,
      userDisplay: {
        config: userDisplayConfig,
        onUpdate: onUpdateUserDisplayConfig
      },
      pagination: {
        buffer: BUFFER,
        hasNextPage: hasNextPage,
        onLoadMore: handleOnLoadMore
      },
      group: {
        indexPath: 'index',
        rowsPath: 'traceStates.nodes[]',
        rowCountPath: 'traceStates.totalCount'
      },
      exportSetup
    }),
    [
      refetching,
      showTableConfigEditor,
      toggleNewTracesTray,
      showNewTracesTray,
      toggleUpdateTracesTray,
      showTracesUpdateTray,
      setSelectedTraceIds,
      userDisplayConfig,
      onUpdateUserDisplayConfig,
      hasNextPage,
      handleOnLoadMore,
      exportSetup
    ]
  );

  return (
    <>
      <div className={classes.overviewTableContainer}>
        <WorkflowContext.Provider value={workflowContext}>
          <OverviewTable
            data={stateData}
            config={displayConfig}
            update={tableUpdate}
          />
        </WorkflowContext.Provider>
      </div>
      {showTableConfigEditor && (
        <JsonEditor
          title="Table configuration"
          jsonString={stringify(displayConfig, null, 2)}
          onSubmit={handleTableConfigUpdate}
          onClose={toggleShowTableConfigEditor}
          codemirrorOptions={configEditorCodemirrorOptions}
        />
      )}
    </>
  );
});

WorkflowOverviewTable.propTypes = {
  classes: PropTypes.object.isRequired,
  successSnackbar: PropTypes.func.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  updateOverviewConfigMutation: PropTypes.func.isRequired,
  handleOnLoadMore: PropTypes.func.isRequired,
  refetchTraces: PropTypes.func.isRequired,
  exportSetup: PropTypes.shape({
    getConfirmationSetup: PropTypes.func.isRequired,
    generateExportPayload: PropTypes.func.isRequired
  }).isRequired,
  context: PropTypes.shape({
    userDisplayConfig: PropTypes.object,
    setUserDisplayConfig: PropTypes.func.isRequired,
    setSelectedTraceIds: PropTypes.func.isRequired,
    showNewTracesTray: PropTypes.bool.isRequired,
    showTracesUpdateTray: PropTypes.bool.isRequired,
    toggleNewTracesTray: PropTypes.func.isRequired,
    toggleUpdateTracesTray: PropTypes.func.isRequired
  }).isRequired
};

const UPDATE_OVERVIEW_CONFIG_MUTATION = gql`
  mutation updateOverviewConfigMutation(
    $workflowConfigId: BigInt!
    $newOverviewConfig: JSON
  ) {
    updateWorkflowConfigByRowId(
      input: {
        rowId: $workflowConfigId
        patch: { overview: $newOverviewConfig }
      }
    ) {
      workflow {
        rowId
        config {
          rowId
          overview
        }
      }
    }
  }
`;

export default compose(
  graphql(UPDATE_OVERVIEW_CONFIG_MUTATION, {
    name: 'updateOverviewConfigMutation'
  }),
  injectSheet(styles),
  withSnackbarsContext,
  withUser,
  withWorkflowOverviewContext
)(WorkflowOverviewTable);
