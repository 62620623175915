import React, { useMemo, memo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import theme from 'style';

import KpiCardSkeleton from './kpiCardSkeleton';
import IconKpi from '../../iconKpi';

import styles from './kpiCard.style';

// Icons name
const ADD_CIRCLE = 'AddCircle';
const CLOCK_GENERAL = 'ClockGeneral';
const MOON = 'Moon';
const TIMELINE = 'TimeLine';

// Background gradients related to its icon
export const bgGradient = {
  ADDCIRCLE: 'linear-gradient(272.97deg, #41AF50 0%, #6CDA7B 100%)',
  CLOCKGENERAL: 'linear-gradient(272.97deg, #802694 0%, #B458C8 100%)',
  MOON: 'linear-gradient(272.97deg, #4B35D2 0%, #5246F7 100%)',
  TIMELINE: 'linear-gradient(272.97deg, #30CECA 0%, #6BEDD8 100%)'
};

export const KpiCard = ({ classes, label, icon, value, loading }) => {
  const renderColor = useMemo(() => {
    const color = {};

    switch (icon) {
      case ADD_CIRCLE:
        color.bgColorGradient = bgGradient.ADDCIRCLE;
        color.bgColor = theme.teal1;
        break;

      case CLOCK_GENERAL:
        color.bgColorGradient = bgGradient.CLOCKGENERAL;
        // TODO: Update atomic globalStyle with new purple1
        color.bgColor = '#B458C8';
        break;

      case MOON:
        color.bgColorGradient = bgGradient.MOON;
        color.bgColor = theme.indigo2;
        break;

      case TIMELINE:
        color.bgColorGradient = bgGradient.TIMELINE;
        color.bgColor = theme.teal1;
        break;

      default:
        return null;
    }

    return { color };
  }, [icon]);

  // TODO: Update Skeleton to reflect new design
  if (loading) return <KpiCardSkeleton icon={!!icon} />;

  const { color } = renderColor;

  return (
    <div
      data-cy="kpi-card"
      // inline style for dynamic colors
      style={{ borderTopColor: color.bgColor }}
      className={classes.wrapper}
    >
      <div className={classes.innerWrapper}>
        {icon && <IconKpi name={icon} bgColor={color.bgColorGradient} />}
        <div className={classes.details}>
          <p data-cy="kpi-card-value" className={classes.value}>
            {value}
          </p>
          <h5 className={classes.label}>{label}</h5>
        </div>
      </div>
    </div>
  );
};

KpiCard.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.number]),
  icon: PropType.string,
  loading: PropType.bool
};

KpiCard.defaultProps = {
  label: '',
  value: null,
  icon: '',
  loading: false
};

export default injectSheet(styles)(memo(KpiCard));
