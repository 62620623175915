import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './placeholder.style';

export const Placeholder = ({ classes, text }) => (
  <div className={classes.root} data-cy="graph-placeholder">
    <p className={classes.text}>{text}</p>
  </div>
);

Placeholder.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string
};

Placeholder.defaultProps = {
  text: 'No data to display'
};

export default injectSheet(styles)(memo(Placeholder));
