export default theme => ({
  comment: {
    display: 'flex',
    paddingBottom: 15
  },
  commentAvatar: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.grey7,
    minWidth: 44,
    height: 44,
    borderRadius: '50%',
    position: 'relative',
    top: 5
  },
  commentContainer: {
    width: '100%',
    paddingBottom: 17,
    margin: '0 13px',
    borderBottom: `1px solid #F8F8F8`,
    minHeight: 70
  },
  userId: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
  },
  userName: {
    color: theme.grey1,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '23px',
    marginRight: 10
  },
  userGroup: {
    color: theme.grey2,
    fontSize: 13,
    paddingLeft: 10,
    borderLeft: `1px solid ${theme.grey2}`
  },
  date: {
    padding: '2px 0',
    color: theme.grey3,
    fontSize: 11,
    fontWeight: 'normal',
    lineHeight: '24px'
  },
  commentRoot: {
    fontSize: 14,
    lineHeight: '23px',
    fontWeight: 500,
    color: theme.grey1,
    '& h1': {
      fontSize: 18,
      fontWeight: 700,
      color: theme.grey1
    },
    '& strong': {
      fontWeight: 700,
      color: theme.grey1
    },
    '& li': {
      position: 'relative',
      paddingLeft: 22,
      fontSize: 14,
      '&::before': {
        position: 'absolute',
        content: '"•"',
        color: theme.indigo3,
        fontWeight: 'bold',
        width: '1em',
        marginLeft: '-1em'
      }
    }
  }
});
