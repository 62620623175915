import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { createBrowserHistory } from 'history';
import { Snackbars } from '@stratumn/atomic';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { ApolloProvider } from 'react-apollo';
import * as routes from 'constant/routes';
import { traceClient } from 'gql';
import {
  PrivateRoute,
  WorkflowBoard,
  WorkflowOverview,
  WorkflowExport,
  GroupBoard,
  Login,
  NewLink,
  TraceInspector,
  ErrorBoundary,
  OAuth,
  PageNotFound,
  PageUnauthorized,
  DataDashboard,
  UserDashboard,
  AnalyticsDashboard,
  WorkflowGroups,
  GroupSettings,
  NewAnalyticsDashboard,
  UnexpectedErrorBoundary
} from 'components';

import theme from 'style';
import 'style/style.css';

import TicketBar, { TicketProvider } from 'components/ui/ticketBar';

export const history = createBrowserHistory();

const Root = () => (
  <ApolloProvider client={traceClient}>
    <ThemeProvider theme={theme}>
      <UnexpectedErrorBoundary>
        <Router history={history}>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Snackbars>
              <ErrorBoundary>
                <TicketProvider>
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 100,
                      left: 50,
                      bottom: 0
                    }}
                  >
                    <TicketBar />
                  </div>
                  <Switch>
                    <Route path={routes.ROUTE_LOGIN} exact component={Login} />
                    <Route path={routes.ROUTE_OAUTH} exact component={OAuth} />

                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_DASHBOARD}
                      exact
                      component={UserDashboard}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_BOARD}
                      exact
                      component={WorkflowBoard}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_OVERVIEW}
                      exact
                      component={WorkflowOverview}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_EXPORT}
                      exact
                      component={WorkflowExport}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_GROUP_BOARD}
                      exact
                      component={GroupBoard}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_NEW_LINK}
                      exact
                      component={NewLink}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_INSPECT_TRACE}
                      exact
                      component={TraceInspector}
                    />
                    <PrivateRoute path={routes.ROUTE_USER_DASHBOARD} exact>
                      <Redirect to={routes.ROUTE_WORKFLOW_DASHBOARD} />
                    </PrivateRoute>
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_GROUPS}
                      exact
                      component={WorkflowGroups}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_GROUP_SETTINGS}
                      exact
                      component={GroupSettings}
                    />
                    {/* accessible by all environments except release */}
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_DATA_DASHBOARD}
                      exact
                      component={DataDashboard}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_ANALYTICS}
                      exact
                      component={AnalyticsDashboard}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_WORKFLOW_NEW_ANALYTICS}
                      exact
                      component={NewAnalyticsDashboard}
                    />
                    <PrivateRoute
                      path={routes.ROUTE_UNAUTHORIZED}
                      exact
                      component={PageUnauthorized}
                    />
                    <PrivateRoute path="*" component={PageNotFound} />
                  </Switch>
                </TicketProvider>
              </ErrorBoundary>
            </Snackbars>
          </QueryParamProvider>
        </Router>
      </UnexpectedErrorBoundary>
    </ThemeProvider>
  </ApolloProvider>
);

export default Root;
