import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './page2.style';

const GraphDelaysForLettersToBeSent = React.lazy(() =>
  import('../graphs/Open-investigations/delays-for-letters-to-be-sent.js')
);
const GraphDelaysFromCreationOfTheInvestigation = React.lazy(() =>
  import(
    '../graphs/Open-investigations/delays-from-creation-of-the-investigation.js'
  )
);
const GraphDelaySinceTheInvestigationWasSent = React.lazy(() =>
  import(
    '../graphs/Open-investigations/delays-since-the-investigation-was-sent.js'
  )
);
const GraphDistributionBySteps = React.lazy(() =>
  import('../graphs/Open-investigations/distribution-by-steps.js')
);
const GraphFillingRateByInvestigatorPage2 = React.lazy(() =>
  import('../graphs/Open-investigations/filling-rate-by-investigator.js')
);

const generalData = [
  {
    data: '30',
    title: 'Open investigations'
  },
  {
    data: '585k€',
    title: 'current insured amount in treatment'
  },
  {
    data: '6 (117k€)',
    title: 'Found beneficiaries'
  },
  {
    data: '24 (468k€)',
    title: 'unFound beneficiaries'
  },
  {
    data: '20.5%',
    title: 'Research success'
  }
];

export const OpenInvestigations = ({ classes }) => (
  <div className={classes.investigations}>
    <div className={classes.grid}>
      <div className={classes.generalDataOI}>
        {generalData.map(el => (
          <div className={classes.generalDataDivOI} key={el.title}>
            <h2>{el.data}</h2>
            <h6>{el.title}</h6>
          </div>
        ))}
      </div>
      <div className={classes.stepsOI}>
        <h1>Distribution by steps (volumes)</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphDistributionBySteps />
          </Suspense>
        </div>
      </div>
      <div className={classes.delaysLetterSentOI}>
        <h1>Delays for letters to be sent to found beneficiaries</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphDelaysForLettersToBeSent />
          </Suspense>
        </div>
      </div>
      <div className={classes.delaysInvestigationSentOI}>
        <h1>Delays since the investigation was rooted to investigator</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphDelaySinceTheInvestigationWasSent />
          </Suspense>
        </div>
      </div>
      <div className={classes.fillingRateInvestigatorOI}>
        <h1>Average filling rate by investigator</h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphFillingRateByInvestigatorPage2 />
          </Suspense>
        </div>
      </div>
      <div className={classes.delaysFromCreationInvestigationOI}>
        <h1>
          Delays from creation of the investigation (beneficiary not found)
        </h1>
        <div className={classes.chart}>
          <Suspense fallback={<div>Loading...</div>}>
            <GraphDelaysFromCreationOfTheInvestigation />
          </Suspense>
        </div>
      </div>
    </div>
  </div>
);

OpenInvestigations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(OpenInvestigations);
