import { init as initApm } from 'elastic-apm-js-base';

import { ROOT_API_URL, MEDIA_API_URL, ACCOUNT_API_URL } from 'constant/api';
import {
  APM_ACTIVATE,
  APM_SERVER_URL,
  APM_SERVICE_NAME,
  VERSION
} from 'constant/monitoring';

const apm = initApm({
  active: APM_ACTIVATE === 'true',
  serviceName: APM_SERVICE_NAME,
  serviceVersion: VERSION,
  serverUrl: APM_SERVER_URL,
  distributedTracingOrigins: [ROOT_API_URL, MEDIA_API_URL, ACCOUNT_API_URL]
});

export default apm;
