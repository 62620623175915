import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import moment from 'moment';

import { getByPath } from 'utils/widgets';

import styles from './date.style';

const DEFAULT_DISPLAY_FORMAT = 'DD/MM/YYYY';

const DateViewImpl = React.memo(({ classes, view, data }) => {
  const {
    path,
    inputFormat,
    format = DEFAULT_DISPLAY_FORMAT,
    isDeadline,
    deadlineWarningBuffer = 0,
    donePath,
    deadlineWarningIcon
  } = view;

  const dateStr = getByPath(data, path);
  if (!dateStr) return null;

  const date = moment.utc(dateStr, inputFormat).local();

  // Deadline functionalities
  let warning = false;
  let daysToDeadline = null;
  let done = null;
  let countdownStr = 'Done';
  if (isDeadline) {
    daysToDeadline = date.diff(moment(), 'days');
    const daysToWarning = daysToDeadline - deadlineWarningBuffer;
    done = getByPath(data, donePath);
    warning = !done && daysToWarning <= 0;
    if (!done) {
      switch (true) {
        case daysToDeadline === 0:
          countdownStr = 'Due today';
          break;
        case daysToDeadline > 0:
          countdownStr = `Due in ${daysToDeadline} days`;
          break;
        default:
          countdownStr = `${-daysToDeadline} days overdue`;
      }
    }
  }
  return (
    <div className={classes.dateView} title={isDeadline && countdownStr}>
      <div className={classes.dateViewValue} data-warning={warning}>
        {date.format(format)}
      </div>
      {warning && (
        <DynamicIcon
          icon={deadlineWarningIcon || 'Clock'}
          className={classes.dateViewDeadlineIcon}
        />
      )}
    </div>
  );
});
DateViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
// but using a date interpreter in case a specific display format has been set
const getFilterConfig = view => ({
  type: 'dateRange',
  path: view.path,
  inputFormat: view.inputFormat,
  format: view.format || DEFAULT_DISPLAY_FORMAT
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = view => {
  const { path, inputFormat, format = DEFAULT_DISPLAY_FORMAT } = view;

  return data => {
    const dateStr = getByPath(data, path);
    if (!dateStr) return '';

    const date = moment.utc(dateStr, inputFormat).local();

    return date.format(format);
  };
};

export default {
  component: injectSheet(styles)(DateViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
