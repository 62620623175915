export default theme => ({
  root: {
    width: '100%',
    height: 128,
    position: 'relative',
    boxShadow: `0 0 0 1px ${theme.grey6}`,
    backgroundColor: theme.grey9
  },
  attachment: {
    width: '100%',
    height: 100,
    overflow: 'hidden',
    position: 'relative'
  },
  attachmentContainer: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.grey4
  },
  icon: {
    width: 42,
    height: 42
  },
  fileInfo: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.grey8,
    opacity: 0,
    transition: 'opacity 100ms linear',
    padding: 7,
    lineHeight: 1.4,
    fontSize: 12,
    color: theme.grey2,
    '&[aria-hidden=false]': {
      opacity: 1
    }
  },
  fileName: {
    fontWeight: 700,
    wordBreak: 'break-word',
    marginBottom: 3
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 28,
    backgroundColor: theme.grey6,
    display: 'flex',
    paddingLeft: 7
  },
  label: {
    lineHeight: '28px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 0,
    flexGrow: 1,
    fontSize: 12,
    fontWeight: 400,
    color: theme.grey2,
    marginRight: 7,
    cursor: 'pointer'
  },
  labelActive: {
    display: 'flex',
    alignItems: 'center',
    color: theme.indigo3
  },
  labelIcon: {
    width: 16,
    height: 16,
    display: 'block',
    marginRight: 7
  },
  fileExt: {
    height: 'inherit',
    fontSize: 12,
    fontWeight: 700,
    color: theme.grey2,
    lineHeight: '28px',
    paddingLeft: 7,
    paddingRight: 7,
    textTransform: 'uppercase',
    backgroundColor: theme.grey8
  }
});
