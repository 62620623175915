import gql from 'graphql-tag';

/**
 * Graphs fragments
 */
const lastActionOnOpenTracesFragment = gql`
  fragment lastActionOnOpenTracesFragment on LastAction {
    icon
    averageTime
    action
    count
  }
`;

const traceCountsByCreationDateFragment = gql`
  fragment traceCountsByCreationDateFragment on Point {
    x
    y
  }
`;

const top5GroupContributorsByTracesFragment = gql`
  fragment top5GroupContributorsByTracesFragment on TopGroupContributorInTraces {
    percent
    name
    avatar
  }
`;

export {
  lastActionOnOpenTracesFragment,
  traceCountsByCreationDateFragment,
  top5GroupContributorsByTracesFragment
};
