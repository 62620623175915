export default theme => ({
  dataImporter: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  dataImporterButton: {
    color: theme.indigo3,
    backgroundColor: 'transparent',
    border: `1px solid currentColor`,
    borderRadius: 4,
    padding: '7px 15px',
    fontSize: 9,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 1,
    cursor: 'pointer',
    transition: 'color 100ms linear',
    '&:focus': {
      outline: 'none'
    }
  },
  importZone: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  dropZoneWrapper: {
    minWidth: 400
  },
  importStatus: {
    width: 400,
    backgroundColor: theme.white1,
    borderRadius: 2,
    padding: 20,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    fontSize: 13
  },
  importStatusMessage: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  importedFileName: {
    fontWeight: 'bold',
    marginLeft: 4,
    marginRight: 15
  },
  importProgressBarContainer: {
    width: '100%',
    height: 10,
    borderRadius: 5,
    border: `1px solid ${theme.grey5}`,
    backgroundColor: theme.grey7,
    marginTop: 7,
    marginBottom: 5
  },
  importProgressBar: {
    height: '100%',
    borderRadius: 5,
    backgroundColor: theme.indigo1
  },
  dataZone: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    '&[data-is-behind=true]': {
      opacity: 0.9
    }
  },
  importedDataMessage: {
    color: theme.grey1,
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: theme.white1,
    borderRadius: 2,
    padding: 15,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    boxShadow: '0px 2px 20px rgba(75, 53, 210, 0.1)',
    fontSize: 13
  },
  importedDataTable: {
    padding: '20px 20px 0px 20px',
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  dataImporterFooter: {
    zIndex: 2,
    width: '100%',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    backgroundColor: theme.white1,
    padding: '12px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '20px',
    fontSize: 14,
    flexShrink: 0
  },
  dataImporterFooterLeft: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '15px'
  },
  dataImporterCommentsContainer: {
    flexGrow: 1,
    maxWidth: 500,
    transition: 'height 1s',
    height: 'auto'
  }
});
