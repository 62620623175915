import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { deepGet } from 'utils';

import styles from './whoDidWhatWhen.style';

export class WhoDidWhatWhen extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    linkCreatedByAccount: PropTypes.object.isRequired
  };

  renderNbDocuments = () => {
    const files = deepGet(this, 'props.link.data.files');
    const filesCount = files ? files.length : 0;

    if (filesCount === 0) return null;
    if (filesCount === 1) return ' with 1 document attached';
    return ` with ${filesCount} documents attached`;
  };

  renderWhoDidWhat = () => {
    const { link, classes, linkCreatedByAccount } = this.props;

    const createdBy = linkCreatedByAccount?.entity || link.group;

    if (link.action && !link.action.form) {
      return (
        <span className={classes.whoDidWhat}>
          {createdBy.name}
          {' performed '}
          {link.action.title}
        </span>
      );
    }

    return (
      <span className={classes.whoDidWhat}>
        {createdBy.name}
        {' completed an action'}
        {this.renderNbDocuments()}
        {':'}
      </span>
    );
  };

  render = () => {
    const { classes, link } = this.props;
    const createdAt = moment.utc(link.createdAt).local();

    return (
      <div className={classes.whoDidWhatWhen}>
        <time dateTime={link.createdAt}>
          <span className={classes.whenDate}>
            {createdAt.format('D.MM.YYYY')}
          </span>{' '}
          {createdAt.format('h:mma')}
        </time>
        {this.renderWhoDidWhat()}
      </div>
    );
  };
}

export default injectSheet(styles)(WhoDidWhatWhen);
