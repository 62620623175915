export default theme => ({
  dateInput: {
    outline: 'none',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: 'border-color 150ms linear 0ms',
    borderRadius: 3,
    padding: '5px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.white1,
      borderColor: theme.grey5,
      '& $dateInputIcon': {
        color: theme.grey3,
        width: 17
      }
    },
    '&:focus, &[data-input-focused=true]': {
      backgroundColor: theme.white1,
      borderColor: theme.indigo3,
      '& $dateInputIcon': {
        color: theme.indigo3,
        width: 17
      },
      '&:hover': {
        '& $dateInputIcon': {
          color: theme.indigo3
        }
      }
    },
    '&[data-is-disabled=true]': {
      cursor: 'default'
    },
    '&::-moz-focus-inner': {
      border: 0 // disable inner dotted border on focused button for firefox
    }
  },
  dateDisplay: {
    flexGrow: 1,
    fontSize: 13,
    lineHeight: '15px',
    color: theme.grey1,
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&[data-is-unset=true]': {
      color: theme.grey3
    }
  },
  dateInputIcon: {
    marginLeft: 5,
    flexShrink: 0,
    height: 17,
    width: 0,
    color: theme.grey4,
    '&[data-is-visible=true]': {
      width: 17
    },
    transition: 'transform 0.3s ease-in-out',
    '&[data-input-focused=true]': {
      transform: 'rotate(180deg)',
      color: theme.indigo3
    }
  },
  tooltipContent: {
    zIndex: 2,
    backgroundColor: theme.white1,
    boxShadow: theme.indigoShadow2,
    borderRadius: 5,
    padding: 2,
    display: 'flex',
    flexFlow: 'column nowrap',
    // note: react-dates uses dynamic inline sizing when navigating across months
    // and the initial picker has no height
    // so for the tooltip to be positioned correctly regarding to available space
    // we set a min height corresponding to a 5 weeks display
    minHeight: 315
  },
  disabledInput: {
    padding: '5px 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  dateCompactInputWrapper: {
    width: '100%',
    height: 40,
    '& label': {
      height: '100%'
    }
  },
  dateCompactInput: {
    flexGrow: 1,
    backgroundColor: theme.grey9,
    border: `1px solid ${theme.grey5}`,
    borderRadius: 2,
    transition: 'border-color 150ms linear 0ms',
    color: theme.grey4,
    '&[data-has-value="true"]': {
      color: theme.grey1
    },
    '&[data-has-focus="true"]': {
      borderColor: theme.indigo3
    },
    '&[data-has-value="true"]:not([data-has-focus="true"])': {
      borderColor: theme.grey2
    },
    outline: 'none',
    padding: '9px 11px',
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dateCompactClose: {
    color: theme.grey1,
    fontSize: 10,
    lineHeight: '13px',
    transition: 'opacity 150ms linear 0ms',
    '&[data-is-visible="false"]': {
      opacity: 0
    }
  },
  compactDatePickerWrapper: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    padding: 10
  }
});
