export default theme => ({
  card: {
    border: `1px solid ${theme.grey5}`,
    borderRadius: 2,
    display: 'flex',
    backgroundColor: theme.grey9,
    boxSizing: 'border-box',
    height: 63
  },

  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 7
  },

  primary: {
    position: 'relative'
  },

  secondary: {
    position: 'relative'
  },

  zoneA: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 49
  },

  zoneB: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  zoneC: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center'
  },

  zoneD: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },

  titleInput: {
    lineHeight: '16px',
    fontSize: 13,
    cursor: 'pointer',
    '& > div': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        color: theme.indigo3
      }
    },
    '& input': {
      fontSize: 'inherit',
      lineHeight: 'inherit'
    }
  },

  avatar: {
    borderRadius: '50%',
    width: 26,
    height: 26,
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      boxShadow: `inset 0px 0px 3px 0px rgba(52, 50, 58, 0.3)`,
      borderRadius: 'inherit'
    },
    '& > img': {
      display: 'block',
      width: '100%',
      height: 'auto'
    }
  },

  events: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 700,
    color: theme.grey1,
    marginRight: 7
  },

  date: {
    fontSize: 11,
    lineHeight: '14px',
    fontWeight: 400,
    letterSpacing: 0.3,
    color: theme.grey2,
    textTransform: 'capitalize'
  },

  transferLabel: {
    marginBottom: 7
  },

  rotated: { transform: 'rotate(180deg)' },

  watchIcon: {
    cursor: 'pointer',
    marginRight: 5,
    width: 16,
    height: 16
  },

  unwatched: {
    fill: theme.grey5
  }
});
