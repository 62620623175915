import React, { memo } from 'react';
import PropType from 'prop-types';
import theme from 'style';

import {
  Bar,
  BarChart as Chart,
  XAxis,
  YAxis,
  ResponsiveContainer
} from 'recharts';

import moment from 'moment';

import { Placeholder } from '../ui';

import CustomTick from './customTick';
import BarChartSkeleton from './skeleton';

import { computeAspectRatio } from '../../utils';

const YAXIS_LEFT_WIDTH = 200;
const formatDuration = d => moment.duration(d, 'seconds').humanize();

export const BarChart = ({ rowsPerCard, dataset, loading }) => {
  if (loading) return <BarChartSkeleton />;
  if (dataset.length === 0) return <Placeholder />;

  const rowCount = dataset.length;

  return (
    <ResponsiveContainer
      width="100%"
      aspect={computeAspectRatio(rowsPerCard, rowCount)}
    >
      <Chart
        data={dataset}
        margin={{ top: 0, right: 10, left: 20, bottom: 0 }}
        barCategoryGap="25%"
        layout="vertical"
      >
        {/** This XAxis even though hidden enables us to scale the data */}
        <XAxis hide type="number" domain={['dataMin', 'dataMax']} />
        <YAxis
          yAxisId="left"
          dataKey="name"
          type="category"
          width={YAXIS_LEFT_WIDTH}
          axisLine={false}
          tickLine={false}
          tick={<CustomTick data={dataset} payload width={YAXIS_LEFT_WIDTH} />}
        />

        <YAxis
          yAxisId="right"
          dataKey="averageTimeSeconds"
          type="category"
          width={28}
          orientation="right"
          axisLine={false}
          tickFormatter={formatDuration}
          tickLine={false}
          tick={{ fontSize: 10, color: theme.grey2 }}
          interval={0}
        />
        <Bar
          yAxisId="left"
          dataKey="averageTimeSeconds"
          radius={5}
          fill={theme.indigo2}
          background={{ fill: theme.grey8 }}
        />
      </Chart>
    </ResponsiveContainer>
  );
};

BarChart.propTypes = {
  loading: PropType.bool,
  dataset: PropType.arrayOf(
    PropType.shape({
      key: PropType.string,
      name: PropType.string,
      icon: PropType.string,
      count: PropType.number,
      averageTimeSeconds: PropType.number
    })
  ),
  rowsPerCard: PropType.number
};

BarChart.defaultProps = {
  dataset: [],
  rowsPerCard: null,
  loading: false
};

export default memo(BarChart);
