import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import { withSnackbarsContext, Icon, TextEllipsis } from '@stratumn/atomic';

import { downloadFile } from 'utils/downloadFile';
import { getByPath } from 'utils/widgets';
import { getFileIconStr } from 'utils';

import LoadingIndicator from 'components/ui/LoadingIndicator';
import styles from './fileCompact.style';

// compact file view
const FileCompactImpl = React.memo(({ classes, view, data, errorSnackbar }) => {
  const [loading, setLoading] = useState(false);
  const [downloadResponse, setDownloadResponse] = useState(null);

  const { path, defaultIcon } = view;

  const fileData = getByPath(data, path);

  const downloadFileFn = useCallback(
    () =>
      downloadFile(
        fileData,
        errorSnackbar,
        setLoading,
        loading,
        setDownloadResponse
      ),
    [fileData]
  );

  if (!fileData || typeof fileData !== 'object') return null;

  const iconStr = getFileIconStr(
    fileData.mimetype,
    fileData.name,
    defaultIcon || 'DocumentDownload'
  );

  return (
    <LoadingIndicator
      spinning={loading}
      showSuccess={downloadResponse !== null}
    >
      <div
        className={classes.fileCompactView}
        onClick={downloadFileFn}
        data-cy="download-link"
      >
        <Icon name={iconStr} size={24} />
        <TextEllipsis
          className={classes.fileName}
          text={fileData.name}
          middleEllipsis
        />
      </div>
    </LoadingIndicator>
  );
});
FileCompactImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  errorSnackbar: PropTypes.func.isRequired
};

// sort defaults to text on file name
const getSortConfig = view => ({
  type: 'text',
  path: `${view.path}.name`
});

// filtering defaults to text search on file name
const getFilterConfig = view => ({
  type: 'text',
  path: `${view.path}.name`
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// return file name
const getStringifyFunction = view => {
  const { path } = view;
  return data => {
    const fileData = getByPath(data, path);
    return (fileData && fileData.name) || '';
  };
};

export default {
  component: compose(
    withSnackbarsContext,
    injectSheet(styles)
  )(FileCompactImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
