export default theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, minmax(162px, 1fr))',
    gridGap: '10px',
    height: 34,
    alignItems: 'center',
    '& button': {
      borderColor: theme.grey7,
      minWidth: 'unset'
    }
  },
  volumesWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumnEnd: 7
  },
  volumesLabel: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '13px',
    textAlign: ' right',
    color: theme.grey1
  },
  volumesDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '13px',
    textAlign: ' right',
    color: theme.grey4
  },
  switchWrapper: {
    margin: '0 10px'
  }
});
