export default theme => ({
  skeletonCardRoot: {
    backgroundColor: theme.white1,
    borderRadius: 15,
    position: 'relative',
    padding: 5,
    width: '100%',
    height: '100%'
  },
  skeletonLarge: {
    width: '100%',
    '&[data-is-flex=true]': {
      display: 'flex'
    }
  },
  skeletonMedium: {
    width: '49%',
    '&[data-is-flex=true]': {
      display: 'flex'
    },
    '&[data-is-flex-end=true]': {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  skeletonCardGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& section': {
      padding: 10
    }
  },
  skeletonCardTwo: {
    extend: 'skeletonCardRoot',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& div': {
      justifyContent: 'space-between'
    }
  }
});
