export default theme => ({
  root: {
    position: 'relative',
    border: `1px solid ${theme.grey8}`,
    borderRadius: 10,
    backgroundColor: theme.white1,
    transition: '200ms ease-out',
    '&[data-drag-enter=true]': {
      border: `1px solid ${theme.indigo2}`
    }
  },
  dropzoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 5px',
    transition: '200ms ease-out',
    '&[data-drag-enter=true]': {
      opacity: 0.2
    },
    '&[data-is-responsive=true]': {
      padding: '15px 15px 0 15px'
    }
  },
  dropzoneBody: {
    display: 'flex',
    '&[data-is-responsive=true]': {
      height: '100%'
    },
    '@media (max-width: 960px)': {
      flexDirection: 'column'
    }
  },
  leftColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flex: '2 2 20em',
    height: '100%',
    paddingRight: 25,
    borderRight: `1px solid ${theme.grey6}`,
    '@media (max-width: 1170px)': {
      paddingLeft: 10,
      paddingRight: 10,
      borderLeft: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '@media (max-width: 960px)': {
      borderRight: 'none',
      borderBottom: `1px solid ${theme.grey6}`
    }
  },
  leftColumnContent: {
    position: 'relative',
    zIndex: 2,
    minHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    transition: 'opacity 200ms linear 100ms',
    minWidth: 170
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 20em',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    marginLeft: 10,
    '&[data-is-responsive=true]': {
      marginTop: 20,
      maxHeight: 185,
      order: 2,
      marginLeft: 0,
      '&[data-is-disabled=true]': {
        marginTop: 0
      }
    }
  },
  dropzoneLabel: {
    fontSize: 14,
    color: theme.grey1,
    marginBottom: 10,
    textAlign: 'center',
    '& p': {
      lineHeight: '18px'
    },
    '& p:first-child': {
      marginBottom: 10
    }
  },
  dropzoneIcon: {
    color: theme.indigo3,
    position: 'absolute',
    top: 'calc(50% - 14px)',
    left: 'calc(50% - 14px)',
    opacity: 0,
    transform: 'translateY(-28px)',
    transition: 'opacity 200ms linear, transform 0ms linear 300ms',
    '&[data-drag-enter=true]': {
      zIndex: 2,
      opacity: 1,
      transform: 'translateY(0)',
      transition:
        'opacity 200ms linear 200ms, transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms'
    }
  },
  fileSelectBtn: {
    color: theme.grey3,
    backgroundColor: 'transparent',
    border: `1px solid currentColor`,
    borderRadius: 4,
    padding: '7px 15px',
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '14px',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    cursor: 'pointer',
    transition: 'color 100ms linear',
    marginBottom: 1,
    '&:focus': {
      outline: 'none',
      color: theme.indigo3
    },
    '&:hover': {
      color: theme.indigo3
    }
  },
  sizeWarning: {
    color: theme.grey4,
    fontSize: 10,
    letterSpacing: '0.24px',
    padding: '10px 0'
  },
  container: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: theme.grey8
  },
  formBody: {
    width: 'calc(100vw - 50px)',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'relative'
  },
  form: {
    backgroundColor: theme.white1,
    padding: 20,
    margin: '20px',
    marginBottom: '100px',
    width: '48%',
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: 2,
    boxShadow: `2px 3px 10px 0 rgba(0,0,0,0.1)`,
    '&[data-has-traceinfo=true]': {
      maxWidth: 600,
      minWidth: 300
    }
  },
  traceInfo: {
    margin: '20px',
    backgroundColor: 'inherit',
    width: '48%',
    borderRadius: 2,
    minWidth: 300
  },
  actionButtonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 50
  },
  actionButton: {
    marginRight: 3.5,
    marginLeft: 3.5
  },
  dataToolBody: {
    backgroundColor: theme.grey8,
    height: 'calc(100vh - 70px)',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between'
  },
  formsBody: {
    backgroundColor: theme.grey8,
    height: 'calc(100vh - 70px)',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between'
  },
  traysContainer: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    '&[data-is-left=true]': {
      right: 'unset',
      left: 0,
      flexFlow: 'row nowrap'
    }
  },
  traceInfoTray: {
    fontSize: 13,
    lineHeight: 1.25,
    color: theme.grey1,
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%'
  },
  traceInfoTrayBody: {
    flexGrow: 1,
    overflow: 'auto'
  },
  traceInfoTrayContent: {
    padding: 10
  },
  traceInfoTrayFooter: {
    flexShrink: 0,
    height: 70,
    width: '100%',
    padding: 14,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  traceInfoTrayActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  wordListMain: {
    width: '100%',
    height: 'calc(100% - 50px)',
    borderRadius: 7
  },
  filterWordInput: {
    width: '100%',
    height: 50,
    backgroundColor: 'transparent',
    border: '#C5C5C5 1px solid',
    fontSize: 15,
    borderRadius: '5px 5px 0 0',
    paddingLeft: 10,
    color: 'grey',
    '&:focus-visible': {
      outline: 'none'
    }
  },
  listInput: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    paddingLeft: 10,
    '&:focus-visible': {
      outline: 'none'
    }
  },
  wordList: {
    display: 'flex',
    flexDirection: 'column'
  },
  wordListContainer: {
    overflowY: 'scroll',
    height: 'calc(100% - 50px)',
    borderRadius: '0 0 5px 5px',
    borderTop: 0,
    border: '#C5C5C5 1px solid'
  },
  wordRow: {
    display: 'flex',
    flexDirection: 'column',

    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    height: 40,
    borderBottom: '#C5C5C5 1px solid',
    '&:last-child': {
      borderBottom: 0
    }
  },
  leftIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rightIcon: {
    width: 50,
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rowHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 40
  },
  wordRowLeft: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'row'
  },
  positionRow: {
    width: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  wordPosition: {
    fontWeight: 400,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#355c82'
    }
  },
  pageLoading: {
    '& canvas': {
      filter: 'blur(5px)'
    }
  },
  bgLoader: {
    height: '100%',
    left: '50%',
    top: '50%',
    opacity: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 250ms linear 250ms',
    '&[data-loading="true"]': {
      opacity: 1
    }
  },
  buttonBottom: {
    width: '100%',
    height: 30,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5
  },
  rightTop: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  middleText: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
