import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'react-apollo';

import filesize from 'filesize';

import { LoaderTraceLogo, withSnackbarsContext } from '@stratumn/atomic';
import { Document } from '@stratumn/icons';

import { downloadFile } from 'utils/downloadFile';
import Ellipsis from 'components/ui/ellipsis';

import styles from './downloadFile.style';

export const DownloadFile = React.memo(
  ({ classes, data, errorSnackbar, readonly }) => {
    const [loading, setLoading] = React.useState(false);

    const { name, size } = data;

    if (loading) return <LoaderTraceLogo />;

    let rootProps;

    if (!readonly) {
      rootProps = {
        className: classes.downloadFileWrapper,
        'data-is-downloadable': true,
        onClick: () => downloadFile(data, errorSnackbar, setLoading, loading)
      };
    } else {
      rootProps = {
        className: classes.downloadFileWrapper,
        'data-is-downloadable': false
      };
    }

    return (
      <div {...rootProps}>
        <Document className={classes.documentIcon} />
        <div
          className={classes.fileDetailsWrapper}
          data-cy="download-documentation"
        >
          <div className={classes.fileName}>
            <Ellipsis>{name}</Ellipsis>
          </div>

          <div className={classes.fileSize}>{filesize(size)}</div>
        </div>
      </div>
    );
  }
);

DownloadFile.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number
  }).isRequired,
  readonly: PropTypes.bool,
  errorSnackbar: PropTypes.func.isRequired
};

DownloadFile.defaultProps = {
  readonly: false
};

export default compose(withSnackbarsContext, injectSheet(styles))(DownloadFile);
