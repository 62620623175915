import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { MultiSelect, Switch } from '@stratumn/atomic';

import styles from './analyticsFilters.style';

export const AnalyticsFilters = ({
  classes,
  groups,
  selectedGroups,
  handleSelectedGroups
}) => {
  const handleSelectMultipleItems = useCallback(newItems => {
    handleSelectedGroups(newItems);
  }, []);

  /**
   * @constant {array} - Build the multiselect's options from the workflow's groups
   */
  const groupsOptions = groups.map(group => ({
    label: group.name,
    value: group.label
  }));

  return (
    <div className={classes.root}>
      <MultiSelect
        dataCy="multi-select-group"
        displayValue="Group"
        options={groupsOptions}
        selectedValues={selectedGroups}
        onSelect={handleSelectMultipleItems}
        shadows
      />
      {/** TODO: Add program filters */}
      <MultiSelect
        dataCy="multi-select-program"
        displayValue="Program"
        options={[]}
        selectedValues={[]}
        onSelect={() => {}}
        shadows
        disabled
      />
      {/** TODO: Add submission period filters */}
      <MultiSelect
        dataCy="multi-select-submission-period"
        displayValue="Submission Period"
        options={[]}
        selectedValues={[]}
        onSelect={() => {}}
        shadows
        disabled
      />
      {/** TODO: Add volumes filters */}
      <div className={classes.volumesWrapper}>
        <div className={classes.volumesLabel}>Volumes</div>
        <span className={classes.switchWrapper}>
          <Switch label="volumes" disabled />
        </span>
        <div className={classes.volumesDescription}>Insured amounts</div>
      </div>
    </div>
  );
};

AnalyticsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  selectedGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectedGroups: PropTypes.func.isRequired
};

export default injectSheet(styles)(memo(AnalyticsFilters));
