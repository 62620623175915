export default {
  tooltipBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'scroll',
    '&[data-is-clickable=true]': {
      zIndex: 6,
      overflow: 'hidden'
    }
  },
  tooltipArrowWrapper: {
    visibility: 'hidden',
    position: 'absolute',
    zIndex: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tooltipContainer: {
    visibility: 'hidden',
    position: 'absolute',
    zIndex: 7
  }
};
