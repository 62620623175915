import { TYPE_ORGANIZATION, TYPE_TEAM } from 'constant/account';

const compareNames = (a, b) => a.entity.name.localeCompare(b.entity.name);

export const parseEntities = memberOfNodes => {
  // the goal here is to find all the entities the user belongs to, that might be owning a team
  // so either a team or an organization
  // and prepare them so as to display in an UI-efficient manner, ie organizations first and teams next
  const teams = [];
  const organizations = [];
  memberOfNodes.forEach(account => {
    const {
      entity: { __typename }
    } = account;
    // split teams and organizations
    if (__typename === TYPE_ORGANIZATION) {
      organizations.push(account);
    }
    if (__typename === TYPE_TEAM) {
      teams.push(account);
    }
  });

  let organization;
  if (organizations[0]) {
    organization = organizations[0].entity;
  } else if (teams.length) {
    const { entity: { organization: firstTeamOrganization } = {} } = teams[0];
    organization = firstTeamOrganization;
  }

  return {
    // if no organization found take the first team's organization
    organization,
    teams: organizations.sort(compareNames).concat(teams.sort(compareNames))
  };
};
