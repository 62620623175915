import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import moment from 'moment';

import { getByPath } from 'utils/widgets';

import HTMLViewProvider from '../html';

import styles from './comment.style';

const { component: HTMLView } = HTMLViewProvider;

const convertDate = date => {
  const newdate = moment(date).format('DD MMMM YYYY LT');
  return newdate;
};

const DEFAULT_COMMENT_PATHS = {
  NAME: 'user.name',
  AVATAR: 'user.avatar',
  GROUP: 'group',
  DATE: 'date',
  ACTION: 'action',
  COMMENT: 'comment'
};

export const CommentView = React.memo(({ classes, view, data }) => {
  const {
    namePath = DEFAULT_COMMENT_PATHS.NAME,
    avatarPath = DEFAULT_COMMENT_PATHS.AVATAR,
    groupPath = DEFAULT_COMMENT_PATHS.GROUP,
    datePath = DEFAULT_COMMENT_PATHS.DATE,
    actionPath = DEFAULT_COMMENT_PATHS.ACTION,
    commentPath = DEFAULT_COMMENT_PATHS.COMMENT
  } = view;

  const commentView = {
    path: commentPath
  };

  const name = getByPath(data, namePath);
  const avatar = getByPath(data, avatarPath);
  const group = getByPath(data, groupPath);
  const date = getByPath(data, datePath);
  const action = getByPath(data, actionPath);

  return (
    data && (
      <div className={classes.comment}>
        <div
          className={classes.commentAvatar}
          style={{
            backgroundImage: avatar ? `url(${avatar})` : null
          }}
        />
        <div className={classes.commentContainer}>
          <ul>
            {data.user && (
              <li className={classes.userId}>
                <div className={classes.userName}>{name}</div>
                {group && <div className={classes.userGroup}>{group}</div>}
              </li>
            )}
            {date && (
              <li className={classes.date}>
                <strong>{action}</strong> | Commented on {convertDate(date)}
              </li>
            )}
            <li>
              <div className={classes.commentRoot}>
                <HTMLView data={data} view={commentView} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  );
});

// sort defaults to text on datePath (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text',
  path: view.datePath || DEFAULT_COMMENT_PATHS.DATE
});

// filtering defaults to text search on comment content
const getFilterConfig = view => ({
  type: 'text',
  path: view.commentPath || DEFAULT_COMMENT_PATHS.COMMENT
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

// stringifies data at commentPath
const getStringifyFunction = view => {
  const { commentPath = DEFAULT_COMMENT_PATHS.COMMENT } = view;
  return data => getByPath(data, commentPath) || '';
};

CommentView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default {
  component: injectSheet(styles)(CommentView),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
