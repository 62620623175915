export const KPI_CARD_BORDER_RADIUS = 5;

export default theme => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    borderTopStyle: 'solid',
    borderTopWidth: '5px',
    borderRadius: `${KPI_CARD_BORDER_RADIUS}px ${KPI_CARD_BORDER_RADIUS}px 0 0`
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 14,
    borderRadius: `0 0 ${KPI_CARD_BORDER_RADIUS}px ${KPI_CARD_BORDER_RADIUS}px`,
    boxShadow: theme.indigoShadow1,
    backgroundColor: theme.white1,
    transition: '250ms',
    '&[data-background-white="false"]': {
      overflow: 'hidden'
    }
  },
  details: {
    display: 'flex',
    alignItems: 'baseline'
  },
  label: {
    fontWeight: 700,
    fontSize: 10,
    lineHeight: '13px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    paddingRight: 6
  },
  value: {
    paddingLeft: 15,
    paddingRight: 5,
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '51px',
    paddingTop: 2
  }
});
