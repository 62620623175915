export default theme =>
  theme && {
    '&::-webkit-scrollbar': {
      cursor: 'pointer',
      width: 5
    },
    '&::-webkit-scrollbar-track': {
      background: theme.grey8,
      borderRadius: 10
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.grey4,
      borderRadius: 10,
      '&:hover': {
        background: theme.grey3
      }
    }
  };
