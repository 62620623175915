export default theme => ({
  root: {
    display: 'flex'
  },
  name: {
    paddingLeft: 5,
    fontSize: 11,
    lineHeight: '14px',
    color: theme.grey1,
    flexGrow: 1
  },
  value: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '13px',
    color: theme.grey4,
    '&[data-is-list=true]': {
      color: theme.grey1,
      fontSize: 11
    }
  },
  dashedLine: {
    alignSelf: 'center',
    width: '100%',
    margin: '0 5px',
    borderTop: `1px dashed ${theme.grey6}`
  }
});
