export default () => ({
  actionsWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden'
  },
  actionsBody: {
    flexGrow: 1,
    overflow: 'auto',
    padding: '25px 10px'
  },
  actionsMessage: {
    marginBottom: 15,
    fontSize: 16
  },
  actionsFooter: {
    height: 70,
    width: '100%',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  }
});
