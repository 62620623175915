export default theme => ({
  upperRoot: {
    marginTop: 15
  },
  root: {
    border: `1px solid ${theme.grey6}`,
    backgroundColor: theme.grey8,
    borderRadius: 2,
    paddingTop: 14,
    paddingLeft: 21,
    paddingRight: 21
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 14
  },
  count: {
    fontSize: 13,
    fontWeight: 700,
    color: theme.grey1
  },
  downloadAll: {
    fontSize: 12,
    color: theme.grey3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      color: theme.indigo3,
      cursor: 'pointer'
    }
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginLeft: 7
  },
  fileList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  fileItem: {
    width: 'calc(33.33% - 14px)',
    flexShrink: 0,
    flexGrow: 0,
    marginRight: 21,
    marginBottom: 21,
    '&:nth-of-type(3n)': {
      marginRight: 0
    },
    position: 'relative'
  }
});
