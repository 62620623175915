export default theme => ({
  link: {
    color: 'unset',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  workflowName: {
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'capitalize',
    padding: '15px 0px'
  },
  numberCell: {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '18px'
  },
  documentationWrapper: {
    color: theme.grey1,
    fontSize: 12
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px'
  },
  linkIcon: {
    paddingLeft: 4,
    paddingRight: 10
  },
  downloadFileWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadDocumentation: {
    display: 'flex',
    color: theme.grey3
  },
  iconBtnAdd: {
    paddingLeft: 4,
    paddingRight: 10
  },
  deleteBtnWrapper: {
    flexShrink: 0,
    marginLeft: 5,
    cursor: 'pointer',
    outline: 'none',
    transition: 'color 100ms linear',
    '&:hover': {
      color: theme.indigo3
    }
  },
  deleteBtn: {
    display: 'block',
    width: 30,
    height: 'auto'
  },
  settingsCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.grey1,
    transition: 'color 100ms linear',
    '&:hover': {
      color: theme.grey2
    }
  },
  settingsIcon: {
    display: 'block',
    flexShrink: 0,
    width: 20,
    height: 20
  }
});
