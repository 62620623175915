import React from 'react';

export const buildWorkflowContext = (workflow, authorGroupLabel) => {
  const { rowId: workflowRowId } = workflow;

  if (!workflowRowId) return { groups: [], author: {} };

  // build a list of groups rowId, label and name
  const groups = workflow.groups.nodes;

  // find the currently authoring group info
  const author = authorGroupLabel
    ? groups.find(group => group.label === authorGroupLabel)
    : null;

  return {
    rowId: workflowRowId,
    groups,
    author
  };
};

// Sharing workflow context with sub components
export const WorkflowContext = React.createContext(null);
export const withWorkflowContext = Component => props => (
  <WorkflowContext.Consumer>
    {value => <Component workflowContext={value} {...props} />}
  </WorkflowContext.Consumer>
);
