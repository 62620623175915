import React, { useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Document, Page, pdfjs } from 'react-pdf';
import filesize from 'filesize';
import {
  withSnackbarsContext,
  Icon,
  TextEllipsis,
  Pushbutton
} from '@stratumn/atomic';
import { getFileIconStr } from 'utils';
import { getMediaClient } from 'client/media';
import { downloadFile } from 'utils/downloadFile';

import styles from './file.style';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/'
};

export const PdfViewerView = React.memo(({ classes, view, data }) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPage, setMaxPage] = useState(0);

  const downloadPDF = () => {
    downloadFile(
      data,
      () => {},
      () => {},
      false,
      () => {}
    );
  };

  const openViewer = async () => {
    const mediaClient = getMediaClient();
    const fileData = await mediaClient.downloadFile(
      data.digest,
      () => {},
      data.key
    );

    setFile(fileData);
    setViewerOpen(true);
  };

  if (!data || typeof data !== 'object') return null;

  return (
    <>
      {viewerOpen && (
        <div className={classes.pdfViewer}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxHeight: 1200,
              overflow: 'hidden'
            }}
          >
            {file && (
              <div className={classes.documentPdf}>
                <div className={classes.buttonBottom}>
                  <Pushbutton
                    primary
                    onClick={() =>
                      setPageNumber(page => (page > 1 ? page - 1 : 1))
                    }
                  >
                    {'<'}
                  </Pushbutton>
                  <p className={classes.middleText}>
                    {pageNumber} / {maxPage}
                  </p>
                  <div className={classes.rightTop}>
                    <Pushbutton
                      primary
                      onClick={() =>
                        setPageNumber(page =>
                          page < maxPage ? page + 1 : maxPage
                        )
                      }
                    >
                      {'>'}
                    </Pushbutton>
                    <Pushbutton primary onClick={() => downloadPDF()}>
                      <Icon name="Download" size={20} />
                    </Pushbutton>
                    <Pushbutton primary onClick={() => setViewerOpen(false)}>
                      <Icon name="Cross" size={20} />
                    </Pushbutton>
                  </div>
                </div>

                <Document
                  file={file}
                  options={options}
                  renderMode="canvas"
                  onLoadSuccess={e => setMaxPage(e._pdfInfo.numPages)}
                >
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    height={window.innerHeight - 100}
                  />
                </Document>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={classes.root}>
        <div className={classes.file} onClick={openViewer} data-cy="file-link">
          <div className={classes.left}>
            <div className={classes.fileIcon}>
              <Icon name={getFileIconStr(data.mimetype, data.name)} size={20} />
            </div>
            <div className={classes.fileInfo}>
              <TextEllipsis
                className={classes.fileName}
                text={data.name}
                middleEllipsis
              />
              <div className={classes.fileSize}>{filesize(data.size)}</div>
            </div>
          </div>
          <div className={classes.right}>open in PDF viewer</div>
        </div>
      </div>
    </>
  );
});

// sort defaults to text on datePath (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text'
});

PdfViewerView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default {
  component: compose(withSnackbarsContext, injectSheet(styles))(PdfViewerView),
  getSortConfig
};
