import gql from 'graphql-tag';

export default {
  userWorkflows: gql`
    fragment UserWorkflowsQueryFragment on WorkflowsConnection {
      nodes {
        rowId
        name
        groups {
          nodes {
            rowId
            label
            name
          }
        }
      }
    }
  `
};
