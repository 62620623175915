export default theme => {
  const COL_BORDER_COLOR = theme.grey5;
  const COL_BG_COLOR = theme.grey8;

  return {
    root: {
      width: 55,
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      padding: '14px 7px',
      boxSizing: 'border-box',
      '&[aria-expanded=true]': {
        width: `calc(100vw - 28px)`,
        maxWidth: 280,
        minWidth: 196
      }
    },
    header: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      padding: '10px 7px 14px 7px',
      backgroundColor: COL_BG_COLOR,
      borderRadius: '3px 3px 0 0',
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
      borderColor: COL_BORDER_COLOR,
      color: theme.grey1,
      '&:last-child': {
        borderRadius: 3,
        borderWidth: 1
      }
    },
    headerIcon: {
      width: 24,
      height: 24,
      color: 'currentColor',
      flexShrink: 0,
      marginRight: 7,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        display: 'block',
        width: '100%',
        height: 'auto',
        fill: 'currentColor',
        '&[width="17"][height="21"]': {
          width: 17,
          height: 21
        }
      }
    },
    headerTitle: {
      flexGrow: 1,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '18px',
      color: 'currentColor',
      minWidth: 0,
      '&::first-letter': {
        textTransform: 'capitalize'
      }
    },
    headerTitleTruncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& > em': {
        fontStyle: 'normal',
        fontWeight: 400
      }
    },
    headerAction: {
      flexShrink: 0,
      width: 19,
      outline: 'none',
      border: 0,
      display: 'flex',
      alignItems: 'flex-start',
      alignSelf: 'flex-start',
      color: theme.grey3,
      appearance: 'none',
      '& > svg': {
        display: 'block',
        width: '100%',
        height: 'auto',
        fill: 'currentColor'
      }
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      borderWidth: '0 1px 0 1px',
      borderStyle: 'solid',
      borderColor: COL_BORDER_COLOR,
      backgroundColor: COL_BG_COLOR,
      '&:last-child': {
        borderRadius: '0 0 3px 3px',
        borderBottomWidth: 1
      }
    },
    traces: {
      flexGrow: 1,
      overflowY: 'auto',
      borderRadius: 'inherit',
      overflowScrolling: 'touch'
    },
    traceList: {
      borderRadius: 'inherit',
      paddingLeft: 6,
      paddingRight: 6
    },
    traceListItem: {
      marginBottom: 7
    },
    footer: {
      minHeight: 3,
      flexShrink: 0,
      borderRadius: '0 0 3px 3px',
      borderWidth: `0 1px 1px 1px`,
      borderStyle: 'solid',
      backgroundColor: COL_BG_COLOR,
      borderColor: COL_BORDER_COLOR,
      display: 'flex'
    },
    collapsed: {
      flexShrink: 1,
      height: 224,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 7,
      backgroundColor: COL_BG_COLOR,
      borderRadius: '3px 3px 0 0',
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
      borderColor: COL_BORDER_COLOR,
      color: theme.grey1,
      '&:last-child': {
        borderRadius: 3,
        borderWidth: 1
      }
    },
    collapsedAction: {
      flexShrink: 0,
      width: 19,
      outline: 'none',
      border: 0,
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 14,
      color: theme.grey3,
      appearance: 'none',
      '&[disabled]': {
        color: theme.grey5,
        cursor: 'default'
      },
      '& > svg': {
        display: 'block',
        width: '100%',
        height: 'auto',
        fill: 'currentColor'
      }
    },
    collapsedTitle: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '18px',
      color: 'currentColor',
      textOrientation: 'sideways',
      writingMode: 'vertical-lr',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&::first-letter': {
        textTransform: 'capitalize'
      },
      '& > em': {
        fontStyle: 'normal',
        fontWeight: 400
      }
    },
    collapsedFooter: {
      minHeight: 3,
      flexShrink: 0,
      borderRadius: '0 0 3px 3px',
      borderWidth: '0 1px 1px 1px',
      borderStyle: 'solid',
      backgroundColor: COL_BG_COLOR,
      borderColor: COL_BORDER_COLOR,
      display: 'flex',
      justifyContent: 'center'
    }
  };
};
