export default theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    borderRadius: 15,
    boxShadow: theme.indigoShadow1,
    backgroundColor: theme.white1,
    transition: '250ms',
    '&[data-background-white="false"]': {
      overflow: 'hidden'
    },
    '&[data-is-hoverable="true"]': {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: ' 0px 10px 25px rgba(75, 53, 210, 0.25)'
      }
    }
  }
});
