import gql from 'graphql-tag';

export default {
  actionQuery: gql`
    fragment NewLinkFragment on Workflow {
      rowId
      name
      groups {
        nodes {
          rowId
          label
          name
          avatar
        }
      }
      rawActionByKey(key: $actionKey)
      # These fields might have $refs we need to resolve
      # and they're not resolved in rawActionByKey
      traces(filter: { rowId: { in: $traceIds } }) {
        nodes {
          rowId
          name
          state {
            rowId
            data
            nextActions
            trace {
              rowId
              head {
                linkHash
                createdByAccount {
                  rowId
                  entity {
                    __typename
                    ... on Account_User {
                      rowId
                      name
                    }
                    ... on Account_Bot {
                      rowId
                      name
                    }
                  }
                }
              }
            }
          }
          workflow {
            rowId
            config {
              rowId
              info
            }
          }
        }
      }
    }
  `
};
