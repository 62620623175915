import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import SegmentDetail from '../segmentDetail';
import SegmentUserDetail from '../segmentUserDetail';
import FileList from '../fileList';

import styles from './segmentInfo.style';

const LINK_CREATED_BY_ACCOUNT_QUERY = gql`
  query linkCreatedByAccountQuery($linkId: ID!) {
    link(id: $linkId) {
      createdByAccount {
        rowId
        signingKey {
          rowId
          publicKey
        }
        entity {
          ... on Account_User {
            rowId
            name
            avatar
            email
            phone
          }
          ... on Account_Bot {
            rowId
            name
            avatar
          }
        }
      }
    }
  }
`;

export const SegmentInfo = ({
  activeSegment,
  traceId,
  workflowContext,
  linkCreatedByAccountQuery,
  classes
}) => {
  const linkCreatedByAccount =
    linkCreatedByAccountQuery?.link?.createdByAccount;

  if (linkCreatedByAccountQuery.loading) {
    return <TraceIconSpinner className={classes.loader} />;
  }

  return (
    <>
      <SegmentUserDetail
        link={activeSegment}
        linkCreatedByAccount={linkCreatedByAccount}
      />
      <div className={classes.segmentContent}>
        <SegmentDetail
          traceId={traceId}
          link={activeSegment}
          linkCreatedByAccount={linkCreatedByAccount}
          workflowContext={workflowContext}
        />
        <FileList segment={activeSegment} traceId={traceId} />
      </div>
    </>
  );
};

SegmentInfo.propTypes = {
  activeSegment: PropTypes.object.isRequired,
  traceId: PropTypes.string.isRequired,
  workflowContext: PropTypes.object.isRequired,
  linkCreatedByAccountQuery: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  graphql(LINK_CREATED_BY_ACCOUNT_QUERY, {
    name: 'linkCreatedByAccountQuery',
    options: ({ activeSegment }) => ({
      variables: { linkId: activeSegment.id }
    })
  }),
  injectSheet(styles)
)(SegmentInfo);
