export default {
  iconView: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'start'
  },
  iconViewIcon: {
    height: 24,
    width: 24,
    transform: 'translateX(-2px)',
    flexShrink: 0, // don't shrink icons, the label will adapt and overflow
    '& > path': {
      transform: 'scale(0.75) translateX(2px) translateY(1px)'
    }
  },
  iconViewLabel: {
    marginLeft: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};
