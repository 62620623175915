export const getFileExt = filename =>
  filename ? filename.split('.').slice(-1)[0].toLowerCase() : null;

export const fileIsImg = filename => {
  const IMG_EXT = ['gif', 'ico', 'png', 'jpg', 'jpeg'];
  const ext = getFileExt(filename);
  return IMG_EXT.includes(ext);
};

// IconDocument - extensions doc, pages, pdf, txt
const DOCUMENTS_MIME_TYPES = [
  'application/msword',
  'application/pdf',
  'text/plain'
];

// IconPresentation - extensions ppt, pptx
const PRESENTATIONS_MIME_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
];

// IconDocumentGraph - extensions csv, xls, numbers
const SPREADSHEETS_MIME_TYPES = ['text/csv', 'application/vnd.ms-excel'];

// IconImage - extensions jpg, png, gif
const IMAGES_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

/**
 * @function getIconStr - match the mimetype or file extension with an icon string
 * @param {string} mimeType
 * @param {string} fileName
 * @returns the icon name to use to represent this file
 */
export const getFileIconStr = (mimetype, fileName, defaultIcon) => {
  switch (true) {
    case DOCUMENTS_MIME_TYPES.includes(mimetype):
    case getFileExt(fileName) === 'pages':
      return 'Doc';

    case PRESENTATIONS_MIME_TYPES.includes(mimetype):
      return 'Presentation';

    case SPREADSHEETS_MIME_TYPES.includes(mimetype):
    case getFileExt(fileName) === 'numbers':
      return 'DocGraph';

    case IMAGES_MIME_TYPES.includes(mimetype):
      return 'Picture';

    default:
      return defaultIcon || 'DocEmpty';
  }
};
