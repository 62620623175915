export const ROUTE_WORKFLOW_DASHBOARD = '/';
export const ROUTE_WORKFLOW_BOARD = '/workflow/:id';
export const ROUTE_WORKFLOW_OVERVIEW = '/workflowoverview/:id';
export const ROUTE_WORKFLOW_EXPORT = '/workflow/:id/export';
export const ROUTE_GROUP_BOARD = '/group/:id';
export const ROUTE_GROUP_FORMS = '/group/:id/forms';
export const ROUTE_GROUP_FORM = '/group/:groupid/form/:actionkey';
export const ROUTE_GROUP_SETTINGS = '/group/:id/settings';
export const ROUTE_NEW_LINK = '/workflow/:wfid/newlink';
export const ROUTE_INSPECT_TRACE = '/trace/:id';
export const ROUTE_USER_DASHBOARD = '/userdashboard';
export const ROUTE_WORKFLOW_GROUPS = '/workflow/:id/settings/groups';
export const ROUTE_LOGIN = '/login';
export const ROUTE_OAUTH = '/oauth';
export const ROUTE_UNAUTHORIZED = '/unauthorized';

// These routes are only for demo purposes.
export const ROUTE_WORKFLOW_DATA_DASHBOARD = '/workflow/:id/dashboard';

// Hidden route for buidling the analytics dashboard
export const ROUTE_WORKFLOW_ANALYTICS = '/workflow/:id/analytics';

// Hidden route for buidling the version 2 of the analytics dashboard
export const ROUTE_WORKFLOW_NEW_ANALYTICS = '/workflow/:id/newAnalytics';
