import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import injectSheet from 'react-jss';

import { history } from 'components/root';

import { ROUTE_WORKFLOW_ANALYTICS } from 'constant/routes';

import { Pulldown, PulldownMenuItem } from '@stratumn/atomic';
import { Download } from '@stratumn/icons';

import { DateRangePicker } from 'components/ui';

import { today, oneWeekAgo } from '../utils';

import styles from './subHeader.style';

export const SubHeader = ({
  classes,
  subHeaderTitle,
  selectedWorkflow,
  userWorkflows,
  onSelectDates,
  withDateRange,
  lastSelectedDates
}) => {
  /**
   * @function handleSelectedWorkflow - updates the url
   * with the correct param id for the selected workflow
   * @param {string} rowId - the workflow selected rowId
   */
  const handleSelectedWorkflow = useCallback(rowId => {
    history.push(ROUTE_WORKFLOW_ANALYTICS.replace(':id', rowId));
  }, []);

  const filteredSelectedWorkflows = useMemo(() => {
    const unselectedWorkflows = userWorkflows.filter(
      workflow => workflow.name !== selectedWorkflow.name
    );
    unselectedWorkflows.unshift(selectedWorkflow);
    return unselectedWorkflows;
  }, [selectedWorkflow]);

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{subHeaderTitle}</h1>
      <div className={classes.actionsWrapper}>
        {/** Hidden until Product team comes up with the exporting logic */}
        {false && (
          <div className={classes.exportWrapper}>
            <Download />
            <span className={classes.exportLabel}>Export CSV view</span>
          </div>
        )}

        {withDateRange && (
          <div className={classes.dataRangePickerWrapper}>
            <DateRangePicker
              onSelectDates={onSelectDates}
              defaultValues={{ startDate: oneWeekAgo(), endDate: today() }}
              lastSelectedDates={lastSelectedDates}
            />
          </div>
        )}
        <div
          className={classes.selectWorkflowButton}
          data-with-daterange={withDateRange}
        >
          <Pulldown buttonLabel={selectedWorkflow && selectedWorkflow.name}>
            {filteredSelectedWorkflows.map(workflow => (
              <div
                key={workflow.rowId}
                className={classes.pulldownMenuItem}
                data-is-selected={selectedWorkflow.name === workflow.name}
              >
                <PulldownMenuItem
                  onClick={() => handleSelectedWorkflow(workflow.rowId)}
                >
                  {workflow.name}
                </PulldownMenuItem>
              </div>
            ))}
          </Pulldown>
        </div>
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  subHeaderTitle: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.shape({
    name: PropTypes.string,
    rowId: PropTypes.string
  }).isRequired,
  userWorkflows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectDates: PropTypes.func,
  withDateRange: PropTypes.bool,
  lastSelectedDates: PropTypes.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj
  })
};

SubHeader.defaultProps = {
  onSelectDates: () => {},
  withDateRange: false,
  lastSelectedDates: null
};

export default injectSheet(styles)(memo(SubHeader));
