import { ANALYTICS_DASHBOARD_MARGIN } from 'style/dimensions';

export default theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '0.03em',
    color: theme.grey1,
    width: '50%'
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50%',
    marginRight: ANALYTICS_DASHBOARD_MARGIN
  },
  exportWrapper: {
    display: 'flex',
    alignItems: 'center',

    color: theme.grey2,
    '& > svg': {
      width: 18,
      height: 18,
      marginRight: 6
    }
  },
  exportLabel: {
    fontSize: 12,
    lineHeight: '15px',
    textAlign: 'right'
  },
  selectWorkflowButton: {
    marginLeft: 18,
    width: '64%',
    '&[data-with-daterange="true"]': {
      width: '50%'
    },
    '& > button': {
      height: 40,
      borderRadius: 5,
      borderColor: 'transparent',
      boxShadow: theme.indigoShadow1,
      width: '100%',
      color: theme.grey1,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
      background: theme.white1,
      '& > span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },
  pulldownMenuItem: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.grey5,
    '&[data-is-selected=true]': {
      '& > li': {
        color: theme.grey1,
        background: theme.grey6
      },
      '& > li > button > span': {
        fontWeight: 600,
        color: theme.grey1
      },
      '& > li > button:hover': {
        cursor: 'unset'
      }
    }
  },
  dataRangePickerWrapper: {
    width: '50%',
    marginLeft: 10
  }
});
