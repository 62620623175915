import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import { Stage } from '@stratumn/atomic';
import { renderStageIcon } from 'utils/stage';
import StageTraces from './traces';

class StageBox extends Component {
  static propTypes = {
    stage: PropTypes.object.isRequired,
    groupId: PropTypes.string.isRequired
  };

  state = {
    open: false
  };

  toggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render = () => {
    const { stage, groupId } = this.props;
    const { open } = this.state;
    const icon = renderStageIcon(stage);
    return (
      <Stage
        header={stage.label}
        traceCount={stage.traces.totalCount}
        onClick={this.toggle}
        icon={icon}
        expanded={open}
      >
        <StageTraces stageId={stage.rowId} groupId={groupId} />
      </Stage>
    );
  };
}

export const fragments = {
  stage: gql`
    fragment StageBoxFragment on Stage {
      rowId
      label
      type
      action {
        key
        icon
      }
      traces(
        filter: {
          watched: { equalTo: $watched }
          or: [
            { rowId: { includesInsensitive: $search } }
            { tags: { contains: [$search] } }
          ]
        }
      ) {
        totalCount
      }
    }
  `
};

export default StageBox;
