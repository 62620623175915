import React, { useState, useCallback } from 'react';
import to from 'await-to-js';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import Path from 'path-to-regexp';
import { withRouter } from 'react-router-dom';
import { withSnackbarsContext } from '@stratumn/atomic';
import { stringify } from '@stratumn/canonicaljson';

import { Header } from 'components/layouts';

import {
  ROUTE_WORKFLOW_BOARD,
  ROUTE_USER_DASHBOARD,
  ROUTE_WORKFLOW_EXPORT,
  ROUTE_WORKFLOW_ANALYTICS
} from 'constant/routes';
import { withError } from 'components/errorBoundary';

import envVars from 'constant/env';

import {
  TYPE_WORKFLOW,
  TYPE_WORKFLOW_CONFIG,
  TYPE_NEW_WORKFLOW_CONFIG_PAYLOAD
} from 'gql/types';

import JsonEditor from 'components/ui/utils/jsonEditor';
import { Download, Settings } from '@stratumn/icons';

import { withUser } from 'contexts';

import styles from './workflowOverview.style';

const configEditorCodemirrorOptions = {
  theme: 'material'
};

export const WorkflowOverviewHeader = React.memo(props => {
  const {
    match,
    history,
    user,
    errorSnackbar,
    successSnackbar,
    workflowQuery,
    isLoading,
    updateWorkflowConfigMutation
  } = props;

  const [showWorkflowConfigEditor, setShowWorkflowConfigEditor] = useState(
    false
  );
  const toggleShowWorkflowConfigEditor = useCallback(
    () => setShowWorkflowConfigEditor(!showWorkflowConfigEditor),
    [showWorkflowConfigEditor, setShowWorkflowConfigEditor]
  );

  const handleWorkflowConfigUpdate = useCallback(
    async newWorkflowConfigStr => {
      const { workflowByRowId: workflow } = workflowQuery;

      const {
        rowId: workflowRowId,
        config: { rowId: workflowConfigId, overview } = {}
      } = workflow;
      const newWorkflowConfig = newWorkflowConfigStr
        ? JSON.parse(newWorkflowConfigStr)
        : {};

      const {
        initState: newInitState,
        initActions: newInitActions,
        stateSchema: newStateSchema,
        actions: newActions,
        transitions: newTransitions
      } = newWorkflowConfig;

      const [err] = await to(
        updateWorkflowConfigMutation({
          variables: {
            workflowRowId,
            newInitState,
            newInitActions,
            newStateSchema,
            newActions,
            newTransitions
          },
          optimisticResponse: {
            newWorkflowConfig: {
              workflow: {
                rowId: workflowRowId,
                config: {
                  rowId: workflowConfigId,
                  overview,
                  ...newWorkflowConfig,
                  __typename: TYPE_WORKFLOW_CONFIG
                },
                __typename: TYPE_WORKFLOW
              },
              __typename: TYPE_NEW_WORKFLOW_CONFIG_PAYLOAD
            }
          }
        })
      );

      if (err) {
        errorSnackbar(
          'Something went wrong during the workflow config update...'
        );
        return;
      }

      successSnackbar('The workflow config was correctly updated');
    },
    [
      workflowQuery,
      errorSnackbar,
      successSnackbar,
      updateWorkflowConfigMutation
    ]
  );

  const goToExport = useCallback(
    () =>
      history.push(
        Path.compile(ROUTE_WORKFLOW_EXPORT)({
          id: match.params.id
        })
      ),
    [history, match]
  );

  const { workflowByRowId: workflow } = workflowQuery;
  const { me } = user;

  let isSuperuser = false;
  let isReader = false;
  let workflowName;
  let workflowConfigStr;

  if (!isLoading) {
    ({ isSuperuser } = me);
    ({ isReader } = workflow);

    workflowName = workflow.name;
    const {
      initState,
      initActions,
      stateSchema,
      actions,
      transitions
    } = workflow.config;
    workflowConfigStr = stringify(
      {
        initState,
        initActions,
        stateSchema,
        actions,
        transitions
      },
      null,
      2
    );
  }

  const configHeader = {
    fullLogo: false,
    loading: isLoading,
    environment: envVars.REACT_APP_ENVIRONMENT,
    topLevel: {
      title: {
        label: workflowName || null
      },
      links: [
        isReader
          ? {
              label: 'analytics',
              path: Path.compile(ROUTE_WORKFLOW_ANALYTICS)({
                id: match.params.id
              }),
              option: workflow ? workflow.name : null
            }
          : null,
        {
          label: 'dashboard',
          path: ROUTE_USER_DASHBOARD
        }
      ]
    },
    bottomLevel: {
      workflowPage: true,
      actions: {
        viewToggle: [
          {
            label: 'overview',
            active: true
          },
          {
            label: 'kanban',
            path: Path.compile(ROUTE_WORKFLOW_BOARD)({
              id: props.match.params.id
            }),
            active: false
          }
        ],
        links: [
          isSuperuser
            ? {
                icon: <Settings />,
                label: 'Workflow configuration',
                onClick: toggleShowWorkflowConfigEditor
              }
            : null,
          {
            icon: <Download />,
            label: 'Export data',
            onClick: goToExport
          }
        ]
      }
    }
  };

  return (
    <>
      <Header config={configHeader} />

      {isSuperuser && showWorkflowConfigEditor && (
        <JsonEditor
          title="Workflow configuration"
          jsonString={workflowConfigStr}
          onSubmit={handleWorkflowConfigUpdate}
          onClose={toggleShowWorkflowConfigEditor}
          codemirrorOptions={configEditorCodemirrorOptions}
        />
      )}
    </>
  );
});

WorkflowOverviewHeader.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  successSnackbar: PropTypes.func.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  workflowQuery: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateWorkflowConfigMutation: PropTypes.func.isRequired
};

const UPDATE_WF_CONFIG_MUTATION = gql`
  mutation updateWorkflowConfigMutation(
    $workflowRowId: BigInt!
    $newInitState: JSON
    $newInitActions: JSON
    $newStateSchema: JSON
    $newActions: JSON
    $newTransitions: JSON
  ) {
    newWorkflowConfig(
      input: {
        config: {
          workflowRowId: $workflowRowId
          initState: $newInitState
          initActions: $newInitActions
          stateSchema: $newStateSchema
          actions: $newActions
          transitions: $newTransitions
        }
      }
    ) {
      workflow {
        rowId
        config {
          rowId
          overview
          initState
          initActions
          stateSchema
          actions
          transitions
        }
      }
    }
  }
`;

export default compose(
  graphql(UPDATE_WF_CONFIG_MUTATION, {
    name: 'updateWorkflowConfigMutation'
  }),
  injectSheet(styles),
  withRouter,
  withSnackbarsContext,
  withUser,
  withError
)(WorkflowOverviewHeader);
