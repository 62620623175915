import React from 'react';
import ReactDOM from 'react-dom';

import { Root } from 'components';
import { VERSION } from 'constant/monitoring';
import apm from 'monitoring/apm';

// configure internationalization on load
import 'utils/i18n';

// polyfills
import 'utils/polyfills';

console.info(`App version=${VERSION}, APM agent enabled=${apm.isEnabled()}`);

ReactDOM.render(<Root />, document.getElementById('root'));
