import { XMLParser } from 'fast-xml-parser';
import jsonata from 'jsonata';

const options = {
  ignoreAttributes: false,
  attributeNamePrefix: '@',
  trimValues: true, // remove spaces
  alwaysCreateTextNode: true,
  textNodeName: 'value'
};

const parser = new XMLParser(options);

export const runXMLParser = (xmlFile, parserConfig) =>
  new Promise((resolve, reject) => {
    xmlFile.text().then(xmlText => {
      const parsedXml = parser.parse(xmlText);
      const { query } = parserConfig;
      const data = jsonata(query).evaluate(parsedXml);
      if (data) {
        resolve({ data });
      } else {
        reject(
          new Error(
            `The provided query in workflow configuration could not extract any information from the XML data`
          )
        );
      }
    });
  });
