export default theme => ({
  groupAvatar: {
    lineHeight: 'normal',
    marginRight: 10
  },
  groupName: {
    flexGrow: 1,
    outline: 'none',
    padding: '0px 10px',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px',
    textTransform: 'capitalize',
    backgroundColor: theme.grey7,
    border: `1px solid ${theme.grey7}`,
    borderRadius: 2,
    '&:focus': {
      backgroundColor: theme.white1,
      borderColor: theme.indigo2
    }
  }
});
