import axios from 'axios';
import { ROOT_API_URL } from 'constant/api';
import { getAuthToken } from 'utils/localStorage';

const getAuthHeader = () => ({
  Authorization: `Bearer ${getAuthToken()}`
});

export const getToken = async authCode => {
  const response = await axios.request({
    url: `${ROOT_API_URL}/oauth`,
    method: 'POST',
    data: JSON.stringify({ authCode }),
    headers: { 'Content-Type': 'application/json' }
  });

  return response.data.token;
};

export const downloadAuditTrail = async traceId => {
  const rsp = await axios.request({
    url: `${ROOT_API_URL}/audittrail/download`,
    params: { trace_id: traceId },
    headers: getAuthHeader(),
    responseType: 'blob'
  });
  return rsp.data;
};
