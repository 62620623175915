import { TAB_HEIGHT } from 'style/dimensions';

export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: TAB_HEIGHT,
    backgroundColor: theme.white1,
    color: theme.grey5,
    transition: '250ms',
    '&[data-is-active=true]': {
      background: theme.greyTable,
      color: theme.grey1
    },
    '&[data-is-active=false]': {
      opacity: 0.7,
      '&:hover': {
        cursor: 'pointer',
        color: theme.grey1
      }
    }
  },
  label: {
    lineHeight: '11px',
    fontStyle: 'normal',
    fontWeight: 700
  },
  icon: {
    width: 27,
    marginBottom: 8
  }
});
