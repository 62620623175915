import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import styles from './iconKpi.style';

// bgColor is used to set the icon wrapper's background directly in its stylesheet
// eslint-disable-next-line no-unused-vars
export const IconKpi = ({ classes, name, bgColor }) => (
  <div className={classes.root}>
    <DynamicIcon icon={name} className={classes.svg} />
  </div>
);

IconKpi.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  bgColor: PropTypes.string
};

IconKpi.defaultProps = {
  name: '',
  bgColor: ''
};

export default injectSheet(styles)(memo(IconKpi));
