// These styles are being used by different charts.
import React from 'react';
import theme from 'style';
import CustomTick from '../ui/customTick';
import { graphType } from '../../constant';

// Recharts components styling
export const rechartsStyles = {
  /**
   * style1:
   * - no y-axis line, only displaying labels
   * - only horizontal cartesian grid
   */
  style1: {
    root: {
      margin: {
        top: 10,
        right: 4,
        left: -29,
        bottom: 0
      }
    },
    cartesianGrid: {
      vertical: false,
      stroke: theme.grey5,
      strokeDasharray: '5 5'
    },
    xAxis: {
      axisLine: { stroke: 'rgb(236,235,242)' },
      tickLine: { strokeWidth: 0 },
      interval: 0,
      tick: <CustomTick />
    },
    yAxis: {
      axisLine: { strokeWidth: 0 },
      tickLine: { stroke: 'rgb(236,235,242)', right: 10 },
      tick: { fill: theme.grey3, fontSize: 8 },
      interval: 0
    },
    tooltip: {
      description: 'pending traces',
      type: graphType.BAR,
      cursor: false
    }
  },
  /**
   * style2:
   * - no y-axis line, display explicit period labels (today, 6 months ago, ...)
   * - no cartesian grid
   */
  style2: {
    root: {
      barCategoryGap: '15%',
      margin: { left: -9 }
    },
    cartesianGrid: {
      horizontal: false,
      vertical: false,
      stroke: theme.grey5,
      strokeDasharray: '5 5'
    },
    xAxis: {
      axisLine: { stroke: 'rgb(236,235,242)' },
      tickLine: { strokeWidth: 0 },
      interval: 0,
      tick: <CustomTick fromNow />
    },
    tooltip: {
      title: 'trace',
      type: graphType.COLUMN,
      cursor: false
    }
  }
};

export const mainStyles = () => ({
  wrapper: {
    width: '100%',
    height: '100%'
  }
});
