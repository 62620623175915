import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';
import pathToRegexp from 'path-to-regexp';
import classnames from 'classnames';

import { IconbuttonAdd, Icon, withSnackbarsContext } from '@stratumn/atomic';
import { Cross, Settings } from '@stratumn/icons';

import { formatNumber } from 'utils';
import {
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_WORKFLOW_GROUPS
} from 'constant/routes';

import { withTransactionAsync, TransactionType } from 'monitoring/transaction';
import to from 'await-to-js';
import Ellipsis from 'components/ui/ellipsis';

import mutation from './uploadDocumentationModal/mutation';
import DownloadFile from './downloadFile';
import UploadDocumentationModal from './uploadDocumentationModal';

import styles from './row.style';

export const WorkflowRow = React.memo(
  ({
    classes,
    columnsClassNames,
    workflow,
    isSuperuser,
    updateWorkflowDocumentation,
    successSnackbar,
    errorSnackbar
  }) => {
    const [showModal, setShowModal] = React.useState(false);

    const { rowId, canUpdate, name, traces, groups, documentation } = workflow;

    const toggleModal = useCallback(() => {
      setShowModal(!showModal);
    }, [showModal]);

    const deleteWorkflowDocumentation = () => {
      const { url, file } = documentation;
      let documentationName;

      if (url) documentationName = url;
      if (file) documentationName = file.name;

      withTransactionAsync(
        'updateWorkflowDocumentation',
        TransactionType.action,
        async () => {
          const [err] = await to(
            updateWorkflowDocumentation({
              variables: { rowId: rowId, url: '', fileDigest: null }
            })
          );
          if (err) {
            errorSnackbar(
              `${documentationName} 
               documentation could not be deleted`
            );
          }
          successSnackbar(`${documentationName} documentation deleted`);
        }
      );
    };

    const renderDeleteWorkflowDoc = () => (
      <button
        className={classes.deleteBtnWrapper}
        data-cy="delete-button"
        type="reset"
        title="delete documentation"
        onClick={deleteWorkflowDocumentation}
      >
        <Cross className={classes.deleteBtn} />
      </button>
    );

    const renderDocumentation = () => {
      if (documentation) {
        const { file, url } = documentation;

        if (url) {
          return (
            <div className={classes.linkWrapper}>
              <div className={classes.linkIcon}>
                <Icon name="LinkLight" size={22} />
              </div>
              <Ellipsis>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </Ellipsis>
              {canUpdate && renderDeleteWorkflowDoc()}
            </div>
          );
        }
        if (file) {
          return (
            <div className={classes.downloadFileWrapper}>
              <DownloadFile data={file} />
              {canUpdate && renderDeleteWorkflowDoc(0)}
            </div>
          );
        }
      }

      if (!canUpdate) return null;

      return (
        <>
          <div className={classes.uploadDocumentation}>
            <div className={classes.iconBtnAdd}>
              <IconbuttonAdd
                onClick={toggleModal}
                data-cy="open-workflow-documentation-modal"
              />
            </div>
            Add documentation
          </div>
          {showModal && (
            <UploadDocumentationModal
              workflowRowId={rowId}
              onClose={toggleModal}
            />
          )}
        </>
      );
    };

    return (
      <tr>
        <th className={columnsClassNames.columnName}>
          <Link
            className={classes.link}
            to={pathToRegexp.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: rowId })}
            data-cy="workflow-row-name"
          >
            <div className={classes.workflowName}>
              <Ellipsis>{name}</Ellipsis>
            </div>
          </Link>
        </th>
        <td
          className={classnames(
            columnsClassNames.columnTraces,
            classes.numberCell
          )}
          data-cy="trace-count"
        >
          {formatNumber(traces.totalCount)}
        </td>
        <td
          className={classnames(
            columnsClassNames.columnGroups,
            classes.numberCell
          )}
          data-cy="group-count"
        >
          {formatNumber(groups.totalCount)}
        </td>
        <td
          className={classnames(
            columnsClassNames.columnDocumentation,
            classes.documentationWrapper
          )}
          data-cy="documentation"
        >
          {renderDocumentation()}
        </td>
        {canUpdate && isSuperuser && (
          <td className={columnsClassNames.columnSettings}>
            <Link
              className={classnames(classes.link, classes.settingsCell)}
              to={pathToRegexp.compile(ROUTE_WORKFLOW_GROUPS)({ id: rowId })}
              data-cy="workflow-settings-link"
            >
              <Settings className={classes.settingsIcon} />
            </Link>
          </td>
        )}
      </tr>
    );
  }
);

WorkflowRow.propTypes = {
  classes: PropTypes.object.isRequired,
  columnsClassNames: PropTypes.shape({
    columnName: PropTypes.string,
    columnTraces: PropTypes.string,
    columnGroups: PropTypes.string,
    columnDocumentation: PropTypes.string,
    columnSettings: PropTypes.string
  }).isRequired,
  workflow: PropTypes.object.isRequired,
  isSuperuser: PropTypes.bool,
  successSnackbar: PropTypes.func.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
  updateWorkflowDocumentation: PropTypes.func.isRequired
};
WorkflowRow.defaultProps = {
  isSuperuser: false
};

export const fragments = {
  workflowRow: gql`
    fragment WorkflowRowFragment on Workflow {
      rowId
      canUpdate
      name
      description
      # https://stratumn.atlassian.net/browse/ENG-248
      # https://github.com/stratumn/trace-api/pull/599
      # workflow.traces calls the workflow_traces() function, which seems to go through all workflow traces.

      # We just want the count in this case, and tracesDeprecated iterates on the raw table which is more optimized.
      # This reduces the user dashboard loading time from ~18s to ~5.5s for admin@stratumn.com on staging,
      # with a couple of workflows with thousands of traces.

      # Making the change in the backend is not possible because workflow.traces as it is
      # is used by cnp-proxy-server to get filtered traces.
      traces: tracesDeprecated {
        totalCount
      }
      groups {
        totalCount
      }
      documentation {
        url
        file {
          name
          size
          digest
        }
      }
    }
  `
};

export default compose(
  graphql(mutation.updateWorkflowDocumentation, {
    name: 'updateWorkflowDocumentation'
  }),
  injectSheet(styles),
  withSnackbarsContext
)(WorkflowRow);
