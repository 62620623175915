export default theme => ({
  root: {
    position: 'relative',
    border: `1px solid ${theme.grey8}`,
    borderRadius: 10,
    backgroundColor: theme.white1,
    transition: '200ms ease-out',
    '&[data-drag-enter=true]': {
      border: `1px solid ${theme.indigo2}`
    }
  },
  dropzoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 5px',
    transition: '200ms ease-out',
    '&[data-drag-enter=true]': {
      opacity: 0.2
    },
    '&[data-is-responsive=true]': {
      padding: '15px 15px 0 15px'
    }
  },
  dropzoneBody: {
    display: 'flex',
    '&[data-is-responsive=true]': {
      height: '100%'
    },
    '@media (max-width: 960px)': {
      flexDirection: 'column'
    }
  },
  leftColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flex: '2 2 20em',
    height: '100%',
    paddingRight: 25,
    borderRight: `1px solid ${theme.grey6}`,
    '@media (max-width: 1170px)': {
      paddingLeft: 10,
      paddingRight: 10,
      borderLeft: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '@media (max-width: 960px)': {
      borderRight: 'none',
      borderBottom: `1px solid ${theme.grey6}`
    }
  },
  leftColumnContent: {
    position: 'relative',
    zIndex: 2,
    minHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    transition: 'opacity 200ms linear 100ms',
    minWidth: 170
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 20em',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    marginLeft: 10,
    '&[data-is-responsive=true]': {
      marginTop: 20,
      maxHeight: 185,
      order: 2,
      marginLeft: 0,
      '&[data-is-disabled=true]': {
        marginTop: 0
      }
    }
  },
  dropzoneLabel: {
    fontSize: 14,
    color: theme.grey1,
    marginBottom: 10,
    textAlign: 'center',
    '& p': {
      lineHeight: '18px'
    },
    '& p:first-child': {
      marginBottom: 10
    }
  },
  dropzoneIcon: {
    color: theme.indigo3,
    position: 'absolute',
    top: 'calc(50% - 14px)',
    left: 'calc(50% - 14px)',
    opacity: 0,
    transform: 'translateY(-28px)',
    transition: 'opacity 200ms linear, transform 0ms linear 300ms',
    '&[data-drag-enter=true]': {
      zIndex: 2,
      opacity: 1,
      transform: 'translateY(0)',
      transition:
        'opacity 200ms linear 200ms, transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms'
    }
  },
  fileSelectBtn: {
    color: theme.grey3,
    backgroundColor: 'transparent',
    border: `1px solid currentColor`,
    borderRadius: 4,
    padding: '7px 15px',
    fontWeight: 700,
    fontSize: 11,
    lineHeight: '14px',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    cursor: 'pointer',
    transition: 'color 100ms linear',
    marginBottom: 1,
    '&:focus': {
      outline: 'none',
      color: theme.indigo3
    },
    '&:hover': {
      color: theme.indigo3
    }
  },
  sizeWarning: {
    color: theme.grey4,
    fontSize: 10,
    letterSpacing: '0.24px',
    padding: '10px 0'
  },
  file: {
    padding: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    overflow: 'hidden',
    color: theme.grey1,
    backgroundColor: theme.grey9,
    borderRadius: 2,
    border: `1px solid ${theme.greyTable}`,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'border-color 250ms',
    '&:hover': {
      borderColor: theme.indigo2
    },
    '&:active': {
      backgroundColor: theme.grey8
    },
    '&[data-is-loading=true]': {
      opacity: 0.5
    }
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '10px',
    overflow: 'hidden'
  },
  fileIcon: {
    color: theme.indigo2
  },
  fileInfo: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden'
  },
  fileName: {
    fontSize: 12,
    lineHeight: '15px',
    overflow: 'hidden'
  },
  fileSize: {
    fontSize: 10,
    lineHeight: '13px',
    color: theme.grey4
  },
  right: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    fontSize: 10,
    lineHeight: '13px'
  },
  uploadDate: {
    color: theme.grey4
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pdfViewer: {
    position: 'fixed',
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: 'rgba(0,0,0,0.2)',
    paddingTop: 20,
    top: 0,
    left: 0,
    zIndex: 100
  },
  documentPdf: {
    position: 'relative',
    maxHeight: 1200
  },
  backdrop: {
    width: '100%',
    minHeight: '100%',
    backgroundColor: 'black',
    opacity: 0.3,
    zIndex: 100,
    top: 0,
    left: 0
  },
  buttonBottom: {
    width: '100%',
    height: 30,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rightTop: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },
  middleText: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
