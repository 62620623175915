export default theme => ({
  savedViewsMenuContent: {
    width: 250,
    color: theme.grey1,
    backgroundColor: theme.grey9,
    borderRadius: 2,
    border: `1px solid ${theme.grey5}`,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  savedViewsMenuList: {
    fontSize: 12
  },
  savedViewsMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 36,
    '& button': {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: 30,
      '& svg': {
        width: 20,
        height: 20
      },
      '&:hover': {
        cursor: 'pointer',
        color: theme.indigo2,
        backgroundColor: theme.grey7
      }
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.grey8,
      '& button': {
        display: 'flex'
      }
    }
  },
  savedViewsMenuItemSelected: {
    color: theme.indigo2,
    fontWeight: 'bold'
  },
  savedViewsMenuItemName: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    minWidth: 0,
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '&:hover': {
      color: theme.indigo2
    }
  },
  errorMessage: {
    color: theme.warningRed,
    fontSize: 12,
    margin: '5px 0'
  },
  saveViewButton: {
    display: 'block',
    margin: 5,
    '& button': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& span': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  },
  saveViewButtonIcon: {
    marginRight: 5,
    height: 22
  },
  savedViewsMenuArrow: {
    width: 20,
    height: 10,
    display: 'block',
    position: 'absolute',
    top: -8,
    right: 22,
    zIndex: 3,
    stroke: theme.grey5,
    fill: theme.grey9,
    strokeLinecap: 'round',
    strokeWidth: '1',
    strokeLinejoin: 'round'
  },
  savedViewsMenuHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 15,
    margin: 5,
    marginBottom: 2,
    padding: 3,
    borderBottom: `1px solid ${theme.grey5}`
  },
  // Modald content
  savedModeDromDown: {
    marginBottom: 16
  },
  promptMessage: {
    fontSize: 16,
    '& strong': {
      fontWeight: 'bold',
      color: theme.indigo2
    }
  }
});
