import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  CheckboxTick,
  PrivateProcess as Radio,
  PrivateProcessFill as RadioFill,
  DownArrow,
  DownArrowFill
} from '@stratumn/icons';

import injectSheet from 'react-jss';

import styles from './displayMenu.style';

// display menu
const DisplayMenu = React.memo(
  ({
    classes,
    tableConfig,
    displayConfig,
    toggleColumnSelection,
    setGroupBy
  }) => {
    const [showColumns, setShowColumns] = useState(true);
    const [showGroups, setShowGroups] = useState(!!displayConfig.groupBy);

    const selectedColumnsKeys = displayConfig.columns.map(
      colDisplayConfig => colDisplayConfig.key
    );

    return (
      <>
        <svg
          className={classes.displayMenuArrow}
          data-name="arrow"
          aria-hidden="true"
          viewBox="0 0 20 10"
        >
          <polyline points="5,8 10,0.85 15,8 15,10 5,10" stroke="none" />
          <polyline points="5,8 10,0.85 15,8" />
        </svg>
        <div className={classes.displayMenuContent}>
          <div
            className={classes.displayMenuHeader}
            onClick={() => setShowColumns(!showColumns)}
            data-is-expanded={showColumns}
          >
            <div>Columns</div>
            <div className={classes.displayMenuHeaderToggle}>
              {showColumns ? <DownArrowFill /> : <DownArrow />}
            </div>
          </div>
          {showColumns && (
            <div className={classes.displayMenuList}>
              {tableConfig.columns.map(colDef => (
                <div
                  key={colDef.key}
                  className={classes.displayMenuItem}
                  onClick={() => toggleColumnSelection(colDef.key)}
                >
                  <span className={classes.displayMenuItemIcon}>
                    {selectedColumnsKeys.includes(colDef.key) && (
                      <CheckboxTick />
                    )}
                  </span>
                  <span className={classes.displayMenuItemHeader}>
                    {colDef.header}
                  </span>
                </div>
              ))}
            </div>
          )}
          {tableConfig.groups && (
            <div
              className={classes.displayMenuHeader}
              onClick={() => setShowGroups(!showGroups)}
              data-is-expanded={showGroups}
            >
              <div>Group by</div>
              <div className={classes.displayMenuHeaderToggle}>
                {showGroups ? <DownArrowFill /> : <DownArrow />}
              </div>
            </div>
          )}
          {showGroups && (
            <div className={classes.displayMenuList}>
              <div
                className={classes.displayMenuItem}
                onClick={() => setGroupBy(null)}
              >
                <span className={classes.displayMenuRadioIcon}>
                  {displayConfig.groupBy ? <Radio /> : <RadioFill />}
                </span>
                <span className={classes.displayMenuItemHeader}>None</span>
              </div>
              {tableConfig.groups.map(group => (
                <div
                  key={group.label}
                  className={classes.displayMenuItem}
                  onClick={() => setGroupBy(group)}
                >
                  <span className={classes.displayMenuRadioIcon}>
                    {displayConfig.groupBy &&
                    displayConfig.groupBy.path === group.path ? (
                      <RadioFill />
                    ) : (
                      <Radio />
                    )}
                  </span>
                  <span className={classes.displayMenuItemHeader}>
                    {group.label}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
);
DisplayMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  tableConfig: PropTypes.object.isRequired,
  displayConfig: PropTypes.object.isRequired,
  toggleColumnSelection: PropTypes.func.isRequired,
  setGroupBy: PropTypes.func.isRequired
};

export default injectSheet(styles)(DisplayMenu);
