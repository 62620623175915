import React, { useContext } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { multipleFileDownload } from 'utils/downloadFile/multipleFileDownload';
import { withSnackbarsContext } from '@stratumn/atomic';
import { TicketContext } from 'components/ui/ticketBar';
import { ENABLE_BATCH_DOWNLOAD } from 'constant/featureFlags';

import File from '../file';

import styles from './fileList.style';

export const FileList = ({ classes, segment, traceId }) => {
  const { setTickets } = useContext(TicketContext);

  const extractFiles = segment_ => {
    if (!segment_.action || !segment_.action.form) return [];
    if (!segment_.data) return [];
    const { uiSchema } = segment_.action.form;
    return Object.entries(segment_.data)
      .filter(
        ([k]) => uiSchema[k] && uiSchema[k]['ui:field'] === 'FileUploadField'
      )
      .reduce((prev, files) => [...prev, ...files.slice(1)[0]], []);
  };

  const filesSegment = extractFiles(segment);
  const multiFiles = {
    files: [
      {
        trace_uuid: traceId,
        digests: filesSegment.map(file => file.digest)
      }
    ],
    workflow_id: segment.workflowId
  };

  const downloadAllFiles = async () => {
    const ticket = await multipleFileDownload(multiFiles);

    setTickets(currentTickets => [
      ...currentTickets,
      {
        ticket_id: ticket,
        status: 'pending'
      }
    ]);
  };

  const totalCount = filesSegment.length;

  if (totalCount < 1) return null;

  return (
    <div className={classes.upperRoot}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.count}>
            {totalCount} {totalCount === 1 ? 'Attachment' : 'Attachments'}
          </div>
          {ENABLE_BATCH_DOWNLOAD && (
            <div className={classes.downloadAll} onClick={downloadAllFiles}>
              Download all
            </div>
          )}
        </div>
        <div className={classes.fileList}>
          {filesSegment.map(file => (
            <div key={file.digest} className={classes.fileItem}>
              <File file={file} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

FileList.propTypes = {
  classes: PropTypes.object.isRequired,
  segment: PropTypes.object.isRequired,
  traceId: PropTypes.string.isRequired
};

export default injectSheet(styles)(withSnackbarsContext(React.memo(FileList)));
// export default injectSheet(styles)(FileList);
