import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import style from './skeleton.style';

/* attributes height, width, margin, borderRadius are passed down to stylesheet for dynamic styling */

// eslint-disable-next-line
export const Skeleton = ({ classes, height, width, borderRadius, margin }) => (
  <div className={classes.loading} />
);

Skeleton.propTypes = {
  classes: PropType.object.isRequired,
  height: PropType.number.isRequired,
  width: PropType.number.isRequired,
  borderRadius: PropType.number.isRequired,
  margin: PropType.number
};

Skeleton.defaultProps = {
  margin: 10
};

export default injectSheet(style)(Skeleton);
