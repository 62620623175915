import gql from 'graphql-tag';

import { fragment as referencesFragment } from './references';

const linkFragment = gql`
  fragment LinkFragment on Link {
    linkHash
    id
    workflowId
    actionKey
    createdAt
    height
    group {
      rowId
      name
      avatar
    }
    processState
    data
    formId

    evidences {
      totalCount
      nodes {
        proof
        provider
        backend
        linkHash
      }
    }

    action {
      key
      title
      icon
      description
      form {
        schema
        uiSchema
      }
      dataImporter
      dataEditor
    }

    ...ReferencesFragment
  }
  ${referencesFragment}
`;

export default {
  link: linkFragment,
  trace: gql`
    fragment TraceInspectorFragment on Trace {
      rowId
      name
      head {
        group {
          rowId
        }
        ...LinkFragment
      }
      workflow {
        config {
          rowId
          info
        }
        rowId
        name
        groups {
          nodes {
            rowId
            label
            canAct
            name
            avatar
            actions {
              totalCount
            }
          }
        }
        actions {
          nodes {
            key
            title
          }
        }
      }
      links {
        nodes {
          ...LinkFragment
        }
      }
      state {
        meta
        data
        rowId
        nextActions
        tasks
        trace {
          rowId
          head {
            linkHash
            createdByAccount {
              rowId
              entity {
                ... on Account_User {
                  rowId
                  name
                }
                ... on Account_Bot {
                  rowId
                  name
                }
              }
            }
          }
        }
      }
    }
    ${linkFragment}
  `
};
