import React, { useMemo, memo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import theme from 'style';

import IconKpi from '../../iconKpi';

import styles from './kpiTab.style';

// Icons name
const CLOCK_GENERAL = 'ClockGeneral';
const ANALYTICS = 'Analytics';

// Background gradients related to its icon
export const bgGradient = {
  ANALYTICS: 'linear-gradient(272.97deg, #30CECA 0%, #6BEDD8 100%)', // TODO: add tealGradient3 to atomic globalStyle.js
  CLOCKGENERAL: 'linear-gradient(272.97deg, #41AF50 0%, #6CDA7B 100%)' // TODO: add greenGradient3 to atomic globalStyle.js
};

export const KpiTab = ({
  classes,
  label,
  description,
  icon,
  value,
  active,
  loading
}) => {
  const renderColor = useMemo(() => {
    const color = {};

    switch (icon) {
      case ANALYTICS:
        color.bgColorGradient = bgGradient.ANALYTICS;
        color.bgColor = theme.teal1;
        break;

      case CLOCK_GENERAL:
        color.bgColorGradient = bgGradient.CLOCKGENERAL;
        color.bgColor = theme.validGreen;
        break;

      default:
        color.bgColorGradient = theme.indigo1;
        color.bgColor = theme.indigo1;
    }

    return { color };
  }, [icon]);

  const { color } = renderColor;

  // TODO: kpiTab skeleton
  if (loading) return <div>...loading</div>;

  return (
    <div
      data-cy="kpi-tab"
      // inline style for dynamic colors
      style={{ borderTopColor: active ? color.bgColor : 'transparent' }}
      className={classes.wrapper}
    >
      <div className={classes.innerWrapper} data-is-active={active}>
        {icon && (
          <IconKpi
            name={icon}
            bgColor={active ? color.bgColorGradient : theme.grey5}
          />
        )}
        <div className={classes.details}>
          <h5 className={classes.label} data-is-active={active}>
            {label}
          </h5>
          <div className={classes.valueWrapper}>
            <p data-cy="kpi-tab-value" className={classes.value}>
              {value}
            </p>
            <p className={classes.description}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

KpiTab.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string,
  description: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.number]),
  icon: PropType.string,
  active: PropType.bool.isRequired,
  loading: PropType.bool
};

KpiTab.defaultProps = {
  label: '',
  description: '',
  value: null,
  icon: '',
  loading: false
};

export default injectSheet(styles)(memo(KpiTab));
