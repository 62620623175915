export const SMALL_PROPS = {
  borderRadius: 2,
  margin: 1.5
};

export const MEDIUM_PROPS = {
  borderRadius: 2,
  margin: 3.5
};

/**
 * @function pixelToPercentConverion
 * @param {string} parent - The size of the parent container
 * @param {string} child - The size of it's child element
 * @returns {float} Converts from 'px' to '%' the size of the child relative to the parent, so it stays responsive
 */

export const pixelToPercentConvertion = (parent, child) =>
  (child / parent) * 100;
