import env from './env';

export const CLIENT_ID = env.REACT_APP_CLIENT_ID;
export const ROOT_API_URL = env.REACT_APP_API_URL;
export const GRAPHQL_API_URL = `${ROOT_API_URL}/graphql`;
export const NOTIFICATION_API_URL = `${ROOT_API_URL}/graphql`.replace(
  /^http/,
  'ws'
);

export const MEDIA_API_URL = env.REACT_APP_MEDIA_API_URL;
export const TICKET_API_URL = env.REACT_APP_TICKET_API_URL;

export const ACCOUNT_API_URL = env.REACT_APP_ACCOUNT_API_URL;
export const GRAPHQL_ACCOUNT_API_URL = `${ACCOUNT_API_URL}/graphql`;

export const ACCOUNT_URL = env.REACT_APP_ACCOUNT_URL;

export const ACCOUNT_LOGIN_URL = `${ACCOUNT_URL}/login`;

export const REPORTING_URL = env.REACT_APP_REPORTING_API_URL;
export const REPORTING_API_REALTIME = `${REPORTING_URL}/realtime`;
export const REPORTING_API_REPORT = `${REPORTING_URL}/reporting`;
export const GRAPHQL_REPORTING_API_URL = `${REPORTING_URL}/graphql`;
