import env from './env';

// Whether or not to disaply the link to the reporting dashboard
export const ENABLE_ANALYTICS_LINK = env.REACT_APP_ENABLE_ANALYTICS_LINK
  ? JSON.parse(env.REACT_APP_ENABLE_ANALYTICS_LINK)
  : false;

// Whether or not to display the toggle to turn on sub totals in the
// table view
export const ENABLE_SUB_TOTAL_AGGREGATION = env.REACT_APP_ENABLE_SUB_TOTAL_AGGREGATION
  ? JSON.parse(env.REACT_APP_ENABLE_SUB_TOTAL_AGGREGATION)
  : false;

// Whether or not to display the toggle to turn on sub totals in the
// table view
export const ENABLE_BATCH_DOWNLOAD = env.REACT_APP_ENABLE_BATCH_DOWNLOAD
  ? JSON.parse(env.REACT_APP_ENABLE_BATCH_DOWNLOAD)
  : false;
