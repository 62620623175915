const ROW = 'row';
const COLUMN = 'column';
const BOX = 'box';

/**
 * @function buildGridTemplate - creates a grid template property
 * @param {string} direction - either row or column
 * @param {Array<{size: Number}>} sizes - each item sizes
 * @returns an object - { template: string, value: string }
 */
export const buildGridTemplate = (direction, sizes) => {
  if (sizes.length === 0) return null;
  switch (direction) {
    case ROW:
      return {
        template: 'gridTemplateRows',
        // build the columns size according to each item's size
        value: sizes.map(size => size).join(' ')
      };
    case COLUMN:
      return {
        template: 'gridTemplateColumns',
        value: sizes.map(size => size).join(' ')
      };

    default:
      return null;
  }
};

/**
 * @function buildKpisConfig - creates a new kpis' config object
 * @param {object} kpis
 * @param {object} kpis.content
 * @param {string} kpis.content.type
 * @param {number | string} kpis.content.height
 * @param {string} kpis.content.direction
 * @param {Array<{size: Number, content: object}>} kpis.content.items
 * @returns a new kpis' config with layout styles.
 */
const buildKpisConfig = kpis => {
  if (!kpis.content) return null;

  // kpis wrapper style
  const wrapperStyle = {
    height: kpis.size || '100%'
  };

  if (kpis.content.type === BOX) {
    if (kpis.content.items) {
      const { template, value } = buildGridTemplate(
        kpis.content.direction,
        // extract only the items' sizes
        kpis.content.items.map(item => item.size)
      );
      wrapperStyle[template] = value;
    }
  }

  // build new kpis' items array without the size property
  const items = kpis.content.items.map(item => ({ content: item.content }));

  return { wrapperStyle, items };
};

/**
 * @function buildGraphsConfig - creates a new graphs' config object
 * @param {object} graphs
 * @param {object} graphs.content
 * @param {string} graphs.content.type
 * @param {number | string} graphs.content.height
 * @param {string} graphs.content.direction
 * @param {Array<{size: Number, content: object}>} graphs.content.items
 * @returns a new graphs' config with layout styles.
 */
const buildGraphsConfig = graphs => {
  // graphs wrapper style
  const wrapperStyle = {
    height: graphs.size || '100%'
  };

  if (graphs.content.type === BOX) {
    if (graphs.content.items) {
      const { template, value } = buildGridTemplate(
        graphs.content.direction,
        // extract only the items' sizes
        graphs.content.items.map(item => item.size)
      );
      wrapperStyle[template] = value;
    }
  }

  const items = [];

  // compute the last row line number
  let rowLineEnd = 1;
  graphs.content.items.forEach(item => {
    if (item.content.direction === ROW) {
      rowLineEnd += item.content.items.length;
    }
  });

  // iterate on the graphs and build the layout style
  graphs.content.items.map((itemDepth1, index) => {
    // build the columns size according to each item's size. We use "fr" fraction which will automatically fill the width of its cell
    const columnLineStart = index + 1;
    const columnLineEnd = index + 2;

    if (itemDepth1.content.type === BOX) {
      if (itemDepth1.content.items) {
        const { template, value } = buildGridTemplate(
          itemDepth1.content.direction,
          // extract only the items' sizes
          itemDepth1.content.items.map(item => item.size)
        );
        wrapperStyle[template] = value;
      }
    }

    // If we don't have any rows, the grid-row will take the whole column's space.
    if (itemDepth1.content.direction !== ROW) {
      return items.push({
        graphStyle: {
          gridColumn: `${columnLineStart} / ${columnLineEnd}`,
          gridRow: `1 / ${rowLineEnd}` // since we only have one item, we'll assume it takes the whole column hence the rowLineStart set by default to 1.
        },
        content: itemDepth1.content
      });
    }

    // only consider two levels deep to implement the current design
    itemDepth1.content.items.map((itemDepth2, idx) =>
      items.push({
        graphStyle: {
          gridColumn: `${columnLineStart} / ${columnLineEnd}`,
          gridRow: `${idx + 1} / ${idx + 2}`
        },
        content: itemDepth2.content
      })
    );

    return items;
  });

  wrapperStyle.gridTemplateColumns = wrapperStyle.gridTemplateColumns.trim();

  return { wrapperStyle, items };
};

/**
 * @function parseLayout - creates a new config object with style properties
 * @param {object} config
 * @param {object} config.wrapperStyle - can be used to use grid for the overall layout
 * @param {object} config.kpis
 * @param {object} config.graphs
 * @returns a new config with layout styles
 */
export const parseLayout = config => {
  const buildLayout = {
    // TODO: see how we can use this wrapperStyle on the root element once we implemented the filter row component
    // This could be an overkill for now
    wrapperStyle: {},
    kpis: null,
    graphs: null
  };

  if (config.type === BOX) {
    buildLayout.wrapperStyle.height = config.height || '100%';
    const { template, value } =
      buildGridTemplate(
        config.direction,
        // extract only the items' sizes
        config.items.filter(item => item.size)
      ) || {};

    if (template) buildLayout.wrapperStyle[template] = value;
  }

  config.items.forEach(item => {
    if (item.label === 'kpis') {
      buildLayout.kpis = buildKpisConfig(item);
    }
    if (item.label === 'graphs') {
      buildLayout.graphs = buildGraphsConfig(item);
    }
  });

  return buildLayout;
};
