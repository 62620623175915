import React, { useCallback, useMemo, useEffect } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import Path from 'path-to-regexp';
import { Pushbutton, withSnackbarsContext, FormWriter } from '@stratumn/atomic';

import { WorkflowContext } from 'utils/workflowContext';
import { LocalStorageContext } from 'contexts';

import { withLeavingAlertContext } from 'components/beforeLeavingAlert';

import {
  getUserInfoDisplayConfig,
  manageLocalStorage,
  sectionsLocalStorage
} from 'components/ui/utils/localStorage';

import { Widget } from 'components/ui/widget';
import { ROUTE_WORKFLOW_OVERVIEW } from 'constant/routes';
import { uploadFile, uploadConfig } from 'client/media';

import styles from './newLink.style';

const FORM_ID = 'formId';

export const Form = React.memo(props => {
  const {
    classes,
    match,
    history,
    location,
    action,
    workflowContext,
    traces,
    disabled,
    toggleSignDialog,
    formData,
    setFormData,
    setCleanupFn,
    setAskBeforeLeaving,
    setLeavingCallback
  } = props;

  const { rowId: workflowRowId } = workflowContext;

  // localStorage key for backup cache
  const cachedUpdatesKey = useMemo(
    () => `newLinkForm_${workflowRowId}_${location.search}`,
    [workflowRowId, location]
  );

  // Cached data
  const cachedFormData = useMemo(() => {
    const localStorageValue = localStorage.getItem(cachedUpdatesKey);
    return localStorageValue ? JSON.parse(localStorageValue) : null;
  }, [cachedUpdatesKey]);

  // at first mount if there is cached data:
  useEffect(() => {
    if (cachedFormData) {
      // call setFormData with it
      setFormData(cachedFormData);
      // set leaving alert condition to true
      setAskBeforeLeaving(true);
    }
  }, [cachedFormData]);

  // set cleanup function (triggered after form submission) and leaving callback (triggered after user confirmed leaving)
  useEffect(() => {
    if (cachedUpdatesKey) {
      const cleanupLocalStorage = () =>
        localStorage.removeItem(cachedUpdatesKey);
      setLeavingCallback(cleanupLocalStorage);
      setCleanupFn(cleanupLocalStorage);
    }
  }, [cachedUpdatesKey]);

  const handleFormChange = useCallback(
    ({ formData: data }) => {
      setAskBeforeLeaving(true);
      localStorage.setItem(cachedUpdatesKey, JSON.stringify(data));
      setFormData(data);
    },
    [cachedUpdatesKey]
  );

  const handleLocalStorage = useCallback(
    ({ index, isCollapsed }) => {
      const [trace] = traces;
      return manageLocalStorage(trace, { index, isCollapsed });
    },
    [traces]
  );

  const goToWorkflowOverview = () => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  };

  const goBack = () => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // By default go back to workflow overview
    goToWorkflowOverview();
  };

  const handleUploadFile = async (
    file,
    onSuccess,
    onError,
    onProgress,
    disableEncryption
  ) => {
    uploadFile(file, onSuccess, onError, onProgress, disableEncryption);
  };

  const [trace] = traces;

  const schema = action.form
    ? action.form.schema
    : {
        type: 'object',
        title: action.title,
        description: action.description
      };
  const uiSchema = action.form ? action.form.uiSchema : {};

  // Hide trace info for batch update
  const batchUpdate = traces.length > 1;

  const { MAX_FILE_SIZE, FILE_EXTENSION_WHITELIST_MAP } = uploadConfig;

  const wfUserDisplayConfig = useMemo(
    () => getUserInfoDisplayConfig(trace?.workflow?.rowId) || undefined,
    [trace?.workflow?.rowId]
  );

  const localStorageContext = useMemo(
    () => ({
      userInfoConfig:
        (wfUserDisplayConfig && wfUserDisplayConfig[trace?.rowId]) ||
        sectionsLocalStorage(
          trace?.workflow?.config?.info?.view?.sections || []
        ),
      setLocalStorage: handleLocalStorage
    }),
    [trace?.rowId]
  );

  return (
    <div className={classes.container}>
      <div className={classes.formBody}>
        <div
          className={classes.form}
          data-cy="formjs-writer"
          data-has-traceinfo={
            trace && !batchUpdate && !!trace.workflow.config.info
          }
        >
          <FormWriter
            id={FORM_ID}
            title={action.title}
            description={action.description}
            schema={schema}
            uiSchema={uiSchema}
            onChange={handleFormChange}
            formData={formData || cachedFormData}
            uploadFile={handleUploadFile}
            uploadConfig={{
              maxFileSize: MAX_FILE_SIZE,
              fileExtensionWhitelistMap: FILE_EXTENSION_WHITELIST_MAP
            }}
            onSubmit={toggleSignDialog}
            workflowContext={workflowContext}
          />
          <div className={classes.actionButtonWrapper}>
            <div className={classes.actionButton}>
              <Pushbutton onClick={() => goBack()} dataCy="cancel">
                Cancel
              </Pushbutton>
            </div>
            <div className={classes.actionButton}>
              <Pushbutton
                formId={FORM_ID}
                disabled={disabled}
                dataCy="submit"
                type="submit"
                primary
              >
                Submit
              </Pushbutton>
            </div>
          </div>
        </div>
        {trace && !batchUpdate && trace.workflow.config.info && (
          <div data-cy="trace-info" className={classes.traceInfo}>
            <WorkflowContext.Provider value={workflowContext}>
              <LocalStorageContext.Provider value={localStorageContext}>
                <Widget
                  widget={trace.workflow.config.info}
                  data={trace.state}
                />
              </LocalStorageContext.Provider>
            </WorkflowContext.Provider>
          </div>
        )}
      </div>
    </div>
  );
});

Form.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  action: PropType.object.isRequired,
  workflowContext: PropType.object.isRequired,
  traces: PropType.arrayOf(PropType.object).isRequired,
  disabled: PropType.bool.isRequired,
  toggleSignDialog: PropType.func.isRequired,
  formData: PropType.object,
  setFormData: PropType.func.isRequired,
  setCleanupFn: PropType.func.isRequired,
  setAskBeforeLeaving: PropType.func.isRequired,
  setLeavingCallback: PropType.func.isRequired
};

Form.defaultProps = {
  formData: null
};

export default compose(
  injectSheet(styles),
  withSnackbarsContext,
  withRouter,
  withLeavingAlertContext
)(Form);
