import React, { Component } from 'react';
import PropType from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Path from 'path-to-regexp';

import { FILTERS_QUERY } from 'gql/localQueries';
import { graphqlWithFilters } from 'utils';
import {
  ROUTE_WORKFLOW_EXPORT,
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_WORKFLOW_DASHBOARD,
  ROUTE_USER_DASHBOARD,
  ROUTE_WORKFLOW_ANALYTICS
} from 'constant/routes';

import { withError } from 'components/errorBoundary';

import { history } from 'components/root';
import { Header } from 'components/layouts';

import {
  LayoutKanban,
  LayoutKanbanHeader,
  LayoutKanbanMain,
  ATC
} from '@stratumn/atomic';
import { Download } from '@stratumn/icons';

import envVars from 'constant/env';

import GroupColumn from './group';
import fragments from './fragments';

export class WorkflowBoard extends Component {
  static propTypes = {
    match: PropType.object.isRequired,
    filtersQuery: PropType.object,
    workflowQuery: PropType.object.isRequired,
    // eslint-disable-next-line
    errorContext: PropType.object.isRequired
  };

  static defaultProps = {
    filtersQuery: {}
  };

  setDocTitle() {
    const {
      workflowQuery: { workflowByRowId: { name } = {} } = {}
    } = this.props;
    if (name) document.title = `${name} - Kanban - Trace`;
  }

  componentDidMount() {
    this.setDocTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workflowQuery !== this.props.workflowQuery)
      this.setDocTitle();
  }

  subscribeToWorkflow(props) {
    const { workflowQuery, match } = props;
    this.unsubscribeFromWorkflow = workflowQuery.subscribeToMore({
      document: subscriptions.workflow,
      variables: { id: `workflow:${match.params.id}` }
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.subscribeToWorkflow(this.props);
  }

  componentWillUnmount() {
    this.unsubscribeFromWorkflow();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      filtersQuery,
      workflowQuery: { loading, workflowByRowId },
      errorContext: { handleError },
      match: { params }
    } = nextProps;

    // This check avoids rerendering the component after the erroBoundary has been triggered
    if (!loading && !workflowByRowId) {
      handleError('workflow', params.id, ROUTE_WORKFLOW_DASHBOARD);
      return;
    }

    if (filtersQuery.filters === this.props.filtersQuery.filters) {
      return;
    }

    if (this.unsubscribeFromWorkflow) {
      this.unsubscribeFromWorkflow();
    }

    this.subscribeToWorkflow(nextProps);
  }

  handleExport = () =>
    history.push(
      Path.compile(ROUTE_WORKFLOW_EXPORT)({
        id: this.props.match.params.id
      })
    );

  renderHeader = () => {
    const {
      workflowQuery: { loading, workflowByRowId: workflow },
      match
    } = this.props;

    const configHeader = {
      fullLogo: false,
      loading,
      environment: envVars.REACT_APP_ENVIRONMENT,
      topLevel: {
        title: {
          label: !loading ? workflow.name : null
        },
        links: [
          workflow?.isReader
            ? {
                label: 'analytics',
                path: Path.compile(ROUTE_WORKFLOW_ANALYTICS)({
                  id: match.params.id
                }),
                option: workflow ? workflow.name : null
              }
            : null,
          {
            label: 'dashboard',
            path: ROUTE_USER_DASHBOARD
          }
        ]
      },
      bottomLevel: {
        workflowPage: true,
        actions: {
          viewToggle: [
            {
              label: 'overview',
              path: Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
                id: match.params.id
              }),
              active: false
            },
            {
              label: 'kanban',
              active: true
            }
          ],
          links: [
            {
              icon: <Download />,
              label: 'Export data',
              onClick: this.handleExport
            }
          ]
        }
      }
    };

    return <Header config={configHeader} />;
  };

  renderMain = () => {
    const {
      workflowQuery: { workflowByRowId: workflow }
    } = this.props;

    return (
      <ATC>
        {workflow.groups.nodes.map(group => (
          <GroupColumn group={group} key={group.rowId} />
        ))}
      </ATC>
    );
  };

  render = () => {
    const {
      workflowQuery: { loading, error, workflowByRowId }
    } = this.props;

    /**
     * If the workflow id doesn't exist,
     * we return null and let errorBoundary render the oops page
     */
    if (error || (!workflowByRowId && !loading)) {
      return null;
    }

    return (
      <LayoutKanban>
        <LayoutKanbanHeader>{this.renderHeader()}</LayoutKanbanHeader>
        <LayoutKanbanMain loading={loading}>
          {loading ? null : this.renderMain()}
        </LayoutKanbanMain>
      </LayoutKanban>
    );
  };
}

export const queries = {
  workflowQuery: gql`
    query workflowQuery(
      $workflowId: BigInt!
      $watched: Boolean
      $search: String
    ) {
      workflowByRowId(rowId: $workflowId) {
        ...WorkflowQueryFragment
      }
    }
    ${fragments.workflow}
  `
};

export const subscriptions = {
  workflow: gql`
    subscription listenWorkflow(
      $id: String!
      $watched: Boolean
      $search: String
    ) {
      listen(topic: $id) {
        relatedNodeId
        relatedNode {
          ...WorkflowQueryFragment
        }
      }
    }
    ${fragments.workflow}
  `
};

export default compose(
  graphql(FILTERS_QUERY, {
    name: 'filtersQuery'
  }),
  graphqlWithFilters(queries.workflowQuery, {
    name: 'workflowQuery',
    options: ({ match }) => ({
      variables: { workflowId: match.params.id }
    })
  }),
  withError
)(WorkflowBoard);
