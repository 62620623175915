export default theme => ({
  labelsView: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  labelsViewElement: {
    fontSize: 11, // 85%
    letterSpacing: 0.2,
    margin: 1,
    border: `1px solid ${theme.grey1}`,
    borderRadius: 2,
    padding: '2px 5px',
    height: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
