import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { history } from 'components/root';
import classnames from 'classnames';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import Path from 'path-to-regexp';

import { ROUTE_INSPECT_TRACE } from 'constant/routes';
import { formatDate } from 'utils';
import { WatchingFill, Watching } from '@stratumn/icons';

import styles from './card.style';

export class TraceCard extends Component {
  static propTypes = {
    trace: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    watchTraceMutation: PropTypes.func.isRequired
  };

  renderTitle = () => {
    const { trace, classes } = this.props;

    return (
      <div
        className={classes.titleInput}
        onClick={this.handleTitleClick}
        data-cy="trace-card-title"
      >
        {trace.name}
      </div>
    );
  };

  handleTitleClick = () => {
    const {
      trace: { rowId }
    } = this.props;

    history.push(Path.compile(ROUTE_INSPECT_TRACE)({ id: rowId }));
  };

  handleWatchTrace = () => {
    const {
      trace: { rowId, watched },
      watchTraceMutation
    } = this.props;
    watchTraceMutation({ variables: { id: rowId, watched: !watched } });
  };

  renderWatchIcon = () => {
    const {
      trace: { watched },
      classes
    } = this.props;
    const Icon = watched ? WatchingFill : Watching;
    const className = classnames({
      [classes.watchIcon]: true,
      [classes.unwatched]: !watched
    });
    return <Icon className={className} onClick={this.handleWatchTrace} />;
  };

  render = () => {
    const { classes, trace } = this.props;

    // Links are created by an account.
    // However, its entity can be null if the current user is a collaborator
    // and the entity is not visible to the group.
    // The display falls back to the link group in that case.
    const { avatar } = trace.head.createdByAccount?.entity || trace.head.group;

    return (
      <div className={classes.card}>
        <div className={classes.content}>
          <div className={classes.primary}>
            <div className={classes.zoneA}>{this.renderTitle()}</div>
            <div className={classes.zoneB}>{this.renderWatchIcon()}</div>
          </div>
          <div className={classes.secondary}>
            <div className={classes.zoneC}>
              <div className={classes.events}>
                {trace.head.height.toString().padStart(2, '0')}
              </div>
              <div className={classes.avatar}>
                <img src={avatar} alt="Created by" />
              </div>
            </div>
            <div className={classes.zoneD}>
              <div className={classes.date}>
                {formatDate(trace.head.createdAt)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export const fragments = {
  trace: gql`
    fragment TraceCardFragment on Trace {
      rowId
      name
      watched
      head {
        processState
        height
        createdAt
        linkHash
        createdByAccount {
          rowId
          entity {
            __typename
            ... on Account_User {
              rowId
              avatar
            }
            ... on Account_Bot {
              rowId
              avatar
            }
          }
        }
      }
    }
  `
};

const mutations = {
  watchTrace: gql`
    mutation watchTrace($id: UUID!, $watched: Boolean!) {
      watchTrace(input: { id: $id, watched: $watched }) {
        trace {
          rowId
          watched
        }
      }
    }
  `
};

export default compose(
  graphql(mutations.watchTrace, {
    name: 'watchTraceMutation'
  }),
  injectSheet(styles),
  withApollo
)(TraceCard);
