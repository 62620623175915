import React from 'react';

import { getFilterConfig as getWidgetFilterConfig } from 'components/ui/widget';
import { filterData as doFilterData } from 'utils/filtering';

import TextFilter from './text';
import DateRangeFilter from './dateRange';

// client side table filter

// filters type to component map
const filtersMap = {
  text: TextFilter,
  dateRange: DateRangeFilter
};

// get the relevant filter config to apply
// ie either specified in the column definition or default provide by the cell widget itself
const getFilterConfig = colConfig =>
  colConfig.def.filter || getWidgetFilterConfig(colConfig.def.cell || {});

// get the relevant filter
const getFilter = filterConfig => filtersMap[filterConfig.type] || TextFilter; // default to simple text input filter

/* component interface */
export const getFilterComponent = (colConfig, setColumnFilter) => {
  const filterConfig = getFilterConfig(colConfig);
  const FilterComponent = getFilter(filterConfig);
  return (
    <FilterComponent
      colConfig={colConfig}
      setColumnFilter={setColumnFilter}
      filterConfig={filterConfig}
    />
  );
};

const applyColumnFilter = (dataToDisplay, colConfig) => {
  if (colConfig.filter) {
    // note: we let the filter function do the data.filter() itself for performance reasons as some preprocessing might be required in specific cases
    const filterConfig = getFilterConfig(colConfig);
    return doFilterData(dataToDisplay, filterConfig, colConfig.filter.value);
  }
  return dataToDisplay;
};

export const filterData = (dataArray, displayConfig) => {
  // apply successive fixed column filters
  const dataToDisplay = displayConfig.fixedColumns.reduce(
    applyColumnFilter,
    dataArray
  );
  // apply successive column filters
  return displayConfig.columns.reduce(applyColumnFilter, dataToDisplay);
};
