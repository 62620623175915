export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  kpiTabsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    height: 97
  },
  kpiTabWrapper: {
    display: 'flex',
    width: '100%'
  },
  tabView: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white',
    padding: '8px 14px'
  },
  filtersRow: {
    display: 'flex'
  },
  graphWrapper: {
    display: 'flex'
  }
});
