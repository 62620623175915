import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import theme from 'style';

import moment from 'moment';

const formatDuration = d => moment(d).format('DD/MM');

const format = {
  TODAY: 'TODAY',
  SIX_MONTHS_AGO: '6 MONTHS AGO',
  ONE_YEAR_AGO: '1 YEAR AGO'
};

const textPos = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end'
};

export const CustomTick = ({ index, x, y, payload: { value }, fromNow }) => {
  /**
   * @constant - Render x-axis tick labels
   * - index 0 and 6 respectively corresponds to today and 6 months
   * @returns {string} a date format - DD/MM or an explicit fromNow string
   */
  const label = useMemo(() => {
    if (fromNow) {
      if (index === 0) return format.TODAY;
      if (index === 6) return format.SIX_MONTHS_AGO;
      if (index === 11) return format.ONE_YEAR_AGO;
      return null;
    }
    if (index !== 0 && index !== 6) return null;

    return formatDuration(value);
  }, [index, value]);

  // svg text positioning on x-axis
  const textAnchorPos = useMemo(() => {
    if (index === 0) return textPos.START;
    if (index === 6) return textPos.END;
    if (index === 11) return textPos.END;

    return null;
  }, [index]);

  if (!label) return null;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={8}
        x={0}
        y={0}
        dy={10}
        textAnchor={textAnchorPos}
        fill={theme.grey3}
      >
        {label}
      </text>
    </g>
  );
};

CustomTick.propTypes = {
  index: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string
  }).isRequired,
  fromNow: PropTypes.bool
};

CustomTick.defaultProps = {
  fromNow: false
};

export default memo(CustomTick);
