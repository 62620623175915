import React from 'react';
import gql from 'graphql-tag';
import PropType from 'prop-types';
import { Route } from 'react-router-dom';
import { graphql } from 'react-apollo';

import PasswordProtection from 'components/passwordProtection';
import { UserContext } from 'contexts';
import apm from 'monitoring/apm';

class PrivateRoute extends React.PureComponent {
  static propTypes = {
    component: PropType.elementType.isRequired,
    profileQuery: PropType.object.isRequired
  };

  render() {
    const {
      component: Component,
      profileQuery: { loading, me, accounts },
      ...rest
    } = this.props;

    if (me) {
      apm.setUserContext({
        id: me.id,
        username: me.name,
        email: me.email
      });
    }

    return (
      <UserContext.Provider value={{ loading, me, accounts }}>
        <PasswordProtection>
          <Route {...rest} render={props => <Component {...props} />} />
        </PasswordProtection>
      </UserContext.Provider>
    );
  }
}

const queries = {
  profileQuery: gql`
    query myProfile {
      me {
        id
        rowId
        name
        avatar
        email
        isSuperuser
        account {
          id
          rowId
          signingKey {
            rowId
            publicKey
            privateKey {
              rowId
              passwordProtected
              decrypted
            }
          }
        }
        phone
      }
    }
  `
};

export default graphql(queries.profileQuery, {
  name: 'profileQuery'
})(PrivateRoute);
