export default {
  realtime: {
    type: 'box',
    direction: 'row',
    items: [
      {
        label: 'kpis',
        size: '95px',
        content: {
          type: 'box',
          direction: 'column',
          items: [
            {
              label: 'kpi1',
              size: '1fr',
              content: {
                type: 'card',
                label: 'open traces',
                icon: 'TimeLine',
                widget: {
                  type: 'metric'
                },
                query: 'openTraces'
              }
            },
            {
              label: 'kpi2',
              size: '1fr',
              content: {
                type: 'card',
                label: 'inactive traces',
                icon: 'Moon',
                widget: {
                  type: 'metric'
                },
                query: 'inactiveTraces'
              }
            },
            {
              label: 'kpi3',
              size: '1fr',
              content: {
                type: 'card',
                label: 'avg. time between 2 actions',
                icon: 'ClockGeneral',
                widget: {
                  type: 'metric'
                },
                query: 'avgTimeBetweenActions',
                time: true
              }
            }
          ]
        }
      },
      {
        label: 'graphs',
        size: '100%',
        content: {
          type: 'box',
          direction: 'column',
          height: '100%',
          items: [
            {
              label: 'graph1',
              size: '1fr',
              content: {
                type: 'graph',
                label: 'last main action on open traces',
                description: 'Number of traces by the last action performed.',
                widget: {
                  type: 'bar'
                },
                query: 'lastActionOnOpenTraces'
              }
            },
            {
              size: '1fr',
              content: {
                type: 'box',
                direction: 'row',
                items: [
                  {
                    label: 'graph2',
                    size: '1fr',
                    content: {
                      type: 'graph',
                      label: 'top 5 groups in terms of traces contribution',
                      description:
                        'Number of traces in which the group is involved.',
                      widget: {
                        type: 'progress'
                      },
                      query: 'top5GroupContributorsByTraces'
                    }
                  },
                  {
                    label: 'graph3',
                    size: '1fr',
                    content: {
                      type: 'graph',
                      label: 'distribution by creation date',
                      description: 'Number of traces per month.',
                      widget: {
                        type: 'column'
                      },
                      query: 'traceCountsByCreationDate',
                      style: 'style2'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  }
};
