export default theme => ({
  reference: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.grey2,
    '& > a': {
      color: theme.indigo3,
      textDecoration: 'none'
    }
  }
});
