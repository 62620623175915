export default theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr) 380px',
    gridGap: '10px',
    gridTemplateRows: 'repeat(9, 10.2%)',
    '& > div': {
      boxShadow: '0px 1px 15px #E5E3EBB3',
      borderRadius: '5px'
    },
    '& h1': {
      fontSize: 20,
      fontWeight: 700,
      color: theme.grey1,
      padding: 30,
      height: '30%',
      '&::after': {
        content: '""',
        position: 'relative',
        display: 'block',
        height: 7,
        width: 30,
        top: 20,
        backgroundColor: theme.teal1,
        borderRadius: '2px'
      }
    }
  },
  chart: {
    position: 'relative',
    height: '70%',
    border: `30px solid ${theme.white1}`,
    borderTop: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.white1
  },
  stepGA: {
    gridColumn: '1 / 6',
    gridRow: '1 / 6'
  },
  generalDataGA: {
    gridColumn: '6 / 10',
    gridRow: '1 / 2',
    backgroundColor: theme.indigo1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: 90
  },
  generalDataDivGA: {
    minWidth: '33%',
    padding: '0 20px',
    float: 'left',
    color: 'white',
    borderRight: '2px solid #4D32CC',
    '&:last-child': {
      borderRight: 'none'
    },
    '& h2': {
      fontSize: '26px',
      letterSpacing: 1.6,
      fontWeight: 600,
      lineHeight: '2.5vw'
    },
    '& h6': {
      fontSize: '10px',
      letterSpacing: 1.1,
      textTransform: 'uppercase'
    }
  },
  datePickerSpacing: {
    paddingLeft: 85
  },
  datePickerGA: {
    gridColumn: '10 / 11',
    gridRow: '1 / 2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20
  },
  totalInvestigatorGA: {
    gridColumn: '6 / 11',
    gridRow: '2 / 6'
  },
  delaysInvestigatorGA: {
    gridColumn: '1 / 6',
    gridRow: '6 / 10'
  },
  fillingRateInvestigatorGA: {
    gridColumn: '6 / 9',
    gridRow: '6 / 10'
  },
  successfulInvestigatorGA: {
    gridColumn: '9 / 11',
    gridRow: '6 / 10'
  },
  openInvestigationsGA: {
    display: 'grid'
  }
});
