import React, { memo, useMemo } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import moment from 'moment';

import { pluralize } from 'utils';

import { TYPE_BAR, TYPE_COLUMN } from '../../../constant';
import styles from './customTooltip.style';

export const CustomTooltip = ({ classes, title, active, payload, type }) => {
  // bar chart var
  const name = payload && payload[0]?.payload.name;
  const value = payload && payload[0]?.value;

  // column chart var
  const count = payload && payload[0]?.payload.count;
  const day = payload && payload[0]?.payload.day;

  const renderTitle = useMemo(() => {
    let createdAt;

    switch (type) {
      case TYPE_BAR:
        return name;

      case TYPE_COLUMN:
        createdAt = moment.utc(day).local();
        return `${pluralize(count, title, true)} created in ${createdAt.format(
          'MMMM YYYY'
        )}`;

      default:
        return null;
    }
  }, [type, name, count, day]);

  return active ? (
    <div className={classes.root}>
      <h5
        className={classes.title}
        style={{ paddingBottom: type === TYPE_BAR ? 10 : 0 }}
      >
        {renderTitle}
      </h5>
      {type === TYPE_BAR && (
        <p className={classes.description}>{pluralize(value, title, true)}</p>
      )}
    </div>
  ) : null;
};

CustomTooltip.propTypes = {
  classes: PropType.object.isRequired,
  active: PropType.bool.isRequired,
  title: PropType.string.isRequired,
  payload: PropType.array,
  type: PropType.string.isRequired
};

CustomTooltip.defaultProps = {
  payload: null
};

export default injectSheet(styles)(memo(CustomTooltip));
