import React, { useState, useCallback } from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';

import { ROUTE_USER_DASHBOARD } from 'constant/routes';
import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import {
  LayoutKanbanHeader,
  LayoutKanban,
  LayoutKanbanMain
} from '@stratumn/atomic';

import { Analytics, ClockGeneral } from '@stratumn/icons';

import { Header } from 'components/layouts';

import TabMenu from './tabMenu';
import Tab from './tabMenu/tab';
import SubHeader from './subHeader';
import RealTime from './realTime';
import Report from './report';

import { today, oneWeekAgo } from './utils';
import { parseLayout } from './utils/parsing';
import fragments from './fragments';
import defaultConfig from './defaultAnalyticsConfig';

import styles from './analyticsDashboard.style';

/**
 * @constant - tabsInfo is a list of tabs
 * @type {array}
 */
const tabsInfo = [
  {
    subHeaderTitle: 'Real-time analysis',
    label: 'real-time',
    icon: <ClockGeneral />
  },
  {
    subHeaderTitle: 'Report',
    label: 'report',
    icon: <Analytics />,
    dateRange: true
  }
];

export const AnalyticsDashboard = props => {
  // First tab active by default
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: oneWeekAgo(),
    endDate: today()
  });

  const {
    classes,
    userWorkflowsQuery,
    match: { params }
  } = props;

  // TODO: fetch defaultConfig from trace-api
  const { realtime: realtimeConfig } = defaultConfig || {};

  /**
   * We make sure that the reporting dashboard displays the correct workflow
   * as well as the correct url param id (aka the workflow rowId)
   */
  const selectedWorkflow = userWorkflowsQuery?.workflows?.nodes?.find(
    wf => wf.rowId === params.id
  );

  const handleSelectedDates = d => {
    const filterDateRange = {
      startDate: d?.startDate,
      endDate: d?.endDate
    };
    setDateRange(filterDateRange);
  };

  const handleActiveTab = index => {
    setActiveTabIndex(index);
  };

  const renderTabs = useCallback(
    () =>
      tabsInfo.map((tab, index) => (
        <Tab
          key={tab.label + index}
          label={tab.label}
          icon={tab.icon}
          active={activeTabIndex === index}
          onClick={() => handleActiveTab(index)}
        />
      )),
    [activeTabIndex]
  );

  if (!props.userWorkflowsQuery.workflows) return null;

  const { loading, workflows } = userWorkflowsQuery;

  const configHeader = {
    loading,
    fullLogo: true,
    topLevel: {
      title: {
        label: (
          <div className={classes.betaLogoWrapper}>
            <span className={classes.betaLogoLabel}>version 2</span>
          </div>
        )
      },
      links: [
        {
          label: 'analytics',
          active: true
        },
        {
          label: 'dashboard',
          path: ROUTE_USER_DASHBOARD
        }
      ]
    }
  };

  const { subHeaderTitle, dateRange: withDateRange } = tabsInfo[activeTabIndex];

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <LayoutKanban>
        <LayoutKanbanHeader>
          <Header config={configHeader} />
        </LayoutKanbanHeader>

        <LayoutKanbanMain greyTheme loading={loading}>
          {!loading && (
            <>
              <TabMenu activeTabIndex={activeTabIndex}>{renderTabs()}</TabMenu>
              <div className={classes.body}>
                <SubHeader
                  activeTabIndex={activeTabIndex}
                  subHeaderTitle={subHeaderTitle}
                  selectedWorkflow={selectedWorkflow}
                  userWorkflows={workflows.nodes}
                  onSelectDates={handleSelectedDates}
                  withDateRange={withDateRange}
                  lastSelectedDates={dateRange}
                />

                <div className={classes.pageWrapper}>
                  {activeTabIndex === 0 ? (
                    <RealTime
                      config={parseLayout(realtimeConfig)}
                      selectedWorkflow={selectedWorkflow}
                      selectedGroups={selectedGroups}
                      setSelectedGroups={setSelectedGroups}
                    />
                  ) : (
                    <Report workflowRowId={params.id} dateRange={dateRange} />
                  )}
                </div>
              </div>
            </>
          )}
        </LayoutKanbanMain>
      </LayoutKanban>
    </>
  );
};

AnalyticsDashboard.propTypes = {
  classes: PropType.object.isRequired,
  userWorkflowsQuery: PropType.object.isRequired,
  match: PropType.object.isRequired
};

export const USER_WORKFLOWS_QUERY = gql`
  query userWorkflowsQuery {
    workflows {
      ...UserWorkflowsQueryFragment
    }
  }
  ${fragments.userWorkflows}
`;

export default compose(
  graphql(USER_WORKFLOWS_QUERY, {
    name: 'userWorkflowsQuery'
  }),
  withRouter,
  injectSheet(styles)
)(AnalyticsDashboard);
