import React, { useCallback, useRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import moment from 'moment';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';
import { CircleInfo as IconCircleInfo, DownArrowFill } from '@stratumn/icons';

import InfoTooltip from 'components/ui/utils/infoTooltip';

import theme from 'style';

import { useTooltip } from 'utils/hooks';
import { TOOLTIP_POSITION } from '../constant';

import KpiCardSkeleton from './kpiCardSkeleton';
import CardLayout from '../layout';
import IconKpi from '../iconKpi';

import styles from './kpiCard.style';

// The edge case is when no traces are created yet
// 0 serves as a placeholder
const formatDuration = d =>
  d === 0 ? 0 : moment.duration(d, 'seconds').humanize();

export const KpiCard = ({
  classes,
  title,
  dynamic,
  iconSmall,
  iconLarge,
  value,
  tooltip,
  time,
  fluctuation,
  light,
  bgWhite,
  loading
}) => {
  // Custom hook which takes care of tooltip's mouse events
  const [onMouseEnter, onMouseLeave, showTooltip] = useTooltip(tooltip);

  const kpiCardRef = useRef(null);

  /**
   * Displays the fluctuation number with either an:
   * - upward green arrow or
   * - downward red arrow
   */
  const renderFluctuationNumber = useCallback(
    num => (
      <div className={classes.fluctuationWrapper}>
        {num >= 0 ? (
          <div className={classes.fluctuation} data-is-upward>
            <div className={classes.arrowSvg}>
              <DownArrowFill data-is-upward />
            </div>

            <span>+{num.toFixed(2)}%</span>
          </div>
        ) : (
          <div className={classes.fluctuation}>
            <div className={classes.arrowSvg}>
              <DownArrowFill />
            </div>
            <span>{num.toFixed(2)}%</span>
          </div>
        )}
      </div>
    ),
    [fluctuation]
  );

  /**
   * Displays either singular or plural hours abbrev
   */
  const renderTime = useCallback(
    num => (
      <span className={classes.fluctuationWrapper}>{formatDuration(num)}</span>
    ),
    [value]
  );

  /**
   * Displays either a value conversion in hours or a standard value
   */
  const renderValue = useMemo(
    () => (time ? String((value / 3600).toFixed()) : String(value)),
    [value]
  );

  const rootProps = {
    className: classes.root,
    'data-is-dynamic': dynamic,
    'data-is-light': light,
    'data-cy': 'kpi-card',
    onClick: () => dynamic && null
  };

  if (loading) return <KpiCardSkeleton icon={!!iconLarge} />;

  return (
    <>
      <CardLayout hoverable={dynamic} bgWhite={bgWhite}>
        <div {...rootProps}>
          {iconLarge && <IconKpi name={iconLarge} />}
          <div className={classes.details} data-large-icon={!!iconLarge}>
            <div className={classes.titleWrapper}>
              <h5 className={classes.title}>{title}</h5>
              {tooltip && (
                <div
                  data-cy="kpi-card-tooltip"
                  className={classes.tooltipWrapper}
                  ref={kpiCardRef}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <IconCircleInfo />
                </div>
              )}
            </div>
            <div
              className={classes.valueWrapper}
              data-cy="kpi-card-value-wrapper"
            >
              <p className={classes.value}>
                {time ? renderTime(value) : renderValue}
              </p>

              {fluctuation && renderFluctuationNumber(fluctuation)}
            </div>
          </div>
          {iconSmall && (
            <DynamicIcon icon={iconSmall} data-is-dynamic={dynamic} />
          )}
        </div>
      </CardLayout>
      {showTooltip && (
        <InfoTooltip
          clientRef={kpiCardRef}
          text={tooltip}
          textColor={theme.white1}
          backgroundColor={theme.grey2}
          position={TOOLTIP_POSITION}
          delay={200}
        />
      )}
    </>
  );
};

KpiCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  value: PropTypes.number,
  dynamic: PropTypes.bool,
  iconSmall: PropTypes.string,
  iconLarge: PropTypes.string,
  tooltip: PropTypes.string,
  time: PropTypes.bool,
  fluctuation: PropTypes.number,
  light: PropTypes.bool,
  bgWhite: PropTypes.bool,
  loading: PropTypes.bool
};

KpiCard.defaultProps = {
  title: '',
  value: null,
  dynamic: false,
  iconSmall: '',
  iconLarge: '',
  tooltip: '',
  time: false,
  fluctuation: null,
  light: false,
  bgWhite: true,
  loading: false
};

export default injectSheet(styles)(memo(KpiCard));
