import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import shortid from 'shortid';

import {
  AreaChart as AreaRecharts,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import { CustomTooltip, Placeholder } from '../ui';

import { mainStyles, rechartsStyles } from './area.style';

export const AreaChart = ({ classes, dataset, loading }) => {
  // TODO: create an AreaChart skeleton
  if (loading)
    return (
      <div className={classes.spinner}>
        <span>...loading</span>
        <TraceIconSpinner />
      </div>
    );
  if (dataset.length === 0) return <Placeholder />;

  return (
    <ResponsiveContainer className={classes.root} minHeight={150}>
      <AreaRecharts
        data={dataset}
        syncId={shortid.generate()}
        {...rechartsStyles.root}
      >
        <CartesianGrid {...rechartsStyles.cartesianGrid} />
        <XAxis dataKey="x" {...rechartsStyles.xAxis} />
        <YAxis {...rechartsStyles.yAxis} />
        {rechartsStyles.tooltip && (
          <Tooltip
            content={<CustomTooltip {...rechartsStyles.tooltip} />}
            cursor={rechartsStyles.tooltip.cursor}
          />
        )}
        <Area dataKey="y" {...rechartsStyles.area} />
      </AreaRecharts>
    </ResponsiveContainer>
  );
};

AreaChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.date,
      y: PropTypes.number
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectSheet(mainStyles)(memo(AreaChart));
