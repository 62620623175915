import React, { useState, useCallback } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import { withSnackbarsContext } from '@stratumn/atomic';
import { Download } from '@stratumn/icons';
import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import { getFileExt, getFileIconStr } from 'utils';
import { downloadFile } from 'utils/downloadFile';
import LoadingIndicator from 'components/ui/LoadingIndicator';

import styles from './file.style';

export const File = ({ classes, file, errorSnackbar }) => {
  const [hovered, setHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadResponse, setDownloadResponse] = useState(null);

  const handleMouseEnter = useCallback(() => setHovered(true), [hovered]);
  const handleMouseLeave = useCallback(() => setHovered(false), [hovered]);

  const renderFileInfo = useCallback(
    () => (
      <div className={classes.fileInfo} aria-hidden={!hovered}>
        <div className={classes.fileName}>{file.name}</div>
        {filesize(file.size).split(' ').join('')}
      </div>
    ),
    []
  );

  const renderDownloadFile = () => (
    <div
      data-cy="download-file"
      className={classes.label}
      onClick={() =>
        downloadFile(
          file,
          errorSnackbar,
          setIsLoading,
          isLoading,
          setDownloadResponse
        )
      }
    >
      {hovered ? (
        <div className={classes.labelActive}>
          <Download className={classes.labelIcon} /> Download
        </div>
      ) : (
        file.name
      )}
    </div>
  );

  return (
    <LoadingIndicator
      spinning={isLoading}
      showSuccess={downloadResponse !== null}
    >
      <div
        data-cy="file-wrapper"
        className={classes.root}
        title={file.name}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={classes.attachment}>
          <div className={classes.attachmentContainer}>
            <DynamicIcon
              icon={getFileIconStr(file.mimetype, file.name)}
              className={classes.icon}
            />
          </div>
          {renderFileInfo()}
        </div>
        <div className={classes.footer}>
          {renderDownloadFile()}
          <div className={classes.fileExt}>{getFileExt(file.name)}</div>
        </div>
      </div>
    </LoadingIndicator>
  );
};

File.propTypes = {
  classes: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  errorSnackbar: PropTypes.func.isRequired
};

export default injectSheet(styles)(withSnackbarsContext(React.memo(File)));
