export default {
  loadingGif: {
    display: 'block',
    width: 21,
    height: 21,
    margin: 'auto',
    marginTop: 21,
    marginBottom: 28,
    opacity: 0.5
  }
};
