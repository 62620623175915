import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';

import { ExpandLane, CollapseLane } from '@stratumn/icons';
import TraceCard, {
  fragments as traceCardFragments
} from 'components/traceCard';
import { renderStageIcon } from 'utils/stage';

import styles from './column.style';

export class Column extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    stage: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.getTracesCount() === 0,
      forceCollapsedState: false
    };
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.forceCollapsedState) return;

    const newCollapsedState = this.getTracesCount(nextProps) === 0;
    if (newCollapsedState !== this.state.collapsed) {
      this.setState({ collapsed: newCollapsedState });
    }
  }

  toggleCollapsed = () =>
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
      forceCollapsedState: true
    }));

  getTracesCount = (props = null) => {
    const { stage } = props || this.props;
    return stage.traces.totalCount || stage.traces.nodes.length;
  };

  renderHeader = () => {
    const { stage, classes } = this.props;
    const { collapsed } = this.state;
    const traceCount = this.getTracesCount();

    if (collapsed) {
      return (
        <div className={classes.collapsed}>
          <button
            type="button"
            className={classes.collapsedAction}
            onClick={this.toggleCollapsed}
          >
            <ExpandLane />
          </button>
          <div className={classes.collapsedTitle}>
            {stage.label} <em>({traceCount})</em>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.header}>
        <div className={classes.headerIcon}>{renderStageIcon(stage)}</div>
        <div className={classes.headerTitle}>
          <div className={classes.headerTitleTruncate}>
            {stage.label} <em>({traceCount})</em>
          </div>
        </div>
        <button
          type="button"
          className={classes.headerAction}
          onClick={this.toggleCollapsed}
        >
          <CollapseLane />
        </button>
      </div>
    );
  };

  renderBody = () => {
    const { classes, group, stage } = this.props;
    const { collapsed } = this.state;

    if (collapsed) {
      return <div className={classes.collapsedFooter} />;
    }

    return (
      <>
        {!!stage.traces.nodes.length && (
          <div className={classes.body}>
            <div className={classes.traces}>
              <div className={classes.traceList}>
                {stage.traces.nodes.map(trace => (
                  <div className={classes.traceListItem} key={trace.rowId}>
                    <TraceCard trace={trace} groupId={group.rowId} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className={classes.footer} />
      </>
    );
  };

  render = () => {
    const { classes, stage } = this.props;
    const { collapsed } = this.state;

    return (
      <div
        className={classes.root}
        aria-expanded={!collapsed}
        key={stage.rowId}
      >
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  };
}

export const fragments = {
  stage: gql`
    fragment StageColumnFragment on Stage {
      rowId
      label
      type
      action {
        key
        icon
      }
      traces(
        filter: {
          watched: { equalTo: $watched }
          or: [
            { rowId: { includesInsensitive: $search } }
            { tags: { contains: [$search] } }
          ]
        }
      ) {
        nodes {
          ...TraceCardFragment
        }
        totalCount
      }
    }
    ${traceCardFragments.trace}
  `
};

export default injectSheet(styles)(Column);
