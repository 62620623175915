import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import styles from './errorMessage.style';

export const NewLinkErrorMessage = ({ classes, message, status, isUpdate }) => {
  const isUserError = String(status)[0] === '4';
  return isUserError ? (
    `The traces could not be ${
      isUpdate ? 'updated' : 'initialized'
    }: ${message}`
  ) : (
    <>
      Something went wrong during{' '}
      {isUpdate ? 'the traces update' : 'the traces initialization'} !
      <samp className={classes.errorMessageTechnicalDetail}>
        {status}: {message}
      </samp>
    </>
  );
};

NewLinkErrorMessage.propTypes = {
  classes: PropType.object.isRequired,
  message: PropType.string.isRequired,
  status: PropType.number.isRequired,
  isUpdate: PropType.bool.isRequired
};

export default compose(injectSheet(styles))(NewLinkErrorMessage);
