import React, { memo, useEffect, useState } from 'react';
import injectSheet from 'react-jss';
import PropType from 'prop-types';
import classNames from 'classnames';

import moment from 'moment';

import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import styles from './customTick.style';

const ICON_SIZE = 22;
const FONT_SIZE = 10;
const formatDuration = d => moment.duration(d, 'seconds').humanize();

export const CustomTick = ({
  classes,
  payload: { coordinate, offset, value },
  data,
  index,
  width
}) => {
  const [truncate, setTruncate] = useState(0);

  const tickDetails = data.filter(entry => entry.action === value);

  const icon = tickDetails[0]?.icon;
  const averageTime = tickDetails[0]?.averageTime;

  /**
   * In order to truncate the custom tick if the strings are too long,
   * we need to gather a few measurements after the first render such as:
   * - the width of the full tick
   * - the width of the text on the right hand side of the icon (the one being truncated)
   */
  useEffect(() => {
    const tickWidth = document.getElementsByClassName('tick')[index].getBBox()
      .width;

    const tickTextRightWidth = document
      .getElementsByClassName('tick-text-right')
      [index].getComputedTextLength();

    /**
     * If the width of the tick is greater than the allowed fixed width, then truncate
     * This has one pitfall; it does not take into account different strings' sizes
     * (e.g: 'm' will be larger than 'i').
     * This will truncate the word before reaching its width limit.
     */
    if (tickWidth >= width) {
      setTruncate(
        Math.ceil((tickTextRightWidth - tickWidth - width) / FONT_SIZE + 2) +
          FONT_SIZE / 2
      );
    } else {
      setTruncate(0);
    }
  }, [value, width, index]);

  /**
   * If we need to truncate, we
   * - add an accessibility title attribute
   * - slice the string
   * - add three dots
   */
  const renderValue = () => {
    if (truncate === 0) return value;

    return (
      <>
        <title>{value}</title>
        {value.slice(0, -truncate)}...
      </>
    );
  };

  return (
    <g className="tick" transform={`translate(${offset},${coordinate})`}>
      <text className={classes.averageTime} x={30}>
        {formatDuration(averageTime)} ago
      </text>

      <g transform={`translate(${33},${-FONT_SIZE})`}>
        <svg viewBox="0 0 32 32" width={ICON_SIZE} height={ICON_SIZE}>
          <DynamicIcon className={classes.icon} icon={icon} title={icon} />
        </svg>
      </g>

      <text className={classNames([classes.value, 'tick-text-right'])} x={60}>
        {renderValue()}
      </text>
    </g>
  );
};

CustomTick.propTypes = {
  classes: PropType.object.isRequired,
  payload: PropType.object.isRequired,
  data: PropType.arrayOf(
    PropType.shape({
      icon: PropType.string,
      action: PropType.string
    })
  ).isRequired,
  index: PropType.number.isRequired,
  width: PropType.number.isRequired
};

export default injectSheet(styles)(memo(CustomTick));
