import React from 'react';
import PropType from 'prop-types';

import { Icon, icons } from '@stratumn/atomic';
import OldDynamicIcon from '@stratumn/icons/lib/DynamicIcon';

export const DynamicIcon = ({ icon, size }) => {
  if (icons[icon]) {
    return <Icon name={icon} size={size} />;
  }

  return <OldDynamicIcon icon={icon} style={{ width: size, height: size }} />;
};

DynamicIcon.propTypes = {
  icon: PropType.string,
  size: PropType.number
};

DynamicIcon.defaultProps = {
  icon: 'Doc',
  size: 35
};

export default React.memo(DynamicIcon);
