import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import qs from 'query-string';
import Path from 'path-to-regexp';
import to from 'await-to-js';
import { fromLinkObject } from '@stratumn/js-chainscript';
import {
  withLeavingAlertContext,
  withLeavingAlertProvider
} from 'components/beforeLeavingAlert';

import { traceClient } from 'gql';
import { deepGet } from 'utils';
import { TRAY_PORTAL_RIGHT, TOOLTIP_PORTAL } from 'constant/htmlIds';
import { useFunctionAsState } from 'utils/hooks';
import { Header } from 'components/layouts';
import SignLinkDialog from 'components/signLinkDialog';

import { withSnackbarsContext, NotFound } from '@stratumn/atomic';
import { withUser } from 'contexts';

import {
  ROUTE_WORKFLOW_DASHBOARD,
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_USER_DASHBOARD,
  ROUTE_INSPECT_TRACE
} from 'constant/routes';

import envVars from 'constant/env';

import { buildWorkflowContext } from 'utils/workflowContext';

import TraceIconSpinner from 'components/ui/traceIconSpinner';

import apm from 'monitoring/apm';
import { TransactionType } from 'monitoring/transaction';

import fragments from './fragments';

import DataTool from './dataTool';
import Batch from './batch';
import Form from './form';
import Signature from './signatureTool';
import Caviardage from './caviardageTool';
import ErrorMessage from './errorMessage';

import styles from './newLink.style';

export const NewLink = React.memo(props => {
  const {
    classes,
    match,
    history,
    location,
    actionQuery,
    successSnackbar,
    errorSnackbar,
    createLinksMutation,
    setAskBeforeLeaving,
    setLeavingAlertModalConfig,
    user
  } = props;

  const { workflowByRowId: workflow = {}, loading } = actionQuery;
  const { rowId: workflowId, rawActionByKey, actionByKey } = workflow;
  const action = !rawActionByKey ? null : { ...rawActionByKey, ...actionByKey };

  const [signDialogOpen, setSignDialogOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [mutationProgress, setMutationProgress] = useState(false);
  const [cleanupFn, setCleanupFn] = useFunctionAsState(null);

  // Set document title and BeforeLeaving Alert content based on workflow data
  useEffect(() => {
    const {
      name: workflowName,
      rawActionByKey: { title: actionTitle } = {},
      traces: { nodes: traces = [] } = {}
    } = workflow;

    if (actionTitle) {
      const nbTraces = traces.length;
      let traceName = null;
      if (nbTraces > 0) {
        traceName = 'Batch update';
        if (nbTraces === 1) {
          traceName = traces[0].name;
        }
      }
      document.title = `${actionTitle}${
        traceName ? ` - ${traceName}` : ''
      } - ${workflowName} - Trace`;

      setLeavingAlertModalConfig({
        title: `Quit **${actionTitle}** action`,
        content: `You're about to quit **${actionTitle}** action without saving your changes.<br>All unsaved changes could be lost.<br>Are you sure?`,
        confirmBtnText: `QUIT ACTION`
      });
    }
  }, [workflow]);

  const toggleSignDialog = useCallback(
    () => setSignDialogOpen(!signDialogOpen),
    [signDialogOpen, setSignDialogOpen]
  );

  const goToHome = () => history.push(ROUTE_WORKFLOW_DASHBOARD);

  const goToWorkflowOverview = () => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  };

  const goBack = links => {
    if (location.state && location.state.from) {
      if (location.state.goToTraceInspector && links.length === 1) {
        const { mapId } = links[0].link.meta;
        history.push(Path.compile(ROUTE_INSPECT_TRACE)({ id: mapId }));
      } else {
        history.push(location.state.from);
      }
      return;
    }

    // by default go back to workflow overview
    goToWorkflowOverview();
  };

  // build the workflow context passed to each interface
  const workflowContext = useMemo(() => {
    const { groupKey: authorGroupLabel } = qs.parse(location.search);
    return buildWorkflowContext(workflow, authorGroupLabel);
  }, [workflow, location]);

  // tracesList is a list of { traceId, formData }
  const sendLinksRequest = async (signingKey, tracesList) => {
    // 1 - init links for all selected traces
    // add the index as a 'cacheBuster' field to the variables so that Apollo
    // never sees the initAttestationLink queries as duplicates
    // even when all required variables are the same
    const links = await Promise.all(
      tracesList.map(async ({ traceId, formData: traceFormData }, idx) => {
        const {
          data: { initAttestationLink }
        } = await traceClient.query({
          query: INIT_ATTESTATION_QUERY,
          variables: {
            traceId,
            formData: traceFormData,
            actionKey: action.key,
            groupId: workflowContext.author.rowId,
            cacheBuster: idx
          },
          fetchPolicy: 'no-cache'
        });
        const newLink = fromLinkObject(initAttestationLink.link);
        newLink.sign(signingKey, '[version,data,meta]');
        return { ...newLink, data: traceFormData };
      })
    );

    // 2 - send them in a batch
    await createLinksMutation({
      variables: { input: links }
    });

    return links;
  };

  const createLinks = async (signingKey, formDataTmp = formData) => {
    const tx = apm.startTransaction('newLinks', TransactionType.action);

    const { traceIds } = qs.parse(location.search);
    let tracesList;
    if (traceIds) {
      // if traceIds is not null this is an action to update existing traces
      // check whether this is an actual batch by checking the number of ','-separated trace ids
      tracesList = traceIds
        .split(',')
        .map(traceId => ({ traceId, formData: formDataTmp }));
    } else {
      // no traceIds provided so this is a trace creation
      // action being batchable is driven by its config
      // and if action is batched the formData is actually a list
      tracesList = action.canBatch
        ? formDataTmp.map(({ data }) => ({ formData: data }))
        : [{ formData: formDataTmp }]; // otherwise formData is the actual form data to submit
    }
    setMutationProgress(true);

    apm.addTags({ actionKey: action.key });

    const [err, links] = await to(sendLinksRequest(signingKey, tracesList));

    // catch potential errors of the sendLinksRequest function
    if (err) {
      console.error(err);

      const {
        message: errorMessage,
        status: errorStatus
      } = err.networkError.result[0].errors[0];

      setMutationProgress(false);

      errorSnackbar(
        <ErrorMessage
          message={errorMessage}
          status={errorStatus}
          isUpdate={!!traceIds}
        />
      );
      toggleSignDialog();
      return;
    }

    // if a cleanup function has been set by the action interface provider
    // (eg data tool cleanu local storage if successful)
    // call it now
    if (cleanupFn) cleanupFn();

    // Since the new link was sent, we disable the "Before leaving alert" so it won't ask user confirmation (before changing the route)
    setAskBeforeLeaving(false);

    goBack(links);
    successSnackbar(
      traceIds
        ? 'All the traces were successfully updated'
        : 'The traces were successfully created'
    );

    if (tx) tx.end();
  };

  const header = useMemo(() => {
    if (!workflow) return <Header config={{ fullLogo: true }} />;
    if (!action) return <Header config={{ fullLogo: true }} />;

    /**
     * Batch update logic for Header Configuration:
     *
     * If we have more than one trace, we don't want to
     * display, in the left sub-header breadcrumbs,
     * the first trace.name.
     */

    const batchUpdate = workflow.traces.nodes.length > 1;
    const batchUpdateLink = {
      label: 'Batch update'
    };
    const firstBreadcrumbLink = {
      label: deepGet(workflow, 'traces.nodes[0].name', null),
      path: deepGet(workflow, 'traces.nodes[0].rowId')
        ? `/trace/${workflow.traces.nodes[0].rowId}`
        : null
    };

    const rawActionByKeyLabel = {
      label: deepGet(workflow, 'rawActionByKey.title', null)
    };

    const groupNameLabel = {
      label: deepGet(workflowContext, 'author.name', null)
    };

    const bottomLevelLinks = [
      batchUpdate ? batchUpdateLink : firstBreadcrumbLink,
      rawActionByKeyLabel
    ];

    if (!batchUpdate) bottomLevelLinks.push(groupNameLabel);

    const configHeader = {
      fullLogo: false,
      loading,
      environment: envVars.REACT_APP_ENVIRONMENT,
      topLevel: {
        title: {
          label: workflow.name,
          path: Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: workflow.rowId })
        },
        links: [
          {
            label: 'dashboard',
            path: ROUTE_USER_DASHBOARD
          }
        ]
      },
      bottomLevel: {
        workflowPage: true,
        infoContext: { links: bottomLevelLinks }
      }
    };

    return <Header config={configHeader} />;
  }, [workflow, loading]);

  const body = useMemo(() => {
    if (loading) return <TraceIconSpinner />;
    if (!workflow) return <NotFound onHomeClick={goToHome} />;
    if (!action || !workflowContext.author)
      return <NotFound onHomeClick={goToWorkflowOverview} />;

    // resolve the type of interface provided
    const isDataTool = !!(action.dataImporter || action.dataEditor);
    const { traces: { nodes: traces = [] } = {} } = workflow;
    const isBatchCreation = !traces.length && action.canBatch;
    const isSignature = !!action.eSignature;
    const isCaviardage = !!action.caviardage;

    switch (true) {
      case isSignature:
        return (
          <Signature
            action={action}
            email={user?.me?.email}
            user={user}
            traces={traces}
            workflowContext={workflowContext}
            createLinks={createLinks}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
      case isDataTool:
        // either data editor or data importer interface
        return (
          <DataTool
            action={action}
            workflowContext={workflowContext}
            traces={workflow.traces.nodes}
            toggleSignDialog={toggleSignDialog}
            disabled={mutationProgress}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
      case isBatchCreation:
        // batched traces creation
        return (
          <Batch
            action={action}
            workflowContext={workflowContext}
            toggleSignDialog={toggleSignDialog}
            disabled={mutationProgress}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
      case isCaviardage:
        return (
          <Caviardage
            action={action}
            email={user?.me?.email}
            user={user}
            traces={traces}
            workflowContext={workflowContext}
            createLinks={createLinks}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );

      default:
        // single form (potentially batched updates)
        return (
          <Form
            action={action}
            workflowContext={workflowContext}
            traces={workflow.traces.nodes}
            disabled={mutationProgress}
            toggleSignDialog={toggleSignDialog}
            formData={formData}
            setFormData={setFormData}
            setCleanupFn={setCleanupFn}
          />
        );
    }
  }, [
    actionQuery,
    formData,
    toggleSignDialog,
    mutationProgress,
    workflowContext
  ]);

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <div id={TRAY_PORTAL_RIGHT} className={classes.traysContainer} />
      {header}
      {body}
      {!action?.eSignature && (
        <SignLinkDialog
          open={signDialogOpen}
          disabled={mutationProgress}
          onClose={toggleSignDialog}
          groupId={workflowContext.author.rowId}
          workflowId={workflowId}
          title="Digital signature"
          description="Sign this action with your private signing key."
          onSendLink={createLinks} // note: clear local storage if successful ?
        />
      )}
    </>
  );
});

NewLink.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  actionQuery: PropType.object.isRequired,
  user: PropType.object.isRequired,
  successSnackbar: PropType.func.isRequired,
  errorSnackbar: PropType.func.isRequired,
  createLinksMutation: PropType.func.isRequired,
  setAskBeforeLeaving: PropType.func.isRequired,
  setLeavingAlertModalConfig: PropType.func.isRequired
};

const INIT_ATTESTATION_QUERY = gql`
  query newLinkInitQuery(
    $traceId: UUID
    $actionKey: String
    $groupId: BigInt!
    $formData: JSON
  ) {
    initAttestationLink(
      traceId: $traceId
      action: $actionKey
      groupId: $groupId
      formData: $formData
      type: FREE
      hashed: true
    )
  }
`;
const ACTION_QUERY = gql`
  query actionQuery(
    $actionKey: String!
    $workflowId: BigInt!
    $traceIds: [String!]
  ) {
    workflowByRowId(rowId: $workflowId) {
      ...NewLinkFragment
    }
  }
  ${fragments.actionQuery}
`;

const CREATE_LINKS_MUTATION = gql`
  mutation createLinks($input: [CreateLinkInput!]) {
    createLinks(input: $input) {
      links {
        id
        linkHash
      }
    }
  }
`;

export default compose(
  injectSheet(styles),
  graphql(ACTION_QUERY, {
    name: 'actionQuery',
    options: ({ match, location }) => ({
      variables: {
        actionKey: qs.parse(location.search).actionKey,
        workflowId: match.params.wfid,
        traceIds: (qs.parse(location.search).traceIds || '').split(',') || []
      },
      fetchPolicy: 'cache-and-network'
    })
  }),
  graphql(CREATE_LINKS_MUTATION, {
    name: 'createLinksMutation'
  }),
  withUser,
  withRouter,
  withSnackbarsContext,
  withLeavingAlertProvider,
  withLeavingAlertContext
)(NewLink);
