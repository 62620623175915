import React, { memo, useMemo } from 'react';
import PropType from 'prop-types';
import theme from 'style';

// Represents the labels for the 12 ticks displayed on the x-axis
const timeMapping = [
  'Today',
  '',
  '',
  '',
  '',
  '',
  '6 months ago',
  '',
  '',
  '',
  '',
  '1 year ago'
];

export const CustomTick = props => {
  const { index, x, y } = props;

  // x-axis tick labels;
  const label = timeMapping[index];

  // svg text positioning on x-axis
  const textAnchorPos = useMemo(() => {
    switch (index) {
      case 0:
        return 'middle';
      case 6:
        return 'end';
      case 11:
        return 'middle';

      default:
        return 'start';
    }
  }, [index]);

  if (!label) return null;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={10}
        x={0}
        y={0}
        dy={10}
        textAnchor={textAnchorPos}
        fill={theme.grey2}
      >
        {label}
      </text>
    </g>
  );
};

CustomTick.propTypes = {
  index: PropType.number.isRequired,
  x: PropType.number.isRequired,
  y: PropType.number.isRequired
};

export default memo(CustomTick);
