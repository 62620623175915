/**
 * In order to hide the tick lines we don't want to display, we need to overide recharts css.
 */
export default () => ({
  root: {
    // hides all the ticks line except the 6 months one
    '& .recharts-cartesian-grid-vertical line:not(:nth-child(6))': {
      display: 'none'
    },
    // moves the tick line slightly to place it on the right hand side of the 6 months bar
    '& .recharts-cartesian-grid-vertical line:nth-child(6)': {
      transform: 'translate(16px, 0)'
    },
    // hides the ticks below the x-axis
    '& .recharts-cartesian-axis-tick-line': {
      display: 'none'
    },
    // so the start and end ticks don't get cut off.
    '& .recharts-surface': {
      overflow: 'unset'
    }
  }
});
