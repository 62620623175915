import apm from './apm';

export const SpanType = {
  crypto: 'app.crypto',
  mutation: 'app.mutation',
  outgoingRequest: 'app.request.outgoing'
};

export const TransactionType = {
  action: 'app.action', // explicit user action
  auto: 'app.auto' // automatically triggered action
};

/**
 * Wrap an existing function inside a span.
 * @param {string} name of the span.
 * @param {string} spanType type of span.
 * @param {function} fn synchronous function to invoke.
 */
export const withSpan = (name, spanType, fn) => {
  const span = apm.startSpan(name, spanType);

  try {
    return fn();
  } finally {
    if (span) span.end();
  }
};

/**
 * Wrap an existing asynchronous function inside a span.
 * @param {string} name of the span.
 * @param {string} spanType type of span.
 * @param {function} fn asynchronous function to invoke.
 */
export const withSpanAsync = async (name, spanType, fn) => {
  const span = apm.startSpan(name, spanType);

  try {
    const res = await fn();
    return res;
  } finally {
    if (span) span.end();
  }
};

/**
 * Wrap an existing function inside a transaction.
 * @param {string} name of the transaction.
 * @param {string} txType type of transaction.
 * @param {function} fn synchronous function to invoke.
 */
export const withTransaction = (name, txType, fn) => {
  const tx = apm.startTransaction(name, txType);

  try {
    return fn();
  } finally {
    if (tx) tx.end();
  }
};

/**
 * Wrap an existing asynchronous function inside a transaction.
 * @param {string} name of the transaction.
 * @param {string} txType type of transaction.
 * @param {function} fn asynchronous function to invoke.
 */
export const withTransactionAsync = async (name, txType, fn) => {
  const tx = apm.startTransaction(name, txType);

  try {
    const res = await fn();
    return res;
  } finally {
    if (tx) tx.end();
  }
};
