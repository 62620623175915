export default theme => ({
  skeletonCardRoot: {
    backgroundColor: theme.white1,
    borderRadius: 15,
    position: 'relative',
    padding: 5,
    width: '100%',
    height: '100%'
  },
  skeletonCardGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& section': {
      padding: 10
    }
  },
  skeletonCardFour: {
    extend: 'skeletonCardRoot',
    '& section:nth-of-type(1)': {
      justifyContent: 'center',
      alignItems: 'flex-end'
    },
    '& section:nth-of-type(2)': {
      justifyContent: 'space-around',
      paddingTop: 5
    }
  }
});
