import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './bar.style';

// eslint-disable-next-line no-unused-vars
export const ProgressBar = ({ classes, percent, largest }) => (
  <div className={classes.root}>
    <div className={classes.fill} data-is-largest={largest} />
  </div>
);

export default injectSheet(styles)(memo(ProgressBar));

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  percent: PropTypes.number.isRequired,
  largest: PropTypes.bool
};

ProgressBar.defaultProps = {
  largest: false
};
