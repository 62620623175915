import React from 'react';
import moment from 'moment';

import { graphType } from '../constant';

import { AreaChart, ColumnChart, ProgressChart } from '../charts';

// The edge case is when no traces are created yet
// 0 serves as a placeholder
export const formatDuration = d =>
  d === 0 ? 0 : moment.duration(d, 'seconds').humanize();

// default query date range params "last 7 days"
export const today = () => moment().utc().local();

export const oneWeekAgo = () => moment().utc().local().subtract(7, 'd');

/**
 * @function computeAspectRatio - aspect is used if we
 * have a large dataset with pagination
 * @param {number} rowsPerCard - width
 * @param {number} rowCount - height
 * @constant {object} design - design graph's size in order to keep the proportion of the graph relative to the dataset.(e.g: tabulation in barChart)
 * @constant {number} design.width - set to 547px
 * @constant {number} design.height - set to 390px
 */
const design = {
  width: 547,
  height: 390
};
export const computeAspectRatio = (rowsPerCard, rowCount) => {
  // if the height is greater than or equal to the width,
  // we'll let recharts use the prop `width` from <ResponsiveContainer/> to calculate the aspectRatio
  if (rowCount >= rowsPerCard) return undefined;

  const aspectRatio = design.width / design.height;

  // add a space splot for the X axis when assessing the ratio rowsPerCard/rowCount
  return aspectRatio.toFixed(2) * ((rowsPerCard + 1) / (rowCount + 1));
};

/**
 * @function mapCharts - its purpose is to map the data from the configuration and consume the dataset from the reporting-api
 * @param {object} widget
 * @param {string} widget.type - e.g area, bar, column, ...
 * @param {string} style - generates a specific style for a same chart
 * @param {Array} object.dataset - data consumed by the chart
 * @param {boolean} object.loading - Apollo loading prop
 * @returns - a chart
 */
export const mapCharts = (
  widget,
  style,
  { dataset, loading },
  renderBarChart
) => {
  let renderChart = null;

  switch (widget) {
    case graphType.AREA:
      // Number of traces per month from today's date to a year ago
      renderChart = (
        <AreaChart dataset={dataset.slice(0, 12)} loading={loading} />
      );
      break;

    case graphType.BAR:
      renderChart = renderBarChart(dataset, loading);
      break;

    case graphType.COLUMN:
      // Number of traces per month from today's date to a year ago
      renderChart = (
        <ColumnChart
          dataset={dataset.slice(0, 12)}
          loading={loading}
          subType={style || 'style1'}
        />
      );
      break;

    case graphType.PROGRESS:
      // Displays the top 5 contributing groups
      renderChart = (
        <ProgressChart dataset={dataset.slice(0, 5)} loading={loading} />
      );
      break;

    default:
      break;
  }
  return renderChart;
};
