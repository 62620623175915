import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { FieldSearchCompact, Pushbutton } from '@stratumn/atomic';
import { Add } from '@stratumn/icons';

import { pluralize } from 'utils';

import styles from './subHeader.style';

export const SubHeader = ({
  classes,
  workflow,
  searchGroups,
  updateSearch,
  openAddGroupModal
}) => {
  const {
    nonFilteredGroups: { totalCount: nbNonFilteredGroups } = {},
    groups: { totalCount: nbFilteredGroups } = {}
  } = workflow || {};

  let groupsCount = 'No groups added yet';
  if (nbNonFilteredGroups) {
    const totalGroupsCount = pluralize(
      nbNonFilteredGroups,
      'group',
      false,
      true
    );
    if (searchGroups) {
      groupsCount = `${nbFilteredGroups} of ${totalGroupsCount}`;
    } else {
      groupsCount = totalGroupsCount;
    }
  }

  return (
    <div className={classes.subHeader}>
      <div className={classes.subHeaderLeft}>
        <div className={classes.subHeaderTitle}>Groups</div>
        <div className={classes.searchInput}>
          <FieldSearchCompact
            label="Search Groups"
            name="search"
            value={searchGroups}
            onValueChange={updateSearch}
            maxWidth
            dataCy="groups-filter-input"
          />
        </div>
      </div>
      <div className={classes.subHeaderRight}>
        {workflow && <div className={classes.groupsCount}>{groupsCount}</div>}
        <Pushbutton
          primary
          onClick={openAddGroupModal}
          dataCy="add-group-button"
        >
          <div className={classes.addGroupLabel}>
            Add Group
            <Add className={classes.addGroupIcon} />
          </div>
        </Pushbutton>
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  workflow: PropTypes.object,
  searchGroups: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  openAddGroupModal: PropTypes.func.isRequired
};

SubHeader.defaultProps = {
  workflow: null
};

export default compose(injectSheet(styles), React.memo)(SubHeader);
