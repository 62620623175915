import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter, Link } from 'react-router-dom';

import { deepGet } from 'utils';

import { history } from 'components/root';
import { ROUTE_WORKFLOW_DASHBOARD } from 'constant/routes';
import { ENABLE_ANALYTICS_LINK } from 'constant/featureFlags';

import {
  Header as AtomicHeader,
  HeaderSection,
  HeaderSectionItem,
  PushtextHref
} from '@stratumn/atomic';
import Ellipsis from 'components/ui/ellipsis';
import ProfileButton from 'components/ui/profileButton';

import BottomLink from './bottomLink';

import styles from './header.styles';

/**
 * Header Component
 */
export const Header = React.memo(props => {
  const {
    classes,
    config: { fullLogo, loading, topLevel, bottomLevel, environment }
  } = props;

  const displayEnvironment = environment !== 'release' && environment;

  const goTo = path => path && history.push(path);

  /**
   * @Constant renderTopLevelLinks
   *
   * link.option is a temporary field to render the hidden link
   * to the demo "Analytics" page.
   * We execute **inWhiteList** so we render this link only
   * in the whitelisted environment.
   *
   * @returns the links in the top-right header
   */
  const renderTopLevelLinks = useMemo(() => {
    if (!topLevel) return null;

    if (!topLevel.links)
      return (
        <HeaderSectionItem>
          <ProfileButton avatarSize={40} />
        </HeaderSectionItem>
      );

    return topLevel.links.map((link, index) => {
      if (!link) return null;

      return (
        <HeaderSectionItem key={link.label}>
          <div className={classes.linkWrapper}>
            {!link.option ? (
              <div className={classes.link} data-is-active={link.active}>
                <PushtextHref
                  href={link.path || ''}
                  disabled={link.active}
                  suffix={
                    link.label === 'analytics' && (
                      <div className={classes.betaLogoWrapper}>
                        <span className={classes.betaLogoLabel}>beta</span>
                      </div>
                    )
                  }
                >
                  {link.label}
                </PushtextHref>
              </div>
            ) : (
              // Show the analytics link according to the environment
              ENABLE_ANALYTICS_LINK &&
              link.label && (
                <div className={classes.link} data-is-active={link.active}>
                  <PushtextHref disabled={link.active} href={link.path}>
                    {link.label}
                  </PushtextHref>
                </div>
              )
            )}
            {topLevel.links.length - 1 === index && (
              <ProfileButton avatarSize={bottomLevel ? 25 : 40} />
            )}
          </div>
        </HeaderSectionItem>
      );
    });
  }, [topLevel]);

  /**
   * @Constant renderBottomLevelLeft
   * @returns the links in the sub-header left side
   * e.g newLink page
   */
  const renderBottomLevelLeft = useMemo(() => {
    if (!deepGet(bottomLevel, 'infoContext.links')) return null;

    /**
     * If there are no links enabled, we do not want to apply the arrowSeparator while still applying the barSeparator.
     */
    const hasEnabledLinks =
      !loading &&
      bottomLevel.infoContext.links.filter(link => link.path).length > 0;

    return (
      !loading &&
      bottomLevel.infoContext.links.map(link => {
        // Some groups.nodes.owner.team.name are missing (e.g:  AXA Global Re)
        if (!link.label) return null;

        // Check if it's an active link
        if (link.path) {
          return (
            <React.Fragment key={link.label}>
              <Link
                className={classes.breadcrumb}
                data-is-disabled={false}
                to={link.path}
              >
                <Ellipsis maxWidth="300px">{link.label}</Ellipsis>
              </Link>
              <span className={classes.arrowSeparator}>&#8594;</span>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={link.label}>
            <div
              className={classes.breadcrumb}
              data-is-disabled
              data-has-enabledlinks={hasEnabledLinks}
            >
              {link.label}
            </div>
          </React.Fragment>
        );
      })
    );
  }, [!loading && deepGet(bottomLevel, 'infoContext.links')]);

  /**
   * @Constant renderActions
   * @returns the links in the sub-header right side
   */
  const renderActions = useMemo(() => {
    if (!bottomLevel || !bottomLevel.actions) return null;

    const { viewToggle, links } = bottomLevel.actions;

    // Toggle views between 2 or more items
    // e.g: switch between overview and kanban
    const displayViewToggle = viewToggle && (
      <div className={classes.viewToggleWrapper}>
        <span className={classes.actionsLabel}>View Type:</span>
        <div className={classes.viewToggle}>
          {viewToggle.map(item => (
            <React.Fragment key={item.label}>
              <BottomLink
                label={item.label}
                path={item.path}
                active={item.active}
                type="viewToggle"
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    );

    const displayLinks =
      links &&
      links.map(
        link =>
          link && (
            <BottomLink
              key={link.label}
              label={link.label}
              onClick={link.onClick}
              type="standard"
              icon={link.icon}
            />
          )
      );

    return (
      <div className={classes.actionsWrapper}>
        {displayViewToggle}
        {displayLinks}
      </div>
    );
  }, [bottomLevel && bottomLevel.actions]);

  /**
   * @constant - atomicHeaderProps
   * @returns - The atomic Header component props
   */
  const atomicHeaderProps = {
    'data-cy': 'return-home',
    onHomeClick: () => goTo(ROUTE_WORKFLOW_DASHBOARD),
    fullLogo,
    loading,
    subheaderPrimary:
      (bottomLevel && bottomLevel.infoContext && renderBottomLevelLeft) || null,
    subheaderSecondary:
      (bottomLevel && bottomLevel.workflowPage && renderActions) || null
  };

  let headerEllipsis;
  if (topLevel && topLevel.title && topLevel.title.label) {
    headerEllipsis = displayEnvironment ? (
      <Ellipsis maxWidth="450px">
        <span
          style={{
            maxWidth: '300px',
            display: 'block',
            float: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {topLevel.title.label}
        </span>{' '}
        <span className={classes.environmentStyle}>
          {environment} environment
        </span>
      </Ellipsis>
    ) : (
      <Ellipsis maxWidth="300px">{topLevel.title.label} </Ellipsis>
    );
  }

  return (
    <AtomicHeader {...atomicHeaderProps}>
      {!loading && deepGet(topLevel, 'title.label') && (
        <HeaderSection level="primary">
          {topLevel.title.path ? (
            <Link
              className={classes.title}
              to={topLevel.title.path}
              data-is-link
            >
              {headerEllipsis}
            </Link>
          ) : (
            <h1 className={classes.title} data-is-link={false}>
              {headerEllipsis}
            </h1>
          )}
        </HeaderSection>
      )}

      <HeaderSection level="secondary">
        {topLevel && topLevel.notifications && (
          <HeaderSectionItem>{topLevel.notifications}</HeaderSectionItem>
        )}
        {topLevel && renderTopLevelLinks}
      </HeaderSection>
    </AtomicHeader>
  );
});

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.shape({
    fullLogo: PropTypes.bool,
    loading: PropTypes.bool,
    environment: PropTypes.string,
    topLevel: PropTypes.shape({
      title: PropTypes.shape({
        label: PropTypes.node,
        path: PropTypes.string
      }),
      notifications: PropTypes.node,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          path: PropTypes.string,
          active: PropTypes.boolean,
          option: PropTypes.string
        })
      )
    }),
    bottomLevel: PropTypes.shape({
      workflowPage: PropTypes.bool,
      infoContext: PropTypes.shape({
        links: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string
          })
        )
      }),
      actions: PropTypes.shape({
        viewToggle: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
            active: PropTypes.bool
          })
        ),
        links: PropTypes.arrayOf(
          PropTypes.shape({
            icon: PropTypes.node,
            label: PropTypes.string,
            onClick: PropTypes.func
          })
        )
      })
    })
  })
};

Header.defaultProps = {
  config: {
    fullLogo: false,
    loading: false,
    topLevel: null,
    bottomLevel: null,
    actions: null,
    environment: null
  }
};

export default withRouter(injectSheet(styles)(Header));
