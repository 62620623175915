import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { DownArrowFill } from '@stratumn/icons';

import { useToggle } from 'utils/hooks';

import ParticipantCard from './participantCard';

import styles from './participantsList.style';

export const ParticipantsList = ({
  classes,
  title,
  participants,
  teamAccountId,
  removeGroupMembers
}) => {
  const [displayParticipants, toggleDisplayParticipants] = useToggle(true);

  const removeAll = useCallback(() => {
    removeGroupMembers(
      teamAccountId
        ? [teamAccountId]
        : participants.map(participant => participant.accountId),
      title
    );
  }, [participants, teamAccountId, removeGroupMembers]);

  if (!participants.length) return null;

  return (
    <div className={classes.list}>
      <div className={classes.listHeader}>
        <div
          className={classes.listTitle}
          onClick={toggleDisplayParticipants}
          data-cy="list-toggle"
        >
          {`${title} (${participants.length})`}
          <DownArrowFill
            className={classes.displayListIcon}
            data-is-up={!displayParticipants}
          />
        </div>
        <div
          className={classes.removeAllButton}
          onClick={removeAll}
          data-cy="remove-all-button"
        >
          {`Remove ${teamAccountId ? 'team' : 'all'}`}
        </div>
      </div>
      {displayParticipants && (
        <div className={classes.participants}>
          {participants.map(
            ({ accountId: participantAccountId, entity: participant }) => (
              <div key={participant.rowId}>
                <ParticipantCard
                  participant={participant}
                  participantAccountId={participantAccountId} // note: if within a team, the participant has an account id of null and hence will not be removable
                  removeGroupMembers={removeGroupMembers}
                />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

ParticipantsList.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  participants: PropTypes.arrayOf(PropTypes.object),
  teamAccountId: PropTypes.string,
  removeGroupMembers: PropTypes.func.isRequired
};

ParticipantsList.defaultProps = {
  title: null,
  participants: {},
  teamAccountId: null
};

export default compose(injectSheet(styles), React.memo)(ParticipantsList);
