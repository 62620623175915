const dominantBaseline = 'middle';

export default theme => ({
  averageTime: {
    dominantBaseline: dominantBaseline,
    textAnchor: 'end',
    fontWeight: 700,
    fontSize: 10,
    fill: theme.grey2
  },
  value: { dominantBaseline: dominantBaseline, fill: theme.grey1, fontSize: 11 }
});
