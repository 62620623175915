import darkBackground from 'assets/kpi.card.dark.pattern.svg';
import lightBackground from 'assets/kpi.card.light.pattern.svg';

export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: '18px 14px',
    backgroundImage: `url(${darkBackground})`,
    backgroundPosition: '108% 0',
    backgroundRepeat: 'no-repeat',
    '&[data-is-dynamic=false]&[data-is-light="false"]': {
      backgroundImage: 'linear-gradient(90deg, #30CECA 0%, #6BEDD8 100%)',
      color: theme.white1,
      '&::after': {
        backgroundImage: `url(${lightBackground})`,
        backgroundPosition: '108% 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    '&[data-is-dynamic=true]': {
      '& > svg': {
        position: 'relative',
        flexShrink: 0,
        width: 20,
        height: 20,
        left: 0,
        transition: 'left 250ms'
      }
    },
    '&:hover': {
      '& > svg': {
        '&[data-is-dynamic=true]': {
          left: 5
        }
      }
    }
  },
  details: {
    flexGrow: 1,
    paddingTop: 8,
    '&[data-large-icon="true"]': {
      marginLeft: 15
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 12
  },
  tooltipWrapper: {
    display: 'inline-flex',
    zIndex: 2,
    '& > svg': {
      width: 17,
      height: 17
    }
  },
  title: {
    fontWeight: 700,
    fontSize: 10,
    lineHeight: '13px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    paddingRight: 6
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'baseline'
  },
  value: {
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '51px',
    paddingTop: 2
  },
  timeWrapper: {
    paddingLeft: 10
  },
  fluctuationWrapper: {
    paddingLeft: 10
  },
  fluctuation: {
    display: 'flex',
    alignItems: 'baseline',
    color: theme.warningRed,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    '&[data-is-upward="true"]': {
      color: theme.validGreen
    }
  },
  arrowSvg: {
    display: 'flex',
    alignSelf: 'end',
    bottom: 2,
    position: 'relative',
    '& > svg': {
      height: 18,
      width: 18,
      color: theme.warningRed,
      '&[data-is-upward="true"]': {
        color: theme.validGreen,
        transform: 'rotate(-180deg)'
      }
    }
  }
});
