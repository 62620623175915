export default theme => ({
  windowRoot: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: 10,
    background: theme.white1,
    boxShadow: theme.indigoShadowHover,
    borderRadius: 5,
    width: '100%',
    transition: 'boxShadow 0.3s ease-in-out',
    '&[data-is-collapse="true"]': {
      boxShadow: theme.indigoShadow1,
      transition: '0.3s',
      '&:hover': {
        boxShadow: theme.indigoShadowHover
      }
    }
  },
  windowTitleWrapper: {
    padding: '7.5px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    cursor: props => (props.view.collapsable ? 'pointer' : 'unset')
  },
  windowTitle: {
    paddingLeft: '7.5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: 600,
    color: theme.grey1,
    flexGrow: 1,
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  windowInfo: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '10px'
  },
  icon: {
    color: theme.indigo2,
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-collapse="true"]': {
      transform: 'rotate(-180deg)',
      color: theme.grey1
    }
  },
  windowItemCount: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: theme.grey4
  },
  itemsWrapper: {
    borderTop: `1px solid ${theme.grey7}`,
    padding: '20px 15px 15px 15px',
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '20px'
  },
  itemWidget: {
    '&:empty': {
      display: 'none'
    },
    '&[data-is-hoverable="true"]': {
      padding: 10,
      cursor: 'pointer',
      backgroundColor: theme.grey9,
      borderRadius: 2,
      border: `1px solid ${theme.greyTable}`,
      transition: 'border-color 250ms',
      '&:hover': {
        borderColor: theme.indigo2
      },
      '&:active': {
        backgroundColor: theme.grey8
      }
    }
  }
});
