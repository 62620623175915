import NumberView from './number';
import ProgressView from './progress';
import TextView from './text';
import LinkView from './link';
import LabelsView from './labels';
import DateView from './date';
import ActivityView from './activity';
import IconView from './icon';
import ProseView from './prose';
import ListView from './list';
import HTMLView from './html';
import CodeView from './code';
import WindowView from './window';
import KeyValueView from './keyValue';
import BoxView from './box';
import ArrayView from './array';
import FileView from './file';
import CommentView from './comment';
import TableView from './table';
import DataTabView from './dataTab';
import FileCompactView from './fileCompact';
import AvatarView from './avatar';
import ListCompactView from './listCompact';
import BooleanView from './boolean';
import PdfViewer from './pdfViewer';

/* views map */
// views are plain object literals that can provide access to
// 1 - component: the component to display
// 2 - getSortConfig: a default sorting configuration
// 3 - getFilterConfig: a default filtering configuration
// 4 - getDefaultWidth: a default width, for example for table columns sizing
// 5 - getStringifyFunction: a function to report how the view exports itself to string
// 6 - getEditedPath: a function to report if and which path can be edited in this view
const viewsMap = {
  number: NumberView,
  progress: ProgressView,
  text: TextView,
  link: LinkView,
  labels: LabelsView,
  date: DateView,
  activity: ActivityView,
  icon: IconView,
  prose: ProseView,
  list: ListView,
  html: HTMLView,
  code: CodeView,
  window: WindowView,
  keyValue: KeyValueView,
  box: BoxView,
  array: ArrayView,
  file: FileView,
  comment: CommentView,
  table: TableView,
  dataTab: DataTabView,
  fileCompact: FileCompactView,
  avatar: AvatarView,
  listCompact: ListCompactView,
  boolean: BooleanView,
  pdfViewer: PdfViewer
};

// get the relevant view
export const getView = view => viewsMap[view.type] || TextView;
