export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.03em',
    userSelect: 'none'
  },
  numbers: {
    width: 48,
    textAlign: 'center'
  },
  arrow: {
    '& > svg': {
      width: 15,
      height: 15,
      display: 'flex'
    },
    '& :hover': {
      cursor: 'pointer'
    },
    '&[data-is-disabled="true"]': {
      color: theme.grey5,
      '& :hover': {
        cursor: 'unset'
      }
    }
  }
});
