export default theme => ({
  tooltipWrapper: {
    width: '100%',
    height: '100%'
  },
  tooltipWrapperContent: {
    minWidth: 60,
    maxWidth: 440,
    padding: '10px 20px',
    backgroundColor: theme.white1,
    borderRadius: 5,
    boxShadow: theme.indigoShadow2
  },
  tooltipWrapperTitle: {
    fontSize: 14,
    fontWeight: 600,
    borderBottom: `1px solid ${theme.grey5}`,
    marginBottom: 5
  },
  tooltipWrapperBody: {
    fontSize: 13,
    lineHeight: '20px',
    textAlign: 'justify'
  },
  tooltipWrapperArrowUp: {
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `6px solid ${theme.white1}`
  }
});
