export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  kpiCardsWrapper: {
    display: 'grid',
    gridTemplateColumns: ({
      config: {
        kpis: {
          wrapperStyle: { gridTemplateColumns }
        }
      }
    }) => gridTemplateColumns || 'none',
    gridGap: '20px',
    height: ({
      config: {
        kpis: {
          wrapperStyle: { height }
        }
      }
    }) => height || '100%',
    width: '100%'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    marginTop: 10,
    padding: '8px 14px 14px 14px',
    backgroundColor: theme.white1,
    borderRadius: 5
  },
  filtersWrapper: {
    marginBottom: 8
  },
  graphGridWrapper: {
    display: 'grid',
    gridTemplateColumns: ({
      config: {
        graphs: {
          wrapperStyle: { gridTemplateColumns }
        }
      }
    }) => gridTemplateColumns || 'none',
    gridTemplateRows: ({
      config: {
        graphs: {
          wrapperStyle: { gridTemplateRows }
        }
      }
    }) => gridTemplateRows || 'none',
    gridGap: '10px',
    height: ({
      config: {
        graphs: {
          wrapperStyle: { height }
        }
      }
    }) => height || '100%',
    width: '100%',
    borderRadius: 5,
    backgroundColor: theme.white1
  }
});
