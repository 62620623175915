export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    minHeight: 170,
    marginTop: 14,
    '&[data-is-report=true]': {
      minHeight: 'auto'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '48%',
    '&[data-is-report=true]': {
      width: '100%'
    }
  },
  bar: {
    marginTop: 3
  },
  placeholder: {
    fontSize: 11,
    lineHeight: '14px',
    color: theme.grey1
  }
});
