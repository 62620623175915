export default theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gridGap: '10px',
    gridTemplateRows: 'repeat(9, 10.2%)',
    '& > div': {
      boxShadow: '0px 1px 15px #E5E3EBB3',
      borderRadius: '5px'
    },
    '& h1': {
      fontSize: 20,
      fontWeight: 700,
      color: theme.grey1,
      padding: 30,
      height: '30%',
      '&::after': {
        content: '""',
        position: 'relative',
        display: 'block',
        height: 7,
        width: 30,
        top: 20,
        backgroundColor: theme.teal1,
        borderRadius: '2px'
      }
    }
  },
  chart: {
    position: 'relative',
    height: '70%',
    border: `30px solid ${theme.white1}`,
    borderTop: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.white1
  },
  generalDataOI: {
    gridColumn: '1 / 11',
    gridRow: '1 / 2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: 90
  },
  generalDataDivOI: {
    width: '33%',
    padding: '0 20px',
    float: 'left',
    borderRight: `2px solid ${theme.grey7}`,
    '&:last-child': {
      borderRight: 'none'
    },
    '& h2': {
      fontSize: '26px',
      letterSpacing: 1.6,
      fontWeight: 600,
      lineHeight: '2.5vw'
    },
    '& h6': {
      fontSize: '10px',
      letterSpacing: 1.1,
      textTransform: 'uppercase'
    }
  },
  stepsOI: {
    gridColumn: '1 / 6',
    gridRow: '2 / 6'
  },
  delaysLetterSentOI: {
    gridColumn: '6 / 11',
    gridRow: '2 / 6'
  },
  delaysInvestigationSentOI: {
    gridColumn: '1 / 4',
    gridRow: '6 / 10'
  },
  fillingRateInvestigatorOI: {
    gridColumn: '4 / 6',
    gridRow: '6 / 10'
  },
  delaysFromCreationInvestigationOI: {
    gridColumn: '6 / 11',
    gridRow: '6 / 10'
  }
});
