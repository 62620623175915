import React from 'react';
import PropType from 'prop-types';
import { compose, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';

import {
  ROUTE_WORKFLOW_BOARD,
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_USER_DASHBOARD
} from 'constant/routes';
import { withError } from 'components/errorBoundary';

import { Analytics, ClockGeneral, Download } from '@stratumn/icons';

import { Header } from 'components/layouts';
import {
  LayoutKanban,
  LayoutKanbanMain,
  Tabs,
  Pushbutton
} from '@stratumn/atomic';

import envVars from 'constant/env';

import GlobalActivityHistory from './pages/page1';
import OpenInvestigations from './pages/page2';

import styles from './dashboard.style';

const tabsDashboard = [
  {
    prefix: <ClockGeneral style={{ height: '25px', marginTop: '5px' }} />,
    label: 'Global activity history',
    component: <GlobalActivityHistory />
  },
  {
    prefix: <Analytics style={{ height: '25px', marginTop: '5px' }} />,
    label: 'Open investigations',
    component: <OpenInvestigations />
  }
];

const renderMain = props => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.containerHolder}>
        <Tabs tabs={tabsDashboard} folder slider={false} />
      </div>
    </div>
  );
};

renderMain.propTypes = {
  classes: PropType.object.isRequired
};

/* download buttons */

const downloadButtons = [
  {
    title: 'Download PDF',
    fn: () => {
      console.log('downloaded PDF');
    }
  },
  {
    title: 'Download CSV',
    fn: () => {
      console.log('downloaded CSV');
    }
  }
];

/* data dashboard global */
export const DataDashboard = props => {
  const {
    classes,
    kpiQuery: { loading, workflowByRowId: workflow },
    errorContext: { handleError }
  } = props;

  const configHeader = {
    loading,
    environment: envVars.REACT_APP_ENVIRONMENT,
    topLevel: {
      title: {
        label: !loading ? workflow.name : null
      },
      links: [
        {
          label: 'overview',
          path: !loading
            ? ROUTE_WORKFLOW_OVERVIEW.replace(':id', workflow.rowId)
            : null
        },
        {
          label: 'kanban',
          path: !loading
            ? ROUTE_WORKFLOW_BOARD.replace(':id', workflow.rowId)
            : null
        },
        {
          label: 'dashboard',
          path: ROUTE_USER_DASHBOARD
        }
      ]
    }
  };

  if (envVars.REACT_APP_ENVIRONMENT === 'release') {
    handleError();
    return null;
  }

  return (
    <LayoutKanban>
      <Header config={configHeader} />

      <LayoutKanbanMain loading={loading} greyTheme>
        {!loading && renderMain(props)}
        <div className={classes.downloadData}>
          {downloadButtons.map((el, i) => (
            <Pushbutton
              sexyShadow
              onClick={el.fn}
              key={i}
              prefix={
                <Download style={{ display: 'block', width: 20, height: 20 }} />
              }
            >
              {el.title}
            </Pushbutton>
          ))}
        </div>
      </LayoutKanbanMain>
    </LayoutKanban>
  );
};

DataDashboard.propTypes = {
  classes: PropType.object.isRequired,
  kpiQuery: PropType.object.isRequired,
  errorContext: PropType.object
};

DataDashboard.defaultProps = {
  errorContext: null
};

export const queries = {
  kpiQuery: gql`
    query kpiQuery($workflowId: BigInt!) {
      workflowByRowId(rowId: $workflowId) {
        name
        rowId
        traces {
          nodes {
            rowId
            links {
              nodes {
                data
                createdAt
                actionKey
                linkHash
              }
            }
            state {
              rowId
              data
            }
          }
        }
      }
    }
  `
};

export default compose(
  graphql(queries.kpiQuery, {
    name: 'kpiQuery',
    options: ({ match }) => ({
      variables: { workflowId: match.params.id }
    })
  }),
  injectSheet(styles),
  withError
)(DataDashboard);
