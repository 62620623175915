import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import moment from 'moment';

import Segment from '../segment';
import styles from './segmentList.style';

class SegmentList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    activeSegment: PropTypes.object.isRequired,
    pulldown: PropTypes.element.isRequired,
    links: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    links: []
  };

  interval = (seg1, seg2) => {
    const time2 = moment.utc(seg2.createdAt).local();
    const time1 = moment.utc(seg1.createdAt).local();
    return moment.duration(time2.diff(time1)).humanize();
  };

  centerActiveItem = () => {
    if (!this.activeItemRef) return;

    const {
      left: activeItemLeft,
      width: activeItemWidth
    } = this.activeItemRef.getBoundingClientRect();
    const { width: listWidth } = this.listRef.getBoundingClientRect();
    const distanceOverCentre =
      activeItemLeft + activeItemWidth - 63 - listWidth / 2;

    if (distanceOverCentre > 0) {
      this.listRef.scrollLeft = distanceOverCentre;
    }
  };

  componentDidMount = () => {
    this.centerActiveItem();
  };

  render = () => {
    const { links, onClick, activeSegment, pulldown, classes } = this.props;

    return (
      <div className={classes.root}>
        <div
          className={classes.segments}
          ref={e => {
            this.listRef = e;
          }}
        >
          {links
            .slice(0)
            .reverse()
            .map((link, i) => {
              const reverseIndex = links.length + 1 - i;
              const isActive = activeSegment.linkHash === link.linkHash;
              const isLast = i === links.length - 1;
              const interval = i
                ? this.interval(links[reverseIndex - 1], link)
                : null;
              return (
                <div
                  className={classes.segmentItem}
                  key={link.linkHash}
                  ref={e => {
                    if (isActive) this.activeItemRef = e;
                  }}
                  data-active-index={i}
                >
                  <Segment
                    onClick={onClick}
                    isActive={isActive}
                    link={link}
                    interval={interval}
                    isFirst={!i}
                    isLast={isLast}
                  />
                </div>
              );
            })}
          {pulldown}
        </div>
      </div>
    );
  };
}

export default injectSheet(styles)(SegmentList);
