import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { TYPE_REPORT } from '../../constant';

import { Label, Placeholder } from '../ui';
import Bar from './bar';
import ProgressChartSkeleton from './skeleton';

import styles from './progress.style';

// The largest progress bar which will inherit a different color
const largest = dataset => Math.max(...dataset.map(o => o.percent));

export const ProgressChart = ({ classes, dataset, type, loading }) => {
  const renderNotEnoughData = useMemo(() => {
    if (dataset.length >= 10 || type !== TYPE_REPORT) return null;
    return <p className={classes.placeholder}>No more data to display.</p>;
  }, [dataset, type]);

  /**
   * calculate the inline-style "height" dynamically in order
   * to keep the same spacing between items (whether we have 1 or 10 items).
   * we should not get more than "10" items for now but as a safe guard we set 100%.
   */
  const height = useMemo(
    () => `${dataset.length < 10 ? dataset.length * 10 : 100}%`,
    [dataset]
  );

  if (loading) return <ProgressChartSkeleton />;
  if (dataset.length === 0) return <Placeholder />;

  return (
    <>
      <ul
        style={{ height }}
        className={classes.root}
        data-is-report={type === TYPE_REPORT}
      >
        {dataset.map(e => (
          <li
            key={e.rowId}
            className={classes.wrapper}
            data-is-report={type === TYPE_REPORT}
          >
            <Label avatar={e.avatar} name={e.name} value={e.percent} />
            <div className={classes.bar}>
              <Bar
                percent={e.percent}
                largest={e.percent === largest(dataset)}
              />
            </div>
          </li>
        ))}
      </ul>
      {renderNotEnoughData}
    </>
  );
};

export default injectSheet(styles)(memo(ProgressChart));

ProgressChart.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.any,
      name: PropTypes.string,
      avatar: PropTypes.string,
      percent: PropTypes.number
    })
  )
};

ProgressChart.defaultProps = {
  type: '',
  dataset: []
};
