export default theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  text: {
    fontSize: 14,
    lineHeight: '15px',
    textAlign: 'center',
    letterSpacing: '0.03em',
    color: theme.grey3
  }
});
