import { theme } from '@stratumn/atomic';
import globalSize from './globalSize';
import scrollbar from './scrollbar';
import mediaQueries from './mediaQueries';
import customTheme from './customTheme';

export default {
  ...theme,
  ...customTheme,
  globalSize,
  mediaQueries,
  scrollbar: scrollbar(theme)
};
