import React, { memo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { Label, Placeholder } from '../ui';

import styles from './list.style';

export const ListChart = ({ classes, dataset, loading }) => {
  if (loading) return <span>TODO</span>;
  if (dataset.length === 0) return <Placeholder />;

  return (
    <ul className={classes.root}>
      {dataset.map(e => (
        <li key={e.rowId} className={classes.wrapper}>
          <Label avatar={e.avatar} name={e.name} value={e.count} type="list" />
        </li>
      ))}
    </ul>
  );
};

export default injectSheet(styles)(memo(ListChart));

ListChart.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.string,
      count: PropTypes.number
    })
  )
};

ListChart.defaultProps = {
  dataset: []
};
